import { Stack } from "@mui/material";
import TextField from '@mui/material/TextField';
import "./input.css";
function Input(props) {
  const {
    value,
    placeholder,
    type,
    id,
    onChange,
    color,
    required,
    disabled,
    className,
    width,
    label,
    style,
    maxLength,
    minLength,
    defaultValue,
    rest_stack_styles
  } = props;
  return (
    <Stack style={{ ...rest_stack_styles,padding:2 }}>
      <TextField
        defaultValue={defaultValue}
        type={type}
        width={width}
        id={id}
        placeholder={placeholder}
        className={className ? "inputStyle " + className : "inputStyle"}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        sx={{ ontSize: "18px", color: "gray", outline:"none",bgcolor:"#f9f9f9",border:"none",borderRadius:"15px",...style }}
        label={label}
      />
    </Stack>
  );
}
export default Input;
