import { CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderShadeImage from "../../component/HeaderShadeImage/HeaderShadeImage";
import InputField from "../../component/InputField/InputField";
import Reviews from "../../component/Reviews/Reviews";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { check_auth, update_profile_verify_async } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { setIdleUpdateProfileStatus } from "../../store/slices/user_auth_slice";

const Settings = () => {
  const dispatch = useDispatch();
  const { user, update_profile_status } = useSelector((state) => state.userAuth);

  const [editProfile, setEditProfile] = useState(false);

  // Form data state
  const [formData, setFormData] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    phonenumber: user?.phonenumber || "",
    avatar: user?.avatar || ""
  });

  // State for checkboxes
  const [mobileNotifications, setMobileNotifications] = useState(user?.number_notifications || false);
  const [emailNotifications, setEmailNotifications] = useState(user?.email_notifications || false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle checkbox clicks
  const handleMobileNotifications = () => {
    setMobileNotifications(!mobileNotifications);
  };

  const handleEmailNotifications = () => {
    setEmailNotifications(!emailNotifications);
  };

  // Handle edit profile click
  const handleEditHandle = () => {
    setEditProfile(true);
    // Initialize notification settings when entering edit mode
    setMobileNotifications(user?.number_notifications || false);
    setEmailNotifications(user?.email_notifications || false);
  };

  const ImageUrlhandle = (elem) => {
    setFormData({ ...formData, avatar: elem }); 

  }

  // Handle form submit
  const handleSubmit = () => {
    const obj = {
      ...formData,
      email_notifications: emailNotifications,
      number_notifications: mobileNotifications
    };
    dispatch(update_profile_verify_async(obj));
    console.log(obj);
  };

  useEffect(() => {
    if (update_profile_status === asyncStatus.SUCCEEDED) {
      setEditProfile(false); // Close edit mode after successful update
      dispatch(check_auth()); // Refresh user details
      dispatch(setIdleUpdateProfileStatus()); // Reset status
    }
  }, [update_profile_status, dispatch]);

  return (
    <Stack bgcolor={"#FAFAFA"} margin={0} padding={0}>
      <HeaderShadeImage
        btnLabel={"Edit Profile"}
        rateNumber={"0"}
        onClick={handleEditHandle}
        editProfile={editProfile}
        ImageUrlhandle={ImageUrlhandle}
      />
      <Stack ml={7} mt={2}>
        {!editProfile && (
          <Grid container spacing={2} alignItems={"self-start"}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <InputField value={formData.email} disabled={true} />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <InputField value={formData.phonenumber} disabled={true} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              marginTop={{ xl: -5, lg: -5, md: -5, sm: 1, xs: 1 }}
            >
              <Reviews />
            </Grid>
          </Grid>
        )}
        {editProfile && (
          <Stack>
            <Stack className="contact-information">CONTACT INFORMATION</Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField
                  value={formData.first_name}
                  onChange={handleInputChange}
                  name="first_name"
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField
                  value={formData.last_name}
                  onChange={handleInputChange}
                  name="last_name"
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField value={formData.email} disabled={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField
                  value={formData.phonenumber}
                  onChange={handleInputChange}
                  name="phonenumber"
                  disabled={false}
                />
              </Grid>

              {/* Mobile Notifications */}
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Stack>
                  <Stack className="contact-information">
                    MOBILE NOTIFICATIONS
                  </Stack>
                  <div
                    className="checkbox-item"
                    onClick={handleMobileNotifications}
                  >
                    <div
                      className={`checkbox ${mobileNotifications ? "checked" : ""}`}
                    ></div>
                    <label className="check-box-label">
                      Enable text message notifications
                    </label>
                  </div>
                </Stack>
              </Grid>

              {/* Email Notifications */}
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Stack>
                  <Stack className="contact-information">
                    EMAIL NOTIFICATIONS
                  </Stack>
                  <div
                    className="checkbox-item"
                    onClick={handleEmailNotifications}
                  >
                    <div
                      className={`checkbox ${emailNotifications ? "checked" : ""}`}
                    ></div>
                    <label className="check-box-label">
                      Promotions and announcements
                    </label>
                  </div>
                </Stack>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5} mt={4}>
                <Stack flexDirection={"row"} alignItems={"center"} gap={4}>
                  <Stack>

                    <CustomButton
                      onClick={handleSubmit}
                      children={
                        update_profile_status === asyncStatus.LOADING ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Update Profile"
                        )
                      }
                    />
                  </Stack>
                  <Stack>
                    <CustomButton
                      onClick={() => setEditProfile(false)}
                      children={
                        "Cancel"
                      }
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Settings;
