// import React, { useState } from 'react';
// import { Avatar, IconButton, Tooltip } from '@mui/material';
// import './CustomMenu.css'; // Import your custom CSS for styling if needed
// import { useNavigate } from 'react-router-dom';
// import { success_toast_message } from '../../../utils/toast_message';
// import { useSelector } from 'react-redux';

// const ProfileMenu = () => {
//     const navigate = useNavigate()
//     const [open, setOpen] = useState(false);

//     const handleToggle = () => {
//         setOpen(!open);
//     };
//     const { user } = useSelector((state) => state.userAuth);

//     const handleClose = () => {
//         setOpen(false);
//         navigate('/')
//         success_toast_message("Logout Successfully")
//         window.location.reload()
//         localStorage.removeItem("auth");
//     };

//     return (
//         <div className="custom-menu">
//             <Tooltip title="Account settings">
//                 <IconButton onClick={handleToggle}>
//                     <Avatar>{user?.first_name?.slice(0, 1)}</Avatar>
//                 </IconButton>
//             </Tooltip>

//             {open && (
//                 <div className="menu-items">
//                     <button className="menu-item" onClick={() => navigate("/dashboard-home")}>
//                         Dashboard
//                     </button>
//                     <button className="menu-item" onClick={handleClose}>
//                         Logout
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default ProfileMenu;
import React, { useEffect, useRef, useState } from "react";
import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import "./CustomMenu.css";
import { useNavigate } from "react-router-dom";
import { success_toast_message } from "../../../utils/toast_message";
import { useSelector } from "react-redux";
import { imgUrl } from "../../../utils/imageUrl";
import CustomModal from "../CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import ButtonComp from "./../ButtonComp";

const ProfileMenu = () => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const menuRef = useRef(null); // Reference to the menu

    const handleToggle = () => {
        setOpen(!open);
    };
    const { user, get_user_profile_data } = useSelector(
        (state) => state.userAuth
    );

    const _handleClose = () => {
        setOpen(false);
        navigate("/");
        success_toast_message("Logout Successfully");
        window.location.reload();
        localStorage.removeItem("auth");
    };

    // Close menu when clicking outside of it
    useEffect(() => {
        const _handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", _handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", _handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-menu" ref={menuRef}>
            <Tooltip title="Account settings">
                <IconButton onClick={handleToggle}>
                    <Avatar src={`${user?.avatar}`}>
                        {user?.first_name?.slice(0, 1)}
                    </Avatar>
                </IconButton>
            </Tooltip>

            {open && (
                <div className="menu-items">
                    <button className="menu-item" onClick={() => navigate("/dashboard-home")}>
                        Dashboard
                    </button>
                    <button
                        className="menu-item"
                        onClick={() => setOpenModal(!openModal)}
                    >
                        Sign Out
                    </button>
                </div>
            )}

            <CustomModal
                isOpen={openModal}
                setIsOpen={() => setOpenModal(!openModal)}
            >
                <Stack
                    sx={{
                        overflow: "auto",
                        backgroundColor: "white",
                        borderRadius: "10px",
                    }}
                    alignItems={"center"}
                    p={1}
                >
                    <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
                        <IconButton onClick={() => setOpenModal(!openModal)}>
                            <RxCross2 size={20} sx={{ color: "black" }} />
                        </IconButton>
                    </Stack>

                    <Stack gap={2} px={1} sx={{ width: "100%" }}>
                        <Typography
                            sx={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "24px",
                            }}
                        >
                            Are you sure you want to logout?
                        </Typography>

                        <Stack
                            gap={2}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <ButtonComp
                                onClick={_handleClose}
                                label={"Yes"}
                                style={{ width: "auto", height: "auto", borderRadius: "15px" }}
                            />
                            <ButtonComp
                                onClick={() => setOpenModal(!openModal)}
                                label={"No"}
                                style={{ width: "auto", height: "auto", borderRadius: "15px" }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </CustomModal>
        </div>
    );
};

export default ProfileMenu;