import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { hostRoutes, userRoutes } from "../../utils/routeList";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import { setAuthState, setIdleKycStatus, setIdleOtpStatus, setIdleRegisterStatus, setIdleStatus } from "../../store/slices/user_auth_slice";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { check_auth } from "../../services/authentication";
import { PublicRoutes } from "./PublicRoutes/PublicRoutes";
import { Complete_Profile_Route, Dashboard_Routes, PrivateRoutes, Verify_Route } from "./PrivateRoutes/PrivateRoutes";
import { AllRoutes } from "./AllRoutes/AllRoutes";
import { themeRed } from "../../utils/colorTheme";
import CustomButton from "../../component/common/Button/Button";
import { exit_session } from "../apiHandle/apiHandle";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

export const RouterApp = () => {
  const { check_auth_status, userAuth, user_login_status, user_register_status, otp_status, kyc_verify_status } = useSelector((state) => state.userAuth);
  const userType = useSelector((state) => state.userType.userType);
  const dispatch = useDispatch();


  useEffect(() => {
    if (user_login_status === asyncStatus.SUCCEEDED) {
      dispatch(check_auth());
      dispatch(setIdleStatus())
    }
  }, [user_login_status])

  useEffect(() => {
    if (user_register_status === asyncStatus.SUCCEEDED) {
      dispatch(check_auth());
      dispatch(setIdleRegisterStatus())
    }
  }, [user_register_status])

  useEffect(() => {
    if (otp_status === asyncStatus.SUCCEEDED) {
      dispatch(check_auth());
      dispatch(setIdleOtpStatus())
    }
  }, [otp_status])

  useEffect(() => {
    if (kyc_verify_status === asyncStatus.SUCCEEDED) {
      dispatch(check_auth());
      dispatch(setIdleKycStatus())
    }
  }, [kyc_verify_status])


  useEffect(() => {
    if (check_auth_status === asyncStatus.IDLE) {
      const authTokens = localStorage.getItem(save_tokens_constant.AUTH) || null;
      if (!authTokens) {
        dispatch(setAuthState(false));
        console.log("not authenticated...");
      } else {
        dispatch(check_auth());
        console.log("authenticated...");
      }
    }
  }, [check_auth_status, dispatch]);

  if (check_auth_status === asyncStatus.IDLE || check_auth_status === asyncStatus.LOADING) {
    return (
      <Stack>
        <LinearProgress color="error" />
      </Stack>
    );
  }

  if (check_auth_status === asyncStatus.ERROR) {
    return (
      <Stack py={2} justifyContent={"center"} alignItems={"center"} height={"100vh"}>
        <Typography align="center" color="red">
          Something went wrong!
        </Typography>
        <Stack justifyContent={"center"} alignItems={"center"} mt={2}>

          <CustomButton onClick={() => exit_session()} children={"Back To Home"} />
        </Stack>
      </Stack>
    );
  }

  const routesToRender = userType === 'Host' ? hostRoutes : userRoutes;


  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Public Routes - No Authentication Required */}
        <Route element={<PublicRoutes />}>
          {routesToRender.map((route, i) => {
            const { linkTo, element, authRequired } = route;
            return !authRequired && <Route key={i} path={linkTo} element={element} />;
          })}
        </Route>



        <Route element={<Dashboard_Routes />}>
          {routesToRender.map((route, i) => {
            const { linkTo, element, authRequired, kyc_verify, otp_verify } = route;
            {/* return authRequired && !kyc_verify && !otp_verify && <Route key={i} path={linkTo} element={element} />; */}
            return authRequired && !kyc_verify && !otp_verify && <Route key={i} path={linkTo} element={element} />;
          })}
        </Route>

        <Route element={<Complete_Profile_Route />}>
          {routesToRender.map((route, i) => {
            const { linkTo, element, authRequired, kyc_verify } = route;
            return authRequired && kyc_verify && <Route key={i} path={linkTo} element={element} />;
          })}
        </Route>

        <Route element={<Verify_Route />}>
          {routesToRender.map((route, i) => {
            const { linkTo, element, authRequired, otp_verify } = route;
            return authRequired && otp_verify && <Route key={i} path={linkTo} element={element} />;
          })}
        </Route>


        {/* Shared Routes (for both authenticated and unauthenticated users) */}
        <Route element={<AllRoutes />}>
          {routesToRender.map((route, i) => {
            const { linkTo, element, both } = route;
            return both && <Route key={i} path={linkTo} element={element} />;
          })}
        </Route>

        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );

};
