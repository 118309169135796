import React from "react";
import Slider from "react-slick";
import dummy_img from "../../assets/dummy-image.jpg";

function CustomPagingSlider({ apiImages }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {apiImages?.map((image, index) => (
          <div key={index}>
            <img
              src={image || dummy_img}
              alt={`Slide ${index + 1}`}
              width="100%"
              height={"200px"}
              style={{ objectFit: "cover" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustomPagingSlider;
