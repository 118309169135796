// import React from "react";
// import './index.css'

// const IconTextField = ({ label, placeholder, icon, type = "text", ...props }) => {
//     return (<>
//         <label className="input-label">{label}</label>
//         <div className="input-container">
//             <div className="input-wrapper">
//                 <span className="input-icon">{icon}</span>
//                 <input
//                     type={type}
//                     placeholder={placeholder}
//                     className="input-field"
//                     {...props} // To accept other input-related props like onChange, value, etc.
//                 />
//             </div>
//         </div>
//     </>
//     );
// };

// export default IconTextField;

import React from "react";
import "./index.css";

const IconTextField = ({
  label,
  placeholder,
  icon,
  type = "text",
  error,
  helperText,
  ...props
}) => {
  return (
    <>
      <label className={`input-label ${error ? "error-label" : ""}`}>
        {label}
      </label>
      <div className="input-container">
        <div className={`input-wrapper ${error ? "input-error" : ""}`}>
          <span className="input-icon">{icon}</span>
          <input
            type={type}
            placeholder={placeholder}
            className="input-field"
            {...props} // To accept other input-related props like onChange, value, etc.
          />
        </div>
        {helperText && (
          <p className={`helper-text ${error ? "error-text" : ""}`}>
            {helperText}
          </p>
        )}
      </div>
    </>
  );
};

export default IconTextField;
