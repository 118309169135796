import React, { useRef } from "react";
import css from "./BarChart.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Stack, Typography } from "@mui/material";
import { themeRed } from "../../../utils/colorTheme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const chartRef = useRef(null);

  const generateGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(0, "#88030A");
    gradient.addColorStop(1, "#BF2E35");
    return gradient;
  };

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Monthly Sales",
        data: [
          3000, 2000, 3500, 4400, 4000, 4500, 3500, 3900, 4000, 4500, 4000,
          4500,
        ],
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This prevents the error when chart is first loaded before resizing
            return null;
          }
          return generateGradient(ctx, chartArea); // Use gradient for the bars
        },
        borderColor: themeRed,
        borderWidth: 1,
        borderRadius: 6,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value}k`; // Format numbers with 'k' for thousands
          },
        },
        suggestedMin: 0,
        suggestedMax: 5000,
      },
    },
  };

  return (
    <div className={css.total_revenue_wrapper}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          className={css.total_rev}
          sx={{
            fontSize: {
              xl: "32px !important",
              lg: "28px !important",
              md: "25px !important",
              sm: "23px !important",
              xs: "21px !important",
            },
          }}
        >
          Total Revenue
        </Typography>
        <Typography
          className={css.total_rev}
          sx={{
            fontSize: {
              xl: "32px !important",
              lg: "28px !important",
              md: "25px !important",
              sm: "23px !important",
              xs: "21px !important",
            },
          }}
        >
          $7.852.000
        </Typography>
      </Stack>
      <Stack alignItems={"center"} sx={{ width: "100%", height: "400px" }}>
        <Bar ref={chartRef} data={data} options={options} />
      </Stack>
    </div>
  );
};

export default BarChart;
