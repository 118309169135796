import React, { useEffect, useState } from "react";
import Navbar from "../../component/navbar/Navbar";
import css from "./CancellationPolicy.module.css";
import { Container, Stack, Typography } from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import Footer from "../../screens/Footer/Footer";

const CancellationPolicy = () => {
  const [userType, setUserType] = useState("");
  const termsConditionsData = [
    {
      section: "Cancellation and Refund Policy",
      subSections: [
        {
          title: "Rentals Booked More Than 24 Hours in Advance",
          content: [
            {
              key: "",
              details: (
                <>
                  Users may cancel their rental at any time by logging into
                  their account and submitting a cancellation request.
                  Cancellations made at least{" "}
                  <span style={{ fontWeight: "600" }}> 24 hours prior </span> to
                  the scheduled trip start time are eligible for a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>
                </>
              ),
            },
            {
              key: "",
              details: (
                <>
                  If a User fails to cancel at least{" "}
                  <span style={{ fontWeight: "600" }}>24 hours before </span>{" "}
                  the trip start time or does not arrive for pick-up, the{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    full rental cost will be charged{" "}
                  </span>
                  , and no refund will be issued.
                </>
              ),
            },
            {
              key: "",
              details: (
                <>
                  Hosts must approve rental requests{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    within four (4) days or 96 hours of the request date{" "}
                  </span>{" "}
                  or{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    at least 24 hours before the trip starts,{" "}
                  </span>{" "}
                  whichever comes first. If the Host does not approve the
                  request within this timeframe, the rental will be
                  automatically canceled, and the User will receive a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>{" "}
                  Additionally, if the Host does not make trailer available for
                  pick-up, the User is entitled to a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>
                </>
              ),
            },
          ],
        },
        {
          title: "Rentals Booked Less Than 24 Hours in Advance",
          content: [
            {
              key: "",
              details: (
                <>
                  For rentals booked within{" "}
                  <span style={{ fontWeight: "600" }}> 24 hours</span> of the
                  trip start time, Users have up to{" "}
                  <span style={{ fontWeight: "600" }}> one (1) hour</span> after
                  booking to cancel and receive a{" "}
                  <span style={{ fontWeight: "600" }}> full refund</span>. If a
                  cancellation is not made within this one-hour window, the{" "}
                  <span style={{ fontWeight: "600" }}>
                    full rental cost will be charged
                  </span>
                  , and no refund will be issued.
                </>
              ),
            },
            {
              key: "",
              details: (
                <>
                  For rental requests made within{" "}
                  <span style={{ fontWeight: "600" }}> 24 hours</span> of the
                  trip start time, Hosts have up to{" "}
                  <span style={{ fontWeight: "600" }}> six (6) hours</span>{" "}
                  after the request is submitted to approve the rental. If the
                  Host does not approve the request within this timeframe, the
                  rental will be automatically canceled, and the User will
                  receive a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>
                </>
              ),
            },
          ],
        },
      ],

      note: (
        <>
          <div>
            <p>
              <strong>
                <u>• Host-Initiated Cancellations:</u>
              </strong>{" "}
              Hosts may cancel a reservation up to 24 hours before the rental
              start time without penalty. Cancellations made within 24 hours of
              the start time will result negatively on the Host's Profile.
              Multiple negative offenses will drop your listing down in the
              search ranks.
            </p>
            <p>
              <strong>
                <u>• Renter-Initiated Cancellations:</u>
              </strong>{" "}
              Renters may cancel up to 24 hours before the rental period begins
              for a full refund. Cancellations within 24 hours will incur a
              cancellation fee of the full rental amount payable to the Host
              (minus Trailer4Rent.ca fee).
            </p>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const user_type = localStorage.getItem("userType");
    setUserType(user_type);
  }, []);

  const _handleOpenClick = () => {
    window.open("/" || "", "_blank"); // Opens in a new tab
  };
  return (
    <div>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={css.main_title}>
           Cancellation Policy
          </Typography>
        </Stack>
      </div>

      <Container
        sx={{
          py: 5,
        }}
        maxWidth="xl"
      >
        {/* cards */}
        {/* <<<<<<<< TERMS AND CONDITIONS >>>>>>> */}
        <Stack px={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 3 }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              fontSize: "25px",
              fontWeight: "500",
            }}
          >
            Trailer4Rent Inc. Cancellation Policy for {userType}
          </Typography>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontFamily: "Book Antiqua, serif !important",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            Last Updated: [09/25/24]
          </Typography>

          <Stack mx={4}>
            <ol className={css.order_lst}>
              {termsConditionsData.map((section, i) => (
                <li key={i}>
                  <Typography
                    className={css.main_heading}
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "#000",
                      fontFamily: "Book Antiqua, serif !important",
                      borderBottom: "4px solid #C3282F",
                      paddingBottom: "4px",
                      display: "inline-block",
                    }}
                  >
                    {section.section}
                  </Typography>

                  {Array.isArray(section.content) ? (
                    <ol className={`${css.order_lst} ${css.nested_order_lst}`}>
                      {section.content.map((item, idx) => (
                        <li key={idx} className={css.sub_data_list}>
                          <Typography
                            variant="caption"
                            className={css.light_txt}
                          >
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <Typography
                      className={css.light_txt}
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {section.content}
                    </Typography>
                  )}

                  {/* Render Note if it exists */}

                  {/* Handle Subsections */}
                  {section.subSections && (
                    <ol className={`${css.order_lst} ${css.nested_order_lst}`}>
                      {section.subSections.map((subSection, subIdx) => (
                        <li key={subIdx}>
                          <Typography
                            className={css.sub_heading}
                            sx={{
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                              display: "inline-block",
                            }}
                          >
                            {subSection.title}
                          </Typography>

                          {Array.isArray(subSection.content) ? (
                            <ol
                              className={`${css.order_lst} ${css.nested_order_lst}`}
                            >
                              {subSection.content.map((item, subItemIdx) => (
                                <li
                                  key={subItemIdx}
                                  className={css.sub_data_list}
                                >
                                  <Typography
                                    variant="caption"
                                    className={css.light_txt}
                                    sx={{
                                      fontSize: "16px",
                                      marginTop: "16px",
                                    }}
                                  >
                                    {item.key && (
                                      <strong
                                        style={{
                                          fontFamily:
                                            "Book Antiqua, serif !important",
                                        }}
                                      >
                                        {item.key}:
                                      </strong>
                                    )}{" "}
                                    {item.details}
                                  </Typography>
                                </li>
                              ))}
                            </ol>
                          ) : (
                            <Typography className={css.light_txt}>
                              {subSection.details}
                            </Typography>
                          )}

                          {/* Render Note if it exists inside subSection */}
                          {subSection.note && (
                            <Typography
                              className={css.note_text}
                              sx={{
                                fontFamily: "Book Antiqua, serif !important",
                              }}
                            >
                              <strong>Note:</strong> {subSection.note}
                            </Typography>
                          )}
                        </li>
                      ))}
                    </ol>
                  )}
                  <br />
                  {section.note && (
                    <Typography
                      className={css.note_text}
                      sx={{
                        fontFamily: "Book Antiqua, serif !important",
                      }}
                    >
                      <strong>Note:</strong> {section.note}
                    </Typography>
                  )}
                </li>
              ))}
            </ol>
          </Stack>
        </Stack>

        <Stack px={{ xl: 14, lg: 14, md: 14, sm: 14, xs: 7 }} py={3}>
          <Typography
            variant="h5"
            // className={css.light_txt}
            sx={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              borderBottom: "4px solid #C3282F",
              paddingBottom: "4px",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Trailer4Rent Inc.
          </Typography>
          <Typography
            variant="caption"
            className={css.light_txt}
            sx={{
              fontWeight: "bold",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Email:{" "}
            <Typography
              // onClick={_handleOpenClick}
              component="a"
              href="mailto:admin@trailer4rent.ca"
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeRed} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              admin@trailer4rent.ca
            </Typography>
          </Typography>
          <Typography
            variant="caption"
            className={css.light_txt}
            sx={{
              fontWeight: "bold",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Website:{" "}
            <Typography
              onClick={_handleOpenClick}
              component="a"
              variant="caption"
              // href="http://www.trailer4rent.ca"
              className={css.light_txt}
              sx={{
                color: `${themeRed} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              www.trailer4rent.ca
            </Typography>
          </Typography>
        </Stack>
      </Container>

      <Footer />
    </div>
  );
};

export default CancellationPolicy;
