import React from "react";
import TestimonialSlider from "../../../component/TestimonialSlider";
import { useNavigate } from "react-router-dom";
import { Box, Container, Stack, Typography } from "@mui/material";
import { themeRed } from "../../../utils/colorTheme";

const TestimonialsSection = () => {
  const navigate = useNavigate();

  const reviews = [
    {
      name: "Ben R.",
      role: "Top Host",
      rentals: 32,
      joinedYear: 2022,
      rating: 5,
      review:
        "Much easier AND cheaper than dealing with the BIG rental companies. So glad there's another option out there",
      reviewedBy: "Ken M.",
    },
    {
      name: "Hansen L.",
      role: "Top Host",
      rentals: 16,
      joinedYear: 2023,
      rating: 5,
      review:
        "Wow, that was a breeze to book and get my job done! I'll be back!",
      reviewedBy: "Tyler R.",
    },
    {
      name: " Don V.",
      role: "Top Host",
      rentals: 18,
      joinedYear: 2022,
      rating: 5,
      review:
        "I don't OWN a trailer, yet I can USE a trailer anytime!! LOVE THIS concept!",
      reviewedBy: "Derrick R.",
    },
    {
      name: "Marc G.",
      role: "Top Host",
      rentals: 33,
      joinedYear: 2021,
      rating: 5,
      review:
        "I take animals to market just twice/year. Renting from Trailer4Rent means I don't have to buy and maintain my own trailer",
      reviewedBy: "Rick P.",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Stack
        sx={{ margin: "100px auto 20px auto" }}
        alignItems={"center"}
        maxWidth={"md"}
      >
        <Typography
          fontWeight={"900"}
          textAlign={"center"}
          sx={{
            fontFamily: "Book Antiqua, serif !important",
            fontSize: {
              xl: "60px",
              lg: "50px",
              md: "45px",
              sm: "40px",
              xs: "30px",
            },
            lineHeight: "normal",
            zIndex: "100",
          }}
        >
          What renters are saying
        </Typography>
        {/* <Box
          sx={{
            width: "100%",
            borderBottom: {
              xl: `25px solid ${themeRed}`,
              lg: `20px solid ${themeRed}`,
              md: `20px solid ${themeRed}`,
              sm: `17px solid ${themeRed}`,
              xs: `15px solid ${themeRed}`,
            },
            outline: "none",
            color: "#99000B26",
            marginTop: {
              xl: "-23px",
              lg: "-21px",
              md: "-21px",
              sm: "-17px",
              xs: "-13px",
            },
          }}
        ></Box> */}
        <Box
          sx={{
            width: "100%",
            borderBottom: {
              xl: `14px solid ${themeRed}`,
              lg: `14px solid ${themeRed}`,
              md: `14px solid ${themeRed}`,
              sm: `14px solid ${themeRed}`,
              xs: `14px solid ${themeRed}`,
            },
            outline: "none",
            color: "#99000B26",
            marginTop: {
              xl: "-20px",
              lg: "-18px",
              md: "-14px",
              sm: "-13px",
              xs: "-9px",
            },
          }}
        ></Box>
      </Stack>
      <Stack>
        <TestimonialSlider
          // onClick={() => {
          //   navigate("/host-details");
          // }}
          reviewsData={reviews}
        />
      </Stack>
    </Container>
  );
};

export default TestimonialsSection;
