import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import hero_img from "../../assets/heroImage.webp";

const AboutUsCom = () => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      {/* Header Section */}
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${hero_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {/* Dark Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
        {/* Header Text */}
        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontFamily: "Book Antiqua, serif !important",
              color: "white",
              textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
            }}
          >
            About Us
          </Typography>
        </Container>
        <Typography
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional for better visibility
            padding: "5px",
            borderRadius: "4px",
            fontSize: "16px",
          }}
        >
          Enclosed Trailer Rental
        </Typography>
      </Box>

      {/* About Content Section */}
      <Container maxWidth="lg" sx={{ padding: "60px 0" }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "16px",
                color: "#000",
                fontFamily: "Book Antiqua, serif !important",
                borderBottom: "10px solid #C3282F",
              }}
            >
              Trailer4Rent is revolutionizing the way trailers are rented and
              shared.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                color: "#555",
                lineHeight: "1.8",
              }}
            >
              Our innovative platform empowers trailer owners with the tools to
              automate and grow their rental business, while renters enjoy the
              convenience of a peer-to-peer marketplace that connects them with
              trusted local trailer hosts.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                color: "#555",
                lineHeight: "1.8",
                marginTop: "16px",
              }}
            >
              When you rent through Trailer4Rent.ca, you’re not just renting a
              trailer—you’re supporting people in your community. Your rental
              helps fund swim lessons, skating classes, mortgages, and holiday
              gifts, rather than contributing to a corporate bottom line.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTQMNLxgoauFHXew__2aCADgUAeOWJbV7Yvw&s"
              alt="About Trailer4Rent"
              sx={{
                width: "100%",
                borderRadius: "12px",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            />
          </Grid>
        </Grid>

        <Box mt={8} textAlign="center">
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "16px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              borderBottom: "10px solid #C3282F",
              paddingBottom: "4px",
            }}
          >
            Diverse Trailers at Competitive Rates
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              maxWidth: "800px",
              margin: "16px auto 0",
              lineHeight: "1.8",
            }}
          >
            We offer a diverse selection of trailers, including flatdeck
            trailers, enclosed trailers, horse & livestock trailers, dump
            trailers, utility trailers, and more—often at rates more competitive
            than traditional rental companies.
          </Typography>
        </Box>

        <Box mt={8} textAlign="center">
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "16px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              borderBottom: "10px solid #C3282F",
              paddingBottom: "4px",
            }}
          >
            Our Mission
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              maxWidth: "800px",
              margin: "16px auto 0",
              lineHeight: "1.8",
            }}
          >
            Whether you're a trailer owner looking to maximize your equipment’s
            earning potential or a renter seeking an affordable and seamless
            experience, Trailer4Rent.ca is your trusted partner in the trailer
            rental journey.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              maxWidth: "800px",
              margin: "16px auto 0",
              lineHeight: "1.8",
            }}
          >
            As we continue to grow, our mission is to make trailer sharing
            accessible, efficient, and reliable across Canada and beyond.
          </Typography>
        </Box>

        <Box textAlign="center" mt={8}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#C3282F",
              marginBottom: "16px",
            }}
          >
            Let’s get YOU rolling!
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default AboutUsCom;
