// import React, { useState } from "react";
// import "./StepOne.css";
// import { CircularProgress, Container, Stack, Typography } from "@mui/material";
// import Navbar from "../../navbar/Navbar";
// import Footer from "../../../screens/Footer/Footer";
// import CustomButton from "../../common/Button/Button";

// import ImageUploader from "../../ImageUploader/ImageUploader";
// import { useDispatch, useSelector } from "react-redux";
// import { kyc_verify_async } from "../../../services/authentication";
// import { asyncStatus } from "../../../utils/asyncStatus";

// const StepOne = () => {
//   const dispatch = useDispatch();
//   const { kyc_verify_status, user } = useSelector((state) => state.userAuth);

//   const [profileImage, setProfileImage] = useState(null);
//   const [drivingLicenseFront, setDrivingLicenseFront] = useState(null);
//   const [idcard, setIdCard] = useState(null);
//   const [insuranceCard, setInsuranceCard] = useState(null);
//   const [registrationCard, setRegistrationCard] = useState(null);
//   const [isExpert, setIsExpert] = useState(null);

//   const handleChange = (value) => {
//     setIsExpert(value);
//   };

//   const handleSubmit = () => {
//     const obj = {
//       avatar: profileImage,
//       transmission: isExpert,
//       driving_license: drivingLicenseFront,
//       id_card: idcard,
//       insurance_card: insuranceCard,
//       registration_card: registrationCard,
//     };
//     dispatch(kyc_verify_async(obj));
//   };

//   return (
//     <Stack bgcolor={"#FAFAFA"} p={3} my={5} className="get-approved">
//       <Typography
//         mt={5}
//         className="Get-Approved-Heading"
//         sx={{
//           fontSize: {
//             xl: "40px",
//             lg: "38px",
//             md: "33px",
//             sm: "30px",
//             xs: "25px",
//           },
//         }}
//       >
//         Get Approved To Drive
//       </Typography>
//       <Stack
//         mt={3}
//         sx={{
//           width: {
//             xl: "fit-content",
//             lg: "fit-content",
//             md: "fit-content",
//             sm: "fit-content",
//             xs: "100%",
//           },
//         }}
//       >
//         <ImageUploader
//           label="Upload your photo"
//           onImageSelect={(img) => setProfileImage(img)}
//           defaultImageUrl={user?.avatar || ""}
//         />
//       </Stack>

//       {/* Show Transmission section only after saving mobile notifications */}

//       <Stack mt={5}>
//         <Stack mb={3}>
//           <hr />
//         </Stack>

//         <Stack className="reuseable-heading" mb={2}>
//           Transmission
//         </Stack>

//         <Stack className="Some-cars-on" mb={2}>
//           Some cars on TruckRent do not have automatic transmissions. Are you an
//           expert at driving manual transmissions?
//         </Stack>

//         <Stack>
//           <label className="label-class-radio">
//             <input
//               className="radio-input"
//               type="radio"
//               name="transmission"
//               value="true"
//               checked={isExpert === true || user.transmission === true}
//               onChange={() => handleChange(true)}
//             />{" "}
//             Yes, I am an expert
//           </label>
//         </Stack>

//         <Stack>
//           <label className="label-class-radio">
//             <input
//               className="radio-input"
//               type="radio"
//               name="transmission"
//               value="false"
//               checked={isExpert === false || user.transmission === false}
//               onChange={() => handleChange(false)}
//             />{" "}
//             No, I am not an expert
//           </label>
//         </Stack>
//       </Stack>

//       {/* Show Document Upload section only after saving transmission section */}

//       <Stack mt={5}>
//         <Stack mb={3}>
//           <hr />
//         </Stack>
//         <Stack className="reuseable-heading" mb={2}>
//           Upload required documents
//         </Stack>

//         <Stack>
//           <Stack
//             flexDirection={{
//               xl: "row",
//               lg: "row",
//               md: "row",
//               sm: "row",
//               xs: "column",
//             }}
//             alignItems={"center"}
//             gap={5}
//             sx={{
//               width: {
//                 xl: "fit-content",
//                 lg: "fit-content",
//                 md: "fit-content",
//                 sm: "fit-content",
//                 xs: "100%",
//               },
//             }}
//           >
//             <Stack
//               sx={{
//                 width: "100%",
//               }}
//             >
//               <ImageUploader
//                 label="Driving License"
//                 onImageSelect={(img) => setDrivingLicenseFront(img)}
//                 defaultImageUrl={user?.driving_license || ""}
//               />
//             </Stack>
//             <Stack
//               sx={{
//                 width: "100%",
//               }}
//             >
//               <ImageUploader
//                 label="ID Card"
//                 onImageSelect={(img) => setIdCard(img)}
//                 defaultImageUrl={user?.id_card || ""}
//               />
//             </Stack>
//           </Stack>
//         </Stack>

//         <Stack mt={2}>
//           <Stack
//             flexDirection={{
//               xl: "row",
//               lg: "row",
//               md: "row",
//               sm: "row",
//               xs: "column",
//             }}
//             alignItems={"center"}
//             gap={5}
//             sx={{
//               width: {
//                 xl: "fit-content",
//                 lg: "fit-content",
//                 md: "fit-content",
//                 sm: "fit-content",
//                 xs: "100%",
//               },
//             }}
//           >
//             <Stack
//               sx={{
//                 width: "100%",
//               }}
//             >
//               <ImageUploader
//                 label="Insurance Card"
//                 onImageSelect={(img) => setInsuranceCard(img)}
//                 defaultImageUrl={user?.insurance_card || ""}
//               />
//             </Stack>
//             <Stack
//               sx={{
//                 width: "100%",
//               }}
//             >
//               <ImageUploader
//                 label="Registration Card"
//                 onImageSelect={(img) => setRegistrationCard(img)}
//                 defaultImageUrl={user?.registration_card || ""}
//               />
//             </Stack>
//           </Stack>
//         </Stack>
//         <Stack></Stack>
//         <Stack mt={4} sx={{ width: "auto" }}>
//           <CustomButton
//             onClick={handleSubmit}
//             children={
//               kyc_verify_status === asyncStatus.LOADING ? (
//                 <CircularProgress size={24} color="inherit" />
//               ) : (
//                 "Submit"
//               )
//             }
//             //   style={{ width: "auto" }}
//           />
//         </Stack>
//       </Stack>
//     </Stack>
//   );
// };

// export default StepOne;

import React, { useState, useEffect } from "react";
import "./StepOne.css";
import { CircularProgress, Stack, Typography } from "@mui/material";
import CustomButton from "../../common/Button/Button";
import ImageUploader from "../../ImageUploader/ImageUploader";
import { useDispatch, useSelector } from "react-redux";
import { kyc_verify_async } from "../../../services/authentication";
import { asyncStatus } from "../../../utils/asyncStatus";
import PreviewVideo from "../../PreviewVideo/PreviewVideo";
import logo_with_text from "../../../assets/logo_with_text.png";

const StepOne = () => {
  const dispatch = useDispatch();
  const { kyc_verify_status, user } = useSelector((state) => state.userAuth);

  // **Set Initial State from `user` Data (if available)**
  const [profileImage, setProfileImage] = useState(null);
  const [drivingLicenseFront, setDrivingLicenseFront] = useState(null);
  const [idcard, setIdCard] = useState(null);
  const [insuranceCard, setInsuranceCard] = useState(null);
  const [registrationCard, setRegistrationCard] = useState(null);
  const [isExpert, setIsExpert] = useState(null);

  // **Load Initial Data from User**
  useEffect(() => {
    if (Object.keys(user || {})?.length > 0) {
      setProfileImage(user?.avatar || null);
      setDrivingLicenseFront(user?.driving_license || null);
      setIdCard(user?.id_card || null);
      setInsuranceCard(user?.insurance_card || null);
      setRegistrationCard(user?.registration_card || null);
      setIsExpert(user?.transmission ?? null);
    }
  }, [user]);

  // **Handle Transmission Change**
  const handleChange = (value) => {
    setIsExpert(value);
  };

  // **Handle Form Submission**
  const handleSubmit = () => {
    const obj = {
      avatar: profileImage,
      transmission: isExpert,
      driving_license: drivingLicenseFront,
      id_card: idcard,
      insurance_card: insuranceCard,
      registration_card: registrationCard,
    };
    dispatch(kyc_verify_async(obj));
  };

  return (
    <Stack bgcolor={"#FAFAFA"} p={3} pt={0} my={5} className="get-approved">
      <Stack alignItems={"start"} justifyContent={"start"} gap={1}>
        <img
          src={logo_with_text}
          style={{ height: "50px", objectFit: "contain" }}
        />
        {/* <span className="ml-2 text-lg font-bold text-gray-800">
          #Let's get YOU rolling
        </span> */}
      </Stack>
      <Typography
        mt={5}
        className="Get-Approved-Heading"
        sx={{
          fontSize: {
            xl: "40px",
            lg: "38px",
            md: "33px",
            sm: "30px",
            xs: "25px",
          },
        }}
      >
        Get Approved To Tow
      </Typography>

      {/* Profile Image Upload */}
      <Stack mt={3}>
        <ImageUploader
          label={
            <>
              Upload your Profile Photo{" "}
              <span style={{ color: "gray", fontSize: "16px" }}>
                (Using your real, recent photo will help identify you when you
                rent a trailer to/from your peers)
              </span>
            </>
          }
          onImageSelect={(img) => setProfileImage(img)}
          defaultImageUrl={profileImage} // Uses state value
        />
      </Stack>

      {/* Transmission Selection */}
      <Stack mt={5}>
        <Stack mb={3}>
          <hr />
        </Stack>
        <Stack className="reuseable-heading" mb={2}>
          {/* Transmission */}
          Hooking up/Pulling a trailer
        </Stack>
        <Stack className="Some-cars-on" mb={2}>
          {/* Some cars on TruckRent do not have automatic transmissions. Are you an
          expert at driving manual transmissions? */}
          Hooking up a trailer/wide right turns/backing up a trailer take
          practice. Do you have any experience with these skills?
        </Stack>
        <Stack>
          <label className="label-class-radio">
            <input
              className="radio-input"
              type="radio"
              name="transmission"
              value="true"
              checked={isExpert === true}
              onChange={() => handleChange(true)}
            />{" "}
            {/* Yes, I am an expert */}
            Yes, I have experience
          </label>
        </Stack>
        <Stack>
          <label className="label-class-radio">
            <input
              className="radio-input"
              type="radio"
              name="transmission"
              value="false"
              checked={isExpert === false}
              onChange={() => handleChange(false)}
            />{" "}
            {/* No, I am not an expert */}
            No, I do not have experience and would like some tips.
          </label>
        </Stack>

        <Stack my={3}>
          <PreviewVideo
            videoUrl={`https://www.youtube.com/watch?v=wJFVMj-bVsY&ab_channel=AutoGuide.com`}
          />
        </Stack>
      </Stack>

      {/* Document Upload Section */}
      <Stack mt={5}>
        <Stack mb={3}>
          <hr />
        </Stack>
        <Typography
          className="reuseable-heading"
          mb={2}
          sx={{ fontSize: "22px", fontWeight: "700" }}
        >
          Upload required documents:{" "}
          <span
            style={{ color: "gray", fontSize: "16px", fontWeight: "normal" }}
          >
            (Your documents will be securely held on our private server and
            never viewed by any Renter nor Host)
          </span>
        </Typography>
        <Stack>
          <Stack
            flexDirection={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            }}
            alignItems={"center"}
            gap={5}
          >
            <Stack sx={{ width: "100%" }}>
              <ImageUploader
                label="Driver's Licence"
                onImageSelect={(img) => setDrivingLicenseFront(img)}
                defaultImageUrl={drivingLicenseFront}
              />
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <ImageUploader
                label="Trailer Registry Card  *If you are a Trailer Host in AB, SK & NB"
                onImageSelect={(img) => setIdCard(img)}
                defaultImageUrl={idcard}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack mt={2}>
          <Stack
            flexDirection={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            }}
            alignItems={"center"}
            gap={5}
          >
            <Stack sx={{ width: "100%" }}>
              <ImageUploader
                label="Vehicle Insurance  *If you are a Trailer Renter"
                onImageSelect={(img) => setInsuranceCard(img)}
                defaultImageUrl={insuranceCard}
              />
            </Stack>
            <Stack sx={{ width: "100%" }}>
              <ImageUploader
                label="Trailer Registration (2 pages)  *If you are a Trailer Host"
                onImageSelect={(img) => setRegistrationCard(img)}
                defaultImageUrl={registrationCard}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* Submit Button */}
        <Stack mt={4} sx={{ width: "auto" }}>
          <CustomButton
            onClick={handleSubmit}
            children={
              kyc_verify_status === asyncStatus.LOADING ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StepOne;
