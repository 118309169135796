import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import ProfileImage from "../../assets/background/profile-image.png";
import "./ProfileCard.css";
import CustomButton from "../common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dashboard_api_async } from "../../services/authentication";
import useWindowDimensions from "./../../utils/hooks/windowDimensions";

const ProfileCard = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { user, dashboard_api_data, dashboard_api_status } = useSelector(
    (state) => state.userAuth
  );

  return (
    <Card
      sx={{
        // maxWidth: 400,
        borderRadius: 4,
        boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
        py: 4,
        position: "relative",
        overflow: "hidden",
        // height: { xl: 320, lg: 280, md: 280, sm: "auto", xs: "auto" },
      }}
    >
      <Stack position={"absolute"} top={0} left={0} right={0}>
        <img
          src={require("../../assets/background/back-shade.png")}
          style={{ objectFit: "contain" }}
        />
      </Stack>
      <Stack
        sx={{
          display: {
            xl: "block",
            lg: "block",
            md: "block",
            sm: "block",
            xs: "none",
          },
        }}
      >
        <hr className="profile-card-line" />
      </Stack>

      <Stack>
        <Stack alignItems={"flex-start"} sx={{ marginTop: "50px", zIndex: 1 }}>
          <Stack
            flexDirection={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            }}
            alignItems={{
              xl: "flex-start",
              lg: "flex-start",
              md: "flex-start",
              sm: "flex-start",
              xs: "center",
            }}
            justifyContent={"space-between"}
            width={"100%"}
            px={5}
          >
            <Stack flexDirection={"column"} alignItems={"center"} gap={3}>
              <Stack>
                <img
                  src={user?.avatar}
                  alt="ProfileImage"
                  className="profile-image-handle"
                />
              </Stack>
              <Stack>
                <Stack className="profile-card-name">
                  {user?.first_name} {user?.last_name}
                </Stack>
                {/* <Stack className='profile-card-id' flexDirection={"row"} alignItems={"center"} gap={0.5} justifyContent={"center"}>
                                    <span>User ID </span> <span style={{ fontWeight: '700' }}>12345</span>
                                </Stack> */}
              </Stack>
            </Stack>

            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              gap={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 2 }}
              mt={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 3.5 }}
            >
              <Stack
                flexDirection={"column"}
                alignItems={"center"}
                gap={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 2 }}
              >
                <Stack className="profile-card-number">
                  {dashboard_api_data?.trailer_count}
                </Stack>
                <Stack className="profile-card-number-inner-name">
                  Trailers
                </Stack>
              </Stack>
              <Stack
                flexDirection={"column"}
                alignItems={"center"}
                gap={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 2 }}
              >
                <Stack className="profile-card-number">$80.00</Stack>
                <Stack className="profile-card-number-inner-name">
                  Revenue
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            alignItems={"center"}
            position={{
              xl: "absolute",
              lg: "absolute",
              md: "absolute",
              sm: "absolute",
              xs: "inherit",
            }}
            right={10}
            bottom={20}
            pr={{
              xl: 5,
              lg: 5,
              md: 5,
              sm: 5,
              xs: 0,
            }}
            my={{
              xl: 0,
              lg: 0,
              md: 0,
              sm: 0,
              xs: 2,
            }}
            sx={{
              //   backgroundColor: "red",
              width: {
                xl: "auto",
                lg: "auto",
                md: "auto",
                sm: "auto",
                xs: "100%",
              },
            }}
          >
            <CustomButton
              onClick={() => navigation("/settings")}
              style={{
                backgroundColor: themeRed,
                color: "white",
              }}
              children={"View Profile"}
            />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProfileCard;
