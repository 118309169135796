import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import GMap from './GMap';

const GOOGLE_MAP_API_KEY = 'AIzaSyCG3Xlad4kf39CqRO9FWw01sIJiixrCx_M';

const MapRender = ({ trailers }) => {
    
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAP_API_KEY,
      version: 'weekly',
      libraries: ['geometry'],
    });

    loader.load()
      .then(() => {
        setLoadMap(true);
      })
      .catch((error) => {
        console.error('Error loading Google Maps: ', error);
      });
  }, []);

  return loadMap ? <GMap trailers={trailers} /> : <div>Loading...</div>;
};

export default MapRender;
