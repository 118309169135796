import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import "./TabComponent.css";

const tabs = ["General", "Guests", "Hosts"];

const FAQs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTab, setActiveTab] = useState("New");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const faqsData = [
    {
      question:
        "How do I know my bank account and other sensitive information is safe and secure?",
      upper_description: (
        <>
          In order to provide a safe, secure environment for your financial
          information and other sensitive data, Trailer4Rent.ca proudly utilizes
          TD Merchant Services. TD Merchant Services has the highest ratings in
          all of North America towards cybersecurity and data protection.
          Companies on platforms such as Shopify, WooCommerce, and Magento may
          choose to have TD Merchant Services as their e-commerce solution. For
          more information, visit their Privacy Center at:
          <br />
          <br />
          We will ask for the following information, so you’re welcome to begin
          gathering it:
        </>
      ),
      lower_description: (
        <>
          Don’t worry, we will walk you through all the details, and get your
          trailers ready to be rented!
        </>
      ),
      content: [
        {
          title: "",
          point: <>1. A detailed description to help it stand out</>,
        },
        {
          title: "",
          point: (
            <>2. Trailer VIN and License plate number / State (or photo)</>
          ),
        },
        {
          title: "",
          point: <>3. Trailer address (where you guest will pick it up)</>,
        },
        {
          title: "",
          point: <>4. Trailer weight and max payload</>,
        },
        {
          title: "",
          point: (
            <>
              5. At least 6 photos of the trailer (see “Do you have any tips…”
              question in FAQ for suggestions)
            </>
          ),
        },
        {
          title: "",
          point: <>6. Pricing Details</>,
        },
        {
          title: "",
          point: <>7. Host Delivery (Opt in or out)</>,
        },
      ],
    },
    {
      question: "What do I need to do to rent a trailer on Trailer4Rent?",
      upper_description: (
        <>To book a trailer on trailer4rent.ca, you will need the following:</>
      ),
      lower_description: "",
      content: [
        {
          title: "1. Account Creation: ",
          point: (
            <>
              Sign up or log into your account on our site to access available
              trailers for rent.
            </>
          ),
        },
        {
          title: "2. Valid Driver's License: ",
          point: (
            <>
              A valid driver’s license is required for identification and to
              ensure you can legally tow the trailer.
            </>
          ),
        },
        {
          title: "3. Payment Method: ",
          point: (
            <>
              A credit card or another accepted payment method to secure your
              booking and cover any potential deposits or fees.
            </>
          ),
        },
        {
          title: "4. Rental Agreement: ",
          point: (
            <>
              Review and agree to the rental terms and conditions, including the
              responsibilities associated with renting the trailer.
            </>
          ),
        },
        {
          title:
            "5. Insurance or Class/Restriction to renters licence (if required): ",
          point: (
            <>
              Depending on the GVW of trailer and the rental terms set out by
              that particular trailer host, you may need additional Class of
              licence or proof of insurance for the trailer during your rental
              period. *Applies to some Specialty trailers or trailers with GVW
              over 10,990lb.
            </>
          ),
        },
        {
          title:
            "6. You must only rent trailer that is insured with same Provincial policy as your truck’s insurance has. ",
          point: (
            <>
              Example: Alberta-insured truck? Look for Alberta-insured trailers
              only.{" "}
            </>
          ),
        },
      ],
    },
    {
      question: "Do I need my own insurance?",
      upper_description: (
        <>
          Each renter must have valid insurance policy on their own tow vehicle.
          As well, if the trailer you wish to rent and/or the payload exceeds
          what your truck policy is rated to carry, you must increase (at your
          expense) the payload capacity amount on your insurance policy prior to
          renting. Trailer4Rent Inc. nor the trailer host will be held
          responsible if your tow vehicle is not properly maintained or
          sufficiently insured to pull the trailer and load.{" "}
        </>
      ),
      lower_description: (
        <>
          Each trailer host must carry valid insurance policies on the
          trailer(s) that they post on our platform.{" "}
        </>
      ),
      content: [],
    },
    {
      question: "Can other people tow a trailer that I booked?",
      upper_description: (
        <>
          Yes, someone else can tow the trailer you booked on trailer4rent.ca,
          but there are a few requirements:
        </>
      ),
      lower_description: (
        <>
          Adding an approved driver to your booking helps ensure that everyone
          involved understands the responsibilities and conditions of the
          rental. However, know that YOU as the primary “Renter” will be held
          100% responsible for any negligence, damage, or theft of the rented
          trailer. Your payment method as well as deposit may be utilized to
          cover damages. <br /> If you need assistance adding someone to your
          booking, feel free to contact us!
        </>
      ),
      content: [
        {
          title: `1. Driver Approval: `,
          point: (
            <>
              The person towing the trailer MUST be added to your booking as an
              approved driver. This will involve providing their driver’s
              license information for verification.
            </>
          ),
        },
        {
          title: `2. Driver’s License and Insurance: `,
          point: (
            <>
              The individual must have a valid driver’s license and, if
              required, provide proof of insurance that covers them while towing
              the trailer.
            </>
          ),
        },
        {
          title: `3. Agreement to Terms: `,
          point: (
            <>
              The additional driver must also review and agree to our Rental
              Terms and Conditions.
            </>
          ),
        },
      ],
    },
    {
      question: "What is the cancellation policy? ",
      upper_description: (
        <>The cancellation policy for Trailer4Rent is as follows:</>
      ),
      lower_description: (
        <>
          For more details or to discuss special circumstances, feel free to
          reach out to our support team directly.
        </>
      ),
      content: [
        {
          title: `1. Free Cancellation (up to 24 hours before pick-up): `,
          point: (
            <>
              You can cancel your booking up to 24 hours before your scheduled
              pick-up time for a full refund.
            </>
          ),
        },
        {
          title: `2. Late Cancellation (within 24 hours of pick-up): `,
          point: (
            <>
              If you cancel within 24 hours of your pick-up time, your booking
              will incur a cancellation fee payable to the Host (minus
              Trailer4Rent.ca fee), and you may only receive a partial refund.
            </>
          ),
        },
        {
          title: `3. No-Show: `,
          point: (
            <>
              If you do not show up at the scheduled pick-up time and do not
              contact us to adjust your booking, the rental fee may be
              non-refundable.
            </>
          ),
        },
        {
          title: `4. The earlier you communicate a change in plans to your rental, the higher chance of you receiving a favourable outcome!  `,
          point: (
            <>
              We understand that things can happen, plans change. Your host may
              be able to work with you on postponing your rental at their
              discretion. Reach out quickly to your host directly.
            </>
          ),
        },
      ],
    },

    {
      question:
        "We’re working on providing intuitive, supplemental insurance options for both trailer Owners (Hosts) and Renters. Here are some options currently available?",
      upper_description: (
        <>
          At this time, we are not offering the option for trailer to be
          delivered to Renter. Therefore, Renter and Renter’s vehicle MUST
          comply with our (Term-conditions for Renters) and pick up trailer from
          Host’s designated location.{" "}
        </>
      ),
      lower_description: (
        <>
          Our team will support you through the process, helping to ensure a
          smooth resolution.
        </>
      ),
      content: [
        {
          title: ``,
          point: (
            <>
              1. Important! As defined in Section : Specific Terms For Guests
              (https:// ), the Renter bears full financial responsibility for
              any physical damage or loss to the trailer while they are renting
              it. If this occurs, either party can initiate a dispute via the
              Trailer4Rent website in the event they are unable to come to a
              mutual agreement.
            </>
          ),
        },
        {
          title: ``,
          point: (
            <>
              2. Almost without exception, the towing vehicle’s insurance covers
              the liability costs when the trailer causes 3rd party property
              damage and/or bodily injury, but does not cover the contents being
              hauled or damage to the trailer. You as the Renter MUST confirm
              that the auto insurance on your towing vehicle is current and
              satisfactory, and verify that with the Host
            </>
          ),
        },
      ],
    },
    {
      question: "Can I get the trailer delivered to me? ",
      upper_description: (
        <>
          At this time, we are not offering the option for trailer to be
          delivered to Renter. Therefore, Renter and Renter’s vehicle MUST
          comply with our (Term-conditions for Renters) and pick up trailer from
          Host’s designated location.{" "}
        </>
      ),
      lower_description: "",
      content: [],
    },
    {
      question: "How do I get discounts when booking a trailer?",
      upper_description: (
        <>
          Good question! Everyone loves a discount! Trailer4Rent has a referral
          program! Check details here:{" "}
        </>
      ),
      lower_description: "",
      content: [],
    },
    {
      question: "Booking fees?",
      upper_description: (
        <>
          The good news is that there are no fees charged to the Renter from
          Trailer4Rent directly. Creating a Rental profile is free.
        </>
      ),
      lower_description: (
        <>
          As a good Renter, you should have sufficient insurance coverage, opt
          in to additional insurance for your trip and keep your vehicle in good
          mechanical condition. The Host will retain a refundable deposit via
          our site with the optimistic expectation to refund the deposit in it’s
          entirety back to you. Provided you take care of the rental unit.{" "}
          <br />
          Trailer4Rent.ca functions as a traditional marketplace. Currently,
          hosts retain 80% of their rental rate, with Trailer4Rent receiving the
          remaining 20%. Hosts set their own daily rental rate. Feel good in the
          fact that your rental fee is going to help a local in your community
          pay for their family’s needs and not going to a large corporation to
          increase their “bottom line”. Support local!
        </>
      ),
      content: [],
    },
    {
      question: "What happens if I have an accident?",
      upper_description: (
        <>
          If you have an accident while renting a trailer from Trailer4Rent,
          here’s what you should do:
        </>
      ),
      lower_description: <></>,
      content: [
        {
          title: `1. Ensure Safety First: `,
          point: (
            <>
              Make sure everyone is safe. If necessary, call emergency services.
            </>
          ),
        },
        {
          title: `2. Contact the Police: `,
          point: (
            <>
              Report the accident to the local authorities, especially if
              there’s property damage, injury, or if other vehicles are
              involved.
            </>
          ),
        },
        {
          title: `3. Document the Accident: `,
          point: (
            <>
              Take photos of the accident scene, including the trailer and any
              other vehicles involved, and note any damages.
            </>
          ),
        },
        {
          title: `4. Notify Trailer4Rent: `,
          point: (
            <>
              Contact Trailer4Rent as soon as possible to report the accident.
              We’ll guide you through the next steps, including the claims
              process and any repair arrangements.
            </>
          ),
        },
        {
          title: `5. Insurance: `,
          point: (
            <>
              Depending on the insurance coverage you chose during booking,
              we’ll work with you to determine what costs or deductibles may
              apply.
            </>
          ),
        },
        {
          title: `6. Repair Costs: `,
          point: (
            <>
              If you’re responsible for the accident, you may be liable for
              repair costs up to the deductible amount specified in the rental
              agreement or insurance terms.
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Stack alignItems={"center"}>
          <Stack maxWidth={"1000px"} mb={10}>
            <Typography
              fontWeight={"700"}
              textAlign={"center"}
              sx={{
                fontSize: {
                  xs: "50px",
                  sm: "50px",
                  md: "60px",
                  lg: "60px",
                  xl: "60px",
                },
                zIndex: "100",
              }}
            >
              FAQs
            </Typography>
            {/* <div
            style={{
              borderBottom: "25px solid brown",
              outline: "none",
              color: "#99000B26",
              marginTop: -50,
            }}
          ></div> */}
          </Stack>
          <Stack width={"100%"} mb={4}>
            {/* <Box className="tab-container">
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            variant="fullWidth"
                            textColor="inherit"
                            TabIndicatorProps={{
                                style: {
                                    display: 'none', // Hide default underline
                                },
                            }}
                        >
                            <Tab label="General" className={selectedTab === 0 ? 'tab-selected' : 'tab-default'} />
                            <Tab label="Guests" className={selectedTab === 1 ? 'tab-selected' : 'tab-default'} />
                            <Tab label="Hosts" className={selectedTab === 2 ? 'tab-selected' : 'tab-default'} />
                        </Tabs>
                    </Box> */}
            <div className="tabs-container">
              {tabs.map((tab) => (
                <div
                  key={tab}
                  className={`tab ${activeTab === tab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>
          </Stack>

          <Grid container alignItems={"stretch"} spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack sx={{ height: "100%" }}>
                <Grid container alignItems={"stretch"} spacing={3}>
                  {faqsData
                    .slice(0, 5)
                    .map(
                      (
                        {
                          question,
                          upper_description,
                          lower_description,
                          content,
                        },
                        index
                      ) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={index}
                        >
                          <Stack sx={{ height: "100%" }}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                  {question}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* Upper Description */}
                                {upper_description && (
                                  <Typography variant="body2" gutterBottom>
                                    {upper_description}
                                  </Typography>
                                )}

                                {/* Content Points */}
                                {content.length > 0 && (
                                  <Stack
                                    spacing={2}
                                    sx={{ marginLeft: "20px" }}
                                  >
                                    {content.map((item, idx) => (
                                      <div key={idx}>
                                        <Typography
                                          // variant="body2"
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          {item.title}
                                        </Typography>
                                        <Typography variant="body2">
                                          {item.point}
                                        </Typography>
                                      </div>
                                    ))}
                                  </Stack>
                                )}

                                {/* Lower Description */}
                                {lower_description && (
                                  <Typography
                                    variant="body2"
                                    // style={{ marginTop: "10px" }}
                                  >
                                    {lower_description}
                                  </Typography>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Stack>
                        </Grid>
                      )
                    )}
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack sx={{ height: "100%" }}>
                <Grid container alignItems={"stretch"} spacing={3}>
                  {faqsData
                    .slice(5, 12)
                    .map(
                      (
                        {
                          question,
                          upper_description,
                          lower_description,
                          content,
                        },
                        index
                      ) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={index}
                        >
                          <Stack sx={{ height: "100%" }}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography fontWeight="bold">
                                  {question}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {upper_description && (
                                  <Typography variant="body2" gutterBottom>
                                    {upper_description}
                                  </Typography>
                                )}
                                {content.length > 0 && (
                                  <Stack
                                    spacing={2}
                                    sx={{ marginLeft: "20px" }}
                                  >
                                    {content.map((item, idx) => (
                                      <div key={idx}>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          {item.title}
                                        </Typography>
                                        <Typography variant="body2">
                                          {item.point}
                                        </Typography>
                                      </div>
                                    ))}
                                  </Stack>
                                )}

                                {lower_description && (
                                  <Typography
                                    variant="body2"
                                    style={{ marginTop: "10px" }}
                                  >
                                    {lower_description}
                                  </Typography>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Stack>
                        </Grid>
                      )
                    )}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default FAQs;
