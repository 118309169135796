// import React from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Stack,
//   Container,
//   Grid,
// } from "@mui/material";
// import "./ReviewSlider.css"; // Your custom CSS file
// import ann_mike from "../../assets/ann_mike.png";

// const reviews = [
//   {
//     name: "Ann & Mike Reynolds",
//     imgSrc: ann_mike,
//     content:
//       "We already had the trailer sitting there. Now, we have a side income stream generating from it! Pays for our vacations! Thanks, Trailer4Rent!",
//     role: "Momentum Owners",
//   },
// ];

// const UniqueReviewSlider = () => {
//   return (
//     <div className="review-slider-wrapper">

//       <Container maxWidth="lg">
//         <Grid container>
//         <Grid item xs={12} sm={12} md={7} lg={7} xl={7}></Grid>
//         <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
//         <Stack
//           alignItems={{
//             xl: "center",
//             lg: "center",
//             md: "center",
//             sm: "center",
//             xs: "center",
//           }}
//           justifyContent={"center"}
//         >
//           <Typography
//             variant="h4"
//             color={"white"}
//             alignItems={"center"}
//             // sx={{ margin: "0 200px" }}
//             mb={3}
//             sx={{
//               fontWeight: "800",
//               fontFamily: "Book Antiqua, serif !important",
//             }}
//           >
//             {/* WHAT THEY SAY ABOUT US */}
//             What Hosts are saying
//           </Typography>
//           {reviews.map((review, index) => (
//             <Stack
//               className="review-card"
//               key={index}
//               sx={{ boxShadow: "none" }}
//             >
//               <CardContent sx={{ boxShadow: "none" }}>
//                 <Stack alignItems={"center"}>
//                   <Avatar
//                     src={review.imgSrc}
//                     alt={review.name}
//                     sx={{ height: "150px", width: "150px" }}
//                   />
//                 </Stack>
//                 <div className="review-stars">
//                   {"⭐".repeat(5)}
//                 </div>
//                 <Typography variant="h5" className="review-name">
//                   {review.name}
//                 </Typography>
//                 <Typography className="review-content">
//                   {review.content}
//                 </Typography>
//               </CardContent>
//             </Stack>
//           ))}
//         </Stack>

//         </Grid>
//         </Grid>

//       </Container>
//     </div>
//   );
// };

// export default UniqueReviewSlider;

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Stack,
  Container,
  Grid,
} from "@mui/material";
import "./ReviewSlider.css"; // Your custom CSS file
import ann_mike from "../../assets/ann_mike.png";

const reviews = [
  {
    name: "Ann & Mike Reynolds",
    imgSrc: ann_mike,
    content:
      "We already had the trailer sitting there. Now, we have a side income stream generating from it! Pays for our vacations! Thanks, Trailer4Rent!",
    role: "Momentum Owners",
  },
];

const UniqueReviewSlider = () => {
  return (
    <div className="review-slider-wrapper">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}></Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Stack
              alignItems={{
                xl: "center",
                lg: "center",
                md: "center",
                sm: "center",
                xs: "center",
              }}
              justifyContent={"center"}
            >
              <Typography
                variant="h4"
                color={"white"}
                mb={3}
                sx={{
                  fontWeight: "800",
                  fontFamily: "Book Antiqua, serif !important",
                }}
              >
                What Hosts Are Saying
              </Typography>
              {reviews.map((review, index) => (
                <Stack
                  className="review-card"
                  key={index}
                  sx={{
                    boxShadow: "none",
                    position: "relative", // Added for absolute positioning of text
                  }}
                >
                  <CardContent sx={{ boxShadow: "none" }}>
                    <Stack alignItems={"center"}>
                      <Avatar
                        src={review.imgSrc}
                        alt={review.name}
                        sx={{ height: "150px", width: "150px" }}
                      />
                    </Stack>
                    <div className="review-stars">{"⭐".repeat(5)}</div>
                    <Typography variant="h5" className="review-name">
                      {review.name}
                    </Typography>
                    <Typography className="review-content">
                      {review.content}
                    </Typography>
                  </CardContent>
                  {/* Text Positioned at Bottom Left */}

                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

      </Container>
      <Typography
        sx={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional for better visibility
          padding: "5px",
          borderRadius: "4px",
          fontSize: "16px",
        }}
      >
        Horse/Livestock Trailer Rental
      </Typography>
    </div>
  );
};

export default UniqueReviewSlider;
