import React from "react";
import css from "./TrailersStepsSection.module.css";
import { Container, Divider, Stack, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";

import { BiSolidRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleUserType } from "../../../store/slices/userTypeSlice";

const TrailersStepsSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rentATrailerSteps = [
    `Browse through trailers in your area.`,
    `Book the trailer that's right for your job.`,
    `Pick up trailer directly from Host.`,
  ];
  const becomeAHostSteps = [
    `Set up your Trailer Host account.`,
    `Upload photos and list your trailer.`,
    `Watch the "side hustle" income roll in!`,
  ];

  const _handleHostSignup = ()=>{
    dispatch(toggleUserType())
    navigate('/signup')
  }

  return (
    <Stack className={css.wrapper}>
      <Stack
        sx={{
          height: "100%",
          background:
            "linear-gradient(180deg, #000 -35.28%, rgba(0, 0, 0, 0.19) 100%)",
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            className={css.how_works_title}
            sx={{
              fontSize: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "35px",
                xs: "35px",
              },
            }}
            mt={7}
          >
            How Easily It Works
          </Typography>
          <Grid container alignItems="stretch">
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Stack
                sx={{
                  height: "100%",
                  padding: {
                    xl: "50px 0px 70px 0px",
                    lg: "50px 0px 70px 0px",
                    md: "50px 0px 70px 0px",
                    sm: "50px 0px 20px 0px",
                    xs: "50px 0px 20px 0px",
                  },
                }}
                gap={10}
              >
                <Typography className={css.rent_a_trailer_txt}>
                  To rent a trailer
                </Typography>

                <Stack gap={5} sx={{ position: "relative" }}>
                  <Stack gap={3}>
                    {rentATrailerSteps.map((step, i) => {
                      return (
                        <Stack direction={"row"} alignItems={"center"} gap={4}>
                          <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              padding: "10px 15px",
                              borderRadius: "10px",
                              backgroundColor: "#ffe9e9",
                            }}
                          >
                            <Typography className={css.step_txt}>
                              Step {i + 1}
                            </Typography>
                          </Stack>

                          <Typography className={css.point}>{step}</Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack alignItems={"flex-end"} justifyContent={"center"}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={2}
                      className={css.get_started_btn}
                      onClick={() => navigate("/rent-trailer")}
                    >
                      <Typography className={css.get_started_btn_txt}>
                        Get Started
                      </Typography>
                      <BiSolidRightArrow className={css.arrow_icon} />
                    </Stack>
                  </Stack>

                  {/* <Typography className={css.trailer_rentals_txt}>
                    Horse/Livestock Trailer Rentals
                  </Typography> */}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={0} xs={0}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: "100%" }}
              >
                {/* <Stack className={css.vertical_line}></Stack> */}
                <Divider
                  orientation="vertical"
                  sx={{
                    borderWidth: "1px",
                    borderColor: "white",
                    height: "62%",
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Stack
                sx={{
                  height: "100%",
                  padding: {
                    xl: "50px 0px 70px 0px",
                    lg: "50px 0px 70px 0px",
                    md: "50px 0px 70px 0px",
                    sm: "50px 0px 50px 0px",
                    xs: "50px 0px 50px 0px",
                  },
                }}
                gap={10}
              >
                <Typography className={css.rent_a_trailer_txt}>
                  To become a Trailer Host
                </Typography>
                <Stack gap={5}>
                  <Stack gap={3}>
                    {becomeAHostSteps.map((step, i) => {
                      return (
                        <Stack direction={"row"} alignItems={"center"} gap={4}>
                          <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              padding: "10px 15px",
                              borderRadius: "10px",
                              backgroundColor: "#ffe9e9",
                            }}
                          >
                            <Typography className={css.step_txt}>
                              Step {i + 1}
                            </Typography>
                          </Stack>

                          <Typography className={css.point}>{step}</Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack alignItems={"flex-end"} justifyContent={"center"}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={2}
                      className={css.get_started_btn}
                      onClick={()=> _handleHostSignup()}
                    >
                      <Typography className={css.get_started_btn_txt}>
                        Get Started
                      </Typography>
                      <BiSolidRightArrow className={css.arrow_icon} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Typography
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional for better visibility
            padding: "5px",
            borderRadius: "4px",
            fontSize: "16px",
          }}
        >
          Horse/Livestock Trailer Rentals
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TrailersStepsSection;
