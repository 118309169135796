import React from "react";
import "./index.css"; // Import the CSS file
import icbc_logo from "../../assets/icbc_logo.jpg";

import { Container, Grid, Stack, Typography } from "@mui/material";
import "./PromoBanner.css";
import logo from "../../assets/logoIcon.png";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HeroSection = () => {
  const navigate = useNavigate();
  const { user, userAuth } = useSelector((state) => state.userAuth);

  const navigateHandle = () => {
    if (userAuth && user) {
      if (
        userAuth &&
        !user?.email_verified &&
        !user?.number_verified &&
        user?.kyc === "Idle"
      ) {
        navigate("/OTPForm");
      } else if (
        userAuth &&
        user?.email_verified &&
        user?.number_verified &&
        user?.kyc === "Idle"
      ) {
        navigate("/StepOne");
      } else {
        navigate("/dashboard-home");
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <Stack bgcolor={"white"}>
      <Container maxWidth="lg" sx={{ bgcolor: "white" }}>
        <Grid container>
          <Grid md={6} lg={6} xl={6} sm={12} xs={12} px={1}>
            <Stack px={2} alignItems={"end"}>
              <div className="promo-banner">
                <div className="promo-text">
                  <div
                    style={{ alignItems: "center", display: "flex", gap: 10 }}
                  >
                    <h4>Let's get YOU rolling!</h4>{" "}
                    <img
                      style={{ marginBottom: 2 }}
                      src={logo}
                      alt="icon"
                      width={100}
                    />
                  </div>
                  {/* <h1
                    style={{
                      fontFamily: "Book Antiqua, serif !important",
                    }}
                  >
                    List for <span className="highlight">FREE</span> & start
                    making money with your current trailer{" "}
                    <span className="highlight">NOW!</span>
                  </h1> */}
                  <Typography
                    variant="h1"
                    sx={{
                      lineHeight: "normal",
                      fontFamily: "Book Antiqua, serif !important",
                    }}
                  >
                    List for <span className="highlight">FREE</span> & start
                    making money with your current trailer{" "}
                    <span className="highlight">NOW!</span>
                  </Typography>
                  {/* <button className="cta-button">Get Started</button> */}
                  <CustomButton
                    onClick={navigateHandle}
                    style={{
                      backgroundColor: themeRed,
                      color: "white",
                    }}
                    children={"Get Started"}
                  />
                  <div className="insurance">
                    INSURANCE PROVIDED BY &nbsp; 
                    {/* <span className="insurance-provider">TRAVELERS®</span> */}
                    <img
                      src={icbc_logo}
                      style={{
                        height: "35px",
                        width: "35px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Stack>
          </Grid>
          <Grid
            md={6}
            lg={6}
            xl={6}
            sm={12}
            xs={12}
            px={2}
            bgcolor={"white"}
          ></Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default HeroSection;
