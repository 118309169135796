// import React, { useState, useRef, useEffect } from "react";
// import {
//   Button,
//   TextField,
//   Stack,
//   List,
//   ListItem,
//   Typography,
//   InputAdornment,
// } from "@mui/material";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { useNavigate } from "react-router-dom";
// import CustomLocationSearch from "../IconInput/customInput";

// // List of predefined Canadian provinces with cities
// const locations = [
//   {
//     name: "Alberta",
//     code: "AB",
//     cities: ["Calgary", "Edmonton", "Red Deer", "Lethbridge", "St. Albert"],
//   },
//   {
//     name: "British Columbia",
//     code: "BC",
//     cities: ["Vancouver", "Victoria", "Surrey", "Burnaby", "Richmond"],
//   },
//   {
//     name: "Manitoba",
//     code: "MB",
//     cities: [
//       "Winnipeg",
//       "Brandon",
//       "Steinbach",
//       "Thompson",
//       "Portage la Prairie",
//     ],
//   },
//   {
//     name: "New Brunswick",
//     code: "NB",
//     cities: ["Fredericton", "Moncton", "Saint John", "Miramichi", "Bathurst"],
//   },
//   {
//     name: "Nova Scotia",
//     code: "NS",
//     cities: ["Halifax", "Sydney", "Truro", "New Glasgow", "Glace Bay"],
//   },
//   {
//     name: "Nunavut",
//     code: "NU",
//     cities: [
//       "Iqaluit",
//       "Rankin Inlet",
//       "Arviat",
//       "Baker Lake",
//       "Cambridge Bay",
//     ],
//   },
//   {
//     name: "Ontario",
//     code: "ON",
//     cities: ["Toronto", "Ottawa", "Mississauga", "Brampton", "Hamilton"],
//   },
//   {
//     name: "Prince Edward Island",
//     code: "PE",
//     cities: [
//       "Charlottetown",
//       "Summerside",
//       "Stratford",
//       "Cornwall",
//       "Montague",
//     ],
//   },
//   {
//     name: "Quebec",
//     code: "QC",
//     cities: ["Montreal", "Quebec City", "Laval", "Gatineau", "Longueuil"],
//   },
//   {
//     name: "Saskatchewan",
//     code: "SK",
//     cities: ["Saskatoon", "Regina", "Prince Albert", "Moose Jaw", "Yorkton"],
//   },
//   {
//     name: "Yukon",
//     code: "YT",
//     cities: [
//       "Whitehorse",
//       "Dawson City",
//       "Watson Lake",
//       "Haines Junction",
//       "Carmacks",
//     ],
//   },
//   {
//     name: "Northwest Territories",
//     code: "NT",
//     cities: ["Yellowknife", "Hay River", "Inuvik", "Fort Smith", "Behchokǫ̀"],
//   },
//   {
//     name: "Newfoundland and Labrador",
//     code: "NL",
//     cities: [
//       "St. John's",
//       "Corner Brook",
//       "Gander",
//       "Mount Pearl",
//       "Happy Valley-Goose Bay",
//     ],
//   },
// ];

// const LocationSearch = ({ isSearch = true, placeholder, onLocationSelect }) => {
//   const [inputValue, setInputValue] = useState("");
//   const [filteredResults, setFilteredResults] = useState([]);
//   const [showSuggestions, setShowSuggestions] = useState(false);
//   const [location, setLocation] = useState("");

//   const navigate = useNavigate();
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         containerRef.current &&
//         !containerRef.current.contains(event.target)
//       ) {
//         setShowSuggestions(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     setInputValue(value);

//     if (!value.trim()) {
//       setFilteredResults([]);
//       setShowSuggestions(false);
//       return;
//     }

//     const filtered = locations.flatMap((state) => [
//       ...(state.name.toLowerCase().includes(value.toLowerCase())
//         ? [{ ...state, type: "province" }]
//         : []),
//       ...state.cities
//         .filter((city) => city.toLowerCase().includes(value.toLowerCase()))
//         .map((city) => ({ name: city, code: state.code, type: "city" })),
//     ]);

//     setFilteredResults(filtered);
//     setShowSuggestions(true);
//   };

//   const handleSuggestionClick = (selection) => {
//     setInputValue(selection.name);
//     setShowSuggestions(false);

//     if (onLocationSelect) {
//       onLocationSelect(selection.code);
//     }
//   };

//   const handleSearchClick = () => {
//     const selected = filteredResults.find(
//       (result) => result.name === inputValue
//     );
//     if (selected) {
//       if (onLocationSelect) {
//         onLocationSelect(selected.code);
//       }
//       navigate("/trailer-map", { state: { location: selected.code } });
//     } else {
//       alert("Please select a valid location from suggestions.");
//     }
//   };

//   const _handleSelectedLocation = (value) => {
//     console.log("values", value);
//     setLocation(value.value.place_id);
//   };
//   return (
//     <Stack sx={{ width: "100%" }} ref={containerRef}>
//       <Stack
//         flexDirection={"row"}
//         alignItems={"center"}
//         sx={{
//           backgroundColor: "white",
//           width: { xl: "50vw", lg: "60vw", md: "70vw", sm: "80vw", xs: "90vw" },
//           borderRadius: 2,
//           boxShadow: 3,
//           position: "relative",
//         }}
//       >
//         <Stack sx={{ flex: 1 }}>

//           <TextField
//             value={inputValue}
//             onChange={handleInputChange}
//             placeholder={placeholder || "I'm looking for a trailer near..."}
//             variant="outlined"
//             fullWidth
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <LocationOnIcon color="error" />
//                 </InputAdornment>
//               ),
//               style: { border: "none", boxShadow: "none" },
//             }}
//             sx={{
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": { border: "none" },
//               },
//             }}
//           />

//         </Stack>
//         {isSearch && (
//           <Stack px={1}>
//             <Button
//               variant="contained"
//               color="error"
//               onClick={handleSearchClick}
//             >
//               Search
//             </Button>
//           </Stack>
//         )}
//       </Stack>

//       {showSuggestions && filteredResults.length > 0 && (
//         <List
//           sx={{
//             width: {
//               xl: "50vw",
//               lg: "60vw",
//               md: "70vw",
//               sm: "80vw",
//               xs: "90vw",
//             },
//             position: "absolute",
//             mt: 8,
//             maxHeight: 300,
//             overflowY: "auto",
//             bgcolor: "background.paper",
//             boxShadow: 3,
//             zIndex: "10000 !important",
//           }}
//         >
//           {filteredResults.map((result, index) => (
//             <ListItem
//               key={index}
//               onClick={() => handleSuggestionClick(result)}
//               sx={{
//                 padding: "8px 16px",
//                 cursor: "pointer",
//                 display: "flex",
//                 alignItems: "center",
//                 "&:hover": {
//                   backgroundColor: "#f0f0f0",
//                   textDecoration: "underline",
//                 },
//                 borderBottom: "1px solid #e0e0e0",
//               }}
//             >
//               <LocationOnIcon sx={{ marginRight: 2, color: "red" }} />
//               <Typography
//                 sx={{ color: "black", width: "100%", whiteSpace: "normal" }}
//               >
//                 {result.name} {result.type === "city" ? `(${result.code})` : ""}
//               </Typography>
//             </ListItem>
//           ))}
//         </List>
//       )}
//     </Stack>
//   );
// };

// export default LocationSearch;

import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomLocationSearch from "../IconInput/customInput";

const LocationSearch = ({ isSearch = true, placeholder, onLocationSelect }) => {
  const [location, setLocation] = useState("");

  const navigate = useNavigate();

  const handleSearchClick = () => {
    if (location) {
      navigate("/trailer-map", { state: { location: location } });
    } else {
      alert("Please select a valid location from suggestions.");
    }
  };

  const _handleSelectedLocation = (value) => {
    setLocation(value?.label);
  };
  return (
    <Stack sx={{ width: "100%" }}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          backgroundColor: "white",
          width: { xl: "50vw", lg: "60vw", md: "70vw", sm: "80vw", xs: "90vw" },
          borderRadius: 2,
          boxShadow: 3,
          position: "relative",
        }}
      >
        <Stack sx={{ flex: 1 }}>
          <CustomLocationSearch
            textCondition={false}
            setSelectedLocationProps={_handleSelectedLocation}
          />
        </Stack>
        {isSearch && location && (
          <Stack px={1}>
            <Button
              variant="contained"
              color="error"
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default LocationSearch;
