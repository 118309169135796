import React from "react";
import Slider from "react-slick";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSlider.css";
import { useNavigate } from "react-router-dom";
import { themeRed } from "../utils/colorTheme";
import ButtonComp from "./common/ButtonComp";

// Custom arrows for the slider
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        // left: "-50px",
        right: "45px",
        top: "-15%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        background: "#f5f5f5",
        // borderRadius: "50%",
        // padding: "10px",
        // boxShadow: "0 2px 10px rgba(0,0,0,0.1)"
      }}
    >
      <ArrowBackIosNew style={{ fontSize: "24px", color: themeRed }} />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: "0px",
        top: "-15%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        background: "#f5f5f5",
      }}
    >
      <ArrowForwardIos style={{ fontSize: "24px", color: themeRed }} />
    </div>
  );
};

const SecondSectionSlider = ({ trailersData }) => {
  const navigate = useNavigate();
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of visible slides at a time
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1103,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ margin: "50px auto", width: "100%", position: "relative" }}>
      <Typography variant="body1" gutterBottom fontWeight={"bold"} sx={{
        fontSize: {
          xs: "20px",
          sm: "2.125rem",
          md: "2.125rem",
          lg: "2.125rem",
          xl: "2.125rem",
        }
      }}>
        Search by Category
      </Typography>
      <Stack sx={{ padding: "15px 0px" }}>
        <Slider {...settings}>
          {trailersData?.map((elem, index) => {
            return (
              <Stack gap={4}>
                <div className="unique-carousel-wrapper">
                  <div className="unique-carousel-item">
                    <img
                      src={elem?.image}
                      alt={elem?.name}
                      className="trailer_img"
                    />
                  </div>
                  <div className="unique-carousel-content-section">
                    <p class="title-text">{elem?.name || ""}</p>
                    {/* <br /> */}
                    <p class="category_description">{elem?.description || ""}</p>
                    {/* <br /> */}
                    <ButtonComp
                      label="Rent Now!"
                      onClick={() => navigate(`/view-category/${elem?.name}`)}
                    />
                  </div>
                </div>
              </Stack>
            );
          })}
        </Slider>
      </Stack>
    </div>
  );
};

export default SecondSectionSlider;
