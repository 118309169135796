import { Stack } from "@mui/system";
import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconTextField from "../../component/IconInput/IconInput";
import { FaRegEyeSlash, FaUser } from "react-icons/fa";
import { MdEmail, MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineNumbers } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../screens/Footer/Footer";
import "./auth.css";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { signup_service_auth } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import TermsConditionCheck from "./TermsConditionCheck";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { user_register_status } = useSelector((state) => state.userAuth);
  const userType = useSelector((state) => state.userType.userType);

  // State to store form data
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phonenumber: "",
    password: "",
  });

  // State for errors
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phonenumber: "",
    password: "",
  });

  // Validation functions
  const validateFirstName = (name) => {
    const nameRegex = /^[A-Za-z]+$/; // Only letters allowed
    if (!nameRegex.test(name)) {
      return "First name can only contain English letters.";
    }
    return "";
  };

  const validateLastName = (name) => {
    const nameRegex = /^[A-Za-z]+$/; // Only letters allowed
    if (!nameRegex.test(name)) {
      return "Last name can only contain English letters.";
    }
    return "";
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[A-Za-z]{1,5}[A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!emailRegex.test(email)) {
      return "Email should have a maximum of 5 alphabetic characters before '@'.";
    }
    return "";
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{9,10}$/; // Only digits, either 9 or 10
    if (!phoneRegex.test(phone)) {
      return "Phone number must be 9 or 10 digits.";
    }
    return "";
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(password)) {
      return "Password must contain at least one letter, one number, one special character, and be at least 8 characters.";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent spaces in any field
    if (/\s/.test(value)) return;

    // Update the form data
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validation for each field
    let errorMsg = "";
    if (name === "first_name") errorMsg = validateFirstName(value);
    if (name === "last_name") errorMsg = validateLastName(value);
    if (name === "email") errorMsg = validateEmail(value);
    if (name === "phonenumber") errorMsg = validatePhoneNumber(value);
    if (name === "password") errorMsg = validatePassword(value);

    // Update errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  const handlePaste = (e, field) => {
    const pastedData = e.clipboardData.getData("Text");
    if (field === "phonenumber" && !/^[0-9]{9,10}$/.test(pastedData)) {
      e.preventDefault();
    }
  };

  const handleSubmit = () => {
    const newErrors = {
      first_name: validateFirstName(data.first_name),
      last_name: validateLastName(data.last_name),
      email: validateEmail(data.email),
      phonenumber: validatePhoneNumber(data.phonenumber),
      password: validatePassword(data.password),
    };

    setErrors(newErrors);

    const errorMessages = Object.values(newErrors).filter((msg) => msg !== "");
    const obj = {
      role: userType,
      send_promotions: false,
      status: "Requested",
      ...data,
    };
    dispatch(signup_service_auth(obj));

    // navigate("/OTPForm");
  };

  return (
    <Stack bgcolor={"white"} minHeight="100vh">
      <Navbar />

      <Stack
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        padding={2}
      >
        <Stack width={"100%"} maxWidth={"800px"} bgcolor={"#fff"} padding={4}>
          <Typography
            variant="h4"
            fontWeight={"700"}
            component="h1"
            align="start"
            gutterBottom
          >
            Sign Up
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <IconTextField
                label="First Name*"
                placeholder="Your First Name"
                icon={<FaUser style={{ color: "#b20e1c" }} />}
                name="first_name"
                value={data.first_name}
                onChange={handleChange}
                error={!!errors.first_name}
                helperText={errors.first_name}
                inputProps={{
                  maxLength: 50, // Optional: Set maximum length for first name
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <IconTextField
                label="Last Name*"
                placeholder="Your Last Name"
                icon={<FaUser style={{ color: "#b20e1c" }} />}
                name="last_name"
                value={data.last_name}
                onChange={handleChange}
                error={!!errors.last_name}
                helperText={errors.last_name}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
          </Grid>

          {/* Email Input */}
          <IconTextField
            label="Email Address*"
            placeholder="Your Email"
            icon={<MdEmail style={{ color: "#b20e1c" }} />}
            name="email"
            value={data.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />

          {/* Phone Number Input */}
          <IconTextField
            label="Phone Number*"
            placeholder="Your Number"
            icon={<MdOutlineNumbers style={{ color: "#b20e1c" }} />}
            name="phonenumber"
            value={data.phonenumber}
            onChange={handleChange}
            onPaste={(e) => handlePaste(e, "phonenumber")}
            error={!!errors.phonenumber}
            helperText={errors.phonenumber}
            inputProps={{
              maxLength: 9, // Restrict to 9 digits for phone number
              pattern: "[0-9]*", // Allow only numbers
            }}
          />

          {/* Password Input */}
          <Stack sx={{ position: "relative" }}>
            <IconTextField
              label="Password*"
              placeholder="Your Password"
              icon={<FaLock style={{ color: "#b20e1c" }} />}
              name="password"
              value={data.password}
              onChange={handleChange}
              type={showPass ? "text" : "password"}
              error={!!errors.password}
              helperText={errors.password}
              inputProps={{
                maxLength: 50,
              }}
            />
            <IconButton
              onClick={() => setShowPass(!showPass)}
              sx={{ position: "absolute", top: "41px", right: "4px" }}
            >
              {showPass ? (
                <FaRegEyeSlash style={{ color: "#b20e1c" }} />
              ) : (
                <MdOutlineRemoveRedEye style={{ color: "#b20e1c" }} />
              )}
            </IconButton>
          </Stack>

          <TermsConditionCheck
            onChange={(accepted) => setTermsAccepted(accepted)}
          />

          {/* <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="error"
                        fullWidth
                    >
                        Sign up
                    </Button> */}
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={handleSubmit}
            disabled={user_register_status === asyncStatus.LOADING || !termsAccepted}
          >
            {user_register_status === asyncStatus.LOADING ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign up"
            )}
          </Button>

          <Typography variant="body2" align="center" mt={2}>
            Already have an account?{" "}
            <a style={{ color: themeRed }} href="/login">
              Login
            </a>
          </Typography>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default SignUp;
