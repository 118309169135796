import Appliance from "../assets/Card Images/appliance.png"
import Appliance_Repair from "../assets/Card Images/Appliance_Repair.png"
import BBQ_Cleaning from "../assets/Card Images/BBQ_Cleaning.png"
import Carpet_Upholstery from "../assets/Card Images/Carpet_&_Upholstery.png"
import Duct_Cleaning from "../assets/Card Images/Duct_Cleaning.png"
import Electrical from "../assets/Card Images/Electrical.png"
import Junk_Removal from "../assets/Card Images/Junk_Removal.png"
import Holiday_Lights from "../assets/Card Images/Holiday_Lights.png"
import Furniture_Assembly from "../assets/Card Images/Furniture_Assembly.png"
import Garage_Door_Repair from "../assets/Card Images/Garage_Door_Repair.png"
import Gas_Services from "../assets/Card Images/Gas_Services.png"
import Lawn_Maintenance from "../assets/Card Images/Lawn_Maintenance.png"
import Heating_Cooling from "../assets/Card Images/Heating_&_Cooling.png"
import Home_Inspection from "../assets/Card Images/Home_Inspection.png"
import Locksmith from "../assets/Card Images/Locksmith.png"
import Painting from "../assets/Card Images/Painting.png"
import Pest_Control from "../assets/Card Images/Pest_Control.png"
import Power_Wash from "../assets/Card Images/Power_Wash.png"
import Window_Eaves from "../assets/Card Images/Window_&_Eaves.png"
import Mobile_Auto_Detail from "../assets/Card Images/Mobile_Auto_Detail.png"
import Plumbing from "../assets/Card Images/Plumbing.png"
import Moving_Delivery from "../assets/Card Images/Moving_&_Delivery.png"
import Snow_Removal from "../assets/Card Images/Snow_Removal.png"
import Handyman_Services from "../assets/Card Images/Handyman_Services.png"


export const CardData = [
    {
        title: "Appliance Install",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Appliance
    },
    {
        title: "Appliance Repair",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Appliance_Repair
    },
    {
        title: "BBQ Cleaning",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: BBQ_Cleaning
    },
    {
        title: "Carpet & Upholstery",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Carpet_Upholstery
    },
    {
        title: "Duct Cleaning",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Duct_Cleaning
    },
    {
        title: "Electrical",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Electrical
    },
    {
        title: "Junk Removal",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Junk_Removal
    },
    {
        title: "Holiday Lights",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Holiday_Lights
    },
    {
        title: "Furniture Assembly",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Furniture_Assembly
    },
    {
        title: "Garage Door Repair",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Garage_Door_Repair
    },
    {
        title: "Gas Services",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Gas_Services
    },
    {
        title: "Lawn Maintenance",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Lawn_Maintenance
    },
    {
        title: "Heating & Cooling",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Heating_Cooling
    },
    {
        title: "Home Inspection",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Home_Inspection
    },
    {
        title: "Locksmith",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Locksmith
    },
    {
        title: "Painting",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Painting
    },
    {
        title: "Pest Control",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Pest_Control
    },
    {
        title: "Power Wash",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Power_Wash
    },
    {
        title: "Window & Eaves",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Window_Eaves
    },
    {
        title: "Mobile Auto Detail",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Mobile_Auto_Detail
    },
    {
        title: "Plumbing",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Plumbing
    },
    {
        title: "Moving & Delivery",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Moving_Delivery
    },
    {
        title: "Snow Removal",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Snow_Removal
    },
    {
        title: "Handyman Services",
        subTitle: 'Gorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: Handyman_Services
    },

]


export const categoryOptions = [
    "Enclosed Trailer Rentals",
    "Dump Trailer Rentals",
    "Flatdeck Trailer Rentals",
    "Horse Trailer Rentals",
    // "Motorcycle Trailer Rentals",
    "Deck-Above Trailer Rentals",
    "Speciality Trailer Rentals",
    "Open Utility Trailer Rentals",
    "Car Hauler Trailer Rentals",
    // "Boat Trailer Rentals",
    // "Tow Dolly Trailer Rentals",
    // "Cargo Trailer Rental",
];
export const lightPlugOptions = ["Flat 4 plug", "7 pin plug"];
export const hitchTypeOptions = [
    "Bumper Pull",
    "Pintle",
    "Gooseneck",
    "Fifth Wheel",
    "Other",
];
export const ballSizeOptions = [
    "3 inch",
    "2-5/16 inch",
    "2 inch",
    "1-7/8 inch",
    "Pintle",
    "Fifth Wheel",
];
export const provinces = [
    "British Columbia",
    "Alberta",
    "Saskatchewan",
    "Manitoba",
    "Ontario",
    "Quebec",
    "New Brunswick",
    "Prince Edward Island (PEI)",
    "Nova Scotia",
    "Newfoundland and Labrador",
    "Yukon",
    "Northwest Territories (NWT)",
    "Nunavut",
];

export const axleOptions = [
    "2000lb",
    "1x3500lb",
    "1x5000lb",
    "2x3500lb",
    "2x5000lb",
    "2x7000lb",
    "2x10000lb",
    "2x12000lb",
    "other",
];

export const statesOptions = [
    {
        label: "Alberta",
        value: "AB",
    },
    {
        label: "British Columbia",
        value: "BC",
    },
    {
        label: "Manitoba",
        value: "MB",
    },
    {
        label: "New Brunswick",
        value: "NB",
    },
    {
        label: "Newfoundland and Labrador",
        value: "NL",
    },
    {
        label: "Northwest Territories",
        value: "NT",
    },
    {
        label: "Nova Scotia",
        value: "NS",
    },
    {
        label: "Nunavut",
        value: "NU",
    },
    {
        label: "Ontario",
        value: "ON",
    },
    {
        label: "Prince Edward Island",
        value: "PE",
    },
    {
        label: "Quebec",
        value: "QC",
    },
    {
        label: "Saskatchewan",
        value: "SK",
    },
    {
        label: "Yukon",
        value: "YT",
    },
];