// import React from "react";
// import Navbar from "../../component/navbar/Navbar";
// import Footer from "../Footer/Footer";

// const OurMission = () => {
//   return (
//     <div>
//       <Navbar />

//       <Footer />
//     </div>
//   );
// };

// export default OurMission;
import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import our_mission_img from "../../assets/SideBySides.jpg";

const OurMission = () => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Navbar />
      {/* Header Section */}
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${our_mission_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {/* Dark Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
        {/* Header Text */}
        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontFamily: "Book Antiqua, serif !important",
              color: "white",
              textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
            }}
          >
            Our Mission
          </Typography>
        </Container>
          <Typography
            sx={{
              position: "absolute",
              bottom: "10px",
              left: "10px",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional for better visibility
              padding: "5px",
              borderRadius: "4px",
              fontSize: "16px",
            }}
          >
            Open Utility Trailer Rental
          </Typography>
      </Box>

      {/* About Content Section */}
      <Container maxWidth="lg" sx={{ padding: "60px 0" }}>
        <Box mt={8} textAlign="center">
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "16px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              borderBottom: "10px solid #C3282F",
              paddingBottom: "4px",
            }}
          >
            Our Mission Is to Benefit Our Users:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              maxWidth: "800px",
              margin: "16px auto 0",
              lineHeight: "1.8",
            }}
          >
            At Trailer4Rent.ca, we are proudly Canada's peer-to-peer marketplace
            for trailer rentals, connecting owners and renters through a secure,
            user-friendly platform that simplifies the rental process. Whether
            you're a contractor, landscaper, rancher, or a parent moving your
            kid away (or back!) home, finding a reliable trailer rental has
            often been a challenge. With thousands of underutilized trailers
            across Canada, the need for a solution has always existed—until now.
            Trailer4Rent.ca bridges this gap, offering a seamless and secure
            solution that benefits both parties. Our platform empowers trailer
            owners to earn passive income effortlessly while providing renters
            with a stress-free, legal, and dependable rental experience. Why
            take unnecessary risks renting for cash? With Trailer4Rent.ca, you
            gain access to the tools and resources you need for a worry-free
            rental process, making trailer sharing safe, simple, and mutually
            rewarding.
          </Typography>
        </Box>

        <Box textAlign="center" mt={8}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#C3282F",
              marginBottom: "16px",
            }}
          >
            Let’s get YOU rolling!
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default OurMission;
