// import React from 'react'

// const HostDetailsScreen = () => {
//   return (
//     <div>HostDetailsScreen</div>
//   )
// }

// export default HostDetailsScreen

import { useNavigate } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
// import "../index.css";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
// import '../../pages/home.css'
import "./hostDetail.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import ProfileImage from "../../assets/background/profile-image.png";
import { EmojiEvents, FavoriteBorder, Star } from "@mui/icons-material";
import { themeRed } from "../../utils/colorTheme";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import CustomButton from "../../component/common/Button/Button";
import { useState } from "react";
import { SlBadge } from "react-icons/sl";
import { useSelector } from "react-redux";

const vehicles = [
  {
    name: "Enclosed Trailer",
    rating: 5.0,
    trips: 131,
    price: "$104/day",
    img: "https://via.placeholder.com/300x150",
  },
  {
    name: "Enclosed Trailer",
    rating: 5.0,
    trips: 74,
    price: "$93/day",
    img: "https://via.placeholder.com/300x150",
  },
  {
    name: "Enclosed Trailer",
    rating: 5.0,
    trips: 29,
    price: "$83/day",
    img: "https://via.placeholder.com/300x150",
  },
  {
    name: "Enclosed Trailer",
    rating: 5.0,
    trips: 74,
    price: "$104/day",
    img: "https://via.placeholder.com/300x150",
  },
  {
    name: "Enclosed Trailer",
    rating: 5.0,
    trips: 29,
    price: "$83/day",
    img: "https://via.placeholder.com/300x150",
  },
  // Add more vehicles as needed
];

function HostDetailsScreen() {
  const navigate = useNavigate();
  const [value, setValue] = useState(2);

  const { user } = useSelector((state) => state.userAuth);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      {/* First Section */}
      <Stack className="mainBgHost">
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            marginTop: {
              xs: "95px",
              sm: "95px",
              md: "140px",
              lg: "140px",
              xl: "140px",
            },
          }}
        ></Stack>
      </Stack>

      <hr
        style={{
          display: "block",
          height: "1px",
          border: 0,
          borderTop: "1px solid #ccc",
          margin: " 1em 0",
          padding: 0,
        }}
      />
      <Stack alignItems={"center"}>
        <Stack alignItems={"center"} maxWidth={"1100px"}>
          <Grid container>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack mt={-6}>
                {/* <img
                  style={{ width: "125px", height: "125px" }}
                  src={ProfileImage}
                  alt="profile-image"
                /> */}
                <Stack
                  mt={-2}
                  ml={2}
                  className="profile-rating"
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={1}
                >
                  <Stack className="rating-number">{2}</Stack>
                  <Stack>
                    <img
                      src={require("../../assets/background/rating-star.png")}
                      alt="star"
                    />
                  </Stack>
                </Stack>
                <Box sx={{ position: "sticky", top: 0, padding: "10px 30px" }}>
                  <Typography
                    variant="h4"
                    fontWeight={"700"}
                    sx={{
                      fontSize: {
                        xs: "40px",
                        sm: "40px",
                        md: "50px",
                        lg: "50px",
                        xl: "50px",
                      },
                    }}
                    gutterBottom
                  >
                    Crystal O.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                    }}
                  >
                    Kapa'a, HI
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                    }}
                  >
                    473 trips · Joined Nov 2023
                  </Typography>
                  <Box mt={2}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                      variant="body2"
                    >
                      All-Star Hosts like Crystal are the top-rated and most
                      experienced hosts on Trailer4Rent.
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      color={"#616161"}
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Verified Info
                    </Typography>
                    <Stack
                      my={1}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                        }}
                      >
                        Approved to drive{" "}
                      </Typography>
                      <IoMdCheckmarkCircleOutline color={themeRed} size={25} />
                    </Stack>
                    <Stack
                      my={1}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                        }}
                      >
                        Email address{" "}
                      </Typography>
                      <IoMdCheckmarkCircleOutline color={themeRed} size={25} />
                    </Stack>
                    <Stack
                      my={1}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "16px",
                            xl: "16px",
                          },
                        }}
                      >
                        Phone number{" "}
                      </Typography>
                      <IoMdCheckmarkCircleOutline color={themeRed} size={25} />
                    </Stack>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      color={"#616161"}
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Languages
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      English
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      color={"#616161"}
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Works
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Trailer4Rent/Physical Therapist/Freediving Instructor
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      color={"#616161"}
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      School
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Pacific University
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography
                      variant="h6"
                      color={"#616161"}
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Host Stats
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Response rate: 100%
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                      }}
                    >
                      Response time: 7 minutes
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack p={2}>
                <Card
                  sx={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/booking-page");
                  }}
                >
                  {/* Image */}
                  <img
                    src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726084941/Rectangle_28_btgded.png"
                    alt="Enclosed Trailer"
                    style={{ width: "100%", objectFit: "contain" }}
                  />

                  {/* Card Content */}
                  <CardContent>
                    {/* Trailer Title */}
                    <Typography
                      fontWeight={"700"}
                      sx={{
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "24px",
                          lg: "24px",
                          xl: "24px",
                        },
                      }}
                      variant="h6"
                    >
                      Enclosed Trailer
                    </Typography>

                    <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                      {/* Rating and Host Info */}
                      <Box display="flex" alignItems="center" mt={1}>
                        {/* Star and rating */}
                        <Star
                          style={{ color: "#f44336", marginRight: "4px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                              xl: "12px",
                            },
                          }}
                          variant="body2"
                          fontWeight={500}
                        >
                          5.0
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                              xl: "12px",
                            },
                          }}
                          variant="body2"
                          color="textSecondary"
                          ml={1}
                        >
                          (131 trips)
                        </Typography>
                      </Box>

                      {/* Host info */}
                      <Box display="flex" alignItems="center" mt={1}>
                        <SlBadge style={{ marginRight: "4px" }} />
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                              xl: "12px",
                            },
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          All-Star Host
                        </Typography>
                      </Box>
                    </Stack>
                    <hr />
                    {/* Pricing */}
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        },
                      }}
                      variant="h6"
                      fontWeight={600}
                      mt={1}
                      textAlign={"end"}
                    >
                      $104/day
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
              <Grid container>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
                  <Card
                    sx={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    }}
                  >
                    {/* Image */}
                    <img
                      src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726084941/Rectangle_28_btgded.png"
                      alt="Enclosed Trailer"
                      style={{ width: "100%", objectFit: "contain" }}
                    />

                    {/* Card Content */}
                    <CardContent>
                      {/* Trailer Title */}
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                        variant="h6"
                      >
                        Enclosed Trailer
                      </Typography>

                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {/* Rating and Host Info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          {/* Star and rating */}
                          <Star
                            style={{ color: "#f44336", marginRight: "4px" }}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            fontWeight={500}
                          >
                            5.0
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                            ml={1}
                          >
                            (131 trips)
                          </Typography>
                        </Box>

                        {/* Host info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          <SlBadge style={{ marginRight: "4px" }} />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                          >
                            All-Star Host
                          </Typography>
                        </Box>
                      </Stack>
                      <hr />
                      {/* Pricing */}
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          },
                        }}
                        variant="h6"
                        fontWeight={600}
                        mt={1}
                        textAlign={"end"}
                      >
                        $104/day
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
                  <Card
                    sx={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    }}
                  >
                    {/* Image */}
                    <img
                      src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726084941/Rectangle_28_btgded.png"
                      alt="Enclosed Trailer"
                      style={{ width: "100%", objectFit: "contain" }}
                    />

                    {/* Card Content */}
                    <CardContent>
                      {/* Trailer Title */}
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                        variant="h6"
                      >
                        Enclosed Trailer
                      </Typography>

                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {/* Rating and Host Info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          {/* Star and rating */}
                          <Star
                            style={{ color: "#f44336", marginRight: "4px" }}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            fontWeight={500}
                          >
                            5.0
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                            ml={1}
                          >
                            (131 trips)
                          </Typography>
                        </Box>

                        {/* Host info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          <SlBadge style={{ marginRight: "4px" }} />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                          >
                            All-Star Host
                          </Typography>
                        </Box>
                      </Stack>
                      <hr />
                      {/* Pricing */}
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          },
                        }}
                        variant="h6"
                        fontWeight={600}
                        mt={1}
                        textAlign={"end"}
                      >
                        $104/day
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
                  <Card
                    sx={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    }}
                  >
                    {/* Image */}
                    <img
                      src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726084941/Rectangle_28_btgded.png"
                      alt="Enclosed Trailer"
                      style={{ width: "100%", objectFit: "contain" }}
                    />

                    {/* Card Content */}
                    <CardContent>
                      {/* Trailer Title */}
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                        variant="h6"
                      >
                        Enclosed Trailer
                      </Typography>

                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {/* Rating and Host Info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          {/* Star and rating */}
                          <Star
                            style={{ color: "#f44336", marginRight: "4px" }}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            fontWeight={500}
                          >
                            5.0
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                            ml={1}
                          >
                            (131 trips)
                          </Typography>
                        </Box>

                        {/* Host info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          <SlBadge style={{ marginRight: "4px" }} />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                          >
                            All-Star Host
                          </Typography>
                        </Box>
                      </Stack>
                      <hr />
                      {/* Pricing */}
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          },
                        }}
                        variant="h6"
                        fontWeight={600}
                        mt={1}
                        textAlign={"end"}
                      >
                        $104/day
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6} p={2}>
                  <Card
                    sx={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    }}
                  >
                    {/* Image */}
                    <img
                      src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726084941/Rectangle_28_btgded.png"
                      alt="Enclosed Trailer"
                      style={{ width: "100%", objectFit: "contain" }}
                    />

                    {/* Card Content */}
                    <CardContent>
                      {/* Trailer Title */}
                      <Typography
                        fontWeight={"700"}
                        sx={{
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                            xl: "24px",
                          },
                        }}
                        variant="h6"
                      >
                        Enclosed Trailer
                      </Typography>

                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {/* Rating and Host Info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          {/* Star and rating */}
                          <Star
                            style={{ color: "#f44336", marginRight: "4px" }}
                          />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            fontWeight={500}
                          >
                            5.0
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                            ml={1}
                          >
                            (131 trips)
                          </Typography>
                        </Box>

                        {/* Host info */}
                        <Box display="flex" alignItems="center" mt={1}>
                          <SlBadge style={{ marginRight: "4px" }} />
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "10px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                            variant="body2"
                            color="textSecondary"
                          >
                            All-Star Host
                          </Typography>
                        </Box>
                      </Stack>
                      <hr />
                      {/* Pricing */}
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          },
                        }}
                        variant="h6"
                        fontWeight={600}
                        mt={1}
                        textAlign={"end"}
                      >
                        $104/day
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Stack alignItems={"center"}>
                <CustomButton
                  style={{
                    backgroundColor: themeRed,
                    color: "white",
                    margin: "25px 0px",
                  }}
                  children={"View All Trailers"}
                />
              </Stack>

              <Stack>
                <Stack p={2} gap={1}>
                  <Typography className="subHeading">LOCATION</Typography>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d45600.64517303216!2d-74.01611958046904!3d40.68789426247765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25bae694479a3%3A0xb9949385da52e69e!2sBarclays%20Center!5e0!3m2!1sen!2s!4v1725485815339!5m2!1sen!2s"
                    width="100%"
                    height="240"
                    style={{ border: 0, borderRadius: 10 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <Typography color={"#616161"} className="subHeading">
                    REVIEWS FROM GUESTS{" "}
                    <span style={{ color: "black" }}>473 Trips</span>
                  </Typography>
                </Stack>
                <Stack gap={8} mb={5}>
                  <Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Stack>
                    <Stack ml={6}>
                      <Typography fontSize={"12px"}>No reviews yet</Typography>
                      <Typography fontSize={"14px"}>
                        John hasn’t received a review on Trailer4Rent yet.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Stack>
                    <Stack ml={6}>
                      <Typography fontSize={"12px"}>No reviews yet</Typography>
                      <Typography fontSize={"14px"}>
                        John hasn’t received a review on Trailer4Rent yet.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Stack>
                    <Stack ml={6}>
                      <Typography fontSize={"12px"}>No reviews yet</Typography>
                      <Typography fontSize={"14px"}>
                        John hasn’t received a review on Trailer4Rent yet.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Stack>
                    <Stack ml={6}>
                      <Typography fontSize={"12px"}>No reviews yet</Typography>
                      <Typography fontSize={"14px"}>
                        John hasn’t received a review on Trailer4Rent yet.
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Footer />
    </div>
  );
}

export default HostDetailsScreen;
