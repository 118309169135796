import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderData from "../../component/common/Slider/Slider";
import "./index.css";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { FaArrowRight } from "react-icons/fa6";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import CustomStorySlider from "../../component/Slider/CustomStorySlider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FourthSection = () => {
  const navigate = useNavigate();
  const { user, userAuth } = useSelector((state) => state.userAuth);

  const navigateHandle = () => {
    if (userAuth && user) {
      if (
        userAuth &&
        !user?.email_verified &&
        !user?.number_verified &&
        user?.kyc === "Idle"
      ) {
        navigate("/OTPForm");
      } else if (
        userAuth &&
        user?.email_verified &&
        user?.number_verified &&
        user?.kyc === "Idle"
      ) {
        navigate("/StepOne");
      } else {
        navigate("/dashboard-home");
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <Container maxWidth="lg">
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        mt={4}
        gap={2}
        bgcolor={"#F0D9DB"}
      >
        <Typography
          mt={5}
          fontWeight={"700"}
          textAlign={"center"}
          sx={{
            width: { md: "900px", lg: "900px", xl: "900px" },
            fontSize: {
              xs: "25px",
              sm: "25px",
              md: "40px",
              lg: "40px",
              xl: "40px",
            },
            fontFamily: "Book Antiqua, serif !important",
          }}
        >
          {/* Hear more host success stories */}
          Ordinary People, Extraordinary Hosts: Hear Their Success Stories!
        </Typography>
        <CustomStorySlider />
      </Stack>
      <Container>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          mt={7}
          gap={1}
          mb={8}
        >
          <Typography
            fontWeight={"700"}
            textAlign={"center"}
            sx={{
              fontSize: {
                xs: "25px",
                sm: "25px",
                md: "40px",
                lg: "40px",
                xl: "40px",
              },
              fontFamily: "Book Antiqua, serif !important",
            }}
          >
            Start building your business plan
          </Typography>
          <Typography
            mb={4}
            textAlign={"center"}
            fontSize={"18px"}
            // sx={{ fontFamily: "Book Antiqua, serif !important" }}
          >
            {/* List your first Trailer to get started today and build your plan to
            take control of your financial future tomorrow. */}
            Turn your trailer into a money-maker—list it today!
          </Typography>
          <CustomButton
            onClick={navigateHandle}
            style={{
              backgroundColor: themeRed,
              color: "white",
              
            }}
            children={"Get Started"}
          />
        </Stack>
      </Container>
    </Container>
  );
};

export default FourthSection;
