import { createSlice } from "@reduxjs/toolkit";

// Check if userType exists in localStorage, otherwise set it to 'User'
const initialUserType = localStorage.getItem('userType') || 'User';

const initialState = {
  userType: initialUserType,
};

// If there was no userType set in localStorage, save 'User' as default
if (!localStorage.getItem('userType')) {
  localStorage.setItem('userType', 'User');
}

const userTypeSlice = createSlice({
  name: "userType",
  initialState,
  reducers: {
    toggleUserType: (state) => {
      state.userType = state.userType === "User" ? "Host" : "User";
      localStorage.setItem("userType", state.userType);
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
      localStorage.setItem("userType", state.userType);
    },
  },
});

export const { toggleUserType, setUserType } = userTypeSlice.actions;
export default userTypeSlice.reducer;