// import React from 'react'

// const CustomStorySlider = () => {
//   return (
//     <div>CustomStorySlider</div>
//   )
// }

// export default CustomStorySlider
import React, { useState } from "react";
// import sliderImg1 from "../../assets/SliderImg1.png";
import sliderImg1 from "../../assets/dylans_sled.png";
import sliderImg2 from "../../assets/HomeTrailer.png";
import "./StorySlider.css";
import useWindowDimensions from "../../utils/hooks/windowDimensions";

const storyData = [
  {
    // title: "How a former auto consultant earned over $50,000 on Trailer4Rent",
    title: `How one B.C. Host is getting "paid to play" with his snowmobiles`,
    // description:
    //   "See how Elan scaled his Trailer sharing business from one Trailer into a lucrative operation",
    description:
      "I bought my trailer to haul around my new sled.With the money I make on Trailer4Rent, it pays my sled payment plus. I'm getting paid to play!!",
    image: sliderImg1,
    link: "#",
  },
  {
    title: "Another success story",
    description:
      "Learn how this entrepreneur made their trailer business work.",
    image: sliderImg2,
    link: "#",
  },
  // Add more stories as needed
];

const CustomStorySlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowDimensions();

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === storyData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? storyData.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="story-slider">
      <div className="story-content">
        <h2 className="story_title">{storyData[currentIndex].title}</h2>
        <p className="story_description">
          {storyData[currentIndex].description}
        </p>
        {/* <a href={storyData[currentIndex].link} className="read-more">
          Read more
        </a> */}
      </div>
      <div className="story-image">
        <button className="prev-arrow" onClick={handlePrevious}>
          &#10094;
        </button>
        <button className="next-arrow" onClick={handleNext}>
          &#10095;
        </button>
        <img
          src={storyData[currentIndex].image}
          alt="Story"
          style={{
            height: "200px",
            width: width < 768 ? "100vw" : "40vw",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default CustomStorySlider;
