import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Stack } from "@mui/material";
import logo from "../../assets/MainLogo.png";
import { toggleUserType } from "../../store/slices/userTypeSlice";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.userType.userType);

  const sections = [
    {
      heading: "Quick Links",
      links: [
        // { label: "Home", path: "/" },
        { label: "About Us", path: "/about-us/" },
        { label: "Contact Us", path: "/contact-us/" },
        { label: "Our Mission", path: "/our-mission/" },
        {
          label: "Become a Host",
          path: "/become-a-host",
        },
      ],
      bgColor: "red",
    },
    {
      heading: "Information",
      links: [
        { label: "Terms & Condition", path: "/terms-and-conditions" },
        { label: "Privacy Policy", path: "/privacy-policy" },
        // { label: "Careers", path: "/careers" },
        // { label: "Team", path: "/team" },
        { label: "FAQ", path: "/faqs" },
      ],
      bgColor: "green",
    },
    {
      heading: "Explore",
      links: [
        { label: "List your trailer", path: "/signup" },
        { label: "Trust & Safety", path: "/trust-safety" },
        { label: "Get Help", path: "/contact-us" },
      ],
      bgColor: "pink",
    },
  ];

  const handleToggle = () => {
    dispatch(toggleUserType());
    navigate("/");
  };

  return (
    <Stack sx={{ backgroundColor: "#f9f9f9", padding: "100px 0px" }}>
      <Container maxWidth="lg">
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Stack sx={{ height: "100%" }}>
              {/* <h2 className="logo">LOGO</h2> */}
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "100px",
                  // height: "100px",
                  objectFit: "contain",
                  marginBottom: "5px",
                }}
              />
              <p className="footer-text">
                Trailer4Rent.ca is a local, peer-to-peer trailer rental platform
                that connects trailer renters and trailer owners.
              </p>
              <p className="footer-text">
                We are providing an opportunity for trailer owners to create
                income from an asset that already own.
              </p>
              <p className="footer-text">
                All trailers listed can be reserved online 24 hours/day, 7
                days/week. Reservations cannot be made by phone.
              </p>
              <div className="social-icons">
                {/* <FaFacebookF className="icon" />
                <FaInstagram className="icon" /> */}
                <a
                  href="https://www.facebook.com/profile.php?id=61561245904913"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="icon" />
                </a>
                <a
                  href="https://www.instagram.com/trailer4rent.ca?igsh=dm1qbHJnankwdTB2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="icon" />
                </a>
              </div>
            </Stack>
          </Grid>
          {sections.map((section, index) => (
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12} key={index}>
              <Stack sx={{ height: "100%" }}>
                <h3 className="main_heading">{section.heading}</h3>
                <ul className="foot_list_wrapper">
                  {section.links.map((link, i) => (
                    <>
                      {link?.path === "/become-a-host" ? (
                        <li
                          key={i}
                          className="li_item"
                          // onClick={() => navigate(link.path)}
                          onClick={handleToggle}
                        >
                          {/* {link.label} */}
                          {userType === "Host" ? "" : "Become a Host"}
                        </li>
                      ) : (
                        <li
                          key={i}
                          className="li_item"
                          onClick={() => navigate(link.path)}
                          // onClick={() => console.log(link.path)}
                        >
                          {link.label}
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Stack>
  );
};

export default Footer;