import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { IoCall } from "react-icons/io5";
import { FaVideo } from "react-icons/fa";
import { themeOrange } from "../../utils/colorTheme";
import { IoIosArrowBack } from "react-icons/io";

const ChatHeader = ({
  user_profile,
  user_name,
  setBackValue,
  handleVideCall,
  handleAudioCall,
}) => {
  const _handleBack = () => {
    setBackValue(false);
  };
  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // background:
        //   "var(--Button, linear-gradient(0deg, #EF873A 0%, #CB5C0A 100%))",
        // padding: "10px",
        borderRadius: "10px",
        // marginBottom: "10px",
      }}
      px={2}
      
    >
      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <Box
            sx={{
              display: {
                xl: "none",
                lg: "none",
                md: "none",
                sm: "block",
                xs: "block",
              },
              cursor: "pointer",
            }}
            onClick={_handleBack}
          >
            <IoIosArrowBack size={20} color="#BF2E35" />
          </Box>
          <Avatar
            alt="Remy Sharp"
            src={user_profile || ""}
            sx={{ width: 43, height: 43, borderRadius: "10px" }}
          />
        </Stack>
        <Stack>
          <Typography
            sx={{
              fontSize: "19px",
              fontWeight: "600",
              fontFamily: '"Poppins", sans-serif',
              color: "black",
              lineHeight: "26px",
            }}
          >
            {user_name || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "12.5px",
              fontWeight: "600",
              fontFamily: '"Poppins", sans-serif',
              color: "grey",
              lineHeight: "19.30px",
            }}
          >
            online
          </Typography>
        </Stack>
      </Stack>
      {/* <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
      <IconButton onClick={handleAudioCall}>
        <IoCall size={20} color="white" />
      </IconButton>
      <IconButton onClick={handleVideCall}>
        <FaVideo size={20} color="white" />
      </IconButton>
    </Stack> */}
    </Stack>
  );
};

export default ChatHeader;
