import React from "react";
import css from "./HeroSection.module.css";
import { Stack, Typography } from "@mui/material";
import LocationSearch from "../../../component/LocationSearch/LocationSearch";
import heroImage from "../../../assets/heroImage.webp"
import AnotherImage from "../../../assets/top_banner_image.JPG"
import { useLocation } from "react-router-dom";

const HeroSection = () => {
  const location = useLocation(); 

  const backgroundImage = location.pathname === "/how-its-work" ? `url(${AnotherImage})` : `url(${heroImage})`;
  return (
    <Stack className={css.wrapper}  sx={{
      backgroundColor: "red",
      backgroundImage: backgroundImage,
      backgroundPosition:"center"
    }}>
      <Stack sx={{ backgroundColor: "rgb(0 0 0 / 25%)", height: "100%" }}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            marginTop: {
              xs: "70px",
              sm: "36px",
              md: "140px",
              lg: "140px",
              xl: "140px",
            },
          }}
          gap={2}
        >
          <Typography
            variant="h1"
            textAlign={"center"}
            fontWeight={"800"}
            // fontSize={"36px"}
            sx={{
              fontSize: {
                xl: "60px",
                lg: "60px",
                md: "60px",
                sm: "45px",
                // xs: "35px",
                xs: "20px",
              },
              lineHeight: "normal",
              fontFamily: "Book Antiqua, serif !important",
            }}
            color={"white"}
          >
            Explore Canada's Trailer Sharing Marketplace!
          </Typography>
          <Stack mt={{xl:3,lg:2,xs:3,sm:2}}>
            <LocationSearch padding={1} />
          </Stack>
          <Typography
            variant="h1"
            textAlign={"center"}
            fontWeight={"500"}
            // fontSize={"50px"}
            sx={{
              lineHeight: "normal",
              fontFamily: "Calibri, serif !important",
              fontSize: {
                xl: "50px",
                lg: "50px",
                md: "50px",
                sm: "30px",
                xs: "30px",
              },
            }}
            color={"white"}
            // marginTop={3}
          >
            Let's get YOU rolling!
          </Typography>
          
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HeroSection;
