import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  TablePagination,
  Typography,
  TableFooter,
  Button,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserBookings } from "../../services/trailers_details_service";
import { asyncStatus } from "../../utils/asyncStatus";
import { useNavigate } from "react-router-dom";
import { HiCheckBadge } from "react-icons/hi2";

const UserBookingsTable = () => {
  const dispatch = useDispatch();
  const {
    get_user_bookings_status,
    get_user_bookings_data,
    get_user_bookings_error,
  } = useSelector((state) => state.trailersDetailsManage);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserBookings());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  const loader = get_user_bookings_status === asyncStatus.LOADING;

  return loader ? (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: "50vh" }}
    >
      {" "}
      <Stack
        // alignItems={"center"}
        // justifyContent={"center"}
        sx={{
          padding: "20px",
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        {[...Array(5)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={40}
            animation="wave"
            style={{ marginBottom: "8px" }}
          />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Stack my={3}>
      <TableContainer component={Paper}>
        {get_user_bookings_error ? (
          <Typography color="error" align="center" style={{ padding: "20px" }}>
            Error fetching bookings: {get_user_bookings_error}
          </Typography>
        ) : (
          <Table aria-label="user bookings table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    User Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    Host Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    Trailer Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    Start Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    End Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    Amount
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    fontSize={16}
                    component="div"
                    fontWeight="bold"
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {get_user_bookings_data?.bookings
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                ?.map((booking) => (
                  <TableRow key={booking._id}>
                    <TableCell>{`${booking.user_id.first_name} ${booking.user_id.last_name}`}</TableCell>
                    <TableCell>
                      {" "}
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        gap={3}
                        my={2}
                      >
                        {" "}
                        {`${booking.host_id.first_name} ${booking.host_id.last_name}`}{" "}
                        {booking.host_id?.kyc === "Completed" &&
                          booking.host_id?.status === "Approved" && (
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              gap={1}
                              sx={{
                                p: "4px 8px",
                                borderRadius: "10px",
                                backgroundColor: "greenyellow",
                                width: "fit-content",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "green",
                                  fontSize: "15px",
                                }}
                              >
                                Verified
                              </Typography>
                              <HiCheckBadge style={{ color: "green" }} />
                            </Stack>
                          )}
                      </Stack>
                    </TableCell>
                    <TableCell>{booking.trailer_id.title}</TableCell>
                    <TableCell>
                      {new Date(booking.start_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(booking.end_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{booking.status}</TableCell>
                    <TableCell>{booking.booking_amount}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          navigate(`/booking-details-user/${booking._id}`)
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={get_user_bookings_data?.bookings.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </Stack>
  );
};

export default UserBookingsTable;
