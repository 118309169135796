import React from 'react'
import "./RevenueCard.css"
import { Stack } from '@mui/material'


const RevenueCard = ({ child, heading, subHeading, price }) => {
    return (
        <div>
            <Stack className='RevenueCardContainer' px={4} py={5}>
                <Stack className='RevenueCardHeading'>
                    {heading}
                </Stack>
                <Stack className='RevenueCardSubHeading' mt={3}>
                    {subHeading}
                </Stack>
                <Stack className='RevenueCardPrice' mt={5}>
                    {price}
                </Stack>
                <Stack mt={5}>
                    {child}
                </Stack>
            </Stack>
        </div>
    )
}

export default RevenueCard
