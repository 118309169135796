// import React from 'react';
// import Slider from 'react-slick';
// import { Card, CardContent, Typography } from '@mui/material';
// import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./TestimonialSlider.css";

// // Sample province data - grayscale image (default) and colorful image (on hover)
// const provinces = [
//     {
//         name: "Nova Scotia",
//         imgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002263_wcntmv.png",
//         colorfulImgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_1_ppslzr.png"
//     },
//     {
//         name: "Quebec",
//         imgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923237/Mask_group_ddpwud.png",
//         colorfulImgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_3_cwauod.png"
//     },
//     {
//         name: "Nunavut",
//         imgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002260_ubamqi.png",
//         colorfulImgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_b1ki3c.png"
//     },
//     {
//         name: "The Yukon",
//         imgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002266_tex18z.png",
//         colorfulImgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_5_nljzsi.png"
//     },
//     {
//         name: "Northwest Territories",
//         imgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002265_mqghck.png",
//         colorfulImgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_4_nvohnj.png"
//     },
//     {
//         name: "Ontario",
//         imgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002264_npbdif.png",
//         colorfulImgSrc: "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_2_sa7qxh.png"
//     }
// ];

// // Custom arrows for carousel
// const PrevArrow = (props) => {
//     const { onClick } = props;
//     return (
//         <div
//             onClick={onClick}
//             style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 position: "absolute",
//                 // left: "-50px",
//                 right: "20px",
//                 top: "-30%",
//                 transform: "translateY(-50%)",
//                 zIndex: 1,
//                 cursor: "pointer",
//                 background: "#fff",
//                 // borderRadius: "50%",
//                 // padding: "10px",
//                 // boxShadow: "0 2px 10px rgba(0,0,0,0.1)"
//             }}
//         >
//             <ArrowBackIosNew style={{ fontSize: "24px" }} />
//         </div>
//     );
// };

// const NextArrow = (props) => {
//     const { onClick } = props;
//     return (
//         <div
//             onClick={onClick}
//             style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 position: "absolute",
//                 right: "-30px",
//                 top: "-30%",
//                 transform: "translateY(-50%)",
//                 zIndex: 1,
//                 cursor: "pointer",
//                 background: "#fff",
//                 // borderRadius: "50%",
//                 // padding: "10px",
//                 // boxShadow: "0 2px 10px rgba(0,0,0,0.1)"
//             }}
//         >
//             <ArrowForwardIos style={{ fontSize: "24px" }} />
//         </div>
//     );
// };

// const TestimonialSlider = () => {
//     // Carousel settings
//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 5, // Number of visible slides at a time
//         slidesToScroll: 1,
//         nextArrow: <NextArrow />,
//         prevArrow: <PrevArrow />,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 2
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1
//                 }
//             }
//         ]
//     };

//     return (
//         <div style={{ margin: "50px auto", width: "80%", position: 'relative' }}>
//             <Typography variant="h5" gutterBottom>
//                 Browse by your Province
//             </Typography>
//             <Slider {...settings}>
//                 {provinces.map((province, index) => (
//                     <Card
//                         key={index}
//                         className="province-card"
//                     >
//                         <CardContent>
//                             <div className="image-wrapper">
//                                 <img
//                                     alt={province.name}
//                                     src={province.imgSrc}
//                                     className="province-img grayscale-img"
//                                 />
//                                 <img
//                                     alt={province.name}
//                                     src={province.colorfulImgSrc}
//                                     className="province-img colorful-img"
//                                 />
//                             </div>
//                             <Typography variant="subtitle1">{province.name}</Typography>
//                         </CardContent>
//                     </Card>
//                 ))}
//             </Slider>
//         </div>
//     );
// };

// export default TestimonialSlider;

import React, { useRef, useState } from "react";
import Slider from "react-slick";
import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSlider.css";
// Import Swiper components and modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { themeRed } from "../utils/colorTheme";

// Testimonial data
const testimonials = [
  {
    name: "Melissa D.",
    role: "All-Star Host",
    trips: "927 trips",
    joinedDate: "Joined Jun 2017",
    review: "Great rental.",
    date: "Oct 2023",
    reviewer: "Ben F.",
  },
  {
    name: "John S.",
    role: "Super Host",
    trips: "725 trips",
    joinedDate: "Joined Mar 2018",
    review: "Fantastic experience.",
    date: "Sep 2023",
    reviewer: "Alice W.",
  },
  {
    name: "Sarah T.",
    role: "Top Host",
    trips: "1025 trips",
    joinedDate: "Joined Jan 2016",
    review: "Highly recommended!",
    date: "Aug 2023",
    reviewer: "Tom H.",
  },
  {
    name: "Sarah T.",
    role: "Top Host",
    trips: "1025 trips",
    joinedDate: "Joined Jan 2016",
    review: "Highly recommended!",
    date: "Aug 2023",
    reviewer: "Tom H.",
  },
  {
    name: "Sarah T.",
    role: "Top Host",
    trips: "1025 trips",
    joinedDate: "Joined Jan 2016",
    review: "Highly recommended!",
    date: "Aug 2023",
    reviewer: "Tom H.",
  },
  // Add more testimonials as needed
];

const TestimonialSlider = ({ onClick, reviewsData }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [expandedReviews, setExpandedReviews] = useState({});

  const _handleToggle = (index, event) => {
    event.stopPropagation();
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const _renderReviewText = (review, index) => {
    const isExpanded = expandedReviews[index];
    const isLongReview = review.length > 60;

    // if (isLongReview) {
    //   const displayedText = isExpanded ? review : review.slice(0, 60);
    //   return (
    //     <>
    //       {displayedText}
    //       <span
    //         onClick={(e) => _handleToggle(index, e)}
    //         style={{
    //           color: themeRed,
    //           cursor: "pointer",
    //           marginLeft: "5px",
    //           // fontWeight: "bold",
    //           fontSize: "16px",
    //         }}
    //       >
    //         {isExpanded ? "See Less" : "See More"}
    //       </span>
    //     </>
    //   );
    // }
    return review; // Return full review if it's not longer than 100 words
  };

  return (
    <div style={{ margin: "50px auto", width: "100%", position: "relative" }}>
      <Stack
        sx={{ padding: "15px 0px" }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <IconButton
          sx={{
            padding: {
              xl: "8px",
              lg: "8px",
              md: "8px",
              sm: "8px",
              xs: "0px",
            },
            fontSize: {
              xl: "30px",
              lg: "30px",
              md: "30px",
              sm: "30px",
              xs: "20px",
            },
          }}
          ref={prevRef}
          className="swiper-button-prev-custom"
        >
          <IoIosArrowBack style={{ color: themeRed }} />
        </IconButton>

        <Swiper
          slidesPerView={3} // Adjust the number of visible slides
          pagination={{
            clickable: false, // Enable pagination bullets
          }}
          spaceBetween={10}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }} // Enable navigation arrows
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          modules={[Navigation]} // Use Swiper modules
          className="mySwiper"
          breakpoints={{
            1200: {
              slidesPerView: 3, // Show 2 slides for xl (>= 1200px)
            },
            1024: {
              slidesPerView: 3, // Show 2 slides for lg (>= 1024px)
            },
            768: {
              slidesPerView: 2, // Show 1 slide for md (>= 768px)
            },
            600: {
              slidesPerView: 1, // Show 1 slide for sm (>= 600px)
            },
            0: {
              slidesPerView: 1, // Show 1 slide for xs (below 600px)
            },
          }}
        >
          {reviewsData?.map((testimonial, i) => {
            return (
              <SwiperSlide key={i}>
                <Card
                  elevation={0}
                  className="testimonial-card"
                  onClick={onClick}
                >
                  <CardContent className="testimonial-card-inside">
                    <Stack gap={3} direction={"row"} alignItems={"center"}>
                      <Avatar sx={{ height: "100px", width: "100px" }} />
                      <div className="testimonial-info">
                        <Typography variant="h6">{testimonial.name}</Typography>
                        <Typography variant="subtitle2">
                          {testimonial.role}
                        </Typography>
                        {/* <Typography variant="body2">
                          {testimonial.rentals} · {testimonial.joinedYear}
                        </Typography> */}
                      </div>
                    </Stack>
                    <div className="testimonial-review">
                      <div className="testimonial-stars">
                        <Rating
                          name={`rating-${i}`}
                          value={testimonial.rating}
                          precision={0.5}
                          readOnly
                          size="small"
                        />
                      </div>
                      <Typography variant="body1" className="testimonial-text">
                        {/* {_renderReviewText(testimonial.review, i)} */}
                        {/* {_renderReviewText(testimonial?.review,i)} */}
                        {testimonial?.review}
                      </Typography>
                      <Typography
                        variant="caption"
                        className="testimonial-reviewer"
                      >
                        {testimonial.reviewedBy}
                        {testimonial.date && `- ${testimonial.date}`}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <IconButton
          sx={{
            padding: {
              xl: "8px",
              lg: "8px",
              md: "8px",
              sm: "8px",
              xs: "0px",
            },
            fontSize: {
              xl: "30px",
              lg: "30px",
              md: "30px",
              sm: "30px",
              xs: "20px",
            },
          }}
          ref={nextRef}
          className="swiper-button-next-custom"
        >
          <IoIosArrowForward style={{ color: themeRed }} />
        </IconButton>
      </Stack>
    </div>
  );
};

export default TestimonialSlider;
