import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select"; // Correct import path
import { Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { themeRed } from "../../utils/colorTheme";

function CustomLocationSearch({
  setSelectedLocationProps,
  textCondition,
  defaultValue,
}) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleSelect = (place) => {
    setSelectedLocation(place);
    setSelectedLocationProps(place);
  };

  // Custom components using react-select's components
  const CustomControl = ({ children, ...props }) => (
    <div style={{ position: "relative" }}>
      <LocationOnIcon
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          color: themeRed,
          fontSize: "1.2rem",
        }}
      />
      <components.Control
        {...props}
        style={{
          paddingLeft: "35px",
          border: "1px solid transparent",
          boxShadow: "none",
          borderRadius: "6px",
        }}
      >
        {children}
      </components.Control>
    </div>
  );

  const CustomOption = ({ children, ...props }) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LocationOnIcon
          style={{
            color: themeRed,
            marginRight: "10px",
            fontSize: "1rem",
          }}
        />
        {children}
      </div>
    </components.Option>
  );

  return (
    <div>
      <div style={{ position: "relative" }}>
        {textCondition && (
          <Typography className="label-text" style={{ marginBottom: "10px" }}>
            Where do you need a Done?
          </Typography>
        )}
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            componentRestrictions: {
              country: ["ca"],
            },
            types: ["(cities)"],
          }}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          query={{
            key: process.env.REACT_APP_GOOGLE_API_KEY,
            language: "en",
            type:"geocode"
          }}
          selectProps={{
            defaultInputValue: defaultValue,
            placeholder: "I'm looking for a trailer near...",
            value: selectedLocation,
            onChange: handleSelect,
            components: {
              Control: CustomControl,
              Option: CustomOption,
            },
            styles: {
              container: (provided) => ({
                ...provided,
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                border: `1px solid ${
                  state.isFocused ? "transparent" : "transparent"
                }`,
                boxShadow: "none",
                "&:hover": {
                  borderColor: state.isFocused ? "transparent" : "transparent",
                },
                padding: "0px 5px",
                borderRadius: "6px",
              }),
              input: (provided) => ({
                ...provided,
                color: "black",
                padding: "10px 10px 10px 0px",
                marginLeft: "30px",
              }),
              singleValue: (provided) => ({
                ...provided,
                paddingLeft: "30px",
              }),
              placeholder: (provided) => ({
                ...provided,
                paddingLeft: "30px",
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: "white",
                border: `1px solid transparent`,
              }),

              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? "#eaeaea" : "white",
                color: "black",
                padding: "10px",
                cursor: "pointer",
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: themeRed,
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: themeRed,
              }),
            },
          }}
        />
      </div>
    </div>
  );
}

export default CustomLocationSearch;
