import React from "react";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import AboutusSection from "../AboutUsSection/AboutUsSection";
import AboutUsCom from "./About";
const AboutUs = () => {
  return (
    <div>
      <Navbar />
      {/* <AboutusSection /> */}
      <AboutUsCom/>
      <Footer />
    </div>
  );
};

export default AboutUs;
