import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";
import OtpInput from "react-otp-input";
import Footer from "../../screens/Footer/Footer";
import Navbar from "../navbar/Navbar";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import {
  resend_otp_async,
  verify_otp_async,
} from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { useNavigate } from "react-router-dom";

const OTPForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(30); // Timer for resend button
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [error, setError] = useState({ phoneOtp: false, emailOtp: false });
  const { otp_status, user } = useSelector((state) => state.userAuth);

  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled]);

  const handleSubmit = async () => {
    // if (phoneOtp.length !== 3 || emailOtp.length !== 3) {
    //   setError({
    //     phoneOtp: phoneOtp.length !== 3,
    //     emailOtp: emailOtp.length !== 3,
    //   });
    //   return;
    // }
    if (emailOtp.length !== 3) {
      setError({
        emailOtp: emailOtp.length !== 3,
      });
      return;
    }
    const obj = {
      emcode: emailOtp,
      mocode: phoneOtp,
    };

    const success = await dispatch(verify_otp_async(obj));
    if (success) {
      if (user.kyc === "Idle") {
        navigate("/StepOne");
      } else {
        navigate("/dashboard-home");
      }
    } else {
      alert("OTP verification failed");
    }

    setError({ phoneOtp: false, emailOtp: false });
  };

  const handleResendOtp = () => {
    dispatch(resend_otp_async());
    setIsResendDisabled(true);
    setTimer(30); // Restart the timer
  };

  return (
    <Stack bgcolor={"white"} minHeight="100vh">
      <Navbar />

      <Box
        sx={{
          maxWidth: 500,
          margin: "35px auto",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography
          align="center"
          gutterBottom
          style={{
            fontSize: 30,
            fontWeight: "bold",
          }}
        >
          Enter <span style={{ color: themeRed }}>OTP</span>
        </Typography>

        <Typography
          align="center"
          gutterBottom
          style={{
            fontSize: 13,
          }}
        >
          OTP has been sent to your{" "}
          <span style={{ fontWeight: "bold", color: themeRed, fontSize: 14 }}>
            phone
          </span>{" "}
          and{" "}
          <span style={{ fontWeight: "bold", color: themeRed, fontSize: 14 }}>
            email
          </span>
        </Typography>

        <Grid
          container
          spacing={4}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"stretch"}
          mt={3}
        >
          {/* Phone OTP Input */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5.5}
            xl={5.5}
            // sx={{ backgroundColor: "red" }}
          >
            <Stack
              alignItems={{
                xl: "flex-start",
                lg: "flex-start",
                md: "center",
                sm: "center",
                xs: "center",
              }}
              justifyContent={"center"}
              sx={{ height: "100%" }}
            >
              <Typography
                align="left"
                gutterBottom
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                Phone OTP
              </Typography>
              <OtpInput
                value={phoneOtp}
                onChange={setPhoneOtp}
                numInputs={3}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 0.4rem",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                }}
              />
              {error.phoneOtp && (
                <Typography color="error" align="center">
                  Please enter all 3 digits of the phone OTP
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
            <Stack
              sx={{ height: "100%" }}
              alignItems="center"
              justifyContent={"center"}
            >
              <Stack
                sx={{
                  borderBottom: "2px solid black",
                  width: "1rem",
                  height: "5px",
                  marginTop: "10px",
                }}
              ></Stack>
            </Stack>
          </Grid>
          {/* Email OTP Input */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5.5}
            xl={5.5}
            // sx={{ backgroundColor: "red" }}
          >
            <Stack
              alignItems={{
                xl: "flex-start",
                lg: "flex-start",
                md: "center",
                sm: "center",
                xs: "center",
              }}
              justifyContent={"center"}
              sx={{ height: "100%" }}
            >
              <Typography
                align="left"
                gutterBottom
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginLeft: 5,
                }}
              >
                Email OTP
              </Typography>
              <OtpInput
                value={emailOtp}
                onChange={setEmailOtp}
                numInputs={3}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 0.4rem",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                }}
              />
              {error.emailOtp && (
                <Typography color="error" align="center">
                  Please enter all 3 digits of the email OTP
                </Typography>
              )}
            </Stack>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            {/* <Button
                            variant="contained"
                            color="error"
                            fullWidth
                            onClick={handleSubmit}
                            disabled={phoneOtp.length !== 3 || emailOtp.length !== 3}
                        >
                            Submit OTP
                        </Button> */}
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={handleSubmit}
              disabled={
                // phoneOtp.length !== 3 ||
                emailOtp.length !== 3 ||
                otp_status === asyncStatus.LOADING
              }
            >
              {otp_status === asyncStatus.LOADING ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit OTP"
              )}
            </Button>
          </Grid>

          {/* Resend OTP Button
          <Grid item xs={12}>
            <Typography
              align="center"
              gutterBottom
              style={{
                fontSize: 13,
              }}
            >
              Send OTP Again{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: themeRed,
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={handleResendOtp}
              >
                Resend
              </span>
            </Typography>
          </Grid> */}
          {/* Resend OTP Button */}
          <Grid item xs={12}>
            <Typography
              align="center"
              gutterBottom
              style={{
                fontSize: 13,
              }}
            >
              Resend OTP in{" "}
              <span
                disabled={isResendDisabled}
                style={{
                  fontWeight: "bold",
                  color: isResendDisabled ? "grey" : themeRed,
                  cursor: "pointer",
                }}
                onClick={handleResendOtp}
              >
                Resend{" "}
              </span>
              {isResendDisabled && (
                <span style={{ fontWeight: "bold", color: themeRed }}>
                  {timer}s
                </span>
              )}
              {/* {isResendDisabled ? (
                <span style={{ fontWeight: "bold", color: themeRed }}>
                  {timer}s
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "bold",
                    color: themeRed,
                    cursor: "pointer",
                  }}
                  onClick={handleResendOtp}
                >
                  Resend
                </span>
              )} */}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Stack>
  );
};

export default OTPForm;
