import React from 'react';
import './Reviews.css'; 

const Reviews = () => {
  const reviews = [
    { id: 1, userInitial: 'J', rating: 0, message: "John hasn't received a review on Trailer4Rent yet." },
    { id: 2, userInitial: 'J', rating: 0, message: "John hasn't received a review on Trailer4Rent yet." },
    { id: 3, userInitial: 'J', rating: 0, message: "John hasn't received a review on Trailer4Rent yet." },
    { id: 4, userInitial: 'J', rating: 0, message: "John hasn't received a review on Trailer4Rent yet." },
  ];

  return (
    <div className="reviews-container">
      <h3 className="review-heading">REVIEWS FROM HOST . <span style={{color:'black'}}>0 Trips</span> </h3>
      <div className="review-summary">
        <span className="review-score">0</span>
        <span className="review-star">
            <img src={require("../../assets/background/rating-star.png")} />
        </span>
        <span className="review-count">(4 reviews)</span>
      </div>

      {/* {reviews.map((review) => (
        <div key={review.id} className="review-item">
          <div className="user-avatar">{review.userInitial}</div>
          <div className="review-content">
            <div className="rating">
              {[...Array(5)].map((star, index) => (
                <span key={index} className="review-star">
                  ★
                </span>
              ))}
            </div>
            <div className="no-reviews-yet">No reviews yet</div>
            <div className="review-message">{review.message}</div>
          </div>
        </div>
      ))} */}
    </div>
  );
};

export default Reviews;
