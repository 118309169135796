import React, { useEffect } from 'react'
import Navbar from '../../component/navbar/Navbar'
import { Container, Stack, Skeleton } from '@mui/material'
import MyGallery from '../../component/MyGallery/MyGallery'
import TrailerDetailsCom from '../../component/TrailerDetailsCom/TrailerDetailsCom'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getTrailerByIdAsync } from '../../services/trailers_details_service'
import { asyncStatus } from '../../utils/asyncStatus'
import Footer from '../Footer/Footer'

const TrailerBookDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { get_trailer_by_id_status, get_trailer_by_id_data } = useSelector(
        (state) => state.trailersDetailsManage
    );

    useEffect(() => {
        dispatch(getTrailerByIdAsync(id));
    }, [dispatch, id]);

    return (
        <div>
            <Navbar />

            <Stack mt={0.4}>
                {get_trailer_by_id_status === asyncStatus.LOADING ? (
                    <Skeleton variant="rectangular" width="100%" height={300} />
                ) : (
                    <MyGallery imagesFromApi={get_trailer_by_id_data?.trailer_photos} />
                )}
            </Stack>

            <Stack>
                <Container>
                    {get_trailer_by_id_status === asyncStatus.LOADING ? (
                        <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
                            {/* Left Skeleton - Main Content */}
                            <Skeleton variant="rectangular" width="65%" height={400} />

                            {/* Right Skeleton - Booking Details */}
                            <Skeleton variant="rectangular" width="35%" height={400} />
                        </Stack>
                    ) : (
                        <TrailerDetailsCom trailerData={get_trailer_by_id_data} />
                    )}
                </Container>
            </Stack>

            <Footer />

        </div>
    )
}

export default TrailerBookDetails;
