
import React, { useState } from 'react';
import {
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Grid,
    Box,
    Typography,
    InputAdornment,
    Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { themeRed } from '../../utils/colorTheme';
import CustomButton from '../../component/common/Button/Button';
import bgIcon from '../../assets/bgIcon.png'

// Custom styles for the form
const useStyles = makeStyles({
    label: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        color: '#000000', // Change label color here
    },
    textField: {
        backgroundColor: '#f7f7f7', // Input background color
    },
    formControl: {
        backgroundColor: '#f7f7f7',
        width: '100%',
    },
    select: {
        backgroundColor: '#f7f7f7',
        width: '100%',
    },
    button: {
        backgroundColor: '#d32f2f',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#b71c1c',
        },
        marginTop: '20px',
    },
});

const TrailerEdit = () => {
    const classes = useStyles();

    const [photos, setPhotos] = useState([null, null, null, null, null, null, null, null, null, null]); // Placeholder for 5 photos

    const handleFileChange = (index, event) => {
        const updatedPhotos = [...photos];
        updatedPhotos[index] = URL.createObjectURL(event.target.files[0]);
        setPhotos(updatedPhotos);
    };
    return (
        <Box sx={{ padding: 4 }}>
            <Grid container spacing={2}>
                {/* Title of Trailer */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Title of Trailer</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Title of trailer"
                    />
                </Grid>

                {/* Year, Make, Model */}
                <Grid item xs={4}>
                    <Typography className={classes.label}>Year</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Year"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.label}>Make</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Make"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.label}>Model</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Model"
                    />
                </Grid>

                {/* Select Category, Hitch Type */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Select Category</Typography>
                    <FormControl className={classes.formControl}>
                        <Select variant="outlined" defaultValue="">
                            <MenuItem value="1">Dump Trailer Rental</MenuItem>
                            {/* Add more options */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>Hitch Type</Typography>
                    <FormControl className={classes.formControl}>
                        <Select variant="outlined" defaultValue="">
                            <MenuItem value="1">Bumper Pull</MenuItem>
                            {/* Add more options */}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Ball Size */}
                <Grid item xs={6}>
                    <Typography className={classes.label}>Ball Size</Typography>
                    <FormControl className={classes.formControl}>
                        <Select variant="outlined" defaultValue="">
                            <MenuItem value="1">2 inch</MenuItem>
                            {/* Add more options */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} mt={3}>
                    {/* <Typography className={classes.label}>Ball Size</Typography> */}
                    <FormControl className={classes.formControl}>
                        <Select variant="outlined" defaultValue="">
                            <MenuItem value="1">2 inch</MenuItem>
                            {/* Add more options */}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Light Plug Configuration */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Light Plug Configuration</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Chain"
                    />
                </Grid>

                {/* Trailer Dimensions */}
                <Grid item xs={6}>
                    <Typography className={classes.label}>Trailer Dimensions</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Dimensions"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Trailer Length</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Length"
                    />
                </Grid>

                {/* Trailer Width */}
                <Grid item xs={6}>
                    <Typography className={classes.label}>Trailer Width</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Width"
                    />
                </Grid>

                {/* Rear Door Opening Height */}
                <Grid item xs={6}>
                    <Typography className={classes.label}>Rear Door Opening Height</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="17"
                    />
                </Grid>

                {/* Tires */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Types</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Tires"
                    />
                    <Stack alignItems={"center"} mt={2} flexDirection={"row"}>
                        <Typography color={"black"}>
                            Payload Capacity:
                        </Typography>
                        <Typography color={themeRed}>GVW - approximately Trailer weightoad!</Typography>
                    </Stack>
                    <Stack mt={2}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            variant="outlined"
                            placeholder="Bumper Pull"
                        />
                    </Stack>
                </Grid>

                {/* Axle */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Axle</Typography>
                    <FormControl className={classes.formControl}>
                        <Select variant="outlined" defaultValue="">
                            <MenuItem value="1">3,500lb</MenuItem>
                            {/* Add more options */}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Other fields like VIN, Province/State, etc. */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Approx. Trailer Value:(?)</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Weight"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.label}>VIN</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="VIN"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.label}>Province/Territory:</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Province/State"
                    />
                    <Stack alignItems={"center"} mt={2} flexDirection={"row"}>
                        <Typography color={"black"}>
                            Insurance Eligibility:
                        </Typography>
                        <Typography color={themeRed}> This information is required to determine insurance eligibility.</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.label}>City</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="City"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.label}>Postal Code</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Postal Code"
                    />
                    <Stack alignItems={"center"} mt={2} flexDirection={"row"}>
                        <Typography color={"black"}>
                            Location:
                        </Typography>
                        <Typography color={themeRed}> Enter your full address. Only Your City And Province Will Be Publicly Visible.</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {/* <Typography className={classes.label}>Axle</Typography> */}
                    <FormControl className={classes.formControl}>
                        <Select variant="outlined" defaultValue="">
                            <MenuItem placeholder='Oak' value="1">Oak</MenuItem>
                            {/* Add more options */}
                        </Select>
                    </FormControl>
                    <Typography color={"black"}>
                        Pricing:
                    </Typography>
                    <Stack alignItems={"center"} mt={2} flexDirection={"row"}>
                        <Typography color={"black"}>
                            Refundable Deposit:
                        </Typography>
                        <Typography color={themeRed}> Check your insurance policy and see if you have a $300 or $500 deductible for "Fire/Theft/Vandalism"  The lower deposit will have higher chance of being rented.</Typography>
                    </Stack>
                </Grid>

                {/* Daily Rate */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Daily Rate</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Daily Rate"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>

                {/* Weekly Rate */}
                <Grid item xs={12}>
                    <Typography className={classes.label}>Weekly Rate</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Weekly Rate"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>Monthly Rate</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Weekly Rate"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>Deposit Required:</Typography>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Weekly Rate"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* <Typography>Trailer Photos</Typography> */}
                    <Typography className={classes.label}>Trailer Photos:</Typography>
                    <div className="photos-grid">
                        {photos.map((photo, index) => (
                            <div key={index} className="photo-container">
                                {photo ? (
                                    <img src={photo} alt={`Trailer ${index}`} className="photo" />
                                ) : (
                                    <label className="photo-placeholder">
                                        <input
                                            type="file"
                                            onChange={(event) => handleFileChange(index, event)}
                                            style={{ display: "none" }}
                                        />
                                        <span className="add-photo-icon"><img width={"100%"} src={bgIcon} /></span>
                                    </label>
                                )}
                            </div>
                        ))}
                    </div>

                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                    <div className="buttons">
                        <CustomButton
                            style={{
                                backgroundColor: themeRed,
                                color: 'white'
                            }} children={"update"} />
                        <CustomButton children={"Save as Draft"} />
                        <CustomButton children={"Cancel"} />
                        {/* <button className="draft-btn"></button>
                        <button className="cancel-btn"></button> */}
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TrailerEdit;
