import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const InformationSection = () => {
  return (
    <div>
      {/* Types of Collected Information */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            paddingBottom: "4px",
            display: "inline-block",
          }}
        >
          I. Types of Collected Information
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginTop: "16px",
          }}
        >
          We collect four categories of personal information: personal
          information you give us, information automatically collected from your
          use of the Services, personal information from third-party sources,
          and information from cookies.
        </Typography>

        {/* Personal Information You Give Us */}
        <Box mt={4}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "16px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              borderBottom: "4px solid #C3282F",
              display: "inline-block",
              paddingBottom: "4px",
            }}
          >
            Personal Information You Give Us
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Account Data"
                secondary="When you register for an account with us, we may require certain personal information such as your name, email address, and a password. If you do not provide such required information, you will not be able to open an account with us."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Profile Data"
                secondary="We may also ask you to provide additional profile information to use certain features of the Services, which may include your address, phone numbers, driver’s license details, and profile photos."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Trailer Data"
                secondary="We collect information you provide in relation to trailer listing details, such as vehicle identification number (VIN), availability dates, reviews, and uploaded photos."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Identity Verification Data"
                secondary="We may collect identity verification information, such as a photograph or scanned copy of a driver’s license, a selfie, or payment card details."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Payment Data"
                secondary="We collect and securely store  your digital payment details, bank account or payment card numbers, and transaction information in connection with an actual or potential transaction with us, which may be processed and stored by one or more third party payment service providers or digital payments companies. Storage of such information shall be subject to those third party payment service providers’ privacy policies."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Communications"
                secondary="When you communicate with us, including via phone, email, or any other method, or if you use the Services to communicate with other users, we collect information about your communication along with any information you choose to provide."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="User Research"
                secondary="We may collect information you provide when you choose to participate in user research initiatives and/or community surveys. This may include demographic information if you choose to share it with us."
              />
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* Information We Automatically Collect */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Information We Automatically Collect
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Usage Data"
              secondary="We may collect information about your use of the Site and the Services, such as bookings you have made, your login history, your searches, the pages or other content you view, how long you spent on a page or screen, sites from which you link or browse to in the Services, navigation paths between pages or screens, information about your activity on a page or screen, access time, duration of access, and other actions on the Services."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Booking Data"
              secondary="We collect transactional information related to the bookings you make through the Services, including the date and time of your booking, amounts charged, and other related booking details."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Device Data"
              secondary="We collect information about your computer or mobile device, such as its operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, unique device identifiers, or general location information such as city, province, or geographic area if available from your device."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Cookies and Similar Technologies"
              secondary={`When you access the Services, we (including companies we work with) may place small data files on your computer or other device. These data files may be cookies, clear gifs, pixel tags,e-tags, "Flash cookies," or other local storage provided by your browser or associated applications (collectively "Cookies"). For more detailed information on our use of these technologies, please see our policy on Cookies, Web Beacons, and Similar Technologies. At this time, we do not respond to browser ‘Do-Not-Track’ signals.`}
            />
          </ListItem>
        </List>
      </Box>

      {/* Personal Information from Third Parties */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Personal Information We Collect From Third Parties
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Third-Party Registration Services"
              secondary={`If you choose to login to our Site or Services through a third-party website or service (e.g., Facebook, Apple, or Google), the third-party service may send us information about you such as your registration and profile information from that service. The information we may receive varies by service and is controlled by the relevant service. By associating an account managed by a third party with your Trailer4Rent Inc. account and authorizing us to have access to this information, you agree that we may collect, store, and use this information in accordance with this Privacy Policy. We are not responsible for how those third parties use and share your information. Please refer to the privacy policies of the respective third-party website or service to understand how they use and share your personal information.`}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Driver’s License Verification"
              secondary={`We may use third party identity verification services to verify your driver’s license, confirm your identity and to protect against fraud and abuse. When you create an account, you will be asked to submit a valid driver’s license, along with an image of yourself (a selfie) so that we can be sure you are the person on your driver’s license. Our third-party provider may use a combination of machine learning tools and optical scans to authenticate your driver’s license, and may use facial recognition technology to produce a unique biometric identifier based on your facial geometry that can be used to compare your selfie to the image on the driver’s license you provided to determine the likelihood that the images are a "match."  For our third party’s privacy policy, please click here.`}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Driver’s License Verification"
              secondary={
                <p>
                  We may use third party identity verification services to
                  verify your driver’s license, confirm your identity and to
                  protect against fraud and abuse. When you create an account,
                  you will be asked to submit a valid driver’s license, along
                  with an image of yourself (a selfie) so that we can be sure
                  you are the person on your driver’s license. Our third-party
                  provider may use a combination of machine learning tools and
                  optical scans to authenticate your driver’s license, and may
                  use facial recognition technology to produce a unique
                  biometric identifier based on your facial geometry that can be
                  used to compare your selfie to the image on the driver’s
                  license you provided to determine the likelihood that the
                  images are a "match." For our third party’s privacy policy,
                  please click here.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Trailer4Rent Inc. does not receive the biometric identifier
                    generated from the images.
                  </span>{" "}
                  It is generated and held by our third-party provider until we
                  inform them that the biometric identifier is no longer needed
                  for the purposes described in this paragraph, which will occur
                  no later than one (1) year after your last interaction with
                  us. For identity verification and security purposes,
                  Trailer4Rent Inc. will have access only to the selfie image
                  and will receive only the results of the identity verification
                  process. Trailer4Rent Inc. does not possess, use, disclose or
                  retain your biometric information for any other commercial
                  purpose. Our third-party provider processes this information
                  on our behalf strictly in accordance with our contractual
                  agreements for identity verification services.
                </p>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Other Sources"
              secondary="To the extent permitted by applicable law, we may receive additional information about you, such as demographic data, fraud detection information, or other third-party data providers. We may combine the information about you that we receive from third-party sources with other information we have about you."
            />
          </ListItem>
        </List>
      </Box>

      {/* GPS Tracking */}
      <Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Gps Tracking
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              secondary={`By renting a trailer, you agree to the following terms and conditions regarding the use, privacy, and legal compliance of the GPS tracking system. Please note that not all trailers are equipped with a GPS tracker.`}
            />
          </ListItem>
        </List>

        <List sx={{ paddingLeft: "10px" }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Purpose of GPS Tracking
                </Typography>
              }
              secondary={
                <>
                  <ListItemText
                    primary="The primary purpose of the GPS tracker installed on certain
                    rental trailers is to ensure the security and proper usage
                    of the trailer. The GPS tracker helps in:"
                    secondary={
                      <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                        <li>
                          Locating and recovering the trailer in case of theft.
                        </li>
                        <li>
                          {" "}
                          Monitoring the trailer’s location to ensure it is
                          being used within the agreed-upon geographic
                          boundaries.
                        </li>
                        <li>
                          Providing data for maintenance and operational
                          efficiency.
                        </li>
                      </ul>
                    }
                  />
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Privacy GPS Data
                </Typography>
              }
              secondary={
                <>
                  <ListItemText
                    primary="We are committed to protecting your privacy. The GPS data collected from the tracker on the rental trailer will be handled in accordance with the following privacy principles:"
                    secondary={
                      <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                        <li>
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >
                            Data Use:
                          </Typography>{" "}
                          GPS data will only be used for security, operational
                          efficiency, and maintenance purposes
                        </li>
                        <li>
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >
                            Access:
                          </Typography>{" "}
                          Only authorized personnel at Trailer4Rent Inc. will
                          have access to the GPS data. This data will not be
                          shared with third parties, except as required by law
                          or as necessary to recover a stolen trailer.
                        </li>
                        <li>
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >
                            Data Retention:
                          </Typography>{" "}
                          GPS data will be retained only as long as necessary to
                          fulfill the purposes outlined above and will be
                          securely deleted thereafter.
                        </li>
                      </ul>
                    }
                  />
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Legal Compliance
                </Typography>
              }
              secondary={
                <>
                  <ListItemText
                    primary="Trailer4Rent Inc.  is committed to complying with all relevant laws and regulations regarding the use of GPS tracking devices. This includes, but is not limited to:"
                    secondary={
                      <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                        <li>
                        <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >Consent:</Typography>{" "} By renting a trailer from Trailer4Rent Inc.,
                          you acknowledge and consent to the use of a GPS
                          tracker on the trailer.
                        </li>
                        <li>
                        <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >Notification:</Typography>{" "} You will be notified that the trailer is
                          equipped with a GPS tracking device at the time of
                          rental.
                        </li>
                        <li>
                        <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >Data Protection:</Typography>{" "} GPS data will be protected in
                          accordance with applicable data protection and privacy
                          laws.
                        </li>
                      </ul>
                    }
                  />
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  User Responsibilities
                </Typography>
              }
              secondary={
                <>
                  <ListItemText
                    primary="As a renter of a GPS-equipped trailer, you agree to the following responsibilities:"
                    secondary={
                      <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                        <li>
                        <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >Proper Use:</Typography>{" "} Use the trailer in compliance with the
                          rental agreement, including any geographic
                          restrictions.
                        </li>
                        <li>
                        <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >Tampering:</Typography>{" "} Do not tamper with, disable, or remove the
                          GPS tracker. Any such actions may result in penalties
                          and additional charges.
                        </li>
                        <li>
                        <Typography
                            variant="caption"
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                            }}
                          >Reporting:</Typography>{" "} Notify Trailer4Rent Inc. immediately if you
                          suspect that the GPS tracker is malfunctioning or has
                          been tampered with.
                        </li>
                      </ul>
                    }
                  />
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Reporting and Addressing Concerns
                </Typography>
              }
              secondary={
                <>
                  <ListItemText primary="If you have any concerns or questions about the use of GPS tracking on rental trailers, or if you suspect any misuse of the GPS data, please contact Trailer4Rent Inc.’s customer support team. We are committed to addressing any issues promptly and ensuring your concerns are resolved." />
                </>
              }
            />
          </ListItem>
          <Divider />
        </List>
      </Box>

      {/* <------------------------------------------------------> */}

      {/* Cookies */}
      <Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Cookies
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Usage of Cookies and Similar Tracking Tools"
              secondary={`When you access the Services, we (including companies we work with) may place small data files on your computer or other device. These data files may be cookies, clear gifs, pixel tags, e-tags, "flash cookies," or other local storage provided by your browser or associated applications (collectively "Cookies"). Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and receive identifiers and other information on computers, phones and other devices. Other technologies, including data that we may store on your web browser or device, identifiers associated with your device and other software, are used for similar purposes. In this policy we refer to all of these technologies as "cookies."`}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="We use cookies if you have a user account"
              secondary={`Cookies enable us to offer the Services to you and to understand the information that we receive about you, including information about your use of other websites and apps, whether or not you are registered or logged in.`}
            />
          </ListItem>
        </List>
      </Box>

      {/* Why We Use Cookies */}
      <Box
        mb={6}
        sx={{
          padding: "40px 20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Why We Use Cookies
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            maxWidth: "800px",
          }}
        >
          Cookies enhance your experience by providing tailored functionality,
          security, and personalization. Here’s why we use them:
        </Typography>

        <List sx={{ paddingLeft: "10px" }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Authentication
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  Cookies help us verify your account and determine when you are
                  logged in so that it makes it easier to access our Services.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Security
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  We use cookies to help us keep your account, data and Services
                  safe and secure. We may also use cookies to combat activity
                  that violates our policies or otherwise degrades our ability
                  to provide the Services.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Advertising
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  We may use cookies to help us show ads and to make
                  recommendations of other businesses or organizations for which
                  you may be interested in their products, services or causes
                  they promote. We also use cookies to track and measure the
                  efficacy of ad campaigns.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Features
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  We use cookies to enable the functionality for the provision
                  of the Services.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Experience
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  We use cookies to provide you with the best experience
                  possible.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "18px",
                  }}
                >
                  Analytics and Research
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  We use cookies to help us understand how you are using the
                  Services so we can improve them.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "16px",
                    color: "#000",
                    fontFamily: "Book Antiqua, serif !important",
                    borderBottom: "4px solid #C3282F",
                    display: "inline-block",
                    paddingBottom: "4px",
                    marginLeft: "-10px !important",
                  }}
                >
                  When you use our Site or Services
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "8px",
                  }}
                >
                  we may store cookies on your device in order to facilitate and
                  customize your use of our Site. A cookie is a small data text
                  file, which a website stores on your computer's hard drive (if
                  your web browser permits) that can later be retrieved to
                  identify you to us. Our cookies store randomly assigned user
                  identification numbers, the country where you are located, and
                  your first name to welcome you back to our Site. The cookies
                  make your ¬use of the Site easier, make the Site run more
                  smoothly and help us to maintain a secure Site. You are always
                  free to decline our cookies if your browser permits, but some
                  parts of our Site may not work properly in that case. We will
                  collect and use non-personally identifiable information about
                  you, such as your IP address, browser type, the server your
                  computer is logged onto, the area code associated with your
                  server. We shall not be liable for the placement of third
                  party cookies or utilization of other tracking technologies.
                  Through your use of the Site, you agree to release us from any
                  liability regarding third party cookies. Data usage fees may
                  apply, and you shall be the solely responsible for such fees.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>

      {/* II. Use Your Personal Information  */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          II. Use Your Personal Information
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginBottom: "16px",
          }}
        >
          We use, securely store, and process your personal information to
          provide and improve the Site and Services and for security and safety
          purposes. For example, we may use your information:
        </Typography>

        <List>
          <ListItem>
            <ListItemText
              primary="To Provide the Services, including, but not limited to:"
              secondary={
                <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                  <li>Operating the Site and Services</li>
                  <li>Providing customer support</li>
                  <li>
                    Communicating with you about the Site, Services, updates,
                    alerts, and any information requested by you
                  </li>
                  <li>Requesting fraud detection or prevention</li>
                  <li>
                    Facilitate logging in if you use a third party to do so,
                    such as Facebook.
                  </li>
                  <li>Personalize your experience.</li>
                  <li>
                    To enable you to communicate with the renter or the owner of
                    the trailer.
                  </li>
                </ul>
              }
            />
          </ListItem>
          <Divider />
          <Divider />
          <ListItem>
            <ListItemText
              primary="For Research and Development"
              secondary={
                <Typography
                  sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  We may use your personal information to analyze and improve
                  the Site and Services and to develop new products and
                  services, including by studying our user demographics of the
                  Services. We may also create aggregated, deidentified, or
                  other anonymous data from your personal information. We make
                  personal information into anonymous data by removing
                  information that makes the data personally identifiable to
                  you. We may use this anonymous data and share it with third
                  parties for our lawful business purposes, including to analyze
                  and improve the Services and promote our business.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="For Marketing and Advertising"
              secondary={
                <span>
                  <p>
                    We and our third party partners may collect and use your
                    information for marketing and advertising purposes,
                    including:
                  </p>
                  <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                    <li>Direct marketing</li>
                    <li>
                      Interest-based advertising with third-party partners
                    </li>
                    <li>
                      Security and safety, including but not limited to
                      verifying your identity, authenticating the information
                      you provided, resolving disputes, collecting fees,
                      detecting and preventing fraud, conduct checks against
                      databases and third-party sources (including government
                      public databases)
                    </li>
                  </ul>
                </span>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="To Comply with Law"
              secondary={
                <Typography
                  sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  We use your personal information as we believe necessary or
                  appropriate, in our sole discretion, to comply with applicable
                  laws, lawful requests, and legal processes, such as to respond
                  to subpoenas or requests from government authorities.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="With Your Consent"
              secondary={
                <Typography
                  sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  We may, in some cases, ask for your consent to use your
                  information. We may also use your information as described in
                  this Privacy Policy. By using our Site and Services, you have
                  agreed to the terms hereunder.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>

      {/*  How We Disclose Your Information */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          III. How We Disclose Your Information
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="With Your Consent"
              secondary="By creating a profile, using our Site or Services, posting a listing, and responding to a listing, you consent to our use of your information to facilitate the Services."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Profiles, Listings, Public Information"
              secondary={
                <Typography
                  sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Your listing on the Site or Services will always include some
                  basic information about you, such as your user ID or name
                  associated with your account, your public profile photo, the
                  city where your trailer is located, your listing description,
                  your calendar availability, transaction related information to
                  allow our community to evaluate your reliability and
                  responsiveness, and reviews or feedback about you. Your
                  listing page may also include aggregate demand information
                  (such as number of page views over a period of time) and
                  information about your cancellations. The Site and Services
                  may also display the approximate geographic pick-up location
                  of your trailer.
                  <br />
                  By using the Site and Services, you agree to allow your public
                  profile and public listing pages to be included in search
                  engines, in which case your public profile and public listing
                  pages may be indexed by search engines and may be published as
                  search results.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Sharing Between Renters and Hosts"
              secondary={
                <Typography
                  sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  We enable trailer owners (Hosts) to offer and share their
                  trailers with other individuals. If you agree to a booking
                  through the Services, we may provide your information to the
                  other party in that transaction as reasonably necessary to
                  facilitate the transaction. For example, we may provide your
                  mobile phone number to facilitate communication or your
                  photograph to facilitate identification. We will also share
                  the address of the trailer and, if applicable, the proposed
                  delivery location with the other party. If you decide to text
                  with the other party to any transaction to which you are a
                  party, standard text message rates may apply.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Service Providers"
              secondary={
                <Typography
                  sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  We may share information with vendors and service providers
                  who support the operation of the Site, Services and business
                  and who need access to such information to carry out their
                  work for us (including, for example, web hosting, payment
                  processing, marketing, insurance, claims administration,
                  trailer recovery, etc.). In some cases, the service provider
                  may directly collect the information from you on our behalf.
                  Any of your information provided to these service providers
                  shall only be used as instructed by us and governed by our
                  agreement with them. Any information you provide these third
                  party services providers will be governed by your agreement
                  with them.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Third-Party Platforms Including Social Media"
              secondary={
                <Typography
                  sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  If you have enabled features or functionality that connect the
                  Services to a third party platform or social media network
                  (such as by logging in to the Services using your account with
                  the third party, providing your API key or similar access
                  token for the Services to a third party, or otherwise linking
                  your Services account to a third party’s services), we may
                  disclose to the third-party platform or social media network
                  the personal information necessary to facilitate the
                  connection or that you authorized us to share. We do not
                  control the third party’s use of your personal information.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Business Advisors"
              secondary="We may disclose your personal information to our business advisors, such as lawyers, bankers, auditors, and insurers, where such disclosure necessary in the course of the professional services that they render to us."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Business Transfers"
              secondary="We may sell, transfer, or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a merger, consolidation, corporate divestiture, acquisition, reorganization, or sale of assets, or in the event of bankruptcy or dissolution."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Responding to Legal Requests, To Prevent Harm, To Protect Our Rights"
              secondary={
                <Typography
                  sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  We may disclose your personal information to courts, law
                  enforcement, governmental or tax authorities, or third
                  parties. We will make such disclosure to the extent we are
                  required or permitted to do so by applicable law or where we
                  consider such disclosure is reasonably necessary to comply
                  with our legal obligations or legal process, to respond to
                  claims asserted against us, to protect our rights, and for our
                  security and safety. We may also disclose your information in
                  response to valid legal requests relating to criminal
                  investigations or alleged or suspected illegal activity or any
                  other activity that may expose us, you, or any other user, or
                  to protect the rights, property, or personal safety of us, our
                  users, or others.
                  <br />
                  We may also disclose your personal information as described
                  elsewhere in this Privacy Policy or as disclosed to you at the
                  time of collection.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default InformationSection;
