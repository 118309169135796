import React from "react";
import { Typography, Box, Tooltip, IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";

const HeadingWithTooltip = ({ heading, info }) => {
  return (
    <Box display="flex" alignItems="center"  mb={0.5}>
      {/* Heading */}
      <Typography
        sx={{
          fontWeight: "bold",
          marginBottom: "0", 
          fontSize:"14px"
        }}
      >
        {heading}
      </Typography>

      {/* Information Icon with Tooltip */}
      <Tooltip title={info} arrow>
        <IconButton sx={{ padding: "0" }}>
          <FaInfoCircle size={15} style={{ color: "gray",marginLeft:5  }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default HeadingWithTooltip;

// Usage Example
// <HeadingWithTooltip heading="Tag Words" info="Add keywords separated by commas." />
