import { GrAppsRounded } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { RiMessageLine } from "react-icons/ri";
import { IoMdCheckboxOutline } from "react-icons/io";
import { PiNotePencil } from "react-icons/pi";
import DashboardHome from "../screens/Dashboard/DashboardHome";
import Messages from "../screens/Dashboard/DashboardMessage";
import DashboardUserSide from "../screens/UserDashboard/DashboardUserSide";
import MessageUser from "../screens/UserDashboard/MessageUser";
import SettingsUser from "../screens/UserDashboard/SettingsUser";
import AllTrailers from "../screens/Dashboard/AllTrailers";
import MyBooking from "../screens/Dashboard/MyBooking";
import MyBankAccount from "../screens/Dashboard/MyBankAccount";
import MyRevenue from "../screens/Dashboard/MyRevenue";
import Settings from "../screens/Dashboard/Settings";

//
import AllTrailerImage from "../assets/Nav-logo/all-trailers.png";
import DashboardImage from "../assets/Nav-logo/dashboard.png";
import TrailerDetails from "../screens/Dashboard/TrailerDetails";
import TrailerEdit from "../screens/Dashboard/TrailerEdit";
import AddAccountForm from "../screens/Dashboard/AddAccount";
import ViewBookingDetails from "../screens/Dashboard/ViewBookingDetails";
import TrailerAdd from "../screens/Dashboard/AddTrailer";
import MyBookingUser from "../screens/UserDashboard/MyBookingUser";
import HistoryUser from "../screens/UserDashboard/HistoryUser";
import ReferralPage from "../screens/UserDashboard/ReferralUser";
import BookingDetailsUser from "../screens/UserDashboard/BookingDetailsUser";
import Tickets from "../screens/Dashboard/Tickets";
import TicketsComments from "../component/common/TicketsComments/TicketsComments";
import FaqsDashoard from "../screens/Dashboard/FaqsDashoard";
import { FaQuestion } from "react-icons/fa";
import StepOne from "../component/KYC_Steps/StepOne/StepOne";

//

export const logoutPage = () => {
  return;
};

// host routes >>>>>>>
export const userDashboardRoutes = [
  {
    caption: "Dashboard",
    linkTo: "/dashboard-home",
    icon: require("../assets/Nav-logo/dashboard.png"),
    element: <DashboardHome />,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "All Trailers",
    linkTo: "/all-trailers",
    icon: require("../assets/Nav-logo/all-trailers.png"),
    element: <AllTrailers />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Message",
    linkTo: "/message",
    icon: require("../assets/Nav-logo/message.png"),
    element: <Messages />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "My Bookings",
    linkTo: "/my-booking",
    icon: require("../assets/Nav-logo/booking.png"),
    element: <MyBooking />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },

  {
    caption: "My Bank Account",
    linkTo: "/my-bank-account",
    icon: require("../assets/Nav-logo/account.png"),
    element: <MyBankAccount />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "My Revenue",
    linkTo: "/my-revenue",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <MyRevenue />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Kyc Verification",
    linkTo: "/kyc-verification",
    icon: require("../assets/Nav-logo/kyc-verification.png"),
    element: <StepOne />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Trailer Details",
    linkTo: "/trailer-details/:id",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <TrailerDetails />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Trailer Edit",
    linkTo: "/trailer-edit",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <TrailerEdit />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Add Account Form",
    linkTo: "/add-account",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <AddAccountForm />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "View Booking Details",
    linkTo: "/view-booking-details/:id",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <ViewBookingDetails />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Trailer Add",
    linkTo: "/trailer-add",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <TrailerAdd />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "FAQ ",
    linkTo: "/faq-host",
    icon: require("../assets/faq.png"),
    element: <FaqsDashoard />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  // {
  //   caption: "Tickets",
  //   linkTo: "/tickets",
  //   icon: require("../assets/Nav-logo/tickets.png"),
  //   element: <Tickets />,
  //   list_in_sidebar: true,
  //   both: true,
  //   admin: true,
  //   user: true,
  // },
  {
    caption: "Tickets Comments",
    linkTo: "/tickets/:id",
    icon: require("../assets/Nav-logo/revenue.png"),
    element: <TicketsComments />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Settings",
    linkTo: "/settings",
    icon: require("../assets/Nav-logo/settings.png"),
    element: <Settings />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
];

export const userFromUserSideRoutes = [
  {
    caption: "Profile",
    linkTo: "/dashboard-home",
    icon: require("../assets/Nav-logo/Profile.png"),
    element: <DashboardUserSide />,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
  {
    caption: "My Booking",
    linkTo: "/my-booking-user",
    icon: require("../assets/Nav-logo/booking.png"),
    element: <MyBookingUser />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Booking Details",
    linkTo: "/booking-details-user/:id",
    icon: require("../assets/Nav-logo/booking.png"),
    element: <BookingDetailsUser />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Referral",
    linkTo: "/referral",
    icon: require("../assets/Nav-logo/referral.png"),
    element: <ReferralPage />,
    list_in_sidebar: false,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Kyc Verification",
    linkTo: "/kyc-verification",
    icon: require("../assets/Nav-logo/kyc-verification.png"),
    element: <StepOne />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },
  {
    caption: "Message",
    linkTo: "/message",
    icon: require("../assets/Nav-logo/message.png"),
    element: <Messages />,
    list_in_sidebar: true,
    both: true,
    admin: true,
    user: true,
  },

  {
    caption: "History",
    linkTo: "/history",
    icon: require("../assets/Nav-logo/History.png"),
    element: <HistoryUser />,
    user: false,
    list_in_sidebar: true,
    both: false,
    admin: true,
    user: false,
  },
];
