import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderShadeImage from "../../component/HeaderShadeImage/HeaderShadeImage";
import InputField from "../../component/InputField/InputField";
import Reviews from "../../component/Reviews/Reviews";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { useParams } from "react-router-dom";
import {
  addUserReviewsAsync,
  completeUserBookingAsync,
  getUserBookingsDetailByIdAsync,
  updateUserBookingDetailsStatusAsync,
} from "../../services/trailers_details_service";
import { useDispatch, useSelector } from "react-redux";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import { asyncStatus } from "../../utils/asyncStatus";
import { PiStarFill } from "react-icons/pi";
import BackShade from "../../assets/background/back-shade.png";
import CustomModal from "../../component/common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import ReactStars from "react-rating-stars-component";
import "./UserDashboardSide.css";
import { setAddUserReviewStatus } from "../../store/slices/trailers_details_slice";

const customMarkerIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41], // size of the shadow
});

const BookingDetailsUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    get_user_bookings_detail_by_id_status,
    get_user_bookings_detail_by_id_data,
    user_update_booking_detail_status_status,
    add_user_reviews_status,
    compelete_user_booking_status,
  } = useSelector((state) => state.trailersDetailsManage);
  const { user } = useSelector((state) => state.userAuth);

  const [editProfile, setEditProfile] = useState(false);
  const [selected, setSelected] = useState(null);
  const [value, setValue] = useState(2);
  const [bookingStatus, setBookingStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  const bookingStatusLoading =
    user_update_booking_detail_status_status === asyncStatus.LOADING;
  const addReviewsLoading = add_user_reviews_status === asyncStatus.LOADING;
  const completeUserBookingLoading =
    compelete_user_booking_status === asyncStatus.LOADING;

  const handleCheckboxClick = (value) => {
    setSelected(value);
  };

  const handleEditHandle = () => {
    setEditProfile(true);
    console.log("ok");
  };

  useEffect(() => {
    dispatch(getUserBookingsDetailByIdAsync(id));
  }, []);

  const _changeDateFormate = (formateDate) => {
    // Check if formateDate is provided and is a valid date
    if (!formateDate) {
      return ""; // Return an empty string if no date is provided
    }

    const date = new Date(formateDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string or "Invalid Date" if the date is not valid
    }

    const options = {
      weekday: "short", // "Thu"
      month: "short", // "Oct"
      day: "numeric", // "31"
      year: "numeric", // "2024"
      hour: "numeric", // "12"
      minute: "2-digit", // "00"
      hour12: true, // AM/PM format
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const isURL =
    get_user_bookings_detail_by_id_data?.booking?.address.includes("https");

  const latitude =
    get_user_bookings_detail_by_id_data?.booking?.trailer_id?.latitude;
  const longitude =
    get_user_bookings_detail_by_id_data?.booking?.trailer_id?.longitude;

  const _handleMapClick = (position) => {
    if (position) {
      const googleMapsUrl = `https://www.google.com/maps?q=${position[0]},${position[1]}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const _handleBookingStatus = (key) => {
    setBookingStatus(key);
    if (key === "Confirmed") {
      dispatch(
        updateUserBookingDetailsStatusAsync({
          booking_id: get_user_bookings_detail_by_id_data?.booking?._id,
          status: key,
        })
      );
    } else {
      dispatch(
        updateUserBookingDetailsStatusAsync({
          booking_id: get_user_bookings_detail_by_id_data?.booking?._id,
          status: key,
        })
      );
    }
  };

  const _handleBookingCompleteAndReview = (key) => {
    // setBookingStatus(key);
    if (key === "review") {
      setOpenModal(!openModal);
    } else {
      dispatch(
        completeUserBookingAsync({
          booking_id: id,
        })
      );
    }
  };

  const _ratingChanged = (newRating) => {
    console.log(newRating);
    setRating(newRating);
  };

  const _handleSubmitReview = () => {
    const reviewsObj = {
      booking_id: id,
      remarks: reviewText,
      rating: rating,
    };
    dispatch(addUserReviewsAsync(reviewsObj));
    console.log("Review Text", reviewsObj);
  };


  useEffect(() => {
    if (add_user_reviews_status === asyncStatus.SUCCEEDED) {
      dispatch(setAddUserReviewStatus());
      setOpenModal(false);
    }
  }, [add_user_reviews_status]);

  function capitalizeFirstWord(description) {
    if (!description) return ""; 

   
    const words = description.trim().split(" ");

   
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

    
    return words.join(" ");
  }

  if (get_user_bookings_detail_by_id_status === asyncStatus.LOADING) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} height={"80vh"}>
        <CircularProgress size={30} color="error" />
      </Stack>
    );
  } else {
   
    return (
      <Stack bgcolor={"#FAFAFA"} margin={0} padding={0}>
        <Box width={"100%"} height={"150px"}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={BackShade}
            alt="background"
          />
        </Box>

        <Stack
          sx={{ position: "relative", top: "-50px", maxWidth: "fit-content" }}
          pl={5}
        >
          <Avatar
            src={get_user_bookings_detail_by_id_data?.booking?.host_id?.avatar}
            width={"150px"}
            sx={{
              border: `4px solid ${themeRed}`,
              borderRadius: `50%`,
              boxShadow: "10.233px 11.938px 25.581px 0px rgba(0, 0, 0, 0.20)",
              objectFit: "cover",
              height: {
                xl: "120px",
                lg: "120px",
                md: "120px",
                sm: "120px",
                xs: "80px",
              },
              width: {
                xl: "120px",
                lg: "120px",
                md: "120px",
                sm: "120px",
                xs: "80px",
              },
            }}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            alignSelf={"center"}
            gap={{
              xl: 2,
              lg: 2,
              md: 2,
              sm: 2,
              xs: 1,
            }}
            sx={{
              backgroundColor: "white",
              borderRadius: "40px",
              boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
              position: "absolute",
              padding: {
                xl: "5px 20px",
                lg: "5px 20px",
                md: "5px 20px",
                sm: "5px 20px",
                xs: "5px 15px",
              },
              bottom: "-15px",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xl: "16px !important",
                  lg: "16px !important",
                  md: "16px !important",
                  sm: "16px !important",
                  xs: "13px !important",
                },
                fontWeight: "700",
              }}
            >
              {get_user_bookings_detail_by_id_data?.avg_rating}
            </Typography>
            <PiStarFill
              style={{ color: themeRed, flexShrink: 0, fontSize: "18px" }}
            />
          </Stack>
        </Stack>

        <Stack ml={7} mt={2}>
          <Stack className="settings-profile-name" flexDirection={"row"} alignItems={"center"}  mt={-3}>
            {get_user_bookings_detail_by_id_data?.booking?.host_id.first_name} {` `} <span style={{color:themeRed}}>{get_user_bookings_detail_by_id_data?.booking?.host_id.last_name}</span>
          </Stack>
          <Grid container spacing={10}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Stack mt={6} gap={2}>
                {/* {get_user_bookings_detail_by_id_data?.booking?.status ===
                  "In-Process" && (
                    <Stack gap={1}>
                      <Typography fontSize={"22px"} fontWeight={"700"}>
                  
                        Please verify your order
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        pr={5}
                        gap={3}
                      >
                        <CustomButton
                          onClick={() => _handleBookingStatus("Confirmed")}
                          style={{
                            padding: "9px 30px",
                            width: "auto",
                            backgroundColor: themeRed,
                            color: "white",
                          }}
                        >
                          {bookingStatusLoading &&
                            bookingStatus === "Confirmed" ? (
                            <CircularProgress size={17} sx={{ color: "white" }} />
                          ) : (
                            "Confirm"
                          )}
                        </CustomButton>
                      </Stack>
                      <Typography
                        fontSize={"14px"}
                        fontWeight={"400"}
                        sx={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "grey",
                        }}
                      >
                        Response time is __ hours, after that it will be
                        automatically considered as rejected by you.
                      </Typography>{" "}
                    </Stack>
                  )} */}

                <Stack
                  flexDirection={"row"}
                  alignItems={"start"}
                  justifyContent={"space-between"}
                //   pr={5}
                >
                  <Stack>
                    <div className="headerName">
                      {get_user_bookings_detail_by_id_data?.booking?.trailer_id
                        ?.title || ""}{" "}
                      <span className="headerNameThemeColor">Trailer</span>{" "}
                    </div>
                    <p className="rating">
                      Trailer Id :{" "}
                      {
                        get_user_bookings_detail_by_id_data?.booking?.trailer_id
                          ?.trailer_id
                      }
                    </p>
                  </Stack>

                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    bgcolor={"white"}
                    p={1}
                    borderRadius={2}
                    border={"1px solid lightgray"}
                    gap={2}
                  >
                    <Typography>Status:</Typography>
                    {get_user_bookings_detail_by_id_data?.booking?.status ===
                      "In-Process" && (
                        <GoDotFill size={20} color="lightgreen" />
                      )}
                    <Typography color="lightgreen" fontWeight={"bold"}>
                      {get_user_bookings_detail_by_id_data?.booking?.status ||
                        ""}
                    </Typography>
                  </Stack>
                </Stack>

                {get_user_bookings_detail_by_id_data?.booking?.status !==
                  "In-Process" ||
                  get_user_bookings_detail_by_id_data?.booking?.status !==
                  "Rejected" ? (
                  get_user_bookings_detail_by_id_data?.booking?.status ===
                    "Confirmed" ? (
                    <CustomButton
                      onClick={() =>
                        _handleBookingCompleteAndReview("complete")
                      }
                      style={{
                        padding: "9px 30px",
                        width: "auto",
                        backgroundColor: themeRed,
                        color: "white",
                      }}
                    >
                      {completeUserBookingLoading ? (
                        <CircularProgress size={17} sx={{ color: "white" }} />
                      ) : (
                        "Complete"
                      )}
                    </CustomButton>
                  ) : !get_user_bookings_detail_by_id_data?.user_review && get_user_bookings_detail_by_id_data?.booking?.status ===
                    "Completed" ? (
                    <CustomButton
                      onClick={() => _handleBookingCompleteAndReview("review")}
                      style={{
                        padding: "9px 30px",
                        width: "auto",
                        backgroundColor: themeRed,
                        color: "white",
                      }}
                    >
                      Write a Review
                    </CustomButton>
                  ) : null
                ) : null}
                <Stack my={1}>
                  <Typography fontSize={"22px"} fontWeight={"700"}>
                    ${" "}
                    {get_user_bookings_detail_by_id_data?.booking
                      ?.booking_type === "Daily"
                      ? get_user_bookings_detail_by_id_data?.booking?.trailer_id
                        ?.daily_rate
                      : get_user_bookings_detail_by_id_data?.booking
                        ?.booking_type === "Weekly"
                        ? get_user_bookings_detail_by_id_data?.booking?.trailer_id
                          ?.weekly_rate
                        : get_user_bookings_detail_by_id_data?.booking?.trailer_id
                          ?.monthly_rate}{" "}
                    /{" "}
                    {get_user_bookings_detail_by_id_data?.booking?.booking_type}
                  </Typography>
                  <Typography
                    fontSize={"12px"}
                    fontWeight={"400"}
                    sx={{ textDecoration: "underline" }}
                  >
                    {/* $159 est. total{" "} */}
                  </Typography>
                </Stack>
              </Stack>
              <Stack alignItems={"center"} mb={2}>
                <hr
                  style={{
                    color: "#E2E2E2",
                    padding: "0px 30px",
                    width: "100%",
                  }}
                />
              </Stack>
              <Stack pb={3}>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography color={themeRed}>Trip start</Typography>
                    <InputField
                      value={_changeDateFormate(
                        get_user_bookings_detail_by_id_data?.booking
                          ?.start_date || ""
                      )}
                      placeholder={_changeDateFormate(
                        get_user_bookings_detail_by_id_data?.booking
                          ?.start_date || ""
                      )}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography color={themeRed}>Trip end</Typography>
                    <InputField
                      value={_changeDateFormate(
                        get_user_bookings_detail_by_id_data?.booking
                          ?.end_date || ""
                      )}
                      placeholder={_changeDateFormate(
                        get_user_bookings_detail_by_id_data?.booking
                          ?.end_date || ""
                      )}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography color={themeRed}>
                      Pickup, Deliver & return location
                    </Typography>
                    {isURL ? (
                      <div
                        style={{
                          height: "300px",
                          width: "100%",
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                      >
                        {latitude && longitude ? (
                          <MapContainer
                            center={[latitude, longitude]}
                            zoom={13}
                            style={{ height: "100%", width: "100%" }}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker
                              icon={customMarkerIcon}
                              eventHandlers={{
                                click: () =>
                                  _handleMapClick([latitude, longitude]),
                              }}
                              position={[latitude, longitude]}
                            >
                              <Popup>
                                {get_user_bookings_detail_by_id_data?.booking
                                  ?.trailer_id?.complete_address || ""}
                              </Popup>
                            </Marker>
                          </MapContainer>
                        ) : (
                          <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              height: "100%",
                            }}
                          >
                            <CircularProgress
                              size={15}
                              sx={{ color: themeRed }}
                            />
                          </Stack>
                        )}
                      </div>
                    ) : (
                      <InputField
                        value={
                          get_user_bookings_detail_by_id_data?.booking?.address
                        }
                        placeholder={
                          get_user_bookings_detail_by_id_data?.booking?.address
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Stack p={2} gap={1}>
                <Typography
                  color={"#616161"}
                  className="subHeading"
                  sx={{ textTransform: "uppercase" }}
                >
                  REVIEWS FROM {user?.role}{" "}
                  <span style={{ color: "black" }}>
                    {get_user_bookings_detail_by_id_data?.reviews?.length || 0}{" "}
                    Trips
                  </span>
                </Typography>
              </Stack>
              <Stack gap={2}>
                {get_user_bookings_detail_by_id_data?.reviews?.length > 0 ? (
                  get_user_bookings_detail_by_id_data?.reviews?.map(
                    (review, i) => {
                      return (
                        <Stack
                          key={i}
                          gap={1}
                          sx={{ borderBottom: "1px solid lightgrey" }}
                        >
                          <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Avatar
                              src={review?.user_id?.avatar || ""}
                              sx={{
                                bgcolor: themeRed,
                                textTransform: "uppercase",
                                height: "50px",
                                width: "50px",
                              }}
                            >
                              {review?.user_id?.first_name?.slice(0, 1)}
                            </Avatar>

                            <Stack>
                              <Typography
                                fontSize={"15px"}
                                sx={{
                                  textTransform: "capitalize",
                                  fontWeight: "500",
                                }}
                              >
                                {review?.user_id?.first_name || ""}{" "}
                                {review?.user_id?.last_name || ""}
                              </Typography>
                              <ReactStars
                                count={5}
                                size={20}
                                isHalf={true}
                                // emptyIcon={<i className="far fa-star"></i>}
                                // halfIcon={<i className="fa fa-star-half-alt"></i>}
                                // fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                                value={review?.rating}
                                edit={false}
                              />
                            </Stack>
                          </Stack>
                          <Typography
                            fontSize={"14px"}
                            sx={{ paddingLeft: "58px", color: "#787878" }}
                          >
                            {capitalizeFirstWord(review?.remarks || "")}
                          </Typography>
                        </Stack>
                      );
                    }
                  )
                ) : (
                  <Stack ml={6} alignItems={"center"} justifyContent={"center"}>
                    <Typography fontSize={"12px"}>No reviews yet</Typography>
                    {/* <Typography fontSize={"14px"}>
                      John hasn’t received a review on Trailer4Rent yet.
                    </Typography> */}
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        {/* <!-------- Review Modal --------!> */}
        <CustomModal
          isOpen={openModal}
          setIsOpen={() => setOpenModal(!openModal)}
        >
          <Stack
            sx={{
              overflow: "auto",
              backgroundColor: "white",
              borderRadius: "10px",
              width: {
                xl: "20vw",
                lg: "30vw",
                md: "50vw",
                sm: "70vw",
                xs: "90vw",
              },
            }}
            alignItems={"center"}
          // p={1}
          >
            <Stack
              direction="row"
              sx={{
                width: "100%",
                borderBottom: `1px solid #99000B`,
                padding: "6px 20px",
              }}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={5}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "28px",
                }}
              >
                Write Review
              </Typography>

              <IconButton
                onClick={() => {
                  setOpenModal(false);
                  setRating(0);
                  setReviewText("");
                }}
              >
                <RxCross2 size={20} sx={{ color: "black" }} />
              </IconButton>
            </Stack>

            <Stack
              gap={2}
              px={1}
              sx={{
                width: "100% !important",
                padding: "15px 20px",
                // backgroundColor: "red",
              }}
            >
              <Stack sx={{ width: "100%" }}>
                {" "}
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Rate our booking
                </Typography>
                <Stack>
                  <ReactStars
                    count={5}
                    onChange={_ratingChanged}
                    size={50}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                    value={rating}
                  />
                </Stack>
              </Stack>

              <Stack>
                <textarea
                  placeholder="Write a review....."
                  className="review-textarea"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
              </Stack>

              <Stack
                gap={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <CustomButton
                  onClick={_handleSubmitReview}
                  style={{
                    width: "auto",
                    height: "auto",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    backgroundColor: "#99000B",
                    color: "white",
                  }}
                >
                  {addReviewsLoading ? (
                    <CircularProgress size={15} sx={{ color: "white" }} />
                  ) : (
                    "Submit Review"
                  )}
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    setOpenModal(!openModal);
                    setRating(0);
                    setReviewText("");
                  }}
                  style={{
                    width: "auto",
                    height: "auto",
                    borderRadius: "8px",
                    padding: "10px 20px",
                  }}
                >
                  Cancel
                </CustomButton>
              </Stack>
            </Stack>
          </Stack>
        </CustomModal>
      </Stack>
    );
  }
};

export default BookingDetailsUser;
