// import React from 'react'

// const RentTrailer = () => {
//   return (
//     <div>RentTrailer</div>
//   )
// }

// export default RentTrailer

import { useNavigate } from "react-router-dom";
import "../../index.css";
import { Container, Grid, Stack, Typography } from "@mui/material";
// import '../../pages/home.css'
import "./trailer.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SecondSectionSlider from "../../component/SecondSectionSlider";
import ProvinceCarousel from "../../component/UserProvinceSlider";
import TestimonialSlider from "../../component/TestimonialSlider";
import UniqueReviewSlider from "../AboutUsSection/AboutUsSection";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import SearchBar from "../../component/SearchBarComponent";
import secondSec from "../../assets/secondSec.png";
import RentATrailerSection from "../../component/RentATrailerSection";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserTrailersListAsync } from "../../services/trailers_details_service";
import LocationSearch from "../../component/LocationSearch/LocationSearch";
import home_design_line from "../../assets/host_designe_line.png";
import ProvinceCarouselSection from "../../pages/HomeSections/ProvinceCarouselSection/ProvinceCarouselSection";
import FaqsSection from "../../pages/HomeSections/FaqsSection/FaqsSection";

function RentTrailer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_user_trailers_list_status, get_user_trailers_list_data } =
    useSelector((state) => state.trailersDetailsManage);

  const handleSearch = (location, fromDate, untilDate) => {
    console.log("Search initiated with:", location, fromDate, untilDate);
    // Add your logic here to perform the search or API call
  };

  useEffect(() => {
    dispatch(getUserTrailersListAsync());
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <!-------- Navbar Section --------!> */}
      <Navbar />

      {/* <!-------- First Section --------!> */}
      <Stack className="rent_trailer_hero_wrapper">
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            marginTop: {
              xs: "95px",
              sm: "95px",
              md: "140px",
              lg: "140px",
              xl: "140px",
            },
          }}
        >
          <Typography
            textAlign={"center"}
            fontWeight={"700"}
            sx={{
              fontSize: {
                xs: "16px",
                sm: "20px",
                md: "30px",
                lg: "30px",
                xl: "30px",
              },
              lineHeight: "normal",
            }}
            color={"black"}
          >
            Discover Trailer4Rent, the world's largest Trailer sharing
            marketplace
          </Typography>
          <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
            <Typography
              variant="h1"
              textAlign={"center"}
              fontWeight={"700"}
              sx={{
                fontSize: {
                  xl: "80px",
                  lg: "70px",
                  md: "60px",
                  sm: "45px",
                  xs: "35px",
                },
                lineHeight: "normal",
              }}
              color={"black"}
            >
              Let's get you rolling!
            </Typography>
            <Stack>
              <LocationSearch padding={1} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* <!-------- Second Section --------!> */}
      <Container maxWidth="lg">
        <Stack alignItems={"center"} my={10}>
          <RentATrailerSection trailerData={get_user_trailers_list_data?.trailers || []} />
          <CustomButton
            onClick={() => navigate("/trailer-map")}
            style={{
              backgroundColor: themeRed,
              color: "white",
            }}
            children={"Book a Trailer"}
          />
        </Stack>
      </Container>

      {/* <!-------- Third Section --------!> */}
      <Container maxWidth="lg">
        <Grid container alignItems={"stretch"} my={10} spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack sx={{ height: "100%" }}>
              <Typography
                fontWeight={"700"}
                textAlign={"center"}
                sx={{
                  fontSize: {
                    xl: "50px",
                    lg: "50px",
                    md: "40px",
                    sm: "45px",
                    xs: "45px",
                  },
                  lineHeight: "normal",
                }}
              >
                Explore the Outdoors
              </Typography>
              <Typography
                fontWeight={"700"}
                textAlign={"center"}
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                }}
              >
                with Our Rugged Car Hauler Trailers
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack sx={{ height: "100%" }}>
              <Typography
                fontWeight={"400"}
                maxWidth={"600px"}
                textAlign={"start"}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Whether hauling in the city, sun, or snow, our Car Hauler
                Trailers are perfect for wherever your journey leads.
              </Typography>
              <Typography
                fontWeight={"400"}
                maxWidth={"600px"}
                textAlign={"start"}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "16px",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Our Car Hauler trailers provide ample space and utility for your
                transportation needs. With robust construction and versatile
                design, they ensure reliable performance on any road—be it
                highways, rugged terrains, or everything in between.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      {/* <!-------- Fourth Section --------!> */}
      <Stack>
        <img
          style={{ margin: "30px 0px", objectFit: "contain" }}
          src={home_design_line}
        />
      </Stack>

      {/* <!-------- Fifth Section --------!> */}
      <Container maxWidth={"lg"}>
        <Stack
          alignItems={"center"}
          // sx={{
          //   paddingX: {
          //     xs: "5px",
          //     sm: "5px",
          //     md: "150px",
          //     lg: "150px",
          //     xl: "150px",
          //   },
          // }}
          mt={5}
        >
          <Grid container alignItems={"stretch"} spacing={5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Stack sx={{ height: "100%" }}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  All-Weather Car Hauler Rentals
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  With robust construction and reliable performance, our rental
                  trailers instill confidence in any weather conditions,
                  ensuring a smooth trip for you and your cargo. Poor road
                  conditions? No problem.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Stack sx={{ height: "100%" }}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  Five-Car Capacity Trailer
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  A Car Hauler Trailer offers a unique way to transport multiple
                  vehicles securely. With a robust design and ample capacity, it
                  ensures safe and efficient transportation. Whether for
                  business or leisure, our trailers elevate your vehicle
                  transport experience.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Stack sx={{ height: "100%" }}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  Five-Vehicle Capacity Hauler
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  With a variety of trailer options from compact to full-size,
                  Trailer4Rent makes it easy to find the perfect hauler for your
                  needs. Our trailers offer ample space for your vehicles and
                  gear, ensuring smooth trips to popular destinations like
                  national parks.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <CustomButton
            style={{
              backgroundColor: themeRed,
              color: "white",
              margin: "25px 0px",
            }}
            onClick={() => navigate("/trailer-map")}
            children={"Book a Trailer Today"}
          />
        </Stack>
      </Container>

      {/* <!-------- Sixth Section --------!> */}
      <Container maxWidth="lg">
        <Stack
          alignItems={"center"}
          // maxWidth={"1400px"}
          justifyContent={"center"}
        >
          <Grid container alignItems={"stretch"} my={5} spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack sx={{ height: "100%" }}>
                <img src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726071669/Rectangle_33_cx2bno.png" />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack sx={{ height: "100%" }} gap={3}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xl: "50px",
                      lg: "50px",
                      md: "40px",
                      sm: "45px",
                      xs: "45px",
                    },
                    lineHeight: "normal",
                  }}
                >
                  Discover the Essence of Car Hauling
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  Car hauling is all about reliability, efficiency, and
                  transporting your vehicles with ease. It's about securely
                  moving your cars and trailers, ensuring they reach their
                  destination safely and conveniently. Whether you're
                  transporting for business or pleasure, car hauling ensures
                  smooth journeys and peace of mind.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>

      {/* <!-------- Seventh Section --------!> */}
      <Container maxWidth="lg">
        <Stack alignItems={"center"} justifyContent={"center"} my={5} gap={4}>
          <Typography
            fontWeight={"700"}
            textAlign={"start"}
            sx={{
              fontSize: {
                xl: "50px",
                lg: "50px",
                md: "40px",
                sm: "45px",
                xs: "45px",
              },
              lineHeight: "normal",
            }}
          >
            Top Trailer Models
          </Typography>
          <Grid container alignItems={"stretch"} spacing={5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Stack sx={{ height: "100%" }}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  Car Hauler Deluxe
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  The Trailer4Rent brand owes its reputation to the Car Hauler
                  Deluxe, its most iconic and capable model. With robust
                  construction, solid axles, and unique features like easy
                  loading ramps, the Car Hauler Deluxe can handle any
                  transportation challenge. What's more, its spacious design
                  ensures you can transport multiple vehicles with ease and
                  security, making it perfect for all your hauling needs.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Stack sx={{ height: "100%" }}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  Heavy-Duty Car Hauler
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  The Trailer4Rent lineup features the Heavy-Duty Car Hauler,
                  its most versatile and powerful model. Designed for robust
                  performance, this trailer can handle heavy loads with ease.
                  With features like reinforced construction and adaptable
                  loading options, the Heavy-Duty Car Hauler is perfect for
                  transporting vehicles in any condition. Its spacious design
                  ensures efficient and secure transportation for all your
                  heavy-duty hauling needs.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Stack sx={{ height: "100%" }}>
                <Typography
                  fontWeight={"700"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  Grand Trailer
                </Typography>
                <Typography
                  fontWeight={"400"}
                  textAlign={"start"}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  Experience the ultimate in luxury and capability with
                  Trailer4Rent's top model. Enjoy a quiet, refined ride with a
                  rich array of features, spacious seating for all passengers,
                  and rugged durability for challenging terrains.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <CustomButton
            onClick={() => navigate("/trailer-map")}
            style={{
              backgroundColor: themeRed,
              color: "white",
              margin: "25px 0px",
            }}
            children={"Book Now"}
          />
        </Stack>
      </Container>

      {/* <!-------- Eighth Section --------!> */}
      <UniqueReviewSlider />

      {/* <!-------- Ninth Section --------!> */}

      <ProvinceCarouselSection />
      {/* <!-------- Tenth Section --------!> */}

      <FaqsSection />
      {/* <!-------- Eleventh Section --------!> */}
      <Footer />
    </div>
  );
}

export default RentTrailer;
