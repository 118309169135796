import { Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import clock from "../../assets/clock.png";
import fixing from "../../assets/fixing.png";
import idea from "../../assets/idea.png";
import ticket from "../../assets/ticket.png";
import "./thirdSection.css";
import { themeGray, themeRed, themeText } from "../../utils/colorTheme";
import CustomButton from "../../component/common/Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ThirdSection = () => {
  const navigate = useNavigate();
  const { user, userAuth } = useSelector((state) => state.userAuth);

  const navigateHandle = () => {
    if (userAuth && user) {
      if (
        userAuth &&
        !user?.email_verified &&
        !user?.number_verified &&
        user?.kyc === "Idle"
      ) {
        navigate("/OTPForm");
      } else if (
        userAuth &&
        user?.email_verified &&
        user?.number_verified &&
        user?.kyc === "Idle"
      ) {
        navigate("/StepOne");
      } else {
        navigate("/dashboard-home");
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <Container maxWidth="lg">
      <Stack justifyContent={"center"} alignItems={"center"} mt={4} gap={2}>
        <Typography
          textAlign={"start"}
          sx={{
            width: {
              xs: "300px",
              sm: "400px",
              md: "700px",
              lg: "700px",
              xl: "700px",
            },
            fontSize: {
              xs: "15px",
              sm: "18px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
          }}
        >
          {/* These days, a reliable side hustle is more than "bonus income"—it’s a
          smart move. Start yours with Trailer4Rent, Canada’s trusted
          trailer-sharing marketplace, and boost your monthly income by safely
          renting out an asset you already own! */}
          These days, a reliable side hustle is more than just "bonus
          income"—it’s a smart move. Start yours with Trailer4Rent, Canada’s
          trusted trailer-sharing marketplace, and boost your monthly income by
          safely renting out an asset you already own!
        </Typography>
        <Typography
          textAlign={"start"}
          sx={{
            width: {
              xs: "300px",
              sm: "400px",
              md: "700px",
              lg: "700px",
              xl: "700px",
            },
            fontSize: {
              xs: "15px",
              sm: "18px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
          }}
        >
          {/* Trailer4Rent empowers entrepreneurs with the tools, resources, and
          support needed to build a thriving trailer portfolio, unlocking the
          potential to earn thousands in extra income each year. */}
          Trailer4Rent empowers entrepreneurs with the tools, resources, and
          support needed to build a thriving trailer portfolio—unlocking the
          potential to earn thousands in extra income each year.
        </Typography>
        <Typography
          textAlign={"start"}
          sx={{
            width: {
              xs: "300px",
              sm: "400px",
              md: "700px",
              lg: "700px",
              xl: "700px",
            },
            fontSize: {
              xs: "15px",
              sm: "18px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
          }}
        >
          {/* Kickstart your journey today by listing your first trailer, then take
          charge of your business plan and grow at your own pace!" */}
          Kickstart your journey today by listing your first trailer, then take
          charge of your business plan and grow at your own pace!
        </Typography>
        <CustomButton
          onClick={navigateHandle}
          style={{
            backgroundColor: themeRed,
            color: "white",
            margin: "70px 0px",
          }}
          children={"Get Started"}
        />
        <Typography
          fontWeight={"700"}
          textAlign={"center"}
          sx={{
            fontSize: {
              xs: "25px",
              sm: "25px",
              md: "40px",
              lg: "40px",
              xl: "40px",
            },
            fontFamily: "Book Antiqua, serif !important",
          }}
        >
          Start a "Side Hustle" that is
        </Typography>
      </Stack>
      <Grid container p={2} gap={10}>
        <Grid item xl={3.2} lg={3.2} md={3.2} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Affordable
            </Typography>
            <Typography fontSize={"16px"}>
              Start off with a trailer you already own or buy one to rent out —
              many types of trailers are in demand in your area.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3.2} lg={3.2} md={3.2} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Scalable
            </Typography>
            <Typography fontSize={"16px"}>
              {/* You decide how many trailers to rent out, scale your business up
              or down however you want, and whether to reinvest your earnings or
              take profits! */}
              You decide how many trailers to rent out, scale your business up
              or down however you want, and whether to reinvest your earnings or
              take profits!
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3.2} lg={3.2} md={3.2} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Lets you be in control
            </Typography>
            <Typography fontSize={"16px"}>
              Need to use the trailer for yourself one weekend? Easily book off
              the unit and use at your leisure! NOW you have a trailer that is
              paying for itself! Win-win!
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack alignItems={"center"}>
            <Typography
              fontWeight={"700"}
              textAlign={"center"}
              sx={{
                width: { md: "800px", lg: "800px", xl: "800px" },
                fontSize: {
                  xs: "25px",
                  sm: "25px",
                  md: "40px",
                  lg: "40px",
                  xl: "40px",
                },
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Trailer4Rent provides the tools to help YOU thrive!
            </Typography>
            {/* <Typography fontSize={"16px"}>
              Trailer4Rent provides the tools to help hosts thrive
            </Typography> */}
          </Stack>
        </Grid>
        <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Insurance available
            </Typography>
            <Typography fontSize={"16px"}>
              {/* Your trailer has insurance on it already. (Depending on the
              Province) you have limited coverage when renting it out. Rest easy
              knowing you can increase the liability insurance even to as low as
              $0 deductible. On offer from _link to be provided_ Learn more
              about Trailer Protection HERE. */}
              Your trailer has insurance on it already. You have limited
              coverage when renting it out. (Depending on the Province) Rest
              easy knowing you can increase the liability insurance even to as
              low as $0 deductible. On offer from _link to be provided_ Learn
              more about Trailer Protection HERE.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Safety & support
            </Typography>
            <Typography fontSize={"16px"}>
              Rely on 24/7 customer support, a reliable online team ready to
              assist you or the renter, and personalized coaching to help you
              build a smart, successful business.
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href="mailto:support@trailer4rent.ca"
              style={{
                color: "#1976d2",
                textDecoration: "none",
                fontWeight: "500",
                "&:hover": { textDecoration: "underline" }
              }}
            >
              support@trailer4rent.ca
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              Demand generation
            </Typography>
            <Typography fontSize={"16px"}>
              {/* Get instant access to our huge list of verified renters, plus
              marketing and advertising support for your trailers. */}
              Get instant access to our growing list of pre-screened and
              verified renters. Plus, marketing and advertising support for your
              trailers.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12}>
          <Stack>
            <Typography
              fontSize={"24px"}
              fontWeight={"700"}
              sx={{
                fontFamily: "Book Antiqua, serif !important",
              }}
            >
              An easy-to-use app
            </Typography>
            <Typography fontSize={"16px"}>
              Manage your business and your bookings seamlessly on the go —
              accept trips, tweak your pricing, message your guests, and more,
              all from your phone.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThirdSection;
