export const asyncStatus = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  ERROR: "error",
};

export const type_constant = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  CHECK_AUTH: "CHECK_AUTH",
  VERIFY_OTP: "VERIFY_OTP",
  VERIFY_KYC: "VERIFY_KYC",
  RESEND_OTP: "RESEND_OTP",
  DASHBOARD_API: " DASHBOARD_API",
  GET_ALL_TRAILERS: " GET_ALL_TRAILERS",
  SIGNUP_VENDOR: "SIGNUP_VENDOR",
  SIGNUP_SERVICES_GET: "SIGNUP_SERVICES_GET",
  GET_SERVICES_HOME: "GET_SERVICES_HOME",
  GET_SERVICE_BY_ID: "GET_SERVICE_BY_ID",
  CHAT_USERS_LIST: "CHAT_USERS_LIST",
  CHAT_HOSTS_LIST: "CHAT_HOSTS_LIST",
  ADD_BANK: "ADD_BANK",
  GET_BANK: "GET_BANK",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  ADD_TRAILER: "ADD_TRAILER",
  EDIT_TRAILER: "EDIT_TRAILER",
  GET_TRAILER_BY_ID: "GET_TRAILER_BY_ID",
  GET_MY_FINANCE: "GET_MY_FINANCE",
  GET_USER_TRAILERS_LIST_ASYNC: "GET_USER_TRAILERS_LIST_ASYNC",
  GET_SEARCH_TRAILERS_LIST_ASYNC: "GET_SEARCH_TRAILERS_LIST_ASYNC",
  GET_TRAILER_BY_ID_ASYNC: "GET_TRAILER_BY_ID_ASYNC",
  GET_HOST_DETAIL_BY_ID_ASYNC: "GET_HOST_DETAIL_BY_ID_ASYNC",
  UPDATE_HOST_TRAILER_STATUS_ASYNC: "UPDATE_HOST_TRAILER_STATUS_ASYNC",
  POST_BOOKING_TRAILER: " POST_BOOKING_TRAILER",
  GET_HOST_MY_BOOKINGS_ASYNC: "GET_HOST_MY_BOOKINGS_ASYNC",
  GET_HOSt_BOOKINGS_DETAIL_BY_ID_ASYNC: "GET_HOSt_BOOKINGS_DETAIL_BY_ID_ASYNC",
  GET_USER_BOOKINGS_DETAIL_BY_ID_ASYNC: "GET_USER_BOOKINGS_DETAIL_BY_ID_ASYNC",
  UPDATE_BANK_ACCOUNT_STATUS_ASYNC: "UPDATE_BANK_ACCOUNT_STATUS_ASYNC",
  GET_BOOKINGS_USER: "GET_BOOKINGS_USER",
  UPDATE_BOOKING_DETAILS_STATUS_ASYNC: "UPDATE_BOOKING_DETAILS_STATUS_ASYNC",
  UPDATE_USER_BOOKING_DETAILS_STATUS_ASYNC: "UPDATE_USER_BOOKING_DETAILS_STATUS_ASYNC",
  
  
  ADD_TICKET: "ADD_TICKET",
  GET_ALL_TICKET: "GET_ALL_TICKET",
  GET_TICKET: "GET_TICKET",
  ADD_COMMENT_TICKET: "ADD_COMMENT_TICKET",


  COMPLETE_USER_BOOKING_ASYNC: "COMPLETE_USER_BOOKING_ASYNC",
  ADD_USER_REVIEWS_ASYNC: "ADD_USER_REVIEWS_ASYNC",
  GET_USER_HISTORY: "GET_USER_HISTORY",


  RESET_PASSWORD_ASYNC: "RESET_PASSWORD_ASYNC",
  RESET_PASSWORD_OTP_ASYNC: "RESET_PASSWORD_OTP_ASYNC",
  FORGOT_PASSWORD_CHANGE_ASYNC: "FORGOT_PASSWORD_CHANGE_ASYNC",

  CONTACT_US_FORM_ASYNC: "CONTACT_US_FORM_ASYNC",


};

export const save_tokens_constant = {
  AUTH: "auth",
};
