import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import css from "./CategoriesSection.module.css";
import SecondSectionSlider from "../../../component/SecondSectionSlider";
import { themeRed } from "../../../utils/colorTheme";
import { Grid } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HorseImg from "../../../assets/CategoriesImages/horseTrailer.webp";
import Car_Trailer_Red from "../../../assets/Car-Trailer-Red.webp";
import flat_deck_trailer_rentals from '../../../assets/flat_deck_trailer_rentals.webp'

export const trailerCategories = [
  {
    id: 1,
    name: "Enclosed Trailer Rentals",
    image: require("../../../assets/CategoriesImages/Enclosed-Trailer-Rentals.webp"),
    description: "Secure, weatherproof trailers for safe transport.",
  },
  {
    id: 2,
    name: "Dump Trailer Rentals",
    image: require("../../../assets/CategoriesImages/Dump Trailer Rentals_11zon.webp"),
    description: "Heavy-duty dump trailers for easy hauling.",
  },
  {
    id: 3,
    // name: "Flatbed Trailer Rentals",
    name: "Deck-Above Trailer Rentals",
    image: require("../../../assets/deck_above_trailer.jpg"),
    description: "Spacious deck trailers for heavy loads.",
  },
  {
    id: 4,
    // name: "Horse Trailer Rentals",
    // name: "Horse/Livestock Trailer Rentals",
    name: "Horse Trailer Rentals",
    image: require("../../../assets/CategoriesImages/HorseLivestock Trailer Rentals_11zon.webp"),
    description: "Safe trailers for animal transport.",
  },
  // {
  //   id: 5,
  //   name: "Motorcycle Trailer Rentals",
  //   image: require("../../../assets/CategoriesImages/Motorcycle-Trailer-Rentals.webp"),
  // },
  // {
  //   id: 6,
  //   name: "Semi Trailer Rentals",
  //   image: require("../../../assets/CategoriesImages/Semi-Trailer-Rentals.webp"),
  // },
  {
    id: 5,
    name: "Speciality Trailer Rentals",
    image: require("../../../assets/CategoriesImages/Specialty Trailer Rentals_11zon.webp"),
    description: "Custom trailers for secure, hassle-free hauling.",
  },
  {
    id: 6,
    name: "Open Utility Trailer Rentals",
    image: require("../../../assets/CategoriesImages/Open Utility Trailer Rentals_11zon.webp"),
    description: "Versatile trailers for all your hauling needs.",
  },
  {
    id: 7,
    name: "Car Hauler Trailer Rentals",
    image: require("../../../assets/CategoriesImages/Car Hauler Trailer Rentals_11zon.webp"),
    description: "Safe, reliable trailers for transporting vehicles.",
  },
  {
    id: 8,
    name: "Flatdeck Trailer Rentals",
    image: flat_deck_trailer_rentals,
    description: "Durable flatdeck trailers built for heavy hauling",

  },
  // {
  //   id: 10,
  //   name: "Boat Trailer Rentals",
  //   image: require("../../../assets/CategoriesImages/Boat-Trailer-Rentals.webp"),
  // },
  // {
  //   id: 11,
  //   name: "Tow Dolly Trailer Rentals",
  //   image: require("../../../assets/CategoriesImages/Tow-Dolly-Trailer-Rentals.webp"),
  // },
  // {
  //   id: 12,
  //   name: "Cargo Trailer Rental",
  //   image: require("../../../assets/CategoriesImages/Cargo-Trailer-Rental.webp"),
  // },
];

const CategoriesSection = () => {
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getBoundingClientRect().width;
      setTextWidth(width);
    }
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Container maxWidth="lg">
        <Stack
          alignItems={"center"}
          mb={10}
          pt={{
            xl: "100px",
            lg: "100px",
            md: "70px",
            sm: "70px",
            xs: "70px",
          }}
        >
          <Stack maxWidth={"lg"}>
            <Typography
              textAlign={"center"}
              sx={{
                fontSize: {
                  xl: "60px",
                  lg: "50px",
                  md: "45px",
                  sm: "40px",
                  xs: "20px",
                },
                fontFamily: "Book Antiqua, serif !important",
                fontWeight: "700",
                lineHeight: "normal",
                zIndex: "1000",
              }}
            >
              See Trailers For Rent In Your Neighbourhood
            </Typography>
            <Box
              sx={{
                // width: textWidth,
                borderBottom: {
                  xl: `14px solid ${themeRed}`,
                  lg: `14px solid ${themeRed}`,
                  md: `14px solid ${themeRed}`,
                  sm: `14px solid ${themeRed}`,
                  xs: `14px solid ${themeRed}`,
                },
                outline: "none",
                color: "#99000B26",
                marginTop: {
                  xl: "-20px",
                  lg: "-18px",
                  md: "-14px",
                  sm: "-13px",
                  xs: "-9px",
                },
              }}
            ></Box>
          </Stack>
        </Stack>
        <Stack
          // alignItems={"center"}
          mb={5}
          // pt={"20px"}
          className={css.bg_see_trailer_rent_wapper}
        >
          {/* <Box sx={{ backgroundColor: themeRed, }}> */}
          <Box
            sx={{
              background:
                "linear-gradient(90deg, #99000B 0%, rgba(0, 0, 0, 0.00) 40.29%), url(<path-to-image>) #d3d3d300 50% / cover no-repeat",
            }}
            py={3}
          >
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              sx={{
                // backgroundColor: "white",
                boxShadow: 2,
                borderRadius: 2,
                // overflow: "hidden",
              }}
              style={{ position: "relative" }}
            >
              {/* Left Section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              // sx={{
              //   color: "white",
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "center",
              //   p: 4,
              // }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingLeft: "20px",
                    color: "white",
                    gap: 2,
                    pt: { sm: 5, xs: 5 },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <CheckCircleIcon sx={{ mr: 1, fontSize: "2rem" }} />
                    <Typography
                      sx={{
                        fontFamily: "Book Antiqua, serif !important",
                        fontWeight: "800",
                        // fontSize: 35,
                        fontSize: {
                          xl: "35px",
                          lg: "35px",
                          md: "35px",
                          sm: "35px",
                          xs: "20px",
                        },
                      }}
                    >
                      Get The Job Done Easily
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <CheckCircleIcon sx={{ mr: 1, fontSize: "2rem" }} />
                    <Typography
                      sx={{
                        fontFamily: "Book Antiqua, serif !important",
                        fontWeight: "800",
                        // fontSize: 35,
                        fontSize: {
                          xl: "35px",
                          lg: "35px",
                          md: "35px",
                          sm: "35px",
                          xs: "20px",
                        },
                      }}
                    >
                      Save Money.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                    <CheckCircleIcon sx={{ mr: 1, fontSize: "2rem" }} />
                    <Typography
                      sx={{
                        fontFamily: "Book Antiqua, serif !important",
                        fontWeight: "800",
                        // fontSize: 35,
                        fontSize: {
                          xl: "35px",
                          lg: "35px",
                          md: "35px",
                          sm: "35px",
                          xs: "20px",
                        },
                      }}
                    >
                      Support Local.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Right Section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={css.car_trailer_red_img}
              >
                {/* <Stack justifyContent={"flex-end"} alignItems={"flex-end"} textAlign={"center"} sx={{color:'white',fontSize:12}} >Enclosed Trailer Rental</Stack> */}
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  textAlign={"center"}
                  sx={{
                    color: "white",
                    fontSize: 12,
                    position: "absolute", // Positioning Stack absolutely
                    bottom: 0, // Moving it to the bottom
                    width: {xl:"20%",lg:"20%",md:"20%",sm:"20%",xs:"50%",}, // Ensure it spans the width of the image
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional background for better visibility
                    padding: "8px 0", // Padding for text
                    right:20
                  }}
                >
                  Enclosed Trailer Rental
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>

        <Stack alignItems={"center"}>
          <SecondSectionSlider trailersData={trailerCategories} />
        </Stack>
      </Container>
    </div>
  );
};

export default CategoriesSection;
