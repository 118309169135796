import React from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";
import "./BookingsCard.css";

const BookingsCard = ({
  first_name,
  last_name,
  src_logo,
  src_back,
  number,
  onClick,
}) => {
  return (
    <Card
      sx={{
        borderRadius: 4,
        boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
        py: 4.5,
        px: 2,
        textAlign: "left",
        position: "relative",
        overflow: "visible",
        height: { xl: 320, lg: 280, md: 280, sm: "auto", xs: "auto" }
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Stack>
              <img src={src_logo} />
            </Stack>
            <Typography variant="body1" className="booking-card-name">
              {first_name}{" "}
              <span className="booking-card-name-theme">{last_name}</span>
            </Typography>
          </Stack>
        </Stack>
        <Stack py={2}>
          <hr className="booking-card-line" />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Stack direction="column" alignItems={"flex-start"} gap={4}>
            <Stack
              className="booking-card-number"
              flexDirection={"row"}
              alignItems={"center"}
              gap={1}
            >
              {number}
            </Stack>
            <Stack className="booking-card-button" onClick={onClick}>
              View All
            </Stack>
          </Stack>

          <Stack
            sx={{
              position: "absolute",
              right: 10,
              bottom: 10,
            }}
          >
            <img src={src_back} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BookingsCard;
