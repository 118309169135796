import React, { useState } from "react";
import ChatWindow from "./ChatWindow";
import "./ChatApp.css";
import { IoIosSend } from "react-icons/io";
import { IoMdAddCircleOutline } from "react-icons/io";
import ChatHeader from "./ChatHeader";
import profile from "../../assets/profile.png";
import { Divider, Stack } from "@mui/material";
import { MdOutlineAttachFile } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import { themeRed } from "../../utils/colorTheme";


const ChatApp = ({
  messages,
  setMessage,
  sendMessage,
  userName,
  backValue,
  handleVideCall,
  handleAudioCall,
  userProfile,
}) => {
  const [newMessage, setNewMessage] = useState("");

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
    setMessage(event.target.value);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (newMessage.trim() !== "") {
      sendMessage();
      setNewMessage("");
    }
  };

  return (
    <div className="App">
      <ChatHeader
        user_name={userName}
        setBackValue={backValue}
        handleVideCall={handleVideCall}
        handleAudioCall={handleAudioCall}
        user_profile={userProfile}
      />
      <Divider sx={{ mb: 2, mt: 1 }} />
      <ChatWindow messages={messages} avatar={userProfile} />
      <form className="input-container" onSubmit={handleSendMessage}>

        <Stack
          position={"relative"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            border: "2px solid #E2E8F0",
            borderRadius: "0px 12px 12px 0px ",
            padding: "10px 7px 10px 21px",
            width: "100%",
            height: "51px",
            backgroundColor: "white",
          }}
        >
          <input
            className="input-field"
            placeholder="Type a message"
            type="text"
            value={newMessage}
            onChange={handleMessageChange}
            style={{
              fontFamily: '"Poppins", sans-serif',
            }}
          />
          <MdOutlineAttachFile size={25} color={"black"} style={{ position: 'absolute', left: 0 }} />
          <button
            type="submit"
            // className="submit-btn"
            style={{ cursor: "pointer", border: 'none', outline: 'none', backgroundColor: themeRed, padding: '5px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}
          >
            <RiSendPlaneFill size={27} color={"white"} sx={{ flexShrink: 0 }} />
          </button>
        </Stack>
      </form>
    </div>
  );
};

export default ChatApp;
