import React from "react";
import css from "./OfferTopBar.module.css";
import { useSelector } from "react-redux";

const OfferTopBar = () => {
  const { user } = useSelector((state) => state.userAuth);
  const { get_user_trailers_list_data } = useSelector(
    (state) => state.trailersDetailsManage
  );

  return (
    <div class={css.custom_banner}>
      <div class={css.text_container}>
        {get_user_trailers_list_data?.setting?.[0]?.blink_txt_start && (
          <p class={css.blink_text}>
            {get_user_trailers_list_data?.setting?.[0]?.blink_txt_start || ""}{" "}
          </p>
        )}
        &nbsp;
        {get_user_trailers_list_data?.setting?.[0]?.centered_text && (
          <p class={css.normal_text}>
            {" "}
            {get_user_trailers_list_data?.setting?.[0]?.centered_text}{" "}
          </p>
        )}
        &nbsp;
        {get_user_trailers_list_data?.setting?.[0]?.blink_txt_end && (
          <p class={css.blink_text}>
            {get_user_trailers_list_data?.setting?.[0]?.blink_txt_end}
          </p>
        )}
      </div>
    </div>
  );
};

export default OfferTopBar;
