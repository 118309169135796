import React, { useEffect, useRef, useState } from 'react';
import { themeRed } from '../../utils/colorTheme';

const GMap = ({ trailers }) => {
  const googleMapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  const markersRef = useRef([]); // To keep track of markers

  useEffect(() => {
    const googleMap = initGoogleMap();
    setMap(googleMap);
  }, []);

  useEffect(() => {
    if (!map) return;

    // Clear old markers
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    // If trailers data is empty, show the default view for Canada
    if (!trailers || trailers.length === 0) {
      map.setCenter({ lat: 56.1304, lng: -106.3468 }); // Center on Canada
      map.setZoom(4);
      return;
    }

    // Set map center to the first trailer with valid coordinates
    const firstTrailerWithCoordinates = trailers?.find(trailer => trailer?.latitude && trailer?.longitude);
    if (firstTrailerWithCoordinates) {
      map.setCenter({
        lat: firstTrailerWithCoordinates.latitude,
        lng: firstTrailerWithCoordinates.longitude,
      });
    }

    // Add new markers to the map
    trailers.forEach((trailer) => {
      if (!trailer.latitude || !trailer.longitude) return;

      const coordinates = {
        lat: trailer.latitude,
        lng: trailer.longitude,
      };

      const marker = new window.google.maps.Marker({
        position: coordinates,
        map: map,
        title: trailer.title,
        icon: {
          url: require("../../assets/trailer.png"),
          scaledSize: new window.google.maps.Size(45, 35),
          labelOrigin: new window.google.maps.Point(25, 50),
        },
        label: {
          text: `$${trailer.daily_rate}/per day`,
          color: themeRed,
          fontSize: '14px',
          fontWeight: 'bold',
          className: 'custom-label-map',
        },
      });

      marker.addListener("click", () => {
        setSelectedTrailer(trailer);
        map.panTo(coordinates); // Center map on the clicked marker
      });

      markersRef.current.push(marker); // Add marker to markers array
    });
  }, [map, trailers]);

  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: { lat: 56.1304, lng: -106.3468 }, // Default to Canada
      zoom: 12,
      streetViewControl: false,
      mapTypeControl: false,
      disableDefaultUI: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit.station",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "administrative",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "landscape.man_made",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
  };

  return (
    <div ref={googleMapRef} style={{ width: "100%", height: "100%" }}>
      {selectedTrailer && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <h2>{selectedTrailer.title}</h2>
          <p>{`$${selectedTrailer.daily_rate}/per day`}</p>
          <img
            src={selectedTrailer.trailer_photos[0]}
            alt={selectedTrailer.title}
            style={{ width: "100px" }}
          />
        </div>
      )}
    </div>
  );
};

export default GMap;


// import React, { useEffect, useRef, useState } from "react";
// import { themeRed } from "../../utils/colorTheme";

// const GMap = ({ trailers }) => {
//   const googleMapRef = useRef(null);
//   const [map, setMap] = useState(null);
//   const [selectedTrailer, setSelectedTrailer] = useState(null);
//   const markersRef = useRef([]);
//   const polygonsRef = useRef([]); // Store city boundaries

//   useEffect(() => {
//     const googleMap = initGoogleMap();
//     setMap(googleMap);
//   }, []);

//   useEffect(() => {
//     if (!map) return;

//     // Clear old markers
//     markersRef.current.forEach((marker) => marker.setMap(null));
//     markersRef.current = [];

//     // Clear old polygons
//     polygonsRef.current.forEach((polygon) => polygon.setMap(null));
//     polygonsRef.current = [];

//     // Default map view
//     if (!trailers || trailers.length === 0) {
//       map.setCenter({ lat: 56.1304, lng: -106.3468 });
//       map.setZoom(4);
//       return;
//     }

//     // Set map center to the first trailer with valid coordinates
//     const firstTrailerWithCoordinates = trailers.find(
//       (trailer) => trailer?.latitude && trailer?.longitude
//     );
//     if (firstTrailerWithCoordinates) {
//       map.setCenter({
//         lat: firstTrailerWithCoordinates.latitude,
//         lng: firstTrailerWithCoordinates.longitude,
//       });
//     }

//     // Add markers and draw city boundaries
//     trailers.forEach((trailer) => {
//       if (!trailer.latitude || !trailer.longitude) return;

//       const coordinates = {
//         lat: trailer.latitude,
//         lng: trailer.longitude,
//       };

//       // Create marker
//       const marker = new window.google.maps.Marker({
//         position: coordinates,
//         map: map,
//         title: trailer.title,
//         icon: {
//           url: require("../../assets/trailer.png"),
//           scaledSize: new window.google.maps.Size(45, 35),
//           labelOrigin: new window.google.maps.Point(25, 50),
//         },
//         label: {
//           text: `$${trailer.daily_rate}/per day`,
//           color: themeRed,
//           fontSize: "14px",
//           fontWeight: "bold",
//           className: "custom-label-map",
//         },
//       });

//       marker.addListener("click", () => {
//         setSelectedTrailer(trailer);
//         map.panTo(coordinates);
//       });

//       markersRef.current.push(marker);

//       // Draw boundary for the city
//       if (trailer.city) {
//         drawCityBoundary(trailer.city, map);
//       }
//     });
//   }, [map, trailers]);

//   const initGoogleMap = () => {
//     return new window.google.maps.Map(googleMapRef.current, {
//       center: { lat: 56.1304, lng: -106.3468 },
//       zoom: 12,
//       streetViewControl: false,
//       mapTypeControl: false,
//       disableDefaultUI: false,
//       mapTypeControl: true,
//       mapTypeControlOptions: {
//         style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
//       },
//       styles: [
//         { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] },
//         { featureType: "transit.station", stylers: [{ visibility: "off" }] },
//         { featureType: "administrative", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
//         { featureType: "landscape.man_made", stylers: [{ visibility: "off" }] },
//       ],
//     });
//   };

//   const drawCityBoundary = (city, map) => {
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ address: city }, (results, status) => {
//       if (status === "OK" && results[0]?.geometry?.bounds) {
//         const bounds = results[0].geometry.bounds;
//         const ne = bounds.getNorthEast();
//         const sw = bounds.getSouthWest();

//         const cityPolygonCoords = [
//           { lat: ne.lat(), lng: sw.lng() },
//           { lat: ne.lat(), lng: ne.lng() },
//           { lat: sw.lat(), lng: ne.lng() },
//           { lat: sw.lat(), lng: sw.lng() },
//         ];

//         const cityPolygon = new window.google.maps.Polygon({
//           paths: cityPolygonCoords,
//           strokeColor: themeRed,
//           strokeOpacity: 0.8,
//           strokeWeight: 2,
//           fillColor: themeRed,
//           fillOpacity: 0.1,
//           map: map,
//         });

//         polygonsRef.current.push(cityPolygon);
//       }
//     });
//   };

//   return (
//     <div ref={googleMapRef} style={{ width: "100%", height: "100%" }}>
//       {selectedTrailer && (
//         <div
//           style={{
//             position: "absolute",
//             bottom: "20px",
//             left: "20px",
//             backgroundColor: "white",
//             padding: "10px",
//             borderRadius: "5px",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//           }}
//         >
//           <h2>{selectedTrailer.title}</h2>
//           <p>{`$${selectedTrailer.daily_rate}/per day`}</p>
//           <img
//             src={selectedTrailer.trailer_photos[0]}
//             alt={selectedTrailer.title}
//             style={{ width: "100px" }}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default GMap;


// import React, { useEffect, useRef, useState } from "react";
// import { themeRed } from "../../utils/colorTheme";

// const GMap = ({ trailers }) => {
//   const googleMapRef = useRef(null);
//   const [map, setMap] = useState(null);
//   const [selectedTrailer, setSelectedTrailer] = useState(null);
//   const markersRef = useRef([]);
//   const polygonsRef = useRef([]); // Store city boundaries

//   useEffect(() => {
//     const googleMap = initGoogleMap();
//     setMap(googleMap);
//   }, []);

//   useEffect(() => {
//     if (!map) return;

//     // Clear old markers
//     markersRef.current.forEach((marker) => marker.setMap(null));
//     markersRef.current = [];

//     // Clear old polygons
//     polygonsRef.current.forEach((polygon) => polygon.setMap(null));
//     polygonsRef.current = [];

//     // Default map view
//     if (!trailers || trailers.length === 0) {
//       map.setCenter({ lat: 56.1304, lng: -106.3468 });
//       map.setZoom(4);
//       return;
//     }

//     // Set map center to the first trailer with valid coordinates
//     const firstTrailerWithCoordinates = trailers.find(
//       (trailer) => trailer?.latitude && trailer?.longitude
//     );
//     if (firstTrailerWithCoordinates) {
//       map.setCenter({
//         lat: firstTrailerWithCoordinates.latitude,
//         lng: firstTrailerWithCoordinates.longitude,
//       });
//     }

//     // Add markers and draw city boundaries
//     trailers.forEach((trailer) => {
//       if (!trailer.latitude || !trailer.longitude) return;

//       const coordinates = {
//         lat: trailer.latitude,
//         lng: trailer.longitude,
//       };

//       // Create marker
//       const marker = new window.google.maps.Marker({
//         position: coordinates,
//         map: map,
//         title: trailer.title,
//         icon: {
//           url: require("../../assets/trailer.png"),
//           scaledSize: new window.google.maps.Size(45, 35),
//           labelOrigin: new window.google.maps.Point(25, 50),
//         },
//         label: {
//           text: `$${trailer.daily_rate}/per day`,
//           color: themeRed,
//           fontSize: "14px",
//           fontWeight: "bold",
//           className: "custom-label-map",
//         },
//       });

//       marker.addListener("click", () => {
//         setSelectedTrailer(trailer);
//         map.panTo(coordinates);
//       });

//       markersRef.current.push(marker);

//       // Draw boundary for the city
//       if (trailer.city) {
//         drawCityBoundary(trailer.city, map);
//       }
//     });
//   }, [map, trailers]);

//   const initGoogleMap = () => {
//     return new window.google.maps.Map(googleMapRef.current, {
//       center: { lat: 56.1304, lng: -106.3468 },
//       zoom: 12,
//       streetViewControl: false,
//       mapTypeControl: false,
//       disableDefaultUI: false,
//       mapTypeControl: true,
//       mapTypeControlOptions: {
//         style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
//       },
//       styles: [
//         { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] },
//         { featureType: "transit.station", stylers: [{ visibility: "off" }] },
//         { featureType: "administrative", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
//         { featureType: "landscape.man_made", stylers: [{ visibility: "off" }] },
//       ],
//     });
//   };

//   const drawCityBoundary = (city, map) => {
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ address: city }, (results, status) => {
//       if (status === "OK" && results[0]?.geometry?.bounds) {
//         const bounds = results[0].geometry.bounds;
//         const ne = bounds.getNorthEast();
//         const sw = bounds.getSouthWest();

//         const cityPolygonCoords = [
//           { lat: ne.lat(), lng: sw.lng() },
//           { lat: ne.lat(), lng: ne.lng() },
//           { lat: sw.lat(), lng: ne.lng() },
//           { lat: sw.lat(), lng: sw.lng() },
//         ];

//         const cityPolygon = new window.google.maps.Polygon({
//           paths: cityPolygonCoords,
//           strokeColor: themeRed,
//           strokeOpacity: 0.8,
//           strokeWeight: 2,
//           fillColor: themeRed,
//           fillOpacity: 0.1,
//           map: map,
//         });

//         polygonsRef.current.push(cityPolygon);
//       }
//     });
//   };

//   return (
//     <div ref={googleMapRef} style={{ width: "100%", height: "100%" }}>
//       {selectedTrailer && (
//         <div
//           style={{
//             position: "absolute",
//             bottom: "20px",
//             left: "20px",
//             backgroundColor: "white",
//             padding: "10px",
//             borderRadius: "5px",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//           }}
//         >
//           <h2>{selectedTrailer.title}</h2>
//           <p>{`$${selectedTrailer.daily_rate}/per day`}</p>
//           <img
//             src={selectedTrailer.trailer_photos[0]}
//             alt={selectedTrailer.title}
//             style={{ width: "100px" }}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default GMap;
