// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import styles from './FinanceTable.module.css';
// import { get_host_finance_details } from '../../services/bank_mange_service';
// import { asyncStatus } from '../../utils/asyncStatus';

// const FinanceTable = ({get_my_finance_data}) => {
//     const dispatch = useDispatch();
//     // const { get_my_finance_status, get_my_finance_data, get_my_finance_error } = useSelector((state) => state.bankManage);

//     // useEffect(() => {
//     //     dispatch(get_host_finance_details());
//     // }, [dispatch]);

//     const financeData = get_my_finance_data?.finance || [];
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 10;

//     // Calculate the indices of the items to display on the current page
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const displayedData = financeData.slice(indexOfFirstItem, indexOfLastItem);

//     // Change page function
//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     // Total number of pages
//     const totalPages = Math.ceil(financeData.length / itemsPerPage);

//     // Render the table or loading/error messages
//     // if (get_my_finance_status === asyncStatus.LOADING) {
//     //     return <div className={styles.loading}>Loading...</div>;
//     // }

//     // if (get_my_finance_error) {
//     //     return <div className={styles.error}>Error: {get_my_finance_error}</div>;
//     // }

//     return (
//         <div className={styles.tableContainer}>
//             {financeData.length > 0 ? (
//                 <>
//                     <div className={styles.tableWrapper}>
//                         <table className={styles.table}>
//                             <thead>
//                                 <tr>
//                                     <th>Booking ID</th>
//                                     <th>Amount</th>
//                                     <th>Status</th>
//                                     <th>Date</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {displayedData.map((item) => (
//                                     <tr key={item._id}>
//                                         <td>{item.booking_id}</td>
//                                         <td>${item.amount}</td>
//                                         <td>{item.status}</td>
//                                         <td>{new Date(item.createdAt).toLocaleString()}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>

//                     <div className={styles.pagination}>
//                         {/* Prev Button */}
//                         <button
//                             className={`${styles.pageButton} ${currentPage === 1 ? styles.disabled : ''}`}
//                             onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
//                             disabled={currentPage === 1}
//                         >
//                             Prev
//                         </button>

//                         {/* Page Numbers */}
//                         {[...Array(totalPages)].map((_, index) => (
//                             <button
//                                 key={index}
//                                 className={`${styles.pageButton} ${currentPage === index + 1 ? styles.active : ''}`}
//                                 onClick={() => handlePageChange(index + 1)}
//                             >
//                                 {index + 1}
//                             </button>
//                         ))}

//                         {/* Next Button */}
//                         <button
//                             className={`${styles.pageButton} ${currentPage === totalPages ? styles.disabled : ''}`}
//                             onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
//                             disabled={currentPage === totalPages}
//                         >
//                             Next
//                         </button>
//                     </div>
//                 </>
//             ) : (
//                 <div>No finance data available.</div>
//             )}
//         </div>
//     );
// };

// export default FinanceTable;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FinanceTable.module.css';
import { get_host_finance_details } from '../../services/bank_mange_service';
import { asyncStatus } from '../../utils/asyncStatus';

const FinanceTable = ({ get_my_finance_data }) => {
    const dispatch = useDispatch();
    const financeData = get_my_finance_data?.finance || [];
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [totalYTD, setTotalYTD] = useState(0);

    useEffect(() => {
        // Filter data for the selected year and calculate YTD earnings
        const filteredData = financeData.filter((item) => {
            const itemYear = new Date(item.createdAt).getFullYear();
            return itemYear === selectedYear;
        });

        const total = filteredData.reduce((acc, item) => acc + (item.amount || 0), 0);
        setTotalYTD(total);
    }, [financeData, selectedYear]);

    // Calculate the indices of the items to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedData = financeData
        .filter((item) => new Date(item.createdAt).getFullYear() === selectedYear)
        .slice(indexOfFirstItem, indexOfLastItem);

    // Change page function
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Total number of pages
    const totalPages = Math.ceil(
        financeData.filter((item) => new Date(item.createdAt).getFullYear() === selectedYear).length /
        itemsPerPage
    );

    return (
        <div className={styles.tableContainer}>
            {/* YTD Earnings Section */}
            <div className={styles.ytdSection}>
                <h3>Total YTD Earnings: ${totalYTD.toLocaleString()}</h3>
                <div>
                    <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                        // className={styles.yearSelector}
                        style={{ padding: '0.5rem' }}
                    >
                        {/* Generate year options dynamically */}
                        {[...new Set(financeData.map((item) => new Date(item.createdAt).getFullYear()))]
                            .sort((a, b) => b - a) // Sort years in descending order
                            .map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                    </select>
                    
                </div>
            </div>

            {financeData.length > 0 ? (
                <>
                    <div className={styles.tableWrapper}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Booking ID</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedData.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.booking_id}</td>
                                        <td>${item.amount}</td>
                                        <td>{item.status}</td>
                                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.pagination}>
                        {/* Prev Button */}
                        <button
                            className={`${styles.pageButton} ${currentPage === 1 ? styles.disabled : ''}`}
                            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Prev
                        </button>

                        {/* Page Numbers */}
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`${styles.pageButton} ${currentPage === index + 1 ? styles.active : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}

                        {/* Next Button */}
                        <button
                            className={`${styles.pageButton} ${currentPage === totalPages ? styles.disabled : ''}`}
                            onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <div>No finance data available.</div>
            )}
        </div>
    );
};

export default FinanceTable;