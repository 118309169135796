import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../screens/Footer/Footer";
import InformationSection from "./InformationSection";
import YourPreferences from "./YourPreferences";
import privacy_policy_banner from "../../assets/privacy_policy_banner.webp";

const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      {/* Navbar Section */}
      <Navbar />

      {/* Header Section */}
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${privacy_policy_banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "60vh",
          minHeight: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          textAlign: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(37, 26, 26, 0.4)", // Using your #c3282f color with 70% opacity
            pointerEvents: "none", // Makes the overlay non-interactive
          },
        }}
      >
        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontFamily: "Book Antiqua, serif !important",
              color: "white",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            }}
          >
            Privacy Policy
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ padding: "40px 0" }}>
        {/* SEction One */}
        <Box mb={4}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "16px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              borderBottom: "4px solid #C3282F",
              paddingBottom: "4px",
            }}
          >
            Privacy Practices Overview
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              marginTop: "16px",
            }}
          >
            This Privacy Policy discloses the privacy practices for Trailer4Rent
            Inc. ("Company"), www.trailer4rent.ca website, the forthcoming
            mobile application and various related services (together referred
            to as the "Site") and any website or app that may link to this
            Privacy Policy (the "Services").
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              marginTop: "16px",
            }}
          >
            Trailer4Rent Inc., the provider of the Services (referred to as "us"
            or "we"), is committed to protecting your privacy online. Please
            read the information below to learn the following regarding your use
            of our Services.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              marginTop: "16px",
            }}
          >
            You acknowledge that this Privacy Policy is part of our Terms of
            Use, and by accessing or using our Services, you agree to be bound
            by all of its terms and conditions. This Policy complies with the
            Personal Information Protection and Electronic Documents Act
            (PIPEDA) of Canada.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              marginTop: "16px",
            }}
          >
            If you do not agree to these terms, please do not access or use our
            Site or Services.
          </Typography>
        </Box>

        {/* Section Tow */}
        <Box mb={4}>
          <InformationSection />
        </Box>

        {/* YourPreferences */}
        <Box mb={4}>
          <YourPreferences />
        </Box>
      </Container>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
