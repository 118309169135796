import React, { useEffect } from "react";
import { ServicesCard } from "../../component/common/Card/ServicesCard/ServicesCard";
import { Container, Stack } from "@mui/material";
import { CardData } from "../../utils/ArrayData";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_services } from "../../services/authentication";
import test from "../../assets/1.png";
import { imgUrl } from "../../utils/imageUrl";
import HomeTrailer from "../../assets/HomeTrailer.png";
import edited_final_tesla_ford_for_T4R from "../../assets/edited_final_tesla_ford_for_T4R.webp"

const SecondSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Stack>
      <img width={"100%"} src={edited_final_tesla_ford_for_T4R} />
    </Stack>
  );
};

export default SecondSection;
