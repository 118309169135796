import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const bookingsData = [
  {
    id: 1546,
    trailerName: "Car Hauler Trailer",
    renter: "Christine Brooks",
    duration: "01 Aug - 04 Aug",
    amount: "$70/day",
    status: "On Rent",
    statusColor: "success",
  },
  {
    id: 1645,
    trailerName: "Car Hauler Trailer",
    renter: "Rosie Pearson",
    duration: "05 Aug - 08 Aug",
    amount: "$80/day",
    status: "Upcoming",
    statusColor: "warning",
  },
  {
    id: 9465,
    trailerName: "Car Hauler Trailer",
    renter: "Darrell Caldwell",
    duration: "09 Aug - 12 Aug",
    amount: "$100/day",
    status: "Completed",
    statusColor: "default",
  },
  {
    id: 6164,
    trailerName: "Car Hauler Trailer",
    renter: "Gilbert Johnston",
    duration: "13 Aug - 16 Aug",
    amount: "$90/day",
    status: "On Rent",
    statusColor: "success",
  },
];

const BookingTable = ({data}) => {
  const { upcomingBookings, dashboard_api_status, dashboard_api_data } =
    useSelector((state) => state.userAuth);
  const navigate = useNavigate();


  const _changeDateFormate = (formateDate) => {
    const date = new Date(formateDate);

    const options = {
      weekday: "short", // "Thu"
      month: "short", // "Oct"
      day: "numeric", // "31"
      year: "numeric", // "2024"
      hour: "numeric", // "12"
      minute: "2-digit", // "00"
      hour12: true, // AM/PM format
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
          overflowX: "auto",
          maxWidth: "100%",
          display: "block",
        }}
      >
        <Table aria-label="booking table" sx={{ minWidth: 1100 }}>
          <TableHead
            sx={{
              background: "#FCFDFD",
            }}
          >
            {/* <TableRow>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Booking ID
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Trailer Name
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Renter
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Duration
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Action
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Booking Type
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Trailer Name
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Renter
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Duration
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.booking_type}
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {row?.trailer_id?.title}
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {row?.user_id?.first_name} {row?.user_id?.last_name}
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#202224",
                      fontSize: "16px",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    {_changeDateFormate(row?.start_date)}{" "}
                  </Typography>{" "}
                  to{" "}
                  <Typography
                    sx={{
                      color: "#202224",
                      fontSize: "16px",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    {_changeDateFormate(row?.end_date)}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  ${row?.booking_amount?.toLocaleString()}.00
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <Stack
                    sx={{
                      width: 110,
                      borderRadius: 2,
                      color:
                        row.status === "In-Process"
                          ? "#00B69B"
                          : row.status === "Confirmed"
                          ? "#FFA756"
                          : "#848484",
                      fontSize: "16px",
                      fontWeight: "700",
                      backgroundColor:
                        row.status === "In-Process"
                          ? "rgb(204 240 235)"
                          : row.status === "Confirmed"
                          ? "rgb(255 237 221)"
                          : "rgb(232 232 232)",
                      p: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {row?.status}
                  </Stack>
                </TableCell>
             
                <TableCell>
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={1}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/view-booking-details/${row?._id}`)
                    }
                  >
                    <Stack>
                      <img
                        src={require("../../assets/background/eye.png")}
                        alt="View"
                      />
                    </Stack>
                    <Stack
                      style={{
                        color: themeRed,
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      View
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BookingTable;
