import { CircularProgress, Container, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import ProfileCard from "../../component/ProfileCard/ProfileCard";
import BookingsCard from "../../component/BookingsCard/BookingsCard";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import BookingTable from "../../component/BookingTable/BookingTable";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../utils/asyncStatus";
import { dashboard_api_async } from "../../services/authentication";

const DashboardHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, dashboard_api_status, dashboard_api_data } = useSelector(
    (state) => state.userAuth
  );

  useEffect(() => {
    dispatch(dashboard_api_async());
  }, []);

  const dashboardLoader = dashboard_api_status === asyncStatus.LOADING;

  return dashboardLoader ? (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: "70vh" }}
    >
      <CircularProgress size={30} sx={{ color: themeRed }} />
    </Stack>
  ) : (
    <Stack p={3}>
      <Stack>
        <Grid container spacing={5}>
          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
            <ProfileCard />
          </Grid>
          <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
            <BookingsCard
              first_name={"Number of"}
              last_name={"Bookings"}
              number={
                <span>
                  <span>{dashboard_api_data?.booking_count || 0}</span>{" "}
                  {dashboard_api_data?.booking_count > 0 ? (
                    <span style={{ color: themeRed }}>+</span>
                  ) : (
                    ""
                  )}
                </span>
              }
              src_logo={require("../../assets/background/booking-image.png")}
              src_back={require("../../assets/background/booking-back-image.png")}
              onClick={() => navigate("/my-booking")}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <BookingsCard
              first_name={"Available"}
              last_name={"Balance"}
              number={<span>$2500</span>}
              src_logo={require("../../assets/background/balance-image.png")}
              src_back={require("../../assets/background/balance-background-image.png")}
              onClick={() => navigate("/my-revenue")}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <BookingsCard
              first_name={"Monthly"}
              last_name={"Earning"}
              number={<span>$5500</span>}
              src_logo={require("../../assets/background/earning-image.png")}
              src_back={require("../../assets/background/earning-back-image.png")}
              onClick={() => navigate("/my-revenue")}
            />
          </Grid>
        </Grid>
        {dashboard_api_data?.upcomingBookings?.length > 0 && (
          <Stack my={2}>
            <Stack
              className="table-upper-heading"
              flexDirection={"row"}
              alignItems={"center"}
              gap={0.5}
              py={1}
            >
              <span>All </span>
              <span className="table-upper-heading-theme">Bookings</span>
            </Stack>
            <BookingTable data={dashboard_api_data?.upcomingBookings || []} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DashboardHome;
