import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const getUserTrailersListAsync = createAsyncThunk(
  type_constant.GET_USER_TRAILERS_LIST_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`trailer-cateogry`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS GET USER TRAILERS LIST ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getSearchTrailersListAsync = createAsyncThunk(
  type_constant.GET_SEARCH_TRAILERS_LIST_ASYNC,
  async (filters, { rejectWithValue }) => {
    try {
      // Construct query parameters from the filters object
      const queryParams = new URLSearchParams(filters).toString();

      // Make the GET request with query parameters
      const response = await apiHandle.get(
        `/search-all-trailers?${queryParams}`
      );
      return response.data; // Return the response data
    } catch (error) {
      console.log("ERROR FETCHING TRAILERS LIST", error);

      // Handle error properly
      return rejectWithValue(
        error?.response?.data?.message || "Failed to fetch trailers"
      );
    }
  }
);

export const getTrailerByIdAsync = createAsyncThunk(
  type_constant.GET_TRAILER_BY_ID_ASYNC,
  async (id) => {
    try {
      const response = await apiHandle.get(`/single-trailer/${id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS GET USER TRAILERS BY ID ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getHostDetailByIdAsync = createAsyncThunk(
  type_constant.GET_HOST_DETAIL_BY_ID_ASYNC,
  async (id) => {
    try {
      const response = await apiHandle.get(`/trailer-by-host-id/${id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS GET HOST DETAILS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const updateHostTrailerStatusAsync = createAsyncThunk(
  type_constant.UPDATE_HOST_TRAILER_STATUS_ASYNC,
  async (trailer_status) => {
    try {
      const response = await apiHandle.post(
        `/host/trailer-status-change`,
        trailer_status
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM UPDATE HOST TRAILER STATUS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const book_Trailer_Async = createAsyncThunk(
  type_constant.POST_BOOKING_TRAILER,
  async (user_data) => {
    try {
      const response = await apiHandle.post(`user/book-a-trailer`, user_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM UPDATE HOST TRAILER STATUS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getHostMyBookingsAsync = createAsyncThunk(
  type_constant.GET_HOST_MY_BOOKINGS_ASYNC,
  async (trailer_status) => {
    try {
      const response = await apiHandle.get(
        `host/get-my-bookings`,
        trailer_status
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM GET HOST MY BOOKINGS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getHostBookingsDetailByIdAsync = createAsyncThunk(
  type_constant.GET_HOSt_BOOKINGS_DETAIL_BY_ID_ASYNC,
  async (trailer_id) => {
    try {
      const response = await apiHandle.get(
        `host/view-my-booking/${trailer_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM GET HOST BOOKINGS DETAIL BY ID ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const getUserBookingsDetailByIdAsync = createAsyncThunk(
  type_constant.GET_USER_BOOKINGS_DETAIL_BY_ID_ASYNC,
  async (trailer_id) => {
    try {
      const response = await apiHandle.get(`user/view-booking/${trailer_id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM GET HOST BOOKINGS DETAIL BY ID ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getUserBookings = createAsyncThunk(
  type_constant.GET_BOOKINGS_USER,
  async () => {
    try {
      const response = await apiHandle.get(`user/bookings`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM GET USER BOOKINGS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const updateBookingDetailsStatusAsync = createAsyncThunk(
  type_constant.UPDATE_BOOKING_DETAILS_STATUS_ASYNC,
  async (booking_status) => {
    try {
      const response = await apiHandle.post(
        `host/booking-reject-confirm`,
        booking_status
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM UPDATE BOOKINGS DETAILS STATUS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const updateUserBookingDetailsStatusAsync = createAsyncThunk(
  type_constant.UPDATE_USER_BOOKING_DETAILS_STATUS_ASYNC,
  async (booking_status) => {
    try {
      const response = await apiHandle.post(
        `user/complete-booking`,
        booking_status
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log(
        "ERRORS FROM UPDATE BOOKINGS DETAILS STATUS FORM USER SIDE ASYNC",
        error
      );
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const completeUserBookingAsync = createAsyncThunk(
  type_constant.COMPLETE_USER_BOOKING_ASYNC,
  async (booking_id) => {
    try {
      const response = await apiHandle.post(
        `user/complete-booking`,
        booking_id
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM COMPLETE USER BOOKING ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addUserReviewsAsync = createAsyncThunk(
  type_constant.ADD_USER_REVIEWS_ASYNC,
  async (reviews_data) => {
    try {
      const response = await apiHandle.post(`user/add-review`, reviews_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM ADD USER REVIEWS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getHistoryAsync = createAsyncThunk(
  type_constant.GET_USER_HISTORY,
  async () => {
    try {
      const response = await apiHandle.get(`user/booking-history`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM ADD USER REVIEWS ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const postNewsLetterAsync = createAsyncThunk(
  type_constant.POST_NEWS_LETTER_ASYNC,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`/submit-newsletter`,post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERRORS FROM ADD NEWs LETTER ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
