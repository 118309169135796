import { useNavigate } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
import "../index.css";
import Navbar from "../component/navbar/Navbar";
import HeroSection from "../screens/FirstSection/HeroSection";
import ThirdSection from "../screens/ThirdSection/ThirdSection";
import SecondSection from "../screens/SecondSection/SecondSection";
import FourthSection from "../screens/FourthSection/FourthSection";
import Footer from "../screens/Footer/Footer";


function BecomeHost() {
  const navigate = useNavigate();

  return (
    <div style={{backgroundColor:"white"}}>
      <Navbar />
      <HeroSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <Footer /> 
    </div>
  );
}

export default BecomeHost;
