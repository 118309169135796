// import { Container, Stack } from "@mui/system";
// import React, { useEffect, useState } from "react";
// import { Grid, Typography } from "@mui/material";
// import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
// import { useNavigate } from "react-router-dom";
// import Input from "../../component/common/Input";
// import ButtonComp from "../../component/common/ButtonComp";
// import { themeBlue, themeRed } from "../../utils/colorTheme";
// import { useDispatch, useSelector } from "react-redux";
// import authImage from "../../assets/auth_image.png";
// import Logo from "../../assets/logo.png";
// import "./auth.css";
// import { common_caption_strings } from "../../utils/language_controls/constant_strings";
// import { login_service_auth } from "../../services/authentication";
// import { asyncStatus } from "../../utils/asyncStatus";
// import { setIdleStatus } from "../../store/slices/user_auth_slice";
// import { LoadingButton } from "@mui/lab";

// const CreatePassword = () => {
//   const dispatch = useDispatch();
//   const [showPass, setShowPass] = useState(true);
//   // const [value, setValue] = useState(true)
//   const [data, setData] = useState({});
//   // const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { forgot_password_changes_status } = useSelector((state) => state.userAuth);

//   const submitHandle = () => {
//     // let obj = {
//     //     email: "martingarix7878@gmail.com",
//     //     password: "password"
//     // }
//     // console.log(data);
//     // dispatch(login_service_auth(data))
//   };
//   useEffect(() => {
//     // if (forgot_password_changes_status === asyncStatus.SUCCEEDED) {
//     //     navigate("/")
//     //     dispatch(setIdleStatus(setIdleStatus))
//     // }
//   }, []);

//   return (
//     <Stack>
//       <Grid container>
//         <Grid item xs={12} sm={12} md={6} lg={6}>
//           <Container maxWidth={"xl"}>
//             <Stack mt={2}>
//               <Stack
//                 justifyContent={"flex-start"}
//                 sx={{
//                   width: "100%",
//                   padding: 5,
//                 }}
//                 textAlign={"left"}
//                 color={"black"}
//               >
//                 <Stack
//                   spacing={3}
//                   mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }}
//                   mb={2}
//                 >
//                   <Stack spacing={1}>
//                     <Stack className="auth_Heading">
//                       <span className="auth_Heading">
//                         Create a New
//                         <span style={{ color: themeRed }}>Password</span>
//                       </span>
//                     </Stack>
//                     <Stack className="auth_sub_Heading">
//                       <span>
//                         Your new password will be different from the existing &
//                         previous ones.
//                       </span>
//                     </Stack>
//                   </Stack>
//                   <Stack>
//                     <Grid container spacing={2}>
//                       <Grid item xs={12} sm={12} md={12} lg={12}>
//                         <Stack gap={6}>
//                           <Stack position={"relative"}>
//                             <Input
//                               id={"password"}
//                               type={showPass ? "password" : "text"}
//                               onChange={(e) =>
//                                 setData({
//                                   ...data,
//                                   [e.target.id]: e.target.value,
//                                 })
//                               }
//                               style={{ borderRadius: "6px" }}
//                               placeholder={common_caption_strings.password}
//                               label="Password"
//                             />

//                             {showPass ? (
//                               <BsFillEyeFill
//                                 onClick={() => setShowPass(false)}
//                                 style={{
//                                   position: "absolute",
//                                   top: 20,
//                                   right: 10,
//                                   cursor: "pointer",
//                                 }}
//                                 size={18}
//                               />
//                             ) : (
//                               <BsFillEyeSlashFill
//                                 onClick={() => setShowPass(true)}
//                                 style={{
//                                   position: "absolute",
//                                   top: 20,
//                                   right: 10,
//                                   cursor: "pointer",
//                                 }}
//                                 size={18}
//                               />
//                             )}
//                           </Stack>
//                           <Stack position={"relative"}>
//                             <Input
//                               id={"password"}
//                               type={showPass ? "password" : "text"}
//                               onChange={(e) =>
//                                 setData({
//                                   ...data,
//                                   [e.target.id]: e.target.value,
//                                 })
//                               }
//                               style={{ borderRadius: "6px" }}
//                               placeholder={common_caption_strings.password}
//                               label="Password"
//                             />

//                             {showPass ? (
//                               <BsFillEyeFill
//                                 onClick={() => setShowPass(false)}
//                                 style={{
//                                   position: "absolute",
//                                   top: 20,
//                                   right: 10,
//                                   cursor: "pointer",
//                                 }}
//                                 size={18}
//                               />
//                             ) : (
//                               <BsFillEyeSlashFill
//                                 onClick={() => setShowPass(true)}
//                                 style={{
//                                   position: "absolute",
//                                   top: 20,
//                                   right: 10,
//                                   cursor: "pointer",
//                                 }}
//                                 size={18}
//                               />
//                             )}
//                           </Stack>
//                         </Stack>
//                         <Stack mt={0.5}>
//                           <span className="mainPara">
//                             Didn't receive the email? Check your spam filter or
//                             try <br />
//                             <span
//                               style={{
//                                 color: "#1C77BF",
//                                 textDecorationLine: "underline",
//                                 cursor: "pointer",
//                                 lineHeight: 2,
//                               }}
//                             >
//                               another email address.
//                             </span>
//                           </span>
//                         </Stack>
//                       </Grid>
//                     </Grid>
//                   </Stack>
//                 </Stack>
//                 <Stack>
//                   <LoadingButton
//                     onClick={submitHandle}
//                     style={{
//                       padding: "16px 8px",
//                       fontSize: "18px",
//                       borderRadius: "8px",
//                       backgroundColor: themeRed,
//                     }}
//                     loading={
//                       forgot_password_changes_status === asyncStatus.LOADING ? true : false
//                     }
//                     variant="contained"
//                   >
//                     {common_caption_strings.confirm}
//                   </LoadingButton>
//                 </Stack>
//                 <Stack mt={1} direction={"row"} justifyContent={"center"}>
//                   <Typography
//                     sx={{
//                       color: themeRed,
//                       mx: "2px",
//                       cursor: "pointer",
//                       textDecorationLine: "underline",
//                     }}
//                   >
//                     {common_caption_strings.resend}
//                   </Typography>
//                 </Stack>
//               </Stack>
//             </Stack>
//           </Container>
//         </Grid>
//         <Grid item xs={12} sm={12} md={6} lg={6}>
//           <Stack alignItems={"center"} className="bgImageSignup" width={1}>
//             {/* <img src={authImage} style={{  width: '80%', }} /> */}
//             <Stack
//               mt={10}
//               width={"70%"}
//               className="transparent-bg"
//               p={4}
//               gap={5}
//             >
//               <Stack>
//                 <img width={"100px"} src={Logo} />
//               </Stack>
//               <Stack>
//                 <Typography
//                   textAlign={"start"}
//                   className="subHeading"
//                   color={"white"}
//                 >
//                   We are <br />
//                   Invite Only Right Now
//                 </Typography>
//                 <Typography className="mainPara" color={"white"}>
//                   Lorem ipsum dolor sit amet consectetur. Duis in venenatis nibh
//                   ipsum. At mattis praesent id odio. Adipiscing vel amet laoreet
//                   vel.
//                 </Typography>
//               </Stack>
//               <Stack mt={20}>
//                 <Typography className="mainPara" color={"white"}>
//                   Already have an Account?
//                 </Typography>
//                 <Typography className="mainPara" color={themeRed}>
//                   Submit
//                 </Typography>
//               </Stack>
//             </Stack>
//           </Stack>
//         </Grid>
//       </Grid>
//     </Stack>
//   );
// };
// export default CreatePassword;

import { Stack, Typography, Button, CircularProgress, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaUser, FaPhone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./auth.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../screens/Footer/Footer";
import IconTextField from "../../component/IconInput/IconInput";
import { TbPassword } from "react-icons/tb";
import { themeRed } from "../../utils/colorTheme";
import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import {
  forgotPasswordChangeAsync,
  login_service_auth,
} from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { IconButton } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";

const CreatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passwordError, setPasswordError] = useState(""); // State for error message

  const { forgot_password_changes_status, reset_password_otp_verify_data } =
    useSelector((state) => state.userAuth);

  const _handlePasswordChange = (key, value) => {
    setPassword({ ...password, [key]: value });
    // Check for password match on each change
    if (key === "confirmPassword" && value !== password.newPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError(""); // Clear error if passwords match
    }
  };

  const submitHandle = () => {
    const obj = {
      email: reset_password_otp_verify_data?.email || "",
      otp: reset_password_otp_verify_data?.otp || "",
      newPassword: password.newPassword,
      confirmPassword: password.confirmPassword,
    };

    dispatch(forgotPasswordChangeAsync(obj));
  };
console.log("errrorrrr",passwordError)
  useEffect(() => {
    if (forgot_password_changes_status === asyncStatus.SUCCEEDED) {
      navigate("/login");
    }
  }, [forgot_password_changes_status]);

  return (
    <Stack bgcolor={"white"} minHeight="100vh">
      <Navbar />

      {/* Centered Stack for the Login Form */}
      <Stack
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        padding={2}
      >
        <Stack
          width={"100%"}
          maxWidth={"600px"} /* Set max width for form */
          bgcolor={"#fff"}
          padding={4}
        >
          {/* Login Title */}
          <Typography
            variant="h4"
            fontWeight={"700"}
            component="h1"
            align="start"
            gutterBottom
          >
            Create a New Password
          </Typography>

          <Typography variant="caption" component="span" align="start" mb={3}>
            Your new password will be different from the existing & previous
            ones.
          </Typography>

          {/* Password Input */}
          <Stack sx={{ position: "relative" }}>
            <IconTextField
              label="New Password"
              placeholder="New Password"
              icon={<FaLock style={{ color: "#b20e1c" }} />}
              type={showPass ? "text" : "password"}
              value={password.newPassword}
              onChange={(e) =>
                _handlePasswordChange("newPassword", e.target.value)
              }
            />
            <IconButton
              onClick={() => setShowPass(!showPass)}
              sx={{ position: "absolute", top: "41%", left: "92%" }}
            >
              {showPass ? (
                <FaRegEyeSlash style={{ color: "#b20e1c" }} />
              ) : (
                <MdOutlineRemoveRedEye style={{ color: "#b20e1c" }} />
              )}
            </IconButton>
          </Stack>
          {/* Password Input */}
          <div>
            <Stack sx={{ position: "relative" }}>
              <IconTextField
                label="Confirm Password"
                placeholder="Confirm Password"
                icon={<FaLock style={{ color: "#b20e1c" }} />}
                type={showConfirmPass ? "text" : "password"}
                value={password.confirmPassword}
                onChange={(e) =>
                  _handlePasswordChange("confirmPassword", e.target.value)
                }
              />
              <IconButton
                onClick={() => setShowConfirmPass(!showConfirmPass)}
                sx={{ position: "absolute", top: "41%", left: "92%" }}
              >
                {showConfirmPass ? (
                  <FaRegEyeSlash style={{ color: "#b20e1c" }} />
                ) : (
                  <MdOutlineRemoveRedEye style={{ color: "#b20e1c" }} />
                )}
              </IconButton>
            </Stack>
            {/* Password Mismatch Error */}
            {passwordError && (
              <FormHelperText error>{passwordError}</FormHelperText>
            )}
          </div>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={submitHandle}
            disabled={forgot_password_changes_status === asyncStatus.LOADING}
          >
            {forgot_password_changes_status === asyncStatus.LOADING ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>

          {/* Sign Up Link */}
          <Typography variant="body2" align="center" mt={2}>
            Don't have an account?{" "}
            <a style={{ color: themeRed }} href="/signup">
              Sign up
            </a>
          </Typography>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default CreatePassword;
