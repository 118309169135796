import { Stack, SvgIcon, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { MdClose } from 'react-icons/md';
import useWindowDimensions from '../../utils/hooks/windowDimensions';
import logo_text from "../../assets/logo.png";
import logo_favicon from '../../assets/logo_favicon.png';
import logo_horizontal from '../../assets/logo.png';
import { userDashboardRoutes, userFromUserSideRoutes } from '../../utils/sideRoute';
import { IoLogOut } from 'react-icons/io5';
import "./style.css"
import { useSelector } from 'react-redux';
import { HiOutlineLogout } from "react-icons/hi";
import { exit_session } from '../../config/apiHandle/apiHandle';
import { RxCross2 } from "react-icons/rx";
import CustomModal from '../common/CustomModal/CustomModal';
import CustomButton from '../common/Button/Button';

export const UserSideBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { active, onClickHanlde, hidden, activeChangeHandle, isActiveState, setIsActiveState } = props;

  const [openModal, setOpenModal] = useState(false)


  const menuClickHandle = () => {
    if (width < 851) {
      activeChangeHandle();
      setIsActiveState(false);
    }
  };

  useEffect(() => {
    if (width < 851) {
      setIsActiveState(false);
    } else {
      setIsActiveState(true);
    }
  }, [width, setIsActiveState]);

  const handleMouseEnter = () => {
    if (width > 851) {
      setIsActiveState(false);
    }
  };

  const handleMouseLeave = () => {
    if (width > 851) {
      setIsActiveState(true);
    }
  };

  const UserRole = "user";

  const userType = useSelector((state) => state.userType.userType);


  return (
    <Stack
      spacing={2}
      className={`sidebar ${active && 'active'} ${hidden && 'hidden-side-bar'}`}
      sx={{ borderRightColor: hidden ? 'green' : '#c3282f' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack flexDirection={"column"} justifyContent={"space-between"} height={"100%"} alignItems={"flex-start"} pb={4} position={"relative"}>
        <Stack onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>
          {width > 850 ? (
            <Stack direction='row' alignItems='center' paddingX={isActiveState ? 1 : 2} mt={2.5} mb={2} spacing={1} sx={{ height: '45px' }}>
              <img className={isActiveState ? "content-effect-logo-text-active" : 'content-effect-logo-text'} src={logo_text} />
            </Stack>
          ) : (
            <Stack px={2}>
              <Stack direction='row' alignItems='center' mt={15} mb={2} spacing={1} sx={{ height: '45px' }}>
                <img className='content-effect' style={{ width: '120px', transition: 'width 0.35s ease-in-out' }} src={logo_horizontal} />
              </Stack>
              <Stack position={"absolute"} right={0} top={100} >
                <IconButton onClick={activeChangeHandle} size='large' color="white" >
                  <MdClose color='white' />
                </IconButton>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack spacing={1} mt={{ xl: -20, lg: 0, md: 0 }}>
          {userType === "User" ?
            React.Children.toArray(userFromUserSideRoutes.map((e) => {
              const { caption, linkTo, list_in_sidebar } = e;
              const isActiveTab = location.pathname === `${linkTo}`;
              return list_in_sidebar && (
                <NavLink
                  onClick={() => menuClickHandle(e)}
                  style={({ isActive }) => ({
                    textDecoration: "inherit",
                    height: "45px",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    backgroundColor: isActive ? "black" : "",
                    position: "relative",
                    width: isActiveState ? "55px" : "auto"
                  })}
                  to={`${e.linkTo}`}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ paddingLeft: isActiveState ? "14px" : "14px" }}
                  >
                    {/* <SvgIcon style={{ paddingRight: "2px" }}>
                    {e.icon}
                  </SvgIcon> */}
                    <div style={{ paddingRight: "3px" }}>
                      <img src={e.icon} />
                    </div>
                    <Typography
                      noWrap
                      className={isActiveState && "sidebar-caption"}
                    >
                      {e.caption}
                    </Typography>
                  </Stack>

                  {isActiveTab && (
                    <div
                      style={{
                        position: "absolute",
                        right: "-10px",
                        width: "0",
                        height: "0",
                        top: "0px",
                        borderBottom: "47.5px solid transparent",
                        borderLeft: "10px solid black",
                      }}
                    ></div>
                  )}
                </NavLink>
              );
            }))
            :
            React.Children.toArray(userDashboardRoutes.map((e) => {
              const { caption, linkTo, list_in_sidebar } = e;
              const isActiveTab = location.pathname === `${linkTo}`;
              return list_in_sidebar && (
                <NavLink
                  onClick={() => menuClickHandle(e)}
                  style={({ isActive }) => ({
                    textDecoration: "inherit",
                    height: "45px",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    backgroundColor: isActive ? "black" : "",
                    position: "relative",
                    width: isActiveState ? "55px" : "auto"
                  })}
                  to={`${e.linkTo}`}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ paddingLeft: isActiveState ? "14px" : "14px" }}
                  >
                    {/* <SvgIcon style={{ paddingRight: "2px" }}>
                    {e.icon}
                  </SvgIcon> */}
                    <div style={{ paddingRight: "3px" }}>
                      <img src={e.icon} />
                    </div>
                    <Typography
                      noWrap
                      className={isActiveState && "sidebar-caption"}
                    >
                      {e.caption}
                    </Typography>
                  </Stack>

                  {isActiveTab && (
                    <div
                      style={{
                        position: "absolute",
                        right: "-10px",
                        width: "0",
                        height: "0",
                        top: "0px",
                        borderBottom: "47.5px solid transparent",
                        borderLeft: "10px solid black",
                      }}
                    ></div>
                  )}
                </NavLink>
              );
            }))

          }
        </Stack>

        <Stack>
          <Stack
            style={{
              textDecoration: "inherit",
              height: "45px",
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              color: "white",
              cursor: 'pointer'
            }}
            // to={``}
            onClick={() => setOpenModal(true)}
          >
            <Stack
              direction="row"
              spacing={1}
              style={{ paddingLeft: isActiveState ? "14px" : "14px" }}
            >
              <SvgIcon style={{ paddingRight: "2px" }}>
                <IoLogOut />
              </SvgIcon>
              <Typography
                noWrap
                className={isActiveState && "sidebar-caption"}
              >
                Logout
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <CustomModal
          isOpen={openModal}
          setIsOpen={() => setOpenModal(!openModal)}
        >
          <Stack
            sx={{
              overflow: "auto",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
            alignItems={"center"}
            p={1}
          >
            <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
              <IconButton
                onClick={() => {
                  setOpenModal(!openModal);
                }}
              >
                <RxCross2 size={20} sx={{ color: "black" }} />
              </IconButton>
            </Stack>

            <Stack gap={2} px={1} sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                Are you sure you want to logout?
              </Typography>

              <Stack
                gap={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <CustomButton
                  onClick={() => exit_session()}
                  children={"Yes"}
                  style={{
                    width: "100px",
                    height: "auto",
                    borderRadius: "15px",
                  }}
                />
                <CustomButton
                  onClick={() => {
                    setOpenModal(!openModal);
                  }}
                  children={"No"}
                  style={{
                    width: "100px",
                    height: "auto",
                    borderRadius: "15px",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </CustomModal>
      </Stack>
    </Stack>
  );
}
