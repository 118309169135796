import { useNavigate } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
import "../index.css";
import Navbar from "../component/navbar/Navbar";
import Footer from "../screens/Footer/Footer";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import "./home.css";
import ProvinceCarousel from "../component/UserProvinceSlider";
import TestimonialSlider from "../component/TestimonialSlider";
import SecondSectionSlider from "../component/SecondSectionSlider";
import UniqueReviewSlider from "../screens/AboutUsSection/AboutUsSection";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getUserTrailersListAsync } from "../services/trailers_details_service";
import { useEffect } from "react";
import LocationSearch from "../component/LocationSearch/LocationSearch";
import { themeRed } from "../utils/colorTheme";
import TrailersStepsSection from "./HomeSections/TrailersStepsSection/TrailersStepsSection";
import HeroSection from "./HomeSections/HeroSection/HeroSection";
import CategoriesSection from "./HomeSections/CategorieSection/CategoriesSection";
import WhyRentSection from "./HomeSections/WhyRentSection/WhyRentSection";
import ProvinceCarouselSection from "./HomeSections/ProvinceCarouselSection/ProvinceCarouselSection";
import TestimonialsSection from "./HomeSections/TestimonialsSection/TestimonialsSection";
import FaqsSection from "./HomeSections/FaqsSection/FaqsSection";
import OfferTopBar from "./HomeSections/OfferTopBar/OfferTopBar";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { get_user_trailers_list_status, get_user_trailers_list_data } =
    useSelector((state) => state.trailersDetailsManage);
  const _handleSearch = (location, fromDate, untilDate) => {
    console.log("Search initiated with:", location, fromDate, untilDate);
    // Add your logic here to perform the search or API call
  };

  useEffect(() => {
    dispatch(getUserTrailersListAsync());
  }, []);

  return (
    <div style={{ backgroundColor: "white", overflow: "hidden" }}>
      {get_user_trailers_list_data?.setting?.[0]?.blink_txt_start ||
      get_user_trailers_list_data?.setting?.[0]?.blink_txt_end ||
      get_user_trailers_list_data?.setting?.[0]?.centered_text ? (
        <OfferTopBar />
      ) : null}
      {/* <!-------- Navbar Section --------!> */}
      <Navbar />

      {/* <!-------- First Section --------!> */}
      <HeroSection />

      {/* <!-------- Second Section --------!> */}
      <CategoriesSection />

      {/* <!-------- Third Section --------!> */}
      <ProvinceCarouselSection />

      {/* <!-------- Fourth Section --------!> */}
      <WhyRentSection />

      {/* <!-------- Fifth Section --------!> */}
      <TrailersStepsSection />

      {/* <!-------- Sixth Section --------!> */}
      <TestimonialsSection />

      {/* <!-------- Seventh Section --------!> */}

      <UniqueReviewSlider />

      {/* Sixth Section */}
      {/* <Container maxWidth="xl" sx={{ paddingBlock: "50px" }}>
        <img
          src="https://res.cloudinary.com/dpfni06vw/image/upload/v1726004690/Group_1000002679_rm5fs8.png"
          // style={{ maxWidth: "1100px" }}
          width={"100%"}
        />
      </Container> */}

      {/* <!-------- Eighth Section --------!> */}
      <FaqsSection />

      {/* <!-------- Ninth Section --------!> */}

      <Footer />
    </div>
  );
}

export default Home;
