import React from "react";
import "./Tabs.css";

const Tabs = ({ tabs, setActiveTab, activeTab }) => {
  const getContainerBorderRadius = () => {
    const firstTabRadius = tabs[0]?.radius || 0;
    const lastTabRadius = tabs[tabs.length - 1]?.radius || 0;

    return `${firstTabRadius}px ${lastTabRadius}px ${lastTabRadius}px ${firstTabRadius}px`;
  };

  return (
    <>
      {/* Tabs For Desktop and Tablet */}
      <div
        className="tabs-container"
        style={{ borderRadius: getContainerBorderRadius() }}
      >
        {tabs.map((tab) => (
          <div
            key={tab.value}
            className={`tab-item ${activeTab === tab.value ? "active" : ""}`}
            onClick={() => setActiveTab(tab.value)}
            style={{ borderRight: `${tab.border || 1}px solid #D7D7D7` }}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {/* Tabs For Mobile */}
      <div className="tabs-container-mobile">
        <select
          className="mobile-tabs-dropdown"
          value={activeTab}
          onChange={(e) => setActiveTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.value} value={tab.value}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Tabs;
