import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";

// // Array of states data
// Corrected Array of Canadian Provinces and Territories with ISO 3166-2:CA Codes
export const states = [
  {
    name: "Alberta",
    code: "AB",
  },
  {
    name: "British Columbia",
    code: "BC",
  },
  {
    name: "Manitoba",
    code: "MB",
  },
  {
    name: "New Brunswick",
    code: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    code: "NL",
  },
  {
    name: "Northwest Territories",
    code: "NT",
  },
  {
    name: "Nova Scotia",
    code: "NS",
  },
  {
    name: "Nunavut",
    code: "NU",
  },
  {
    name: "Ontario",
    code: "ON",
  },
  {
    name: "Prince Edward Island",
    code: "PE",
  },
  {
    name: "Quebec",
    code: "QC",
  },
  {
    name: "Saskatchewan",
    code: "SK",
  },
  {
    name: "Yukon",
    code: "YT",
  },
];

// Functional Component
const StateGrid = ({ category }) => {
  const navigate = useNavigate();

  const handleStateClick = (stateCode) => {
    navigate("/trailer-map", {
      state: { name: category, location_state: stateCode },
    });
  };

  return (
    <div className="container">
      <Stack
        sx={{
          fontSize: 35,
          fontWeight: "bold",
          color: "#000",
        }}
        mb={3}
      >
        {/* SEARCH BY STATE */}
        Search By State
      </Stack>
      <Grid container spacing={3}>
        {states.map((state, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            key={index}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleStateClick(state.code)}
          >
            <LocationOnIcon style={{ color: themeRed, marginRight: "8px" }} />
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>
              {state.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StateGrid;
