// import { Container, Stack } from "@mui/system";
// import React, { useEffect, useState } from "react";
// import { Grid, Typography } from "@mui/material";
// import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
// import { useNavigate } from "react-router-dom";
// import Input from "../../component/common/Input";
// import ButtonComp from "../../component/common/ButtonComp";
// import { themeBlue, themeRed } from "../../utils/colorTheme";
// import { useDispatch, useSelector } from "react-redux";
// import authImage from '../../assets/auth_image.png'
// import Logo from '../../assets/logo.png'
// import './auth.css'
// import { common_caption_strings } from "../../utils/language_controls/constant_strings";
// import { login_service_auth } from "../../services/authentication";
// import { asyncStatus } from "../../utils/asyncStatus";
// import { setIdleStatus } from "../../store/slices/user_auth_slice";
// import { LoadingButton } from "@mui/lab";

// const ForgotPassword = () => {
//     const dispatch = useDispatch()
//     const [showPass, setShowPass] = useState(true);
//     // const [value, setValue] = useState(true)
//     const [data, setData] = useState({});
//     // const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { user_login_status } = useSelector((state) => state.userAuth);

//     const submitHandle = () => {
//         // let obj = {
//         //     email: "martingarix7878@gmail.com",
//         //     password: "password"
//         // }
//         // console.log(data);
//         // dispatch(login_service_auth(data))

//     }
//     useEffect(() => {
//         // if (user_login_status === asyncStatus.SUCCEEDED) {
//         //     navigate("/")
//         //     dispatch(setIdleStatus(setIdleStatus))

//         // }
//     }, [])

//     return (
//         <Stack>
//             <Grid container >
//                 <Grid item xs={12} sm={12} md={6} lg={6} >
//                     <Container maxWidth={"xl"}>
//                         <Stack mt={2} >
//                             <Stack
//                                 justifyContent={"flex-start"}
//                                 sx={{
//                                     width: "100%",
//                                     padding: 5,
//                                 }}
//                                 textAlign={"left"}
//                                 color={"black"}
//                             >
//                                 <Stack spacing={3} mt={{ xl: 15, lg: 15, md: 15, sm: 5, xs: 5 }} mb={2}>
//                                     <Stack spacing={1}>
//                                         <Stack className="auth_Heading">
//                                             <span className="auth_Heading">Forgot <span style={{ color: themeRed, }}>Password?</span></span>
//                                         </Stack>
//                                         <Stack className="auth_sub_Heading">
//                                             <span>
//                                             kindly provide the registered email to change the passcode.
//                                             </span>

//                                         </Stack>
//                                     </Stack>
//                                     <Stack >
//                                         <Grid container spacing={2}>
//                                             <Grid item xs={12} sm={12} md={12} lg={12} >
//                                                 <Input
//                                                     id={"email"}
//                                                     onChange={(e) =>
//                                                         setData({ ...data, [e.target.id]: e.target.value })
//                                                     }
//                                                     style={{ borderRadius: "6px" }}
//                                                     placeholder={common_caption_strings.example_email}
//                                                     label="Enter Your email"
//                                                 />
//                                             </Grid>
//                                         </Grid>
//                                     </Stack>
//                                 </Stack>
//                                 <Stack>

//                                     <LoadingButton
//                                         onClick={submitHandle}
//                                         style={{ padding: "16px 8px", fontSize: "18px", borderRadius: "8px", backgroundColor: themeRed }}
//                                         loading={user_login_status === asyncStatus.LOADING ? true : false} variant="contained">
//                                         {common_caption_strings.submit}
//                                     </LoadingButton>
//                                 </Stack>
//                                 <Stack mt={1} direction={"row"} justifyContent={"center"}>
//                                     <Typography color={"#9F9F9F"}>{common_caption_strings.dont_have_account}</Typography><Typography onClick={() => navigate("/Signup")} sx={{ color: themeRed, mx: "2px", cursor: "pointer" }}>{common_caption_strings.Create_one}</Typography>
//                                 </Stack>

//                             </Stack>
//                         </Stack>
//                     </Container>
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={6} lg={6} >
//                     <Stack alignItems={"center"} className="bgImageSignup" width={1}>
//                         {/* <img src={authImage} style={{  width: '80%', }} /> */}
//                         <Stack mt={10} width={"70%"} className="transparent-bg" p={4} gap={5}>
//                             <Stack>
//                                 <img width={"100px"} src={Logo} />
//                             </Stack>
//                             <Stack>
//                                 <Typography textAlign={"start"} className="subHeading" color={"white"}>We are <br />
//                                     Invite Only Right Now</Typography>
//                                 <Typography className="mainPara" color={"white"}>Lorem ipsum dolor sit amet consectetur. Duis in venenatis nibh ipsum. At mattis praesent id odio. Adipiscing vel amet laoreet vel.</Typography>
//                             </Stack>
//                             <Stack mt={20}>
//                                 <Typography className="mainPara" color={"white"}>Already have an Account?</Typography>
//                                 <Typography className="mainPara" color={themeRed}>Submit</Typography>
//                             </Stack>
//                         </Stack>
//                     </Stack>
//                 </Grid>
//             </Grid>
//         </Stack>
//     );

// }
// export default ForgotPassword;

import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaUser, FaPhone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./auth.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../screens/Footer/Footer";
import IconTextField from "../../component/IconInput/IconInput";
import { themeRed } from "../../utils/colorTheme";
import { MdEmail } from "react-icons/md";

import { asyncStatus } from "../../utils/asyncStatus";
import { resetPasswordAsync } from "../../services/authentication";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { reset_password_status } = useSelector((state) => state.userAuth);
  const userType = useSelector((state) => state.userType.userType);

  const submitHandle = () => {
    const obj = {
      email: email,
    };

    dispatch(resetPasswordAsync(obj));
    // navigate("/check-email");
  };

  useEffect(() => {
    if (reset_password_status === asyncStatus.SUCCEEDED) {
      navigate("/check-email");
    }
  }, [reset_password_status]);

  return (
    <Stack bgcolor={"white"} minHeight="100vh">
      <Navbar />

      {/* Centered Stack for the Login Form */}
      <Stack
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        padding={2}
      >
        <Stack
          width={"100%"}
          maxWidth={"600px"} /* Set max width for form */
          bgcolor={"#fff"}
          padding={4}
        >
          {/* Login Title */}
          <Typography
            variant="h4"
            fontWeight={"700"}
            component="h1"
            align="start"
            gutterBottom
          >
            Forgot Password
          </Typography>
          <Typography variant="caption" component="span" align="start" mb={3}>
            kindly provide the registered email to change the password.
          </Typography>

          {/* Email Input */}
          <IconTextField
            label="Email Address*"
            placeholder="Your Email"
            icon={<MdEmail style={{ color: "#b20e1c" }} />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={submitHandle}
            disabled={reset_password_status === asyncStatus.LOADING}
          >
            {reset_password_status === asyncStatus.LOADING ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>

          {/* Sign Up Link */}
          <Typography variant="body2" align="center" mt={2}>
            Don't have an account?{" "}
            <a style={{ color: themeRed }} href="/signup">
              Sign up
            </a>
          </Typography>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default ForgotPassword;
