import { IconButton, TextField } from "@mui/material";
import React from "react";
import { FiSearch } from "react-icons/fi";
import css from './SearchComponent.module.css'

const SearchComponent = ({ value, onChange,placeholder }) => {
  return (
    <div className={css.search_container}>
      <FiSearch className={css.search_icon} />
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder || "Search by booking ID or Trailer name"}
        className={css.search_input}
      />
    </div>
  );
};

export default SearchComponent;

{
  /* <TextField
            placeholder="Search by trailer name..."
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <IconButton>
                        <FiSearch color="#BDBDBD" />
                    </IconButton>
                ),
                sx: {
                    "& ::placeholder": {
                        color: "black",
                    },
                    borderRadius: "12px",
                    outline: "none",
                    border: "none",
                    height: "45px",
                    backgroundColor: "white",
                    width: "100%"
                },
            }}
        /> */
}
