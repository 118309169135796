import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Avatar,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { themeRed } from "../../utils/colorTheme";
import BookingTabsManagement from "../BookingTabsManagement/BookingTabsManagement";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiCheckBadge } from "react-icons/hi2";

// const GoogleMap = ({ latitude, longitude }) => {
//   const mapRef = useRef(null);

//   useEffect(() => {
//     if (latitude && longitude) {
//       const map = new window.google.maps.Map(mapRef.current, {
//         center: { lat: latitude, lng: longitude },
//         zoom: 12,
//         disableDefaultUI: true,
//       });

//       new window.google.maps.Marker({
//         position: { lat: latitude, lng: longitude },
//         map,
//       });
//     }
//   }, [latitude, longitude]);

//   const handleMapClick = () => {
//     const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
//     window.open(googleMapsURL, "_blank"); // Opens in a new tab
//   };

//   return (
//     <div
//       ref={mapRef}
//       style={{ width: "100%", height: "200px", cursor: "pointer" }}
//       onClick={handleMapClick}
//       title="Click to view larger map"
//     />
//   );
// };

// const GoogleMap = ({ latitude, longitude }) => {
//   const mapRef = useRef(null);
//   const circleRef = useRef(null);

//   useEffect(() => {
//     if (latitude && longitude) {
//       const map = new window.google.maps.Map(mapRef.current, {
//         center: { lat: latitude, lng: longitude },
//         zoom: 12,
//         disableDefaultUI: true,
//       });

//       // Add marker
//       new window.google.maps.Marker({
//         position: { lat: latitude, lng: longitude },
//         map,
//       });

//       // Add circle
//       circleRef.current = new window.google.maps.Circle({
//         strokeColor: themeRed,
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: themeRed,
//         fillOpacity: 0.2,
//         map,
//         center: { lat: latitude, lng: longitude },
//         radius: 5000, // 5km radius in meters
//       });
//     }
//   }, [latitude, longitude]);

//   const handleMapClick = () => {
//     const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
//     window.open(googleMapsURL, "_blank");
//   };

//   return (
//     <div
//       ref={mapRef}
//       style={{ width: "100%", height: "200px", cursor: "pointer" }}
//       onClick={handleMapClick}
//       title="Click to view larger map"
//     />
//   );
// };

const GoogleMap = ({ latitude, longitude }) => {
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    if (latitude && longitude) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 12,
        disableDefaultUI: true,
      });
      mapInstanceRef.current = map;

      // Add marker
      new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
      });

      // Calculate initial radius based on zoom level
      const calculateRadius = (zoom) => {
        // Base meters per pixel at zoom level 0 at equator
        const metersPerPixel =
          (156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
          Math.pow(2, zoom);
        // Return radius for ~100px circle diameter
        return 50 * metersPerPixel; // Adjust this value to change circle size
      };

      // Initial circle setup
      const initialZoom = map.getZoom();
      circleRef.current = new window.google.maps.Circle({
        strokeColor: themeRed,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: themeRed,
        fillOpacity: 0.2,
        map,
        center: { lat: latitude, lng: longitude },
        radius: calculateRadius(initialZoom),
      });

      // Update circle on zoom change
      const zoomListener = map.addListener("zoom_changed", () => {
        const newZoom = map.getZoom();
        const newRadius = calculateRadius(newZoom);
        circleRef.current.setRadius(newRadius);
      });

      // Cleanup
      return () => {
        if (zoomListener) zoomListener.remove();
      };
    }
  }, [latitude, longitude]);

  const handleMapClick = () => {
    const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsURL, "_blank");
  };

  return (
    <div
      ref={mapRef}
      style={{ width: "100%", height: "200px", cursor: "pointer" }}
      onClick={handleMapClick}
      title="Click to view larger map"
    />
  );
};

const calculateDays = (dateRange) => {
  // Split the dateRange to get start and end dates
  const [startDate, endDate] = dateRange.split(" - ");

  // Convert to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const diffInMs = end - start;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1; // +1 to include the end day

  return diffInDays;
};

const TrailerDetailsCom = ({ trailerData }) => {
  const navigate = useNavigate();
  const { userAuth } = useSelector((state) => state.userAuth);

  const [selectedPricing, setSelectedPricing] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [pickupTab, setPickupTab] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const handleDeliveryAddressChange = (address) => {
    setDeliveryAddress(address);
  };
  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const handlePickupTimeChange = (time) => {
    setPickupTime(time);
  };
  const handleTabChange = (time) => {
    setPickupTab(time);
  };
  const numberOfDays = calculateDays(dateRange);

  const obj = {
    trailerData,
    numberOfDays,
    dateRange,
    pickupTime,
    pickupTab:
      pickupTab === "" ? "Pickup" : pickupTab === 0 ? "Pickup" : "Delivery",
    address:
      pickupTab === ""
        ? trailerData?.location
        : pickupTab === 0
        ? trailerData?.location
        : deliveryAddress,
  };

  // Update isDisabled state based on obj's field values
  useEffect(() => {
    const allFieldsFilled =
      trailerData && numberOfDays && dateRange && pickupTime && obj.address;

    setIsDisabled(!allFieldsFilled);
  }, [trailerData, numberOfDays, dateRange, pickupTime, obj.address]);

  const handleBookNowHandle = () => {
    if (userAuth) {
      // Navigate to checkout with the booking details
      navigate("/checkout", { state: obj });
    } else {
      // Store the intended route and state
      sessionStorage.setItem(
        "redirectPath",
        JSON.stringify({
          path: "/checkout",
          state: obj,
        })
      );
      sessionStorage.setItem(
        "trailer_id",
        JSON.stringify({
          state: trailerData?._id,
        })
      );

      // Redirect to login page
      navigate("/login");
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: "20px" }}>
      {/* Left Section for Trailer Details */}
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Stack
              alignItems={"flex-start"}
              direction="row"
              justifyContent={"space-between"}
            >
              <Typography variant="h5" fontWeight={"bold"} width={"50%"}>
                {trailerData?.title} Trailer
              </Typography>
              <Typography
                variant="body1"
                style={{
                  backgroundColor: themeRed,
                  color: "white",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Trailer ID : {trailerData?.trailer_id}
              </Typography>
            </Stack>
            <Typography variant="body2" color="textSecondary">
              <span style={{ display: "flex", alignItems: "center" }}>
                <span>
                  <FaLocationDot color={themeRed} size={20} />
                </span>
                <span>{trailerData?.complete_address}</span>
              </span>
            </Typography>
            <Typography variant="body1" style={{ marginTop: "20px" }}>
              {trailerData?.trailer_description}
            </Typography>

            {/* Specifications */}
            <Typography
              variant="h6"
              style={{ marginTop: "20px", fontWeight: "bold" }}
            >
              Specifications:
            </Typography>
            <Typography variant="body1">
              <strong>Trailer Category:</strong> {trailerData?.category}
            </Typography>
            <Typography variant="body1">
              <strong>Trailer Dimensions:</strong>{" "}
              {trailerData?.rear_door_opening_height} x{" "}
              {trailerData?.trailer_width}
            </Typography>
            <Typography variant="body1">
              <strong>Hitch Type:</strong> {trailerData?.hitch_type}
            </Typography>
            <Typography variant="body1">
              <strong>Ball Size:</strong> {trailerData?.ball_size}
            </Typography>
            <Typography variant="body1">
              <strong>Payload Capacity:</strong> {trailerData?.payload_capacity}
            </Typography>
            <Typography variant="body1">
              <strong>Length:</strong> {trailerData?.trailer_length}
            </Typography>
            <Typography variant="body1">
              <strong>Make:</strong> {trailerData?.make}
            </Typography>
            <Typography variant="body1">
              <strong>Model:</strong> {trailerData?.model}
            </Typography>
            <Typography variant="body1">
              <strong>Year:</strong> {trailerData?.year}
            </Typography>
            <Typography variant="body1">
              <strong>Axles:</strong> {trailerData?.axles}
            </Typography>
            {/* Add more specifications */}
          </CardContent>
        </Card>

        {/* Cancellation Policy Accordion */}
        <Accordion sx={{ marginTop: "20px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Cancellation Policy
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cancel at least 72 hours prior to the start of your rental and
              receive a 75% refund. <br />
              Cancel 48 - 72 hours prior to the start and receive a 50% refund.{" "}
              <br />
              Cancel 24 - 48 hours prior to the start and receive a 25% refund.{" "}
              <br />
              No refund for cancellations within 24 hours. <br />
              Platform fees are non-refundable after the booking is accepted.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Posted By Section */}
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
              my={1}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", fontWeight: "bold" }}
              >
                Posted By
              </Typography>
              {trailerData?.host_id?.kyc === "Completed" &&
                trailerData?.host_id?.status === "Approved" && (
                  <Chip
                    label="Verified"
                    icon={
                      <HiCheckBadge
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    }
                    sx={{
                      backgroundColor: "greenyellow",
                      color: "green",
                      fontWeight: 500,
                      "& .MuiChip-icon": { ml: 0.5 },
                    }}
                  />
                )}
            </Stack>
            {/* <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                    sx={{
                      p: "4px 8px",
                      borderRadius: "10px",
                      backgroundColor: "greenyellow",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "green",
                        fontSize: "15px",
                      }}
                    >
                      Verified
                    </Typography>
                    <HiCheckBadge style={{ color: "green" }} />
                  </Stack> */}
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Stack>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={trailerData?.host_id?.avatar}
                />
              </Stack>
              <Stack>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "500", fontSize: "18px" }}
                >
                  {trailerData?.host_id?.first_name}{" "}
                  {trailerData?.host_id?.last_name}
                </Typography>

                <Typography
                  sx={{ fontWeight: "400", fontSize: "15px", color: "gray" }}
                >
                  Email:{trailerData?.host_id?.email}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {/* Reviews  By Section */}
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              style={{ marginBottom: "20px", fontWeight: "bold" }}
            >
              Reviews
            </Typography>
            <Typography>No reviews yet</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Right Section for Pricing, Booking, and Map */}
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            {/* Pricing Section */}
            <div
              style={{
                border: `1px solid #ddd`,
                borderRadius: "10px",
                padding: "20px",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    background: numberOfDays <= 6 ? themeRed : "#F0F0F0",
                    padding: "10px",
                    borderRadius: "8px",
                    flex: 1,
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color: numberOfDays <= 6 ? "white" : "#000",
                      fontSize: "14px",
                    }}
                  >
                    ${trailerData?.daily_rate}.00
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      color: numberOfDays <= 6 ? "white" : "#000",
                    }}
                  >
                    Per Day
                  </Typography>
                </div>
                <div
                  style={{
                    background:
                      numberOfDays > 6 && numberOfDays <= 29
                        ? themeRed
                        : "#E0E0E0",
                    padding: "10px",
                    borderRadius: "8px",
                    flex: 1,
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color:
                        numberOfDays > 6 && numberOfDays <= 29
                          ? "white"
                          : "#000",
                      fontSize: "14px",
                    }}
                  >
                    ${trailerData?.weekly_rate}.00
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      color:
                        numberOfDays > 6 && numberOfDays <= 29
                          ? "white"
                          : "#000",
                    }}
                  >
                    Per Week
                  </Typography>
                </div>
                <div
                  style={{
                    background:
                      numberOfDays > 29 && numberOfDays <= 100
                        ? themeRed
                        : "#D0D0D0",
                    padding: "10px",
                    borderRadius: "8px",
                    flex: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      color:
                        numberOfDays > 29 && numberOfDays <= 100
                          ? "white"
                          : "#000",
                      fontSize: "14px",
                    }}
                  >
                    ${trailerData?.monthly_rate}.00
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "12px",
                      color:
                        numberOfDays > 29 && numberOfDays <= 100
                          ? "white"
                          : "#000",
                    }}
                  >
                    Per Month
                  </Typography>
                </div>
              </div>
              <Typography
                variant="h6"
                style={{
                  marginTop: "10px",
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Refundable Deposit:
              </Typography>
              <Typography variant="h6" style={{ color: "#000" }}>
                ${trailerData?.refundable_deposit}.00
              </Typography>
            </div>

            {/* <BookingTabsManagement setSelectedPricing={setSelectedPricing} /> */}
            <BookingTabsManagement
              setSelectedPricing={setSelectedPricing}
              onDateRangeChange={handleDateRangeChange}
              onPickupTimeChange={handlePickupTimeChange}
              onTabChange={handleTabChange}
              setDeliveryAddress={handleDeliveryAddressChange}
              onClick={handleBookNowHandle}
              isDisabled={isDisabled}
            />
          </CardContent>
        </Card>

        {/* Map Section */}
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Trailer General Location
            </Typography>
            <GoogleMap
              latitude={trailerData?.latitude}
              longitude={trailerData?.longitude}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TrailerDetailsCom;
