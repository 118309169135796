import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { postNewsLetterAsync } from "../../services/trailers_details_service";
import { asyncStatus } from "../../utils/asyncStatus";
import CustomButton from "../common/Button/Button";

const NewsletterSignup = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const { post_news_letter_status, post_news_letter_data } = useSelector(
    (state) => state.trailersDetailsManage
  );
  const isNewsLetterLoading = post_news_letter_status === asyncStatus.LOADING;
  console.log("aaaaaaaaaaaa", post_news_letter_data || "");

  const _handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postNewsLetterAsync({ email: email }));
  };
  return (
    <Box
      sx={{
        backgroundColor: themeRed,
        borderRadius: "8px",
        padding: "60px 20px",
        margin: "40px 0",
      }}
    >
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography
            variant="h4"
            sx={{ color: "#fff", fontWeight: "bold", mb: 1 }}
          >
            NEWSLETTER
          </Typography>
          <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
            Sign up for newsletter and get the latest news and updates
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack sx={{ height: "100%" }}>
            <form onSubmit={_handleSubmit} style={{ height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 2,
                  height: "100%",
                  paddingRight:"5px"
                }}
              >
                <TextField
                  type="email"
                  required
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "4px 0 0 4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <CustomButton type="submit" disable={isNewsLetterLoading} >
                  {" "}
                  {isNewsLetterLoading ? (
                    <CircularProgress size={20} sx={{ color: themeRed }} />
                  ) : (
                    "Subscribe"
                  )}
                </CustomButton>
                {/* <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mr: 1,
                    backgroundColor: themeRed,
                    color: "#fff",
                    borderRadius: "4px",
                    height: "100% !important",
                    // paddingBlock:"100%",
                    "&:hover": {
                      backgroundColor: themeRed,
                    },
                  }}
                  disabled={isNewsLetterLoading}
                >
                 
                </Button> */}
              </Box>
            </form>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsletterSignup;
