// import React from 'react'

// const ViewBookingDetails = () => {
//   return (
//     <div>ViewBookingDetails</div>
//   )
// }

// export default ViewBookingDetails

import {
  Avatar,
  CircularProgress,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderShadeImage from "../../component/HeaderShadeImage/HeaderShadeImage";
import InputField from "../../component/InputField/InputField";
import Reviews from "../../component/Reviews/Reviews";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import {
  getHostBookingsDetailByIdAsync,
  updateBookingDetailsStatusAsync,
} from "../../services/trailers_details_service";
import { useDispatch, useSelector } from "react-redux";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import { asyncStatus } from "../../utils/asyncStatus";

const customMarkerIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41], // size of the shadow
});

const ViewBookingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const {
    get_host_bookings_detail_by_id_status,
    get_host_bookings_detail_by_id_data,
    update_booking_detail_status_status,
  } = useSelector((state) => state.trailersDetailsManage);

  const [editProfile, setEditProfile] = useState(false);
  const [selected, setSelected] = useState(null);
  const [value, setValue] = useState(2);
  const [bookingStatus, setBookingStatus] = useState("");

  const bookingStatusLoading =
    update_booking_detail_status_status === asyncStatus.LOADING;

  const handleCheckboxClick = (value) => {
    setSelected(value);
  };

  const handleEditHandle = () => {
    setEditProfile(true);
    console.log("ok");
  };
  console.log("editProfile", id);

  useEffect(() => {
    dispatch(getHostBookingsDetailByIdAsync(id));
  }, []);

  const _changeDateFormate = (formateDate) => {
    // Check if formateDate is provided and is a valid date
    if (!formateDate) {
      return ""; // Return an empty string if no date is provided
    }

    const date = new Date(formateDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string or "Invalid Date" if the date is not valid
    }

    const options = {
      weekday: "short", // "Thu"
      month: "short", // "Oct"
      day: "numeric", // "31"
      year: "numeric", // "2024"
      hour: "numeric", // "12"
      minute: "2-digit", // "00"
      hour12: true, // AM/PM format
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const isURL =
    get_host_bookings_detail_by_id_data?.booking?.address.includes("https");

  const latitude =
    get_host_bookings_detail_by_id_data?.booking?.trailer_id?.latitude;
  const longitude =
    get_host_bookings_detail_by_id_data?.booking?.trailer_id?.longitude;

  const _handleMapClick = (position) => {
    if (position) {
      const googleMapsUrl = `https://www.google.com/maps?q=${position[0]},${position[1]}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

 

  const _handleBookingStatus = (key) => {
    setBookingStatus(key);
    if (key === "Confirmed") {
      dispatch(
        updateBookingDetailsStatusAsync({
          booking_id: get_host_bookings_detail_by_id_data?.booking?._id,
          status: key,
        })
      );
    } else {
      dispatch(
        updateBookingDetailsStatusAsync({
          booking_id: get_host_bookings_detail_by_id_data?.booking?._id,
          status: key,
        })
      );
    }
  };

  console.log(
    "get_host_bookings_detail_by_id_data",
    get_host_bookings_detail_by_id_data || ""
  );

  return (
    <Stack bgcolor={"#FAFAFA"} margin={0} padding={0}>
      <HeaderShadeImage
        // btnLabel={"Edit Profile"}
        rateNumber={" 5.0"}
        onClick={handleEditHandle}
      />
      <Stack ml={7} mt={2}>
        <Grid container m={2}>
          <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
            <Stack mt={6} gap={2}>
              {get_host_bookings_detail_by_id_data?.booking?.status ===
                "In-Process" && (
                  <Stack gap={1}>
                    <Typography fontSize={"22px"} fontWeight={"700"}>
                      Please verify your order
                    </Typography>
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                      pr={5}
                      gap={3}
                    >
                      <CustomButton
                        onClick={() => _handleBookingStatus("Confirmed")}
                        style={{
                          padding: "9px 30px",
                          width: "auto",
                          backgroundColor: themeRed,
                          color: "white"
                        }}
                      >
                        {bookingStatusLoading && bookingStatus === "Confirmed" ? (
                          <CircularProgress size={17} sx={{ color: "white" }} />
                        ) : (
                          "Confirm"
                        )}
                      </CustomButton>
                      <CustomButton
                        onClick={() => _handleBookingStatus("Rejected")}
                        style={{
                          padding: "9px 30px",
                          width: "auto",
                          backgroundColor: themeRed,
                          color: "white"
                        }}
                      >
                        {bookingStatusLoading && bookingStatus === "Rejected" ? (
                          <CircularProgress size={17} sx={{ color: "white" }} />
                        ) : (
                          "Reject"
                        )}
                      </CustomButton>
                    </Stack>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"400"}
                      sx={{ fontWeight: "400", fontSize: "14px", color: "grey" }}
                    >
                      Response time is __ hours, after that it will be
                      automatically considered as rejected by you.
                    </Typography>{" "}
                  </Stack>
                )}
              <Stack
                flexDirection={"row"}
                alignItems={"start"}
                justifyContent={"space-between"}
                pr={5}
              >
                <Stack>
                  <div className="headerName">
                    {get_host_bookings_detail_by_id_data?.booking?.trailer_id
                      ?.title || ""}{" "}
                    <span className="headerNameThemeColor">Trailer</span>{" "}
                  </div>
                  <p className="rating">
                    Trailer Id :{" "}
                    {
                      get_host_bookings_detail_by_id_data?.booking?.trailer_id
                        ?.trailer_id
                    }
                  </p>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  bgcolor={"white"}
                  p={1}
                  borderRadius={2}
                  border={"1px solid lightgray"}
                >
                  <Typography>Status:</Typography>
                  {get_host_bookings_detail_by_id_data?.booking?.status ===
                    "In-Process" && <GoDotFill size={20} color="lightgreen" />}
                  <Typography color="lightgreen" fontWeight={"bold"}>
                    {get_host_bookings_detail_by_id_data?.booking?.status || ""}
                  </Typography>
                </Stack>
              </Stack>
              <Stack my={1} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack>
                  <Typography fontSize={"22px"} fontWeight={"700"}>
                    ${" "}
                    {get_host_bookings_detail_by_id_data?.booking
                      ?.booking_type === "Daily"
                      ? get_host_bookings_detail_by_id_data?.booking?.trailer_id
                        ?.daily_rate
                      : get_host_bookings_detail_by_id_data?.booking
                        ?.booking_type === "Weekly"
                        ? get_host_bookings_detail_by_id_data?.booking?.trailer_id
                          ?.weekly_rate
                        : get_host_bookings_detail_by_id_data?.booking?.trailer_id
                          ?.monthly_rate}{" "}
                    {get_host_bookings_detail_by_id_data?.booking?.booking_type}
                  </Typography>
                  {/* <Typography
                    fontSize={"12px"}
                    fontWeight={"400"}
                    sx={{ textDecoration: "underline" }}
                  >
                    $159 est. total{" "}
                  </Typography> */}
                </Stack>
                <Stack>
                      <CustomButton children="Message" style={{padding:10}} 
                      onClick={()=>navigate("/message",{state:{id:get_host_bookings_detail_by_id_data?.booking.user_id._id}})}
                      // onClick={()=>console.log("iddddddd",get_host_bookings_detail_by_id_data?.booking.user_id._id)}
                        />
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems={"center"} mb={2}>
              <hr
                style={{ color: "#E2E2E2", padding: "0px 30px", width: "90%" }}
              />
            </Stack>
            <Stack>
              <Grid container>
                <Grid md={6} lg={6} xl={6} sm={12} xs={12} px={2}>
                  <Typography color={themeRed}>Trip start</Typography>
                  <InputField
                    value={_changeDateFormate(
                      get_host_bookings_detail_by_id_data?.booking
                        ?.start_date || ""
                    )}
                    placeholder={_changeDateFormate(
                      get_host_bookings_detail_by_id_data?.booking
                        ?.start_date || ""
                    )}
                  />
                </Grid>
                <Grid md={6} lg={6} xl={6} sm={12} xs={12} px={2}>
                  <Typography color={themeRed}>Trip end</Typography>
                  <InputField
                    value={_changeDateFormate(
                      get_host_bookings_detail_by_id_data?.booking?.end_date ||
                      ""
                    )}
                    placeholder={_changeDateFormate(
                      get_host_bookings_detail_by_id_data?.booking?.end_date ||
                      ""
                    )}
                  />
                </Grid>
                <Grid
                  md={12}
                  lg={12}
                  xl={12}
                  sm={12}
                  xs={12}
                  px={2}

                //   onClick={() => (isURL ? _handleOpenLocation() : {})}
                >
                  <Typography color={themeRed}>
                    Pickup, Deliver & return location
                  </Typography>
                  {isURL ? (
                    <div
                      style={{
                        height: "300px",
                        width: "100%",
                        borderRadius: "20px",
                        overflow: "hidden",
                      }}
                    >
                      {latitude && longitude ? (
                        <MapContainer
                          center={[latitude, longitude]}
                          zoom={13}
                          style={{ height: "100%", width: "100%" }}
                        >
                          <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          />
                          <Marker
                            icon={customMarkerIcon}
                            eventHandlers={{
                              click: () =>
                                _handleMapClick([latitude, longitude]),
                            }}
                            position={[latitude, longitude]}
                          >
                            <Popup>
                              {get_host_bookings_detail_by_id_data?.booking
                                ?.trailer_id?.complete_address || ""}
                            </Popup>
                          </Marker>
                        </MapContainer>
                      ) : (
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            height: "100%",
                          }}
                        >
                          <CircularProgress
                            size={15}
                            sx={{ color: themeRed }}
                          />
                        </Stack>
                      )}
                    </div>
                  ) : (
                    <InputField
                      value={
                        get_host_bookings_detail_by_id_data?.booking?.address
                      }
                      placeholder={
                        get_host_bookings_detail_by_id_data?.booking?.address
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
            <Stack p={2} gap={1}>
              <Typography color={"#616161"} className="subHeading">
                REVIEWS FROM HOST{" "}
                <span style={{ color: "black" }}>473 Trips</span>
              </Typography>
            </Stack>
            <Stack>
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                <Rating
                  name="simple-controlled"
                  value={value}
                  // onChange={(event, newValue) => {
                  //   setValue(newValue);
                  // }}
                />
              </Stack>
              <Stack ml={6}>
                <Typography fontSize={"12px"}>No reviews yet</Typography>
                <Typography fontSize={"14px"}>
                  John hasn’t received a review on Trailer4Rent yet.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
            {" "}
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ViewBookingDetails;

{
  /* <Typography
                      my={1}
                      component="a"
                      href={
                        get_host_bookings_detail_by_id_data?.booking?.address
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        textDecoration: "underline",
                        color: "grey",
                        cursor: "pointer",
                      }}
                    >
                      {get_host_bookings_detail_by_id_data?.booking?.address}
                    </Typography> */
}
