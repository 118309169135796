import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaUser, FaPhone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./auth.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../screens/Footer/Footer";
import IconTextField from "../../component/IconInput/IconInput";
import { themeRed } from "../../utils/colorTheme";
import { MdEmail } from "react-icons/md";
import OtpInput from "react-otp-input";
import { asyncStatus } from "../../utils/asyncStatus";
import { resetPasswordOTPAsync } from "../../services/authentication";

const CheckYourEmail = () => {
  const dispatch = useDispatch();
  const [emailOtp, setEmailOtp] = useState("");
  const [error, setError] = useState({ emailOtp: false });

  const navigate = useNavigate();
  const { reset_password_otp_verify_status, reset_password_data } = useSelector(
    (state) => state.userAuth
  );

  const submitHandle = () => {
    if (emailOtp.length !== 4) {
      setError({
        emailOtp: emailOtp.length !== 4,
      });
      return;
    }
    const obj = {
      email: reset_password_data || "",
      otp: emailOtp,
    };
    dispatch(resetPasswordOTPAsync(obj));
  };

  useEffect(() => {
    if (reset_password_otp_verify_status === asyncStatus.SUCCEEDED) {
      navigate("/create-password");
    }
  }, [reset_password_otp_verify_status]);

  return (
    <Stack bgcolor={"white"} minHeight="100vh">
      <Navbar />

      {/* Centered Stack for the Login Form */}
      <Stack
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        padding={2}
      >
        <Stack
          width={"100%"}
          maxWidth={"600px"} /* Set max width for form */
          bgcolor={"#fff"}
          padding={4}
          alignItems={"center"}
          justifyContent={"center"}
          flex={1}
        >
          {/* Login Title */}
          <Typography
            variant="h4"
            fontWeight={"700"}
            component="h1"
            align="start"
            gutterBottom
          >
            Check Your email
          </Typography>
          <Typography variant="caption" component="span" align="start" >
            OTP has been sent to your email
          </Typography>

          <Stack my={4} alignItems={"center"} justifyContent={"center"}>
            <OtpInput
              value={emailOtp}
              onChange={setEmailOtp}
              numInputs={4}
              separator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 0.4rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid rgba(0,0,0,0.3)",
              }}
            />
            {error.emailOtp && (
              <Typography color="error" align="center">
                Please enter all 4 digits of the email OTP
              </Typography>
            )}
          </Stack>


          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={submitHandle}
            disabled={reset_password_otp_verify_status === asyncStatus.LOADING}
          >
            {reset_password_otp_verify_status === asyncStatus.LOADING ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>

          {/* Sign Up Link */}
          <Typography variant="body2" align="center" mt={2}>
            Don't have an account?{" "}
            <a style={{ color: themeRed }} href="/signup">
              Sign up
            </a>
          </Typography>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default CheckYourEmail;
