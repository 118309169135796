import {
  Stack,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaUser, FaPhone, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./auth.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../../screens/Footer/Footer";
import IconTextField from "../../component/IconInput/IconInput";
import { TbPassword } from "react-icons/tb";
import { themeRed } from "../../utils/colorTheme";
import { MdEmail, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { login_service_auth } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const { user_login_status } = useSelector((state) => state.userAuth);
  const userType = useSelector((state) => state.userType.userType);

  const handleRedirect = () => {
    const redirectData = JSON.parse(sessionStorage.getItem("redirectPath"));
    if (redirectData?.path) {
      navigate(redirectData.path, { state: redirectData.state });
      sessionStorage.removeItem("redirectPath");
    } else {
      navigate("/dashboard-home");
    }
  };

  const submitHandle = () => {
    const obj = {
      email: email,
      password: password,
      role: userType,
    };

    dispatch(login_service_auth(obj));
  };

  useEffect(() => {
    if (user_login_status === asyncStatus.SUCCEEDED) {
      handleRedirect();
    } else if (user_login_status === asyncStatus.FAILED) {
      console.error("Invalid credentials. Please try again.");
    }
  }, [user_login_status, navigate]);

  return (
    <Stack bgcolor={"white"} minHeight="100vh">
      <Navbar />

      {/* Centered Stack for the Login Form */}
      <Stack
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        padding={2}
      >
        <Stack
          width={"100%"}
          maxWidth={"600px"} /* Set max width for form */
          bgcolor={"#fff"}
          padding={4}
        >
          {/* Login Title */}
          <Typography
            variant="h4"
            fontWeight={"700"}
            component="h1"
            align="start"
            gutterBottom
          >
            Log in
          </Typography>

          {/* Email Input */}
          <IconTextField
            label="Email Address*"
            placeholder="Your Email"
            icon={<MdEmail style={{ color: "#b20e1c" }} />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {/* Password Input */}
          <Stack sx={{ position: "relative" }}>
            <IconTextField
              label="Password"
              placeholder="Your Password"
              icon={<FaLock style={{ color: "#b20e1c" }} />}
              type={showPass ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <IconButton
              onClick={() => setShowPass(!showPass)}
              sx={{ position: "absolute", top: "41px", left: "92%" }}
            >
              {showPass ? (
                <FaRegEyeSlash style={{ color: "#b20e1c" }} />
              ) : (
                <MdOutlineRemoveRedEye style={{ color: "#b20e1c" }} />
              )}
            </IconButton>
          </Stack>

          {/* Log In Button */}
          {/* <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={submitHandle}
                    >
                        Log In
                    </Button> */}
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={submitHandle}
            disabled={user_login_status === asyncStatus.LOADING}
          >
            {user_login_status === asyncStatus.LOADING ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Log In"
            )}
          </Button>
          <Stack alignItems={"flex-end"} sx={{ width: "100%" }} mt={1}>
            <a
              style={{
                color: themeRed,
                fontSize: "15px",
                textDecorationLine: "none",
              }}
              href="/forgot-password"
            >
              Forgot Password
            </a>
          </Stack>

          {/* Sign Up Link */}
          <Typography variant="body2" align="center" mt={2}>
            Don't have an account?{" "}
            <a style={{ color: themeRed }} href="/signup">
              Sign up
            </a>
          </Typography>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default Login;
