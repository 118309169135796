import React, { useState } from 'react';
import './InputField.css';
import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { themeRed } from '../../utils/colorTheme';

const InputField = ({ isSecure = false, placeholder = '', disabled = true, value, onChange, name, type }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="input-container">
            <div className="input-wrapper-settings">
                <input
                    disabled={disabled}
                    type={isSecure && !isPasswordVisible ? 'password' : type ? type : 'text'}
                    placeholder={placeholder}
                    className="input-field"
                    value={value}
                    onChange={onChange} 
                    name={name}  
                />
                {isSecure && (
                    <button
                        type="button"
                        className="toggle-visibility"
                        onClick={togglePasswordVisibility}
                    >
                        {isPasswordVisible ? <BiHide color={themeRed} size={22} /> : <BiShow color={themeRed} size={22} />}
                    </button>
                )}
            </div>
        </div>
    );
};

export default InputField;
