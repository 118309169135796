import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs from "../../component/Tabs/Tabs";
import SearchComponent from "../../component/SearchComponent/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import { get_all_trailer_async } from "../../services/authentication";
import AllTrailersTable from "../../component/AllTrailersTable/AllTrailersTable";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeRed } from "../../utils/colorTheme";

const AllTrailers = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("all");
  const [searchQuery, setSearchQuery] = useState(""); // Add search state

  const tabs = [
    { label: "All", value: "all", radius: "13", border: 1 },
    { label: "Approved Trailer", value: "approvalStatus", border: 1 },
    {
      label: "Trailer Availability Status",
      value: "availabilityStatus",
      // radius: "13",
      border: 1
    },
    { label: "Draft Trailer", value: "Draft",radius: "13" },
  ];

  const { get_all_trailers_data, get_all_trailers_status } = useSelector(
    (state) => state.userAuth
  );

  // Filter data based on active tab and search query
  const filteredData = () => {
    let trailers = get_all_trailers_data?.trailers || [];

    // Apply tab filter
    if (activeTab === "approvalStatus") {
      trailers = trailers.filter(
        (trailer) => trailer.approve_status === "Approved"
      );
    } else if (activeTab === "availabilityStatus") {
      trailers = trailers.filter((trailer) => trailer.status === "Online");
    } else if (activeTab === "Draft") {
      trailers = trailers.filter((trailer) => trailer.approve_status === "Draft");
    }

    // Apply search filter
    if (searchQuery.trim()) {
      trailers = trailers.filter((trailer) =>
        trailer.title?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return trailers;
  };

  useEffect(() => {
    dispatch(get_all_trailer_async());
  }, [dispatch]);

  return (
    <Stack bgcolor={"#FAFAFA"} p={3}>
      <Grid
        container
        alignItems={"stretch"}
        justifyContent={"space-between"}
        my={4}
        spacing={2}
      >
        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
          <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Stack sx={{ width: "100%" }}>
            {/* Pass search query to SearchComponent */}
            <SearchComponent
              placeholder={"Search by trailer name"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>
        </Grid>
      </Grid>

      {/* Check if there are results */}
      {get_all_trailers_status === asyncStatus.LOADING ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "40vh" }}
        >
          <CircularProgress size={30} sx={{ color: themeRed }} />
        </Stack>
      ) : filteredData().length > 0 ? (
        <AllTrailersTable trailers={filteredData()} />
      ) : (
        <Typography variant="h6" color="textSecondary" align="center" mt={4}>
          No trailers match your search criteria.
        </Typography>
      )}
    </Stack>
  );
};

export default AllTrailers;
