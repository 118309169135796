// import React from "react"
// import { useSelector } from "react-redux"
// import { Navigate, Outlet } from "react-router-dom"
// export const PublicRoutes = () => {
//     const { userAuth} = useSelector((state) => state.userAuth)
//     return !userAuth ? <Outlet /> : <Navigate to="/dashboard-home" />
// }
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const PublicRoutes = () => {
  const { userAuth } = useSelector((state) => state.userAuth);
  const location = useLocation();

  // If the user is authenticated and tries to access /login or /signup, redirect to dashboard
  if (userAuth && (location.pathname === "/login" || location.pathname === "/signup")) {
    return <Navigate to="/dashboard-home" />;
  }

  // Otherwise, allow access to public routes (like the home page)
  return <Outlet />;
};
