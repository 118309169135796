import React, { useEffect, useState } from "react";
import "./ChatApp.css";
import { Avatar, Stack, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../assets/profile.png";
import { themeRed } from "../../utils/colorTheme";

const userListData = [
  {
    _id: 1,
    first_name: "Michael",
    last_name: "Smith",
    lst_msg: "hy",
  },
  {
    _id: 2,
    first_name: "David",
    last_name: "Johnson",
    lst_msg: "by",
  },
  {
    _id: 3,
    first_name: "Robert",
    last_name: "Williams",
    lst_msg: "hy",
  },
  {
    _id: 4,
    first_name: "James",
    last_name: "Brown",
    lst_msg: "hy",
  },
  {
    _id: 5,
    first_name: "John",
    last_name: "Jones",
    lst_msg: "hy",
  },
  {
    _id: 6,
    first_name: "William",
    last_name: "Garcia",
    lst_msg: "hy",
  },
  {
    _id: 7,
    first_name: "Charles",
    last_name: "Miller",
    lst_msg: "hy",
  },
  {
    _id: 8,
    first_name: "Joseph",
    last_name: "Davis",
    lst_msg: "hy",
  },
  {
    _id: 9,
    first_name: "George",
    last_name: "Rodriguez",
    lst_msg: "hy",
  },
  {
    _id: 10,
    first_name: "Thomas",
    last_name: "Martinez",
    lst_msg: "hy",
  },
];

const UserList = ({ onSelectUser }) => {
  const { user } = useSelector((state) => state.userAuth);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const { get_hosts_list_data, get_users_list_data } = useSelector(
    (state) => state.ChatSlice
  );
  const dispatch = useDispatch();

  // Filter familyData based on search input
  const filteredUsersData = (
    user?.role === "Host"
      ? get_hosts_list_data?.users
      : get_users_list_data.users
  )?.filter((user) => {
    const fullName = `${user?.first_name ?? ""} ${
      user?.last_name ?? ""
    }`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const _handleSelectUser = (user) => {
    setSelectedUser(user?._id);
    onSelectUser(user);
  };

  const _handleLastMsgLength = (desc) => {
    const newDesc = desc?.slice(0, 35);
    return desc.length > 35 ? `${newDesc}...` : desc;
  };

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <Stack px={2} pb={2}>
        <TextField
          placeholder={"Search User..."}
          value={searchTerm} // Bind searchTerm state to the TextField
          onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm state on input change
          sx={{ padding: "0px 14px" }}
          InputProps={{
            endAdornment: <SearchIcon color="black" />,
            sx: {
              "& ::placeholder": {
                color: "black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the default border
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: themeRed, // Use themeRed for the focused border color
              },
              borderRadius: "10px",
              outline: "none",
              border: "none",
              height: "42px",
              backgroundColor: "#F3F3F3",
              overflow: "hidden",
            },
          }}
        />
      </Stack>
      <Stack sx={{ height: "100%", overflow: "auto", maxHeight: "70vh" }}>
        {filteredUsersData?.length > 0 ? (
          filteredUsersData?.map((chat_user) => (
            <Stack
              key={chat_user?._id}
              px={2}
              py={1.5}
              onClick={() => _handleSelectUser(chat_user)}
              flexDirection={"row"}
              className={`user ${
                selectedUser === chat_user?._id ? "selected" : ""
              }`}
            >
              <Stack mt={0.3}>
                <Avatar
                  alt={chat_user?.first_name || ""}
                  src={chat_user?.avatar || ""}
                  sx={{
                    width: 40,
                    height: 40,
                    textTransform: "capitalize",
                    fontSize: "18px",
                  }}
                ></Avatar>
              </Stack>

              <Stack
                pl={1}
                pr={2}
                flexDirection={"column"}
                alignItems={"start"}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "700",
                    color:
                      selectedUser === chat_user?._id ? "white" : "#13131A",
                    fontFamily: '"Poppins", sans-serif',
                    textTransform: "capitalize",
                  }}
                >
                  {chat_user?.first_name ?? ""} {chat_user?.last_name ?? ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: selectedUser === chat_user?._id ? "white" : "grey",
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  {_handleLastMsgLength(
                    chat_user?.lst_msg?.content ||
                      `Hy there is ${chat_user?.role}...`
                  )}
                </Typography>
              </Stack>
            </Stack>
          ))
        ) : (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "40vh" }}
          >
            <Typography variant="caption">
              No <Typography variant="caption"></Typography>
              {user?.role} Found
            </Typography>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default UserList;
