import React, { useEffect, useState } from "react";
import TrailerImg from "../../assets/TrailerImg.png";
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Rating,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_trailer_by_id } from "../../services/bank_mange_service";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Import the marker icon and shadow images from Leaflet
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import MyGallery from "../../component/MyGallery/MyGallery";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { updateHostTrailerStatusAsync } from "../../services/trailers_details_service";
import { asyncStatus } from "../../utils/asyncStatus";
import { formatApiDate } from "./../../utils/timeFormatter";
import CloseIcon from "@mui/icons-material/Close";

const customMarkerIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41], // size of the shadow
});

const RatingBar = ({ category, rating }) => (
  <div className="ratingBarContainer">
    <span className="ratingCategory">{category}</span>
    <div className="ratingBarBackground">
      <div className="ratingBar" style={{ width: `${(rating / 5) * 100}%` }} />
    </div>
    <span className="ratingValue">{rating}</span>
  </div>
);

const TrailerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    get_trailer_by_id_status,
    get_trailer_by_id_data,
    get_trailer_by_id_error,
  } = useSelector((state) => state.bankManage);
  const params = useParams();

  const getDetailLoader = get_trailer_by_id_status === asyncStatus.LOADING;

  const [isOnline, setIsOnline] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  // Update `isOnline` state when trailer data is fetched
  useEffect(() => {
    if (get_trailer_by_id_data?.trailer) {
      setIsOnline(get_trailer_by_id_data?.trailer?.status === "Available");
    }
  }, [get_trailer_by_id_data]);

  const reviews = [
    {
      id: 1,
      name: "J**********N",
      date: "Aug 31, 2023",
      content:
        "Content will be provided by the client Content will be provided by the client Content will be provided by the client",
    },
    {
      id: 2,
      name: "J**********N",
      date: "Aug 31, 2023",
      content:
        "Content will be provided by the client Content will be provided by the client Content will be provided by the client",
    },
  ];
  const theme = createTheme({
    palette: {
      error: {
        main: "#b71c1c", // Red color for 'error'
      },
    },
  });

  useEffect(() => {
    if (params) {
      dispatch(get_trailer_by_id(params.id));
    }
  }, []);

  const dateStr = get_trailer_by_id_data?.trailer?.host_id?.createdAt;
  let FormatedDate;
  // Check if dateStr is valid
  if (dateStr) {
    const formattedDate = new Date(dateStr).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long", // You can use 'short' for abbreviated month names
      day: "numeric",
    });
    FormatedDate = formattedDate; // Output: September 25, 2024
  }

  const latitude = get_trailer_by_id_data?.trailer?.latitude;
  const longitude = get_trailer_by_id_data?.trailer?.longitude;

  const _handleMapClick = (position) => {
    if (position) {
      const googleMapsUrl = `https://www.google.com/maps?q=${position[0]},${position[1]}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const imageStyle = {
    width: "100%", // Each image fills its container width
    height: "300px", // Consistent height for all images
    objectFit: "cover", // Ensures the image covers the area without distortion
  };

  const handleTrailerStatusChange = (event) => {
    const newStatus = event.target.checked ? "Available" : "Unavailable";
    setIsOnline(event.target.checked);

    dispatch(
      updateHostTrailerStatusAsync({
        trailer_id: get_trailer_by_id_data?.trailer?.trailer_id,
        status: newStatus,
      })
    );
  };

  return getDetailLoader ? (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: "70vh" }}
    >
      <CircularProgress size={30} sx={{ color: themeRed }} />
    </Stack>
  ) : (
    <>
      <Stack mt={0.4} sx={{ width: "100%" }}>
        {/* <MyGallery images={get_trailer_by_id_data?.trailer?.trailer_photos} /> */}
        <LightGallery plugins={[lgThumbnail, lgZoom]} mode="lg-fade">
          {get_trailer_by_id_data?.trailer?.trailer_photos?.map(
            (image, index) => (
              <a
                href={image}
                key={index}
                style={{ display: "inline-block", width: "25%" }}
              >
                <img
                  src={image}
                  alt={`Trailer ${index + 1}`}
                  style={imageStyle}
                />
              </a>
            )
          )}
        </LightGallery>
      </Stack>

      <Stack p={5}>
        <div className="header">
          <Stack
            flexDirection={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            }}
            alignItems={{
              xl: "center",
              lg: "center",
              md: "center",
              sm: "center",
              xs: "flex-start",
            }}
            justifyContent={"space-between"}
          >
            <h1 className="title">
              {get_trailer_by_id_data?.trailer?.title}{" "}
              <span className="trailer">Trailer</span>
            </h1>
            {get_trailer_by_id_data?.trailer?.status && (
              <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                {/* <FaRegEdit style={{ cursor: "pointer" }} onClick={() => navigate('/trailer-edit')} color={themeRed} />
                            <Typography style={{ cursor: "pointer" }} onClick={() => navigate('/trailer-edit')}>Edit</Typography> */}
                <Typography>
                  {isOnline ? "Available" : "Unavailable"}
                </Typography>
                <ThemeProvider theme={theme}>
                  <Switch
                    color="error"
                    checked={isOnline}
                    onChange={handleTrailerStatusChange}
                  />
                </ThemeProvider>
              </Stack>
            )}
          </Stack>
          <p className="rating">Sport S</p>
          <p className="rating" style={{ color: "black" }}>
            <span className="trips">(54 trips)</span>
          </p>
        </div>
        <h3>Hosted By</h3>
        <Stack
          alignItems={"flex-start"}
          className="hostedBy"
          // sx={{ backgroundColor: "red" }}
        >
          <Stack
            direction={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            }}
            sx={{
              // backgroundColor: "blue",
              width: "100%",
            }}
            gap={2}
            alignItems={"center"}
            className="hostedByContent"
          >
            <Stack mr={1}>
              <img
                style={{
                  width: "125px",
                  height: "125px",
                  objectFit: "cover",
                  borderRadius: 150,
                }}
                src={get_trailer_by_id_data?.trailer?.host_id.avatar}
                alt="profile-image"
              />
              <Stack
                mt={-2}
                ml={2}
                className="profile-rating"
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <Stack className="rating-number">
                  {get_trailer_by_id_data?.reviewsSummary?.averageRating || ""}
                </Stack>
                <Stack>
                  <img
                    src={require("../../assets/background/rating-star.png")}
                    alt="star"
                  />
                </Stack>
              </Stack>
            </Stack>
            <div>
              <Typography
                variant="h5"
                className="hostName"
                sx={{
                  color: "black",
                  fontWeight: "600",
                  textAlign: {
                    xl: "left",
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >{`${get_trailer_by_id_data?.trailer?.host_id.first_name} ${get_trailer_by_id_data?.trailer?.host_id.last_name}`}</Typography>
              <p className="hostDetails" style={{ color: "black" }}>
                {/* 29,318 trips  */}• Joined {FormatedDate}
              </p>
              {/* <p className="responseTime" style={{ color: "black" }}>Typically responds within 1 minute</p> */}
            </div>
          </Stack>
          {/* <p className="hostRating">5.0 ★</p> */}
        </Stack>
        <Stack>
          <div className="description">
            <h2 style={{ marginBottom: 10, textAlign: "left" }}>Description</h2>
            <p>{get_trailer_by_id_data?.trailer?.trailer_description || ""}</p>
            {/* <button className="moreButton">More</button> */}
          </div>
          <div className="description">
            <h2 style={{ marginBottom: 10, textAlign: "left" }}>
              Additional Details
            </h2>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Axles:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.axles}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Ball Size:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.ball_size}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Category:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.category}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Complete Address:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.complete_address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Daily Rate:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.daily_rate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Hitch Type:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.hitch_type}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Light Plug Configurations:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.light_plug_configuration}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Make:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.make}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Model:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.model}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Monthly Rate:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.monthly_rate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Payload Capacity:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.payload_capacity}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Provincal Territory:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.province_territory}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Rear Door Opening Height:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.rear_door_opening_height}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Refundable Deposit:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.refundable_deposit}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Tag Words:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.tag_words}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Dimension:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.trailer_dimension}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Length:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.trailer_length}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Width:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.trailer_width}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Weekly Rate:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.weekly_rate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display={"flex"} gap={2}>
                <Typography
                  fontSize={{
                    xl: "18px",
                    lg: "18px",
                    md: "18px",
                    sm: "14px",
                    xs: "14px",
                  }}
                >
                  Year:
                </Typography>
                <Typography variant="body1" color="#7d7d7d">
                  {get_trailer_by_id_data?.trailer?.year}
                </Typography>
              </Grid>
            </Grid>
          </div>

          {get_trailer_by_id_data?.trailer?.insurance_photos?.length > 0 && (
            <Stack my={2}>
              <h2 style={{ marginBottom: 10, textAlign: "left" }}>
                Insurance Photos
              </h2>
              {/* Responsive Grid to Show Images */}
              <Grid container spacing={2}>
                {get_trailer_by_id_data?.trailer?.insurance_photos?.map(
                  (photo, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Box
                        component="img"
                        src={photo}
                        alt={`Insurance Photo ${index + 1}`}
                        sx={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: 2,
                          cursor: "pointer",
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": { transform: "scale(1.05)" },
                        }}
                        onClick={() => handleOpen(photo)}
                      />
                    </Grid>
                  )
                )}
              </Grid>

              {/* Image Preview Dialog */}
              <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogContent
                  sx={{ position: "relative", textAlign: "center" }}
                >
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      color: "white",
                      background: "rgba(0,0,0,0.5)",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box
                    component="img"
                    src={selectedImage}
                    alt="Preview"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "90vh",
                      objectFit: "contain",
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Stack>
          )}

          <div className="ratingsAndReviews">
            <h2
              style={{ marginBottom: 10, textAlign: "left" }}
              className="ratingHeader"
            >
              Ratings
            </h2>
            <p className="ratingSummary">
              {get_trailer_by_id_data?.reviewsSummary?.averageRating?.toLocaleString()}{" "}
              ★{" "}
              <span className="totalRatings">
                (
                {get_trailer_by_id_data?.reviewsSummary?.totalReviews?.toLocaleString()}{" "}
                ratings)
              </span>
            </p>

            {/* <RatingBar category="Cleanliness" rating={0} />
          <RatingBar category="Maintenance" rating={0} />
          <RatingBar category="Communication" rating={0} />
          <RatingBar category="Convenience" rating={0} />
          <RatingBar category="Accuracy" rating={0} /> */}

            <div style={{ marginTop: 10 }} className="reviews">
              <h2 style={{ marginBottom: 10, textAlign: "left" }}>Reviews</h2>

              {get_trailer_by_id_data?.reviews?.map((review, index) => {
                return (
                  <Stack>
                    <Stack my={3}>
                      <Stack gap={2} flexDirection={"row"} alignItems={"start"}>
                        <Avatar
                          sx={{ bgcolor: themeRed }}
                          src={review?.user_id?.avatar || ""}
                        />
                        <Stack flexDirection={"column"}>
                          <Typography
                            className="reviewerName"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {review?.user_id?.first_name || ""}{" "}
                            {review?.user_id?.last_name || ""}
                          </Typography>
                          <Typography fontSize={"12px"} color={"lightgray"}>
                            {formatApiDate(review?.createdAt || "")}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Rating
                        name="simple-controlled"
                        value={review?.rating || ""}
                        // onChange={(event, newValue) => {
                        //     setValue(newValue);
                        // }}
                        readOnly={true}
                      />
                    </Stack>
                    <p className="reviewContent">{review?.remarks || ""}</p>
                  </Stack>
                );
              })}
            </div>
          </div>
        </Stack>
      </Stack>
      <Stack p={5}>
        <div
          style={{
            height: "300px",
            width: "100%",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          {latitude && longitude ? (
            <MapContainer
              center={[latitude, longitude]}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                icon={customMarkerIcon}
                eventHandlers={{
                  click: () => _handleMapClick([latitude, longitude]),
                }}
                position={[latitude, longitude]}
              >
                <Popup>
                  {get_trailer_by_id_data?.trailer?.complete_address || ""}
                </Popup>
              </Marker>
            </MapContainer>
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: "100%",
              }}
            >
              <CircularProgress size={15} sx={{ color: themeRed }} />
            </Stack>
          )}
        </div>
      </Stack>
    </>
  );
};

export default TrailerDetails;
