// import {
//   Card,
//   CardContent,
//   Container,
//   IconButton,
//   Stack,
//   Typography,
// } from "@mui/material";
// import React, { useRef } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
// import { themeRed } from "../../../utils/colorTheme";
// import Slider from "react-slick";
// import css from "./ProvinceCarouselSection.module.css";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// // Import Swiper components and modules
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { trailerCategories } from "../CategorieSection/CategoriesSection";
// import ButtonComp from "../../../component/common/ButtonComp";
// import { useNavigate } from "react-router-dom";
// import british_columbia_grey from "../../../assets/flags/british_columbia_grey.png";
// import british_columbia_clr from "../../../assets/flags/british_columbia_clr.png";
// import alberta_grey from "../../../assets/flags/alberta_grey.png";
// import alberta_clr from "../../../assets/flags/alberta_clr.png";
// import saskatchewan_grey from "../../../assets/flags/saskatchewan_grey.png";
// import saskatchewan_clr from "../../../assets/flags/saskatchewan_clr.png";
// import manitoba_grey from "../../../assets/flags/manitoba_grey.png";
// import manitoba_clr from "../../../assets/flags/manitoba_clr.png";
// import new_brunswick_grey from "../../../assets/flags/new_brunswick_grey.png";
// import new_brunswick_clr from "../../../assets/flags/new_brunswick_clr.png";
// import prince_edward_island_grey from "../../../assets/flags/prince_edward_island_grey.png";
// import prince_edward_island_clr from "../../../assets/flags/prince_edward_island_clr.png";
// // import new_brunswick_grey from "../../../assets/flags/new_brunswick_grey.png";
// // import new_brunswick_clr from "../../../assets/flags/new_brunswick_clr.png";
// import newfoundland_and_labrador_grey from "../../../assets/flags/newfoundland_and_labrador_grey.png";
// import newfoundland_and_labrador_clr from "../../../assets/flags/newfoundland_and_labrador_clr.png";
// import { useSelector } from "react-redux";

// // Sample province data - grayscale image (default) and colorful image (on hover)
// const provinces = [
//   {
//     name: "British Columbia",
//     imgSrc: british_columbia_grey,
//     colorfulImgSrc: british_columbia_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Alberta",
//     imgSrc: alberta_grey,
//     colorfulImgSrc: alberta_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Saskatchewan",
//     imgSrc: saskatchewan_grey,
//     colorfulImgSrc: saskatchewan_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Manitoba",
//     imgSrc: manitoba_grey,
//     colorfulImgSrc: manitoba_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Ontario",
//     imgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002264_npbdif.png",
//     colorfulImgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_2_sa7qxh.png",
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Quebec",
//     imgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923237/Mask_group_ddpwud.png",
//     colorfulImgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_3_cwauod.png",
//     isTrailerAvailable: true,
//   },
//   {
//     name: "New Brunswick",
//     imgSrc: new_brunswick_grey,
//     colorfulImgSrc: new_brunswick_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Prince Edward Island",
//     imgSrc: prince_edward_island_grey,
//     colorfulImgSrc: prince_edward_island_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Nova Scotia",
//     imgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002263_wcntmv.png",
//     colorfulImgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_1_ppslzr.png",
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Newfoundland and Labrador",
//     imgSrc: newfoundland_and_labrador_grey,
//     colorfulImgSrc: newfoundland_and_labrador_clr,
//     isTrailerAvailable: true,
//   },
//   {
//     name: "The Yukon",
//     imgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002266_tex18z.png",
//     colorfulImgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_5_nljzsi.png",
//     isTrailerAvailable: true,
//   },

//   {
//     name: "Northwest Territories",
//     imgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002265_mqghck.png",
//     colorfulImgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_4_nvohnj.png",
//     isTrailerAvailable: true,
//   },
//   {
//     name: "Nunavut",
//     imgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002260_ubamqi.png",
//     colorfulImgSrc:
//       "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_b1ki3c.png",
//     isTrailerAvailable: true,
//   },
// ];

// // Custom arrows for carousel
// const PrevArrow = (props) => {
//   const { onClick } = props;
//   return (
//     <div onClick={onClick} className={`${css.testimonials_btn} ${css.prev}`}>
//       <ArrowBackIosNew style={{ fontSize: "24px", color: themeRed }} />
//     </div>
//   );
// };

// const NextArrow = (props) => {
//   const { onClick } = props;
//   return (
//     <div onClick={onClick} className={`${css.testimonials_btn} ${css.next}`}>
//       <ArrowForwardIos style={{ fontSize: "24px", color: themeRed }} />
//     </div>
//   );
// };

// const ProvinceCarouselSection = () => {
//   const prevRef = useRef(null);
//   const nextRef = useRef(null);

//   const { get_user_trailers_list_status, get_user_trailers_list_data } =
//     useSelector((state) => state.trailersDetailsManage);

//   const navigate = useNavigate();
//   return (
//     <Container maxWidth="lg">
//       <div style={{ margin: "150px auto", width: "100%" }}>
//         <Stack
//           direction={"row"}
//           alignItems={"center"}
//           justifyContent={"space-between"}
//         >
//           <Typography variant="h4" gutterBottom fontWeight={"bold"}>
//             Browse by your Province
//           </Typography>

//           <Stack
//             direction={"row"}
//             alignItems={"center"}
//             justifyContent={"center"}
//           >
//             <IconButton
//               sx={{
//                 padding: {
//                   xl: "8px",
//                   lg: "8px",
//                   md: "8px",
//                   sm: "8px",
//                   xs: "0px",
//                 },
//                 fontSize: {
//                   xl: "30px",
//                   lg: "30px",
//                   md: "30px",
//                   sm: "30px",
//                   xs: "20px",
//                 },
//               }}
//               ref={prevRef}
//               className="swiper-button-prev-custom"
//             >
//               <IoIosArrowBack style={{ color: themeRed }} />
//             </IconButton>
//             <IconButton
//               sx={{
//                 padding: {
//                   xl: "8px",
//                   lg: "8px",
//                   md: "8px",
//                   sm: "8px",
//                   xs: "0px",
//                 },
//                 fontSize: {
//                   xl: "30px",
//                   lg: "30px",
//                   md: "30px",
//                   sm: "30px",
//                   xs: "20px",
//                 },
//               }}
//               ref={nextRef}
//               className="swiper-button-next-custom"
//             >
//               <IoIosArrowForward style={{ color: themeRed }} />
//             </IconButton>
//           </Stack>
//         </Stack>

//         <Swiper
//           slidesPerView={5} // Adjust the number of visible slides
//           pagination={{
//             clickable: false, // Enable pagination bullets
//           }}
//           spaceBetween={10}
//           navigation={{
//             prevEl: prevRef.current,
//             nextEl: nextRef.current,
//           }} // Enable navigation arrows
//           onInit={(swiper) => {
//             swiper.params.navigation.prevEl = prevRef.current;
//             swiper.params.navigation.nextEl = nextRef.current;
//             swiper.navigation.init();
//             swiper.navigation.update();
//           }}
//           modules={[Navigation]} // Use Swiper modules
//           className="mySwiper"
//           breakpoints={{
//             1440: {
//               slidesPerView: 5, // Show 2 slides for xl (>= 1200px)
//             },
//             1366: {
//               slidesPerView: 5, // Show 2 slides for xl (>= 1200px)
//             },
//             1200: {
//               slidesPerView: 4, // Show 2 slides for xl (>= 1200px)
//             },
//             1024: {
//               slidesPerView: 3, // Show 2 slides for lg (>= 1024px)
//             },
//             768: {
//               slidesPerView: 3, // Show 1 slide for md (>= 768px)
//             },
//             600: {
//               slidesPerView: 2, // Show 1 slide for sm (>= 600px)
//             },
//             0: {
//               slidesPerView: 1, // Show 1 slide for xs (below 600px)
//             },
//           }}
//         >
//           {provinces.map((elem, i) => {
//             return (
//               <SwiperSlide key={i}>
//                 <Stack gap={4} sx={{ padding: "11px 0px !important" }}>
//                   <div className={css.unique_carousel_wrapper}>
//                     <div className={css.unique_carousel_item}>
//                       <img
//                         src={elem.imgSrc}
//                         alt={elem?.name}
//                         className={`${css.trailer_img} ${css.grayscale_img} ${css.trailer__blur}`}
//                       />
//                       <img
//                         src={elem?.colorfulImgSrc}
//                         alt={elem?.name}
//                         className={`${css.trailer_img} ${css.colorful_img} ${css.trailer__blur}`}
//                       />

//                       <Typography>{elem.name}</Typography>
//                     </div>
//
//                       <div className={css.unique_carousel_content_section}>
//                         <span class={css.title_text}>
//                           Opening here soon. Check back!
//                         </span>
//                         <br />
//                         {/* <ButtonComp
//                         label="Book Now"
//                         onClick={() => navigate(`/view-category/${elem?.name}`)}
//                       /> */}
//                       </div>
//
//                   </div>
//                 </Stack>
//               </SwiperSlide>
//             );
//           })}
//         </Swiper>
//       </div>
//     </Container>
//   );
// };

// export default ProvinceCarouselSection;

import {
  Card,
  CardContent,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { themeRed } from "../../../utils/colorTheme";
import Slider from "react-slick";
import css from "./ProvinceCarouselSection.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { trailerCategories } from "../CategorieSection/CategoriesSection";
import ButtonComp from "../../../component/common/ButtonComp";
import { useNavigate } from "react-router-dom";
import british_columbia_grey from "../../../assets/flags/british_columbia_grey.png";
import british_columbia_clr from "../../../assets/flags/british_columbia_clr.png";
import alberta_grey from "../../../assets/flags/alberta_grey.png";
import alberta_clr from "../../../assets/flags/alberta_clr.png";
import saskatchewan_grey from "../../../assets/flags/saskatchewan_grey.png";
import saskatchewan_clr from "../../../assets/flags/saskatchewan_clr.png";
import manitoba_grey from "../../../assets/flags/manitoba_grey.png";
import manitoba_clr from "../../../assets/flags/manitoba_clr.png";
import new_brunswick_grey from "../../../assets/flags/new_brunswick_grey.png";
import new_brunswick_clr from "../../../assets/flags/new_brunswick_clr.png";
import prince_edward_island_grey from "../../../assets/flags/prince_edward_island_grey.png";
import prince_edward_island_clr from "../../../assets/flags/prince_edward_island_clr.png";
import newfoundland_and_labrador_grey from "../../../assets/flags/newfoundland_and_labrador_grey.png";
import newfoundland_and_labrador_clr from "../../../assets/flags/newfoundland_and_labrador_clr.png";
import { useSelector } from "react-redux";

// const states = [
//   { name: "Alberta", code: "AB" },
//   { name: "British Columbia", code: "BC" },
//   { name: "Manitoba", code: "MB" },
//   { name: "New Brunswick", code: "NB" },
//   { name: "Nova Scotia", code: "NS" },
//   { name: "Nunavut", code: "NU" },
//   { name: "Ontario", code: "ON" },
//   { name: "Prince Edward Island", code: "PE" },
//   { name: "Quebec", code: "QC" },
//   { name: "Saskatchewan", code: "SK" },
//   { name: "Yukon", code: "YT" },
//   { name: "Northwest Territories", code: "NT" },
//   { name: "Newfoundland and Labrador", code: "NL" },
// ];

// Sample province data - grayscale image (default) and colorful image (on hover)
const provinces = [
  {
    name: "British Columbia",
    imgSrc: british_columbia_grey,
    colorfulImgSrc: british_columbia_clr,
    isTrailerAvailable: true,
    code: "BC",
  },
  {
    name: "Alberta",
    imgSrc: alberta_grey,
    colorfulImgSrc: alberta_clr,
    isTrailerAvailable: true,
    code: "AB",
  },
  {
    name: "Saskatchewan",
    imgSrc: saskatchewan_grey,
    colorfulImgSrc: saskatchewan_clr,
    isTrailerAvailable: true,
    code: "SK",
  },
  {
    name: "Manitoba",
    imgSrc: manitoba_grey,
    colorfulImgSrc: manitoba_clr,
    isTrailerAvailable: true,
    code: "MB",
  },
  {
    name: "Ontario",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002264_npbdif.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_2_sa7qxh.png",
    isTrailerAvailable: true,
    code: "ON",
  },
  {
    name: "Quebec",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923237/Mask_group_ddpwud.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924976/Property_1_Variant2_3_cwauod.png",
    isTrailerAvailable: true,
    code: "QC",
  },
  {
    name: "New Brunswick",
    imgSrc: new_brunswick_grey,
    colorfulImgSrc: new_brunswick_clr,
    isTrailerAvailable: true,
    code: "NB",
  },
  {
    name: "Prince Edward Island",
    imgSrc: prince_edward_island_grey,
    colorfulImgSrc: prince_edward_island_clr,
    isTrailerAvailable: true,
    code: "PE",
  },
  {
    name: "Nova Scotia",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002263_wcntmv.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_1_ppslzr.png",
    isTrailerAvailable: true,
    code: "NS",
  },
  {
    name: "Newfoundland and Labrador",
    imgSrc: newfoundland_and_labrador_grey,
    colorfulImgSrc: newfoundland_and_labrador_clr,
    isTrailerAvailable: true,
    code: "NL",
  },
  {
    name: "The Yukon",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002266_tex18z.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_5_nljzsi.png",
    isTrailerAvailable: true,
    code: "YT",
  },

  {
    name: "Northwest Territories",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002265_mqghck.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_4_nvohnj.png",
    isTrailerAvailable: true,
    code: "NT",
  },
  {
    name: "Nunavut",
    imgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725923236/Group_1000002260_ubamqi.png",
    colorfulImgSrc:
      "https://res.cloudinary.com/dpfni06vw/image/upload/v1725924977/Property_1_Variant2_b1ki3c.png",
    isTrailerAvailable: true,
    code: "NU",
  },
];

const ProvinceCarouselSection = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { get_user_trailers_list_data } = useSelector(
    (state) => state.trailersDetailsManage
  );
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg">
      <div style={{ margin: "150px auto", width: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h4" gutterBottom fontWeight={"bold"}>
            Browse by your Province
          </Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              sx={{ padding: "8px", fontSize: "30px" }}
              ref={prevRef}
              className="swiper-button-prev-custom"
            >
              <IoIosArrowBack style={{ color: themeRed }} />
            </IconButton>
            <IconButton
              sx={{ padding: "8px", fontSize: "30px" }}
              ref={nextRef}
              className="swiper-button-next-custom"
            >
              <IoIosArrowForward style={{ color: themeRed }} />
            </IconButton>
          </Stack>
        </Stack>

        <Swiper
          slidesPerView={5}
          spaceBetween={10}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            1440: { slidesPerView: 5 },
            1366: { slidesPerView: 5 },
            1200: { slidesPerView: 4 },
            1024: { slidesPerView: 3 },
            768: { slidesPerView: 3 },
            600: { slidesPerView: 2 },
            0: { slidesPerView: 1 },
          }}
        >
          {provinces.map((elem, i) => {
            const trailerProvinceData =
              get_user_trailers_list_data?.trailers_by_provice || [];
            const trailerData = trailerProvinceData?.find(
              (trailer) => trailer?.name === elem?.name
            );
            const hasTrailer = trailerData ? trailerData?.hasTrailer : false;

            return (
              <SwiperSlide key={i}>
                <Stack gap={4} sx={{ padding: "11px 0px !important" }}>
                  {/* <div className={css.unique_carousel_wrapper}> */}
                  {!hasTrailer ? (
                    <div className={css.unique_carousel_wrapper}>
                      <div className={css.unique_carousel_item}>
                        <img
                          src={elem.imgSrc}
                          alt={elem?.name}
                          className={`${css.trailer_img} ${css.grayscale_img} ${css.trailer__blur}`}
                        />
                        <img
                          src={elem?.colorfulImgSrc}
                          alt={elem?.name}
                          className={`${css.trailer_img} ${css.colorful_img} ${css.trailer__blur}`}
                        />

                        <Typography>{elem.name}</Typography>
                      </div>

                      <div className={css.unique_carousel_content_section}>
                        <span class={css.title_text}>
                          Opening here soon. Check back!
                        </span>
                        <br />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={css.unique_carousel_wrapper}
                      onClick={() => {
                        navigate("/trailer-map", {
                          state: { location_state: elem.code },
                        });
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className={css.unique_carousel_item}>
                        <img
                          src={elem?.colorfulImgSrc}
                          alt={elem?.name}
                          className={`${css.trailer_img} `}
                        />
                        <Typography>{elem.name}</Typography>
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                </Stack>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Container>
  );
};

export default ProvinceCarouselSection;
