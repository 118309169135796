import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { asyncStatus } from '../../utils/asyncStatus';


const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#C3282F',
            },
          },
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: '#C3282F',
            },
          },
        },
      },
    },
  },
});
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#333',
      '::placeholder': {
        color: '#aab7c4',
      },
      iconColor: '#666EE8',
      backgroundColor: '#fff',
      borderRadius: '4px',
      padding: '10px',
      border: '1px solid #dfe1e5',
    },
    invalid: {
      iconColor: '#e5424d',
      color: '#e5424d',
    },
    complete: {
      iconColor: '#28a745',
    },
  },
};

const BookingAccordion = ({ user, onClick }) => {
  const { trailer_booking_status } = useSelector((state) => state.trailersDetailsManage)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    cardHolderName: '',
    cardHolderAddress: '',
    cardHolderState: '',
    cardHolderCity: '',
    cardHolderZip: '',
    saveCard: false,
    certify: false,
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Stripe CardElement validation
    if (!stripe || !elements) {
      return; // Stripe.js has not loaded yet
    }

    const cardElement = elements.getElement(CardElement);

    // Show form data in the console

    // Confirm payment method with Stripe (dummy example)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: formData.cardHolderName,
        address: {
          line1: formData.cardHolderAddress,
          city: formData.cardHolderCity,
          state: formData.cardHolderState,
          postal_code: formData.cardHolderZip,
        },
      },
    });

    if (error) {
      console.error('[Error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', marginTop: '20px' }}>
        {/* <form onSubmit={handleSubmit}> */}
        <div>
          {/* Begin Your Journey Section */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">1. Begin Your Journey</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    margin="normal"
                    value={user?.first_name}
                    defaultValue={user?.first_name}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    margin="normal"
                    value={user?.last_name}
                    defaultValue={user?.last_name}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    margin="normal"
                    value={user?.email}
                    defaultValue={user?.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    margin="normal"
                    value={user?.phonenumber}
                    defaultValue={user?.phonenumber}
                    disabled
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Confirm & Pay Section */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">2. Confirm & Pay</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Card Holder Name"
                    name="cardHolderName"
                    margin="normal"
                    value={formData.cardHolderName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Card Holder Address"
                    name="cardHolderAddress"
                    margin="normal"
                    value={formData.cardHolderAddress}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth
                    label="Card Holder State"
                    name="cardHolderState"
                    margin="normal"
                    value={formData.cardHolderState}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth
                    label="Card Holder City"
                    name="cardHolderCity"
                    margin="normal"
                    value={formData.cardHolderCity}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth
                    label="Card Holder Zip Code"
                    name="cardHolderZip"
                    margin="normal"
                    value={formData.cardHolderZip}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {/* <Typography variant="body1">Payment Card Information</Typography>
                  <CardElement /> */}
                  <Typography variant="body1">Payment Card Information</Typography>
                  <Box
                    sx={{
                      padding: '10px',
                      border: '1px solid #dfe1e5',
                      borderRadius: '4px',
                      marginTop: '10px',
                    }}
                  >
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </Box>
                </Grid>
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.certify}
                    onChange={handleInputChange}
                    name="certify"
                    color="error"
                  />
                }
                label="I certify that I am at least 25 years old and have a valid driver license."
              />
              <Button variant="contained" fullWidth color="error" type="submit" onClick={onClick}>
                {trailer_booking_status === asyncStatus.LOADING ? "Loading..." : "Confirm & Pay"}
              </Button>
            </AccordionDetails>
          </Accordion>
          {/* </form> */}
        </div>
      </Box>
    </ThemeProvider>
  );
};
export default BookingAccordion;
