import React from 'react';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';

const MyGallery = ({ imagesFromApi = [] }) => {
    const imageStyle = {
        width: '100%', // Each image fills its container width
        height: '300px', // Consistent height for all images
        objectFit: 'cover', // Ensures the image covers the area without distortion
    };

    return (
        <div>
            <LightGallery
                plugins={[lgThumbnail, lgZoom]}
                mode="lg-fade"
            >
                {imagesFromApi.map((imageUrl, index) => (
                    <a href={imageUrl} key={index} style={{ display: 'inline-block', width: '25%' }}>
                        <img
                            src={imageUrl}
                            alt={`Image ${index + 1}`}
                            style={imageStyle}
                        />
                    </a>
                ))}
            </LightGallery>
        </div>
    );
};

export default MyGallery;
