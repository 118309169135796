import {
  applyMiddleware,
  combineReducers,
  compose,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import user_auth_slice from "./slices/user_auth_slice";
import layoutControler from "./slices/layoutControler";
import chat_slice from "./slices/chat_slice";
import userTypeSlice from "./slices/userTypeSlice";
import bank_manage_slice from "./slices/bank_manage_slice";
import trailers_details_slice from "./slices/trailers_details_slice";
import ticket_slice from './slices/ticket_slice'

let reducers = combineReducers({
  userAuth: user_auth_slice,
  layout_controler: layoutControler,
  ChatSlice: chat_slice,
  userType: userTypeSlice,
  bankManage: bank_manage_slice,
  trailersDetailsManage: trailers_details_slice,
  ticketManage: ticket_slice,
});
// const rootReducer = (state, action) => {
//   if (action.type === TYPE_LOGOUT_USER) {
//     state = undefined;
//   }
//   return reducers(state, action);
// };
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = configureStore(
  { reducer: reducers },
  composeEnhancers(applyMiddleware(thunk))
);
export default store;
