import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { UserDashboard } from './UserDashboard';

const MainDashboard = () => {
  const { userAuth, user } = useSelector((state) => state.userAuth);

 
  return <UserDashboard />;
};

export default MainDashboard;
