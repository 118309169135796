// import React from 'react'

// const BookingPage = () => {
//   return (
//     <div>BookingPage</div>
//   )
// }

// export default BookingPage

import { useNavigate, useParams } from "react-router-dom";
// import Navbar from "../component/common/navbar/NavBar";
// import "../index.css";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Rating,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
// import '../../pages/home.css'
// import './hostDetail.css'
import "./bookingPage.css";
import Navbar from "../../component/navbar/Navbar";
import Footer from "../Footer/Footer";
import ProfileImage from "../../assets/background/profile-image.png";
import {
  Email,
  EmojiEvents,
  Facebook,
  FavoriteBorder,
  FileCopy,
  Star,
  Twitter,
} from "@mui/icons-material";
import { themeRed } from "../../utils/colorTheme";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import CustomButton from "../../component/common/Button/Button";
import { useEffect, useState } from "react";
import { SlBadge } from "react-icons/sl";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getHostDetailByIdAsync, getTrailerByIdAsync } from "../../services/trailers_details_service";


const RatingBar = ({ category, rating }) => (
  <div className="ratingBarContainer">
    <span className="ratingCategory">{category}</span>
    <div className="ratingBarBackground">
      <div className="ratingBar" style={{ width: `${(rating / 5) * 100}%` }} />
    </div>
    <span className="ratingValue">{rating}</span>
  </div>
);

function BookingPage() {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(2);
  const [tripStart, setTripStart] = useState("");
  const [tripEnd, setTripEnd] = useState("");

  const { get_trailer_by_id_status, get_trailer_by_id_data } = useSelector(
    (state) => state.trailersDetailsManage
  );

  const [location, setLocation] = useState("Los Angeles, CA 90015");
  const theme = createTheme({
    palette: {
      error: {
        main: "#b71c1c", // Red color for 'error'
      },
    },
  });

  const _maskFullName = (name) => {
    if (name.length <= 2) {
      return name;
    } else {
      const firstChar = name[0];
      const lastChar = name[name.length - 1];
      const maskedName = firstChar + "*".repeat(name.length - 2) + lastChar;

      return maskedName;
    }
  };

  useEffect(() => {
    dispatch(getTrailerByIdAsync(id));
    dispatch(getHostDetailByIdAsync(get_trailer_by_id_data?.host_id));
  }, [dispatch, id]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      {/* First Section */}
      <Stack
        className="mainBgBooking"
        sx={{ background: `url(${get_trailer_by_id_data?.trailer_photos[0]})` }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            marginTop: {
              xs: "95px",
              sm: "95px",
              md: "140px",
              lg: "140px",
              xl: "140px",
            },
          }}
        ></Stack>
      </Stack>

      <Stack alignItems={"center"}>
        <Stack alignItems={"center"} maxWidth={"1100px"}>
          <Grid container>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack mt={2}>
                <Stack p={5}>
                  <div className="header">
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      {/* <h1 className="title">
                        Car Hauler 
                      </h1> */}
                      <span className="title">
                        {get_trailer_by_id_data?.title || ""}
                      </span>
                      {/* <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                                                <Typography style={{ cursor: "pointer" }} onClick={() => navigate('/trailer-edit')}>Edit</Typography>
                                                <Typography>Online/Offline</Typography>
                                                <ThemeProvider theme={theme}>
                                                    <Switch color="error" defaultChecked />
                                                </ThemeProvider>
                                            </Stack> */}
                    </Stack>
                    <p className="rating">Sport S</p>
                    <p className="rating" style={{ color: "black" }}>
                      4.95 ★ <span className="trips">(54 trips)</span>
                    </p>
                  </div>
                  <h3>HOSTED BY</h3>
                  <div className="hostedBy">
                    <div className="hostedByContent">
                      <Stack mr={1}>
                        <img
                          style={{ width: "125px", height: "125px" }}
                          src={require("../../assets/trailerDevImg.png")}
                          alt="profile-image"
                        />
                        <Stack
                          mt={-2}
                          ml={2}
                          className="profile-rating"
                          flexDirection={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={1}
                        >
                          <Stack className="rating-number">{2}</Stack>
                          <Stack>
                            <img
                              src={require("../../assets/background/rating-star.png")}
                              alt="star"
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      <div>
                        <h2 className="hostName" style={{ color: "black" }}>
                          CARSAN
                        </h2>
                        <p className="hostDetails" style={{ color: "black" }}>
                          29,318 trips • Joined Jul 2015
                        </p>
                        <p className="responseTime" style={{ color: "black" }}>
                          Typically responds within 1 minute
                        </p>
                      </div>
                    </div>
                    <p className="hostRating">5.0 ★</p>
                  </div>

                  <div className="description">
                    <h4 style={{ marginBottom: 10 }}>DESCRIPTION</h4>
                    <p>
                      Content will be provided by the client. Content will be
                      provided by the client. Content will be provided by the
                      client.
                    </p>
                    <button className="moreButton">More</button>
                  </div>

                  <div className="ratingsAndReviews">
                    <h2 className="ratingHeader">Ratings and Reviews</h2>
                    <p className="ratingSummary">
                      4.95 ★ <span className="totalRatings">(39 ratings)</span>
                    </p>

                    <RatingBar category="Cleanliness" rating={4.9} />
                    <RatingBar category="Maintenance" rating={5.0} />
                    <RatingBar category="Communication" rating={5.0} />
                    <RatingBar category="Convenience" rating={4.9} />
                    <RatingBar category="Accuracy" rating={5.0} />

                    <div style={{ marginTop: 10 }} className="reviews">
                      <h2 style={{ marginBottom: 10 }}>Reviews</h2>
                      <Stack my={3}>
                        <Stack
                          gap={2}
                          flexDirection={"row"}
                          alignItems={"start"}
                        >
                          <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                          <Stack flexDirection={"column"}>
                            <Typography className="reviewerName">
                              {_maskFullName("John Wick".replace(/\s/g, ""))}
                            </Typography>
                            <Typography fontSize={"12px"} color={"lightgray"}>
                              Aug 31, 2023
                            </Typography>
                          </Stack>
                        </Stack>

                        <Rating
                          name="simple-controlled"
                          value={2}
                          // onChange={(event, newValue) => {
                          //     setValue(newValue);
                          // }}
                        />
                      </Stack>
                      <p className="reviewContent">
                        Content will be provided by the client. Content will be
                        provided by the client. Content will be provided by the
                        client.
                      </p>
                      <Stack my={3}>
                        <Stack
                          gap={2}
                          flexDirection={"row"}
                          alignItems={"start"}
                        >
                          <Avatar sx={{ bgcolor: themeRed }}>J</Avatar>
                          <Stack flexDirection={"column"}>
                            <Typography className="reviewerName">
                              {_maskFullName("John Wick".replace(/\s/g, ""))}
                            </Typography>
                            <Typography fontSize={"12px"} color={"lightgray"}>
                              Aug 31, 2023
                            </Typography>
                          </Stack>
                        </Stack>

                        <Rating
                          name="simple-controlled"
                          value={2}
                          // onChange={(event, newValue) => {
                          //     setValue(newValue);
                          // }}
                        />
                      </Stack>
                      <p className="reviewContent">
                        Content will be provided by the client. Content will be
                        provided by the client. Content will be provided by the
                        client.
                      </p>
                    </div>
                  </div>
                </Stack>
              </Stack>
            </Grid>
            {/* dddd */}
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack p={2}>
                <Box sx={{ maxWidth: 400, margin: "0 auto", padding: 2 }}>
                  {/* Price */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      style={{ textDecoration: "line-through", color: "#aaa" }}
                    >
                      $59
                    </Typography>
                    <Typography variant="h5" style={{ fontWeight: 600 }}>
                      $
                      {get_trailer_by_id_data?.daily_rate?.toLocaleString() ||
                        0}{" "}
                      / day
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    $159 est. total
                  </Typography>
                  <Divider sx={{ my: 2 }} />

                  {/* Trip Start and End */}
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <TextField
                      label="Trip start"
                      type="date"
                      value={tripStart}
                      onChange={(e) => setTripStart(e.target.value)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ mr: 1 }}
                    />
                    <TextField
                      label="Trip end"
                      type="date"
                      value={tripEnd}
                      onChange={(e) => setTripEnd(e.target.value)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>

                  {/* Location Dropdown */}
                  <Box mb={2}>
                    <Select
                      fullWidth
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <MenuItem value="Los Angeles, CA 90015">
                        Los Angeles, CA 90015
                      </MenuItem>
                      {/* Add other locations if needed */}
                    </Select>
                  </Box>

                  {/* Discount */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="body2">3+ day discount</Typography>
                    <Typography variant="body2" color="success.main">
                      $18
                    </Typography>
                  </Box>

                  {/* Continue Button */}
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                      navigate("/checkout");
                    }}
                    sx={{ py: 1, fontWeight: "bold" }}
                  >
                    Continue
                  </Button>
                  <Divider sx={{ my: 2 }} />

                  {/* Distance Included */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Distance included
                    </Typography>
                    <Typography variant="body2" fontWeight={600}>
                      Unlimited
                    </Typography>
                  </Box>

                  {/* Insurance */}
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    INSURANCE & PROTECTION
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    Insurance via Travelers
                  </Typography>
                  <Divider sx={{ my: 2 }} />

                  {/* Add to Favourites */}
                  {/* <Button
                                        variant="outlined"
                                        startIcon={<FavoriteBorder />}
                                        fullWidth
                                        sx={{ mb: 2 }}
                                    >
                                        Add to favourites
                                    </Button> */}

                  {/* Social Icons */}
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <IconButton>
                      <Facebook />
                    </IconButton>
                    <IconButton>
                      <Twitter />
                    </IconButton>
                    <IconButton>
                      <Email />
                    </IconButton>
                    <IconButton>
                      <FileCopy />
                    </IconButton>
                  </Box>

                  {/* Report Listing */}
                  <Typography variant="body2" color="error" textAlign="center">
                    Report listing
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d45600.64517303216!2d-74.01611958046904!3d40.68789426247765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25bae694479a3%3A0xb9949385da52e69e!2sBarclays%20Center!5e0!3m2!1sen!2s!4v1725485815339!5m2!1sen!2s"
          width="100%"
          height="340"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Stack>
      <Footer />
    </div>
  );
}

export default BookingPage;
