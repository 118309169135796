import MainDashboard from "../component/UserDashboard/MainDashboard";
import BecomeHost from "../pages/BecomeHost";
import Home from "../pages/Home";
import CheckYourEmail from "../pages/auth/CheckYourEmail";
import CreatePassword from "../pages/auth/CreatePassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import BookingPage from "../screens/UserScreens/BookingPage";
import CheckOutPage from "../screens/UserScreens/CheckOutPage";
import FAQs from "../screens/UserScreens/FAQs";
import HostDetailsScreen from "../screens/UserScreens/HostDetailsScreen";
import HowItsWork from "../screens/UserScreens/HowItsWork";
import RentTrailer from "../screens/UserScreens/RentTrailer";
import TrailerMapLayout from "../screens/UserScreens/SelectTrailer";
// Steps
import StepOne from "../component/KYC_Steps/StepOne/StepOne";
import OTPForm from "../component/OTPForm/OTPForm";
import TermsAndCondition from "../pages/TermsAndCondition/TermsAndCondition";
import CategoryView from "../pages/Category/CategoryView";
import TrailerBookDetails from "../screens/UserScreens/TrailerBookDetails";
import ContactUs from "../screens/ContactUs";
import AboutUs from "../screens/AboutUs";
import OurMission from "../screens/OurMission";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import CancellationPolicy from "../pages/CancellationPolicy/CancellationPolicy";
// Steps

export const userRoutes = [
  {
    caption: "Home",
    linkTo: "/",
    icon: "",
    element: <Home />,
    authRequired: false,
    both: true,
  },
  {
    caption: "About Us",
    linkTo: "/about-us/",
    icon: "",
    element: <AboutUs />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Privacy Policy",
    linkTo: "/privacy-policy/",
    icon: "",
    element: <PrivacyPolicy />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Contact Us",
    linkTo: "/contact-us/",
    icon: "",
    element: <ContactUs />,
    authRequired: false,
    both: true,
  },
  {
    caption: "How it works",
    linkTo: "/how-its-work",
    icon: "",
    element: <HowItsWork />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Our Mission",
    linkTo: "/our-mission/",
    icon: "",
    element: <OurMission />,
    authRequired: false,
    both: true,
  },
  {
    caption: "FAQ",
    linkTo: "/faqs",
    icon: "",
    element: <FAQs />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Terms And Conditions",
    linkTo: "/terms-and-conditions",
    icon: "",
    element: <TermsAndCondition />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Cancellation Policy",
    linkTo: "/cancellation-policy",
    icon: "",
    element: <CancellationPolicy />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Rent a Trailer",
    linkTo: "/rent-trailer",
    icon: "",
    element: <RentTrailer />,
    authRequired: false,
    // both: true
  },
  {
    caption: "CategoryView",
    linkTo: "/view-category/:name",
    icon: "",
    element: <CategoryView />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Host Details Screen",
    linkTo: "/host-details",
    icon: "",
    element: <HostDetailsScreen />,
    authRequired: false,
    // both: true
  },
  {
    caption: "Booking Page",
    linkTo: "/booking-page/:id",
    icon: "",
    element: <BookingPage />,
    authRequired: false,
    // both: true
  },
  {
    caption: "Trailer Map Layout",
    linkTo: "/trailer-map",
    icon: "",
    element: <TrailerMapLayout />,
    authRequired: false,
    // both: true
  },
  {
    caption: "Trailer Book Details",
    linkTo: "/trailer-book-details/:id",
    icon: "",
    element: <TrailerBookDetails />,
    authRequired: false,
    // both: true
  },
  {
    caption: "CheckOut Page",
    linkTo: "/checkout",
    icon: "",
    element: <CheckOutPage />,
    authRequired: true,
    // both: true
  },

  {
    caption: "UserDashboard",
    linkTo: "/*",
    element: <MainDashboard />,
    authRequired: true,
    // both:false
  },
  {
    caption: "Login",
    linkTo: "/login",
    icon: "",
    element: <Login />,
    authRequired: false,
    // both:true
  },

  {
    caption: "Signup",
    linkTo: "/signup",
    icon: "",
    element: <SignUp />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Forgot Password",
    linkTo: "/forgot-password",
    icon: "",
    element: <ForgotPassword />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Check Email",
    linkTo: "/check-email",
    icon: "",
    element: <CheckYourEmail />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Create password",
    linkTo: "/create-password",
    icon: "",
    element: <CreatePassword />,
    authRequired: false,
    // both:true
  },
  {
    caption: "OTPForm",
    linkTo: "/OTPForm",
    icon: "",
    element: <OTPForm />,
    authRequired: true,
    otp_verify: true,
  },
  {
    caption: "StepOne",
    linkTo: "/StepOne",
    icon: "",
    element: <StepOne />,
    authRequired: true,
    kyc_verify: true,
    // both: true
  },
];

export const hostRoutes = [
  {
    caption: "Home",
    linkTo: "/",
    icon: "",
    element: <BecomeHost />,
    authRequired: false,
    both: true,
  },
  {
    caption: "About Us",
    linkTo: "/about-us/",
    icon: "",
    element: <AboutUs />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Contact Us",
    linkTo: "/contact-us/",
    icon: "",
    element: <ContactUs />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Our Mission",
    linkTo: "/our-mission/",
    icon: "",
    element: <OurMission />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Rent a Trailer",
    linkTo: "/rent-trailer",
    icon: "",
    element: <RentTrailer />,
    authRequired: true,
    // both: true
  },
  {
    caption: "Host Details Screen",
    linkTo: "/host-details",
    icon: "",
    element: <HostDetailsScreen />,
    authRequired: true,
    // both: true
  },
  {
    caption: "Booking Page",
    linkTo: "/booking-page",
    icon: "",
    element: <BookingPage />,
    authRequired: true,
    // both: true
  },
  {
    caption: "Trailer Map Layout",
    linkTo: "/trailer-map",
    icon: "",
    element: <TrailerMapLayout />,
    authRequired: true,
    // both: true
  },
  {
    caption: "CheckOut Page",
    linkTo: "/checkout",
    icon: "",
    element: <CheckOutPage />,
    authRequired: true,
    // both: true
  },

  {
    caption: "UserDashboard",
    linkTo: "/*",
    element: <MainDashboard />,
    authRequired: true,
    // both:false
  },
  {
    caption: "OTPForm",
    linkTo: "/OTPForm",
    icon: "",
    element: <OTPForm />,
    authRequired: true,
    otp_verify: true,
  },
  {
    caption: "StepOne",
    linkTo: "/StepOne",
    icon: "",
    element: <StepOne />,
    authRequired: true,
    kyc_verify: true,
    // both: true
  },
  {
    caption: "Login",
    linkTo: "/login",
    icon: "",
    element: <Login />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Signup",
    linkTo: "/signup",
    icon: "",
    element: <SignUp />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Forgot Password",
    linkTo: "/forgot-password",
    icon: "",
    element: <ForgotPassword />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Check Email",
    linkTo: "/check-email",
    icon: "",
    element: <CheckYourEmail />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Create password",
    linkTo: "/create-password",
    icon: "",
    element: <CreatePassword />,
    authRequired: false,
    // both:true
  },
  {
    caption: "Terms And Conditions",
    linkTo: "/terms-and-conditions",
    icon: "",
    element: <TermsAndCondition />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Cancellation Policy",
    linkTo: "/cancellation-policy",
    icon: "",
    element: <CancellationPolicy />,
    authRequired: false,
    both: true,
  },
  {
    caption: "Privacy Policy",
    linkTo: "/privacy-policy/",
    icon: "",
    element: <PrivacyPolicy />,
    authRequired: false,
    both: true,
  },
  {
    caption: "FAQ",
    linkTo: "/faqs",
    icon: "",
    element: <FAQs />,
    authRequired: false,
    both: true,
  },
];
