import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  addCommentAsync,
  addTicketAsync,
  getAllTicketAsync,
  getTicketAsync,
} from "../../services/Tickets_Service";

const initialState = {
  // ADD TICKET STATE
  add_ticket_status: asyncStatus.IDLE,
  add_ticket_data: null,
  add_ticket_error: null,

  // GET ALL TICKET STATE
  get_all_ticket_status: asyncStatus.IDLE,
  get_all_ticket_data: null,
  get_all_ticket_error: null,

  // GET TICKET STATE
  get_ticket_status: asyncStatus.IDLE,
  get_ticket_data: null,
  get_ticket_error: null,

  // ADD COMMENT STATE
  add_comment_status: asyncStatus.IDLE,
  add_comment_data: null,
  add_comment_error: null,
};

const ticket_slice = createSlice({
  name: "ticket_slice",
  initialState,
  reducers: {
    // setHostChatListStatus(state) {
    //   state.get_hosts_list_status = asyncStatus.IDLE;
    // },
    setAddTicketStatus(state) {
      state.add_ticket_status = asyncStatus.IDLE;
    },
    setAddCommentStatus(state) {
      state.add_comment_status = asyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    // ADD TICKET ASYNC =========>>>>>>>>>>>
    builder.addCase(addTicketAsync.pending, (state, action) => {
      state.add_ticket_status = asyncStatus.LOADING;
    });

    builder.addCase(addTicketAsync.fulfilled, (state, { payload }) => {
      state.add_ticket_status = asyncStatus.SUCCEEDED;
      state.add_ticket_data = payload.ticket;

      // Find the index of the ticket to be replaced in the array
      const index = state.get_all_ticket_data.findIndex(
        (ticket) => ticket._id === payload?.ticket?._id
      );

      if (index !== -1) {
        // Replace the specific ticket with the updated ticket data
        state.get_all_ticket_data[index] = payload?.ticket;
      } else {
        // Optionally, you can push the ticket to the array if it's not found
        state.get_all_ticket_data.push(payload?.ticket);
      }

      success_toast_message(payload.message);
    });

    builder.addCase(addTicketAsync.rejected, (state, action) => {
      state.add_ticket_status = asyncStatus.ERROR;
      state.add_ticket_error = action.error;
      error_toast_message(action.error.message);
    });

    // GET ALL TICKETS ASYNC =========>>>>>>>>>>>
    builder.addCase(getAllTicketAsync.pending, (state, action) => {
      state.get_all_ticket_status = asyncStatus.LOADING;
    });

    builder.addCase(getAllTicketAsync.fulfilled, (state, { payload }) => {
      state.get_all_ticket_status = asyncStatus.SUCCEEDED;
      state.get_all_ticket_data = payload.tickets;
    });

    builder.addCase(getAllTicketAsync.rejected, (state, action) => {
      state.get_all_ticket_status = asyncStatus.ERROR;
      state.get_all_ticket_error = action.error;
      error_toast_message(action.error.message);
    });

    // GET TICKET ASYNC =========>>>>>>>>>>>
    builder.addCase(getTicketAsync.pending, (state, action) => {
      state.get_ticket_status = asyncStatus.LOADING;
    });

    // GET TICKET ASYNC =========>>>>>>>>>>>
    builder.addCase(getTicketAsync.fulfilled, (state, { payload }) => {
      state.get_ticket_status = asyncStatus.SUCCEEDED;
      state.get_ticket_data = payload.ticket;
    });
    builder.addCase(getTicketAsync.rejected, (state, action) => {
      state.get_ticket_status = asyncStatus.ERROR;
      state.get_ticket_error = action.error;
      error_toast_message(action.error.message);
    });

    // ADD COMMENT ASYNC =========>>>>>>>>>>>
    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.add_comment_status = asyncStatus.LOADING;
    });

    builder.addCase(addCommentAsync.fulfilled, (state, { payload }) => {
      state.add_comment_status = asyncStatus.SUCCEEDED;
      state.add_comment_data = payload.completePost;
      state.get_ticket_data.comments = payload?.completePost?.comments;
    });

    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.add_comment_status = asyncStatus.ERROR;
      state.add_comment_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const { setAddTicketStatus, setAddCommentStatus } = ticket_slice.actions;

export default ticket_slice.reducer;
