import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Stack,
} from "@mui/material";
import { themeRed } from "../../utils/colorTheme";

const YourPreferences = () => {
  return (
    <Box>
      {/* Preferences Section */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          IV. Your Preferences
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Preferences"
              secondary={
                <>
                  You can control the methods by which we may contact you about
                  your account, your booking and listing activities, promotions,
                  and announcements by emailing us at{" "}
                  <Link
                    style={{ color: "#C3282F" }}
                    href="mailto:admin@trailer4rent.ca"
                  >
                    admin@trailer4rent.ca
                  </Link>
                  .
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Opting Out"
              secondary="You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of such an email. You may continue to receive service-related and other non-marketing emails. If you receive marketing text messages from us, you may opt out of receiving further marketing text messages from us by replying STOP to our marketing message or as described in the message."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Updating or Correcting Information"
              secondary={
                <>
                  You can review, correct, update, and edit certain information
                  that has been previously provided to us by you at any time by
                  logging in to your account and reviewing your account settings
                  and profile. You can also access or request a correction of
                  your information by contacting us at{" "}
                  <Link
                    style={{ color: "#C3282F" }}
                    href="mailto:admin@trailer4rent.ca"
                  >
                    admin@trailer4rent.ca
                  </Link>
                  . For your protection, we may need to verify your identity
                  before implementing your request.{" "}
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Closing Your Account"
              secondary={
                <>
                  If you wish to close your account and request deletion of your
                  personal information, please send an email to{" "}
                  <Link
                    style={{ color: "#C3282F" }}
                    href="mailto:admin@trailer4rent.ca"
                  >
                    admin@trailer4rent.ca
                  </Link>
                  . Please note that we may be unable to delete information
                  needed to comply with applicable laws, detect or prevent
                  fraud, collect any fees owed, resolve disputes, assist with or
                  process claims, troubleshoot problems, assist with any audits
                  and investigations, to enforce our Terms of Use or other
                  policies, and take other actions reasonably necessary,
                  permitted, or required by applicable law. There may also be
                  residual information that will remain within our databases and
                  other records, which will not be removed.{" "}
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Access"
              secondary={
                <>
                  You can request a copy of your personal information pursuant
                  to any information access rights that you may have under
                  applicable laws by emailing us at{" "}
                  <Link
                    style={{ color: "#C3282F" }}
                    href="mailto:admin@trailer4rent.ca"
                  >
                    admin@trailer4rent.ca
                  </Link>
                  . We may request proof of identification or re-authentication
                  to verify your access request.{" "}
                </>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Interest-based Advertising Choices"
              secondary={
                <span>
                  <p>
                    Your choices for limiting use of your personal information
                    for interest-based advertising include:
                  </p>
                  <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                    <li>
                      Cookie preferences: This can be done through your web
                      browser.
                    </li>

                    <li>
                      Blocking cookies in your browser: Most browsers let you
                      remove or reject cookies, including cookies used for
                      interest-based advertising. To do this, follow the
                      instructions in your browser settings. Many browsers
                      accept cookies by default until you change your settings.
                      For more information about cookies, including how to see
                      what cookies have been set on your device and how to
                      manage and delete them, <br /> visit allaboutcookies.org.{" "}
                    </li>

                    <li>
                      Blocking advertising ID use in your mobile settings: Your
                      mobile device settings may provide functionality to limit
                      use of the advertising ID associated with your mobile
                      device for interest-based advertising purposes.
                    </li>

                    <li>
                      Using privacy plug-ins or browsers: You can block sites
                      from setting cookies for interest-based ads by using a
                      browser with various privacy features and configuring them
                      to block third party cookies/trackers.
                    </li>

                    <li>
                      Advertising industry opt-out tools: You can also use these
                      opt-out options to limit use of your information for
                      interest-based advertising by participating companies (but
                      note we may work with companies that do not participate in
                      these programs).
                    </li>
                  </ul>
                </span>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Some Services Not Available Without Information"
              secondary="Where we are required by law to collect your personal information, or where we need your personal information in order to provide the Site and Services to you, if you do not provide this information when requested (or we later delete it at your request), we may not be able to provide you with the Site or Services."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Third Party Platform and Social Media"
              secondary="If you choose to connect the Site or Services to a third party platform or social media network, such as by using the third party’s authentication service to log into your account on the Services, you may be able to control your settings through the third-party platform or social media network. If you withdraw our ability to access certain information from a third-party platform or social media network, that choice will not apply to information that we have already received from that third party."
            />
          </ListItem>
          <Divider />
        </List>
      </Box>

      {/* Security Section */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          V. Security
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}>
          We employ technical, physical, and organizational measures designed to
          protect information against unauthorized access, destruction, or
          alteration. However, no method of transmitting or storing information
          is 100% secure.
        </Typography>
      </Box>

      {/* Other Information Section */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          VI. ther Information
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Sensitive Information"
              secondary="We ask that you not send us, and you not disclose, any sensitive information (e.g., Social Insurance numbers, passport information, information related to racial or ethnic origin, or health) on or through the Site or Services or otherwise to us unless specifically requested."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Minors"
              secondary="The Site and Services are not intended for anyone under the age of 18 and we do not knowingly collect personal information from users under the age of 18. If a minor under the age of 18 has already provided us with personal information, his or her parent or guardian may contact us to request that we delete it."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="Third-Party Privacy Practices"
              secondary="This Privacy Policy addresses only the use and disclosure of information collected by us. This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any site or service to which the Site or Services may link. The inclusion of a link on the Services does not imply endorsement of the linked site or service by us or by our affiliates. If you disclose your information to others, or if you are directed to a third-party website, their privacy notices and practices will apply."
            />
          </ListItem>
        </List>
      </Box>

      {/* Contact Section */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          VII. Contact
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}>
          If you have any questions or comments about this notice, our Privacy
          Policy, the ways in which we collect and use your personal
          information, your choices and rights regarding such use, or wish to
          exercise your rights under applicable law, please do not hesitate to
          contact us using "ATTENTION: PRIVACY COMPLIANCE OFFICER" in the
          message. You may reach us at
          <Link
            style={{ color: "#C3282F" }}
            href="mailto:admin@trailer4rent.ca"
          >
            admin@trailer4rent.ca
          </Link>
          .
        </Typography>
      </Box>

      {/* Withdrawal of Consent */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          VIII. Withdrawal of Consent
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}>
          If you no longer wish for us to process your data, please contact us
          via email using the{" "}
          <Link
            style={{ color: "#C3282F" }}
            href="mailto:admin@trailer4rent.ca"
          >
            admin@trailer4rent.ca
          </Link>{" "}
          email address.
        </Typography>
      </Box>

      {/* Privacy Policy Updates */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          IX. Privacy Policy Updates
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}>
          We reserve the right to modify this Privacy Policy at any time for any
          reason. It is your sole responsibility to check our policies so please
          review them frequently. Changes and clarifications will take effect
          immediately upon their posting on the Site. Should we make material
          changes to this Policy, we will notify you here that it has been
          updated so that you are aware of what information we collect and how
          it is used.
        </Typography>
      </Box>

      {/* Access to Specific Information and Data Portability Rights  */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#333",
            borderBottom: "4px solid #C3282F",
            fontFamily: "Book Antiqua, serif !important",
            display: "inline-block",
            paddingBottom: "4px",
            marginBottom: "16px",
          }}
        >
          Access to Specific Information and Data Portability Rights
        </Typography>

        {/* Description */}
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "#555",
            marginBottom: "20px",
            maxWidth: "800px",
          }}
        >
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past 12 months. Once we receive and confirm your verifiable consumer
          request, we will disclose to you:
        </Typography>

        {/* List of Rights */}
        <List>
          <ListItem>
            <ListItemText primary="The categories of personal information we collected about you." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="The categories of sources for the personal information we collected about you." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Our business or commercial purpose for collecting that personal information." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="The categories of third parties with whom we share that personal information." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="The specific pieces of personal information we collected about you (also called a data portability request)." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                "If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:sales, identifying the personal information categories that each category of recipient purchased; and disclosures for a business purpose or identifying the personal information categories that each category of recipient obtained."
              }
            />
          </ListItem>
        </List>

        {/* Deletion Request Rights */}
        <Box mt={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#333",
              borderBottom: "4px solid #C3282F",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              paddingBottom: "4px",
              marginBottom: "16px",
            }}
          >
            Deletion Request Rights
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              color: "#555",
              marginBottom: "20px",
              maxWidth: "800px",
            }}
          >
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            consumer request, we will delete (and direct our service providers
            to delete) your personal information from our records, unless an
            exception applies.
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: "16px", color: "#555", marginBottom: "10px" }}
          >
            We may deny your deletion request if retaining the information is
            necessary for us or our service providers to:
          </Typography>

          {/* List of Exceptions */}
          <List>
            <ListItem>
              <ListItemText primary="Complete the transaction for which we collected the personal information, provide a service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Debug the Services or other products to identify and repair errors that impair existing intended functionality." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Comply with any applicable law." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Comply with a legal obligation." />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Make other internal and lawful uses of that information that are compatible with the context in which you provided it." />
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* Right to Request a Correction  */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Right to Request a Correction
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginBottom: "16px",
          }}
        >
          The right to request that we correct inaccurate personal information
          can be made directly to us at
          <Link
            style={{ color: "#C3282F" }}
            href="mailto:admin@trailer4rent.ca"
          >
            admin@trailer4rent.ca
          </Link>
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Exercising Access, Data Portability, and Deletion Rights
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginBottom: "16px",
          }}
        >
          To exercise the access, data portability, and deletion rights
          described above, please submit a verifiable consumer request to us
          through our Site. <br /> <br />
          Only you or a person registered with the province where you reside
          that you authorize to act on your behalf, may make a verifiable
          consumer request related to your personal information. You may also
          make a verifiable consumer request on behalf of your minor child.{" "}
          <br /> <br />
          You may only make a verifiable consumer request for access or data
          portability twice within a 12- month period. The verifiable consumer
          request must:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it." />
          </ListItem>
          <Divider />
        </List>

        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginTop: "16px",
          }}
        >
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginTop: "16px",
          }}
        >
          Making a verifiable consumer request does not require you to create an
          account with us. We will only use personal information provided in a
          verifiable consumer request to verify the requestor's identity or
          authority to make the request.
        </Typography>
      </Box>

      {/* Non-Discrimination  */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Non-Discrimination
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginBottom: "16px",
          }}
        >
          We will not discriminate against you for exercising any of your
          rights. Unless permitted by the applicable law, we will not:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Deny you services." />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Charge you different prices or rates for services, including through granting discounts or other benefits, or imposing penalties." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Provide you a different level or quality of service." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Suggest that you may receive a different price or rate for services or a different level or quality of goods or services." />
          </ListItem>
          <Divider />
        </List>

        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.6)",
            marginTop: "16px",
          }}
        >
          All trailers listed can be reserved online 24 hours a day / 7 days a
          week. Reservations cannot be made by phone.
        </Typography>
      </Box>

      {/* Helpful links  */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Helpful links
        </Typography>
        <br />
        <Link
          href="/about-us"
          style={{
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            textDecorationLine: "underline",
            textDecorationColor: "#C3282F",
          }}
        >
          About
        </Link>
        <br />
        <Link
          href="#"
          style={{
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            textDecorationLine: "underline",
            textDecorationColor: "#C3282F",
          }}
        >
          Blog
        </Link>
        <br />
        <Link
          href="/contact-us"
          style={{
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            textDecorationLine: "underline",
            textDecorationColor: "#C3282F",
          }}
        >
          Contact us
        </Link>
      </Box>

      {/*Contacts info */}
      <Box mb={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#000",
            fontFamily: "Book Antiqua, serif !important",
            borderBottom: "4px solid #C3282F",
            display: "inline-block",
            paddingBottom: "4px",
          }}
        >
          Contacts Info
        </Typography>
        {/* <List>
                        <ListItem>
                            <ListItemText primary="Monitored General Mail : " />
                            <Link href="mainto">support@trailer4rent.ca</Link>
                        </ListItem>
                        <Divider />
                    </List> */}
        <Stack spacing={2} divider={<Divider flexItem />}>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Monitored General Mail:{" "}
            <Link
              style={{ color: `#C3282F`, textDecorationColor: "#C3282F" }}
              href="mailto:support@trailer4rent.ca"
            >
              support@trailer4rent.ca
            </Link>
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default YourPreferences;
