import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  ThemeProvider,
  Switch,
  createTheme,
  Pagination,
} from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { update_bank_account_status_async } from "./../../services/bank_mange_service";
import { useDispatch, useSelector } from "react-redux";
import formatApiDate from "../../utils/timeFormatter";

const BankDetailsTable = ({ data }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { update_bank_account_status_status, update_bank_account_status_data } =
    useSelector((state) => state.bankManage);
  // const [isActiveAccount, setIsActiveAccount] = useState(false);
  const [accountStatus, setAccountStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Pagination current page
  const rowsPerPage = 5; // Number of rows per page

  useEffect(() => {
    // Initialize accountStatus state with current data statuses if data exists
    if (data) {
      const initialStatus = data?.reduce((acc, row) => {
        if (row) {
          acc[row?._id] = row?.is_active === "Active";
        }
        return acc;
      }, {});
      setAccountStatus(initialStatus);
    }
  }, [data]);

  const _handleTrailerStatusChange = (event, bankId) => {
    const newStatus = event.target.checked ? "Active" : "Not-Active";
    setAccountStatus((prevStatus) => ({
      ...prevStatus,
      [bankId]: event.target.checked,
    }));

    dispatch(
      update_bank_account_status_async({
        bank_id: bankId,
        is_active: newStatus,
      })
    );
  };

  const theme = createTheme({
    palette: {
      error: {
        main: "#b71c1c", // Red color for 'error'
      },
    },
  });

  // Paginated data
  const paginatedData = data?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
          overflowX: "auto",
          maxWidth: "100%",
          display: "block",
        }}
      >
        <Table aria-label="booking table" sx={{ minWidth: 1100 }}>
          <TableHead
            sx={{
              background: "#FCFDFD",
            }}
          >
            <TableRow>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Account Details
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Registration Date
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Routing Number
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((row) => {
              return (
                <TableRow key={row?.id}>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {row?.account_number}
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {formatApiDate(row?.updatedAt || "")}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <Stack
                      sx={{
                        // width: 110,
                        borderRadius: 2,
                        color: row.status === "Active" ? "#009983" : "#878787",
                        fontSize: "16px",
                        fontWeight: "700",
                        backgroundColor:
                          row.status === "Active"
                            ? "rgb(204 240 235)"
                            : "rgb(233 233 233)",
                        p: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {row?.routing_number}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Stack
                        style={{
                          color: "rgba(188, 188, 188, 1)",
                          fontSize: "25px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Switch
                            color="error"
                            checked={accountStatus[row?._id] || false}
                            onChange={(event) =>
                              accountStatus[row._id]
                                ? {}
                                : _handleTrailerStatusChange(event, row?._id)
                            }
                          />
                        </ThemeProvider>
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Stack direction="row" justifyContent="center" alignItems="center" mt={2}>
        <Pagination
          count={Math.ceil(data?.length / rowsPerPage)} // Calculate total pages
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#b71c1c", // Match the 'error' color
              color: "white",
            },
            "& .MuiPaginationItem-root": {
              "&:hover": {
                backgroundColor: "#b71c1c",
                color: "white",
              },
            },
          }}
        />
      </Stack>
    </div>
  );
};

export default BankDetailsTable;
