import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";



const AllTrailersTable = ({ trailers }) => {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const rowsPerPage = 10; // Number of rows per page
  const navigate = useNavigate();

  // Get paginated data
  const paginatedTrailers = trailers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const _handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
          overflowX: "auto",
          maxWidth: "100%",
          display: "block",
        }}
      >
        <Table aria-label="booking table" sx={{ minWidth: 1100 }}>
          <TableHead
            sx={{
              background: "#FCFDFD",
            }}
          >
            <TableRow>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Trailer ID
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Trailer Name
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Available Status
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Approval Status
              </TableCell>
              <TableCell
                style={{
                  color: "#202224",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTrailers?.map((row) => (
              <TableRow key={row._id}>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {row?.trailer_id}
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {row?.title}
                </TableCell>

                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  ${row?.monthly_rate}
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <Stack
                    sx={{
                      width: 110,
                      borderRadius: 2,
                      color:
                        row?.status === "On Rent"
                          ? "#00B69B"
                          : row?.status === "Upcoming"
                            ? "#FFA756"
                            : row?.status === "Offline"
                              ? "#848484"
                              : "#848484",
                      fontSize: "16px",
                      fontWeight: "700",
                      backgroundColor:
                        row?.status === "On Rent"
                          ? "rgb(204 240 235)"
                          : row?.status === "Available"
                            ? "rgb(255 237 221)"
                            : row?.status === "Offline"
                              ? "rgb(232 232 232)"
                              : "rgb(232 232 232)",
                      p: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {row?.status}
                  </Stack>
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <Stack
                    sx={{
                      width: 110,
                      borderRadius: 2,
                      color:
                        row?.approve_status === "Approved"
                          ? "#00B69B"
                          : row?.approve_status === "Rejected"
                            ? themeRed
                            : row?.approve_status === "Pending"
                              ? "#848484"
                              : "#848484",
                      fontSize: "16px",
                      fontWeight: "700",
                      backgroundColor:
                        row?.approve_status === "Approved"
                          ? "rgb(204 240 235)"
                          : row?.approve_status === "Rejected"
                            ? "rgb(247, 230, 231)"
                            : row?.approve_status === "Pending"
                              ? "rgb(232 232 232)"
                              : "rgb(232 232 232)",
                      p: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {row?.approve_status}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={2}
                      sx={{ cursor: "pointer" }}

                    >
                      <Stack onClick={() =>
                        navigate(`/trailer-details/${row?.trailer_id}`)
                      }>
                        <img
                          src={require("../../assets/background/eye.png")}
                          alt="View"
                        />
                      </Stack>
                      <Stack onClick={() => navigate("/trailer-add", {
                        state: row
                      })}>
                        <FaEdit size={20} color={themeRed} />
                      </Stack>
                      {/* <Stack
                        style={{
                          color: themeRed,
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        View
                      </Stack> */}
                    </Stack>

                    {/* <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={1}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/trailer-details/${row?.trailer_id}`)
                      }
                    >
                      <Stack>
                        <FaEdit size={20} color={themeRed} />
                      </Stack>
                      <Stack
                        style={{
                          color: themeRed,
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Edit
                      </Stack>
                    </Stack> */}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Stack direction="row" justifyContent="center" alignItems="center" mt={2}>
        <Pagination
          count={Math.ceil(trailers.length / rowsPerPage)} // Calculate total pages
          page={currentPage}
          onChange={_handlePageChange}
          color="primary"
          sx={{
            "& .Mui-selected": {
              backgroundColor: themeRed,
              color: "white",
            },
            "& .MuiPaginationItem-root": {
              "&:hover": {
                backgroundColor: themeRed,
                color: "white",
              },
            },
          }}
        />
      </Stack>
    </div>
  );
};

export default AllTrailersTable;
