import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryAsync } from "../../services/trailers_details_service";

const HistoryUser = () => {
  const dispatch = useDispatch();
  const { get_user_history_data } = useSelector(
    (state) => state.trailersDetailsManage
  );
  const [page, setPage] = useState(0);
  const rowsPerPage = 9; // Number of rows per page

  useEffect(() => {
    dispatch(getHistoryAsync());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div style={{ padding: 20 }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  S.No
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  Host Name
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  Trailer Name
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  Duration
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  Per Day Price
                </TableCell>
                <TableCell
                  style={{
                    color: "#202224",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {get_user_history_data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>
                      {row.host_id?.first_name} {row.host_id?.last_name}
                    </TableCell>
                    <TableCell>{row.trailer_id?.title}</TableCell>
                    <TableCell>
                      {new Date(row.start_date).toLocaleDateString()} -{" "}
                      {new Date(row.end_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      ${row.trailer_id?.daily_rate || "N/A"}/day
                    </TableCell>
                    <TableCell>${row.booking_amount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  count={get_user_history_data?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Showing ${from}-${to} of ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default HistoryUser;
