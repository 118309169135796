import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderShadeImage from "../../component/HeaderShadeImage/HeaderShadeImage";
import InputField from "../../component/InputField/InputField";
import Reviews from "../../component/Reviews/Reviews";
import CustomButton from "../../component/common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardUserSide = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.userAuth);
  const [editProfile, setEditProfile] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleCheckboxClick = (value) => {
    setSelected(value);
  };

  const handleRedirect = () => {
    const redirectData = JSON.parse(sessionStorage.getItem("redirectPath"));
   
    if (redirectData?.path) {
      navigate(redirectData.path, { state: redirectData.state });
      sessionStorage.removeItem("redirectPath");
    }
  };

  useEffect(() => {
    handleRedirect();
  }, []);

  const handleEditHandle = () => {
    setEditProfile(true);
   
  };
  return (
    <Stack bgcolor={"#FAFAFA"} margin={0} padding={0}>
      <HeaderShadeImage
        // btnLabel={"Edit Profile"}
        rateNumber={" 5.0"}
        onClick={handleEditHandle}
      />
      <Stack ml={7} mt={2}>
        {!editProfile && (
          <Grid container spacing={2} alignItems={"self-start"}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <InputField
                    placeholder={`${user?.first_name} ${user?.last_name}`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <InputField placeholder={user?.email} />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <InputField placeholder={user?.phonenumber} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              marginTop={{ xl: -5, lg: -5, md: -5, sm: 1, xs: 1 }}
            >
              <Reviews />
            </Grid>
          </Grid>
        )}
        {editProfile && (
          <Stack>
            <Stack className="contact-information">CONTACT INFORMATION</Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField
                  disabled={false}
                  placeholder="johnwick123@gmail.com"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField
                  disabled={false}
                  placeholder="johnwick123@gmail.com"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField disabled={false} placeholder="+1 242 424 2245" />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputField
                  disabled={false}
                  isSecure={true}
                  placeholder="***************"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Stack>
                  <Stack className="contact-information">
                    MOBILE NOTIFICATIONS
                  </Stack>
                  <div
                    className="checkbox-item"
                    onClick={() => handleCheckboxClick("textMessages")}
                  >
                    <div
                      className={`checkbox ${
                        selected === "textMessages" ? "checked" : ""
                      }`}
                    ></div>
                    <label className="check-box-label">
                      Enable text message notifications
                    </label>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Stack>
                  <Stack className="contact-information">
                    EMAIL NOTIFICATIONS
                  </Stack>
                  <div
                    className="checkbox-item"
                    onClick={() => handleCheckboxClick("promotions")}
                  >
                    <div
                      className={`checkbox ${
                        selected === "promotions" ? "checked" : ""
                      }`}
                    ></div>
                    <label className="check-box-label">
                      Promotions and announcements
                    </label>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5} mt={2}>
                <Stack>
                  <CustomButton
                    onClick={() => setEditProfile(false)}
                    style={{
                      backgroundColor: themeRed,
                      color: "white",
                    }}
                    children={"Update Profile"}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DashboardUserSide;
