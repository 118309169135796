import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  add_bank_account,
  add_trailer,
  edit_trailer,
  get_bank_account,
  get_host_finance_details,
  get_trailer_by_id,
  update_bank_account_status_async,
} from "../../services/bank_mange_service";

const initialState = {
  // ADD BANK
  add_bank_account_status: asyncStatus.IDLE,
  add_bank_account_data: null,
  add_bank_account_error: null,

  // GET BANK
  get_bank_account_status: asyncStatus.IDLE,
  get_bank_account_data: null,
  get_bank_account_error: null,

  // add_trailer
  add_trailer_status: asyncStatus.IDLE,
  add_trailer_data: null,
  add_trailer_error: null,

  // add_trailer
  edit_trailer_status: asyncStatus.IDLE,
  edit_trailer_data: null,
  edit_trailer_error: null,

  // get_trailer_by_id
  get_trailer_by_id_status: asyncStatus.IDLE,
  get_trailer_by_id_data: null,
  get_trailer_by_id_error: null,

  // get_trailer_by_id
  update_bank_account_status_status: asyncStatus.IDLE,
  update_bank_account_status_data: null,
  update_bank_account_status_error: null,

  // get_trailer_by_id
  get_my_finance_status: asyncStatus.IDLE,
  get_my_finance_data: null,
  get_my_finance_error: null,
};

const bank_manage_slice = createSlice({
  name: "bankManage",
  initialState,
  reducers: {
    setAddBankStatus(state) {
      state.add_bank_account_status = asyncStatus.IDLE;
    },
    setAddTrailerStatus(state) {
      state.add_trailer_status = asyncStatus.IDLE;
    },
    setEditTrailerStatus(state) {
      state.edit_trailer_status = asyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    // BANK ADD =========>>>>>>>>>>>
    builder.addCase(add_bank_account.pending, (state, action) => {
      state.add_bank_account_status = asyncStatus.LOADING;
    });

    builder.addCase(add_bank_account.fulfilled, (state, { payload }) => {
      state.add_bank_account_status = asyncStatus.SUCCEEDED;
      success_toast_message("Added Successfully");
      state.add_bank_account_data = payload;
      state.add_bank_account_error = null;
    });

    builder.addCase(add_bank_account.rejected, (state, action) => {
      state.add_bank_account_status = asyncStatus.ERROR;
      state.add_bank_account_error = action.error;
      error_toast_message(action.error.message);
    });

    // BANK GET =========>>>>>>>>>>>
    builder.addCase(get_bank_account.pending, (state, action) => {
      state.get_bank_account_status = asyncStatus.LOADING;
    });

    builder.addCase(get_bank_account.fulfilled, (state, { payload }) => {
      state.get_bank_account_status = asyncStatus.SUCCEEDED;
      //   success_toast_message("Added Successfully");
      state.get_bank_account_data = payload;
      state.get_bank_account_error = null;
    });

    builder.addCase(get_bank_account.rejected, (state, action) => {
      state.get_bank_account_status = asyncStatus.ERROR;
      state.get_bank_account_error = action.error;
      error_toast_message(action.error.message);
    });

    // TRAILER ADD =========>>>>>>>>>>>
    builder.addCase(add_trailer.pending, (state, action) => {
      state.add_trailer_status = asyncStatus.LOADING;
    });

    builder.addCase(add_trailer.fulfilled, (state, { payload }) => {
      state.add_trailer_status = asyncStatus.SUCCEEDED;
      state.add_trailer_data = payload;
      state.add_trailer_error = null;
      success_toast_message("Trailer Added Successfully");
    });

    builder.addCase(add_trailer.rejected, (state, action) => {
      state.add_trailer_status = asyncStatus.ERROR;
      state.add_trailer_error = action.error;
      error_toast_message(action.error.message);
    });

    // TRAILER Edit =========>>>>>>>>>>>
    builder.addCase(edit_trailer.pending, (state, action) => {
      state.edit_trailer_status = asyncStatus.LOADING;
    });

    builder.addCase(edit_trailer.fulfilled, (state, { payload }) => {
      state.edit_trailer_status = asyncStatus.SUCCEEDED;
      state.edit_trailer_data = payload;
      state.edit_trailer_error = null;
      success_toast_message("Trailer Update Successfully");
    });

    builder.addCase(edit_trailer.rejected, (state, action) => {
      state.edit_trailer_status = asyncStatus.ERROR;
      state.edit_trailer_error = action.error;
      error_toast_message(action.error.message);
    });

    // TRAILER BY ID =========>>>>>>>>>>>
    builder.addCase(get_trailer_by_id.pending, (state, action) => {
      state.get_trailer_by_id_status = asyncStatus.LOADING;
    });

    builder.addCase(get_trailer_by_id.fulfilled, (state, { payload }) => {
      state.get_trailer_by_id_status = asyncStatus.SUCCEEDED;
      // success_toast_message("Trailer Added Successfully");
      state.get_trailer_by_id_data = payload;
      state.get_trailer_by_id_error = null;
    });

    builder.addCase(get_trailer_by_id.rejected, (state, action) => {
      state.get_trailer_by_id_status = asyncStatus.ERROR;
      state.get_trailer_by_id_error = action.error;
      error_toast_message(action.error.message);
    });

    // BANK ACCOUNT STATUS UPDATE =========>>>>>>>>>>>
    builder.addCase(
      update_bank_account_status_async.pending,
      (state, action) => {
        state.update_bank_account_status_status = asyncStatus.LOADING;
      }
    );

    builder.addCase(
      update_bank_account_status_async.fulfilled,
      (state, { payload }) => {
        state.update_bank_account_status_status = asyncStatus.SUCCEEDED;
        state.update_bank_account_status_data = payload;
        state.update_bank_account_status_error = null;
        if (Array.isArray(payload.banks)) {
          state.get_bank_account_data = payload.banks;
        }
        success_toast_message(payload.message);
      }
    );

    builder.addCase(
      update_bank_account_status_async.rejected,
      (state, action) => {
        state.update_bank_account_status_status = asyncStatus.ERROR;
        state.update_bank_account_status_error = action.error;
        error_toast_message(action.error.message);
      }
    );

    // GET MY FINANCE REPORT  =========>>>>>>>>>>>
    builder.addCase(get_host_finance_details.pending, (state, action) => {
      state.get_my_finance_status = asyncStatus.LOADING;
    });

    builder.addCase(get_host_finance_details.fulfilled, (state, { payload }) => {
      state.get_my_finance_status = asyncStatus.SUCCEEDED;
      state.get_my_finance_data = payload.data;
      state.get_my_finance_error = null;
    });

    builder.addCase(get_host_finance_details.rejected, (state, action) => {
      state.get_my_finance_status = asyncStatus.ERROR;
      state.get_my_finance_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const { setAddBankStatus, setAddTrailerStatus, setEditTrailerStatus } =
  bank_manage_slice.actions;

export default bank_manage_slice.reducer;
