import React from "react";
import { Button, Grid, TextField, Typography, Paper, Box, Stack } from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import CustomButton from "../../component/common/Button/Button";

const ReferralPage = () => {
    const referralCode = "Agh745dsh";
    const signups = 15; // Example signup count

    const handleCopyCode = () => {
        navigator.clipboard.writeText(referralCode);
        alert("Referral code copied!");
    };

    return (
        <Box sx={{ padding: 4 }}>
            {/* Header */}
            <Typography variant="h4" gutterBottom fontWeight={"700"} sx={{ fontSize: { xs: "26px", sm: "26px", md: "30px", lg: "30px", xl: "30px" }, }}>
                Get <span style={{ color: themeRed }}>10% Off</span> for every signed up 5 friends
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>
                Invite your friends to <a style={{ color: themeRed, textDecoration: "none" }} href="https://trailer4share.com">Trailer4share.com</a>. When they sign up, you will get 10% Off!
            </Typography>

            {/* Main Content */}
            <Grid container spacing={4}>
                {/* Left Section */}
                <Grid item xs={12} md={8}>
                    {/* Referral Code Section */}
                    <Paper elevation={3} sx={{ padding: 3, marginBottom: 3, borderRadius: "10px" }}>
                        <Typography mt={1.5} variant="h6" fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px", lg: "24px", xl: "24px" }, }}>Share your referral code</Typography>
                        <Typography variant="body2" color={"#808080"} sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px", xl: "16px" }, }} gutterBottom>
                            You can also share your referral code by copying and sending it or sharing it on your social media
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid my={1.5} item xs={8}>
                                <TextField fullWidth value={referralCode} variant="outlined" disabled />
                            </Grid>
                            <Grid item xs={2}>

                                <CustomButton
                                    onClick={handleCopyCode}
                                    style={{
                                        backgroundColor: "#F0D4D5",
                                        color: themeRed,
                                    }} children={"Copy Code"} />
                            </Grid>
                            <Grid item xs={2}>
                                <CustomButton
                                    onClick={handleCopyCode}
                                    style={{
                                        backgroundColor: themeRed,
                                        color: "white",
                                    }} children={"Share"} />
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* Invite with Email Section */}
                    <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                        <Typography mt={1.5} variant="h6" fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px", lg: "24px", xl: "24px" }, }}>Invite with email</Typography>
                        <Typography variant="body2" color={"#808080"} sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px", xl: "16px" }, }} gutterBottom>
                            Insert your friend addresses and send them invitations to join trailer4share.com
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid my={1.5} item xs={9}>
                                <TextField fullWidth placeholder="Email address" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <CustomButton
                                    onClick={handleCopyCode}
                                    style={{
                                        backgroundColor: themeRed,
                                        color: "white",
                                    }} children={" Send Invites"} />
                            </Grid>
                        </Grid>
                    </Paper>

                    {/* How it Works Section */}
                    <Box sx={{ marginTop: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px", lg: "24px", xl: "24px" }, }}>
                            How it works
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Paper elevation={1} sx={{ padding: 2, textAlign: "start", bgcolor: "#F0D4D5", height: "150px" }}>
                                    <Typography color={themeRed} fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }} variant="h6">1</Typography>
                                    <Typography color={themeRed} variant="body1" fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>Send Invites</Typography>
                                    <Typography color={themeRed} variant="body2" sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px", xl: "16px" }, }}>
                                        Send your referral code to friends and tell them how cool trailer4share is!
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper elevation={1} sx={{ padding: 2, textAlign: "start", bgcolor: "#F0D4D5", height: "150px" }}>
                                    <Typography color={themeRed} fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }} variant="h6">2</Typography>
                                    <Typography color={themeRed} variant="body1" fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>Sign Up</Typography>
                                    <Typography color={themeRed} variant="body2" sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px", xl: "16px" }, }}>
                                        Let them sign up to our services using your referral code.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper elevation={1} sx={{ padding: 2, textAlign: "start", bgcolor: "#F0D4D5", height: "150px" }}>
                                    <Typography color={themeRed} fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }} variant="h6">3</Typography>
                                    <Typography color={themeRed} variant="body1" fontWeight={"700"} sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>Get your reward</Typography>
                                    <Typography color={themeRed} variant="body2" sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px", xl: "16px" }, }}>
                                        You get 10% off your next booking.
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                {/* Right Sidebar Section */}
                <Grid item xs={12} md={4}>
                    <Stack>
                        <Stack sx={{ color: "white", textAlign: "center", borderRadius: "20px 20px 0px 0px",background: "var(--REd, linear-gradient(180deg, #BF2E35 0%, #88030A 100%))" }}>
                            <Typography my={2} variant="h6" sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>Your referral stats</Typography>
                        </Stack>
                        <Stack sx={{ backgroundColor: "#EFEFEF", color: "white", textAlign: "center", }} direction={"row"} alignItems={"center"} justifyContent={"space-between"} px={10}>
                            <Typography my={2} color={"black"} variant="body1" sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>Successful sign ups</Typography>
                            <Typography color={themeRed} variant="h4" sx={{ fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }, }}>{signups}</Typography>
                        </Stack>
                    </Stack>

                </Grid>
            </Grid>
        </Box>
    );
};

export default ReferralPage;
