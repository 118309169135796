import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Grid,
  Typography,
  CircularProgress,
  Pagination,
} from "@mui/material";
import "./style.css";
import Tabs from "../../component/Tabs/Tabs";
import { themeRed } from "../../utils/colorTheme";
import SearchComponent from "../../component/SearchComponent/SearchComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHostMyBookingsAsync } from "../../services/trailers_details_service";
import { asyncStatus } from "../../utils/asyncStatus";

const bookingsData = [
  {
    id: 1546,
    trailerName: "Car Hauler Trailer",
    renter: "Christine Brooks",
    duration: "01 Aug - 04 Aug",
    amount: "$70/day",
    status: "On Rent",
  },
  {
    id: 1645,
    trailerName: "Car Hauler Trailer",
    renter: "Rosie Pearson",
    duration: "05 Aug - 08 Aug",
    amount: "$80/day",
    status: "Upcoming",
  },
  {
    id: 9465,
    trailerName: "Car Hauler Trailer",
    renter: "Darnell Caldwell",
    duration: "09 Aug - 12 Aug",
    amount: "$100/day",
    status: "Completed",
  },
  {
    id: 6184,
    trailerName: "Car Hauler Trailer",
    renter: "Gilbert Johnston",
    duration: "13 Aug - 16 Aug",
    amount: "$90/day",
    status: "On Rent",
  },
  {
    id: 2615,
    trailerName: "Car Hauler Trailer",
    renter: "Alan Cain",
    duration: "17 Aug - 19 Aug",
    amount: "$150/day",
    status: "Upcoming",
  },
  {
    id: 8944,
    trailerName: "Car Hauler Trailer",
    renter: "Alfred Murray",
    duration: "20 Aug - 22 Aug",
    amount: "$125/day",
    status: "Completed",
  },
  {
    id: 4195,
    trailerName: "Car Hauler Trailer",
    renter: "Maggie Sullivan",
    duration: "23 Aug - 27 Aug",
    amount: "$90/day",
    status: "On Rent",
  },
  {
    id: 5615,
    trailerName: "Car Hauler Trailer",
    renter: "Rosie Todd",
    duration: "23 Aug - 27 Aug",
    amount: "$140/day",
    status: "Upcoming",
  },
  {
    id: 1456,
    trailerName: "Car Hauler Trailer",
    renter: "Dollie Hines",
    duration: "23 Aug - 27 Aug",
    amount: "$70/day",
    status: "Completed",
  },
];

const trailers = ["All Trailer", "Car Hauler Trailer", "Car Trailer"];

const tabs = [
  { label: "All", value: "all", radius: "13", border: 1 },
  { label: "Current Booking", value: "currentBooking", border: 1 },
  { label: "Confirmed Booking", value: "upcomingBooking", border: 1 },
  { label: "Past Booking", value: "pastBooking", radius: "13" },
];

const bookingTypes = ["All Types", "Daily", "Weekly", "Monthly"];

const MyBooking = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [selectedTrailer, setSelectedTrailer] = useState("All Trailer");
  const [selectedBookingType, setSelectedBookingType] = useState("All Types");
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [searchText, setSearchText] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5); // Number of rows per page

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_host_my_bookings_data, get_host_my_bookings_status } =
    useSelector((state) => state.trailersDetailsManage);

  const trailerDetailsLaoder =
    get_host_my_bookings_status === asyncStatus.LOADING;

  const filterBookings = () => {
    return get_host_my_bookings_data?.bookings?.filter((booking) => {
      const matchesTab =
        activeTab === "all" ||
        (activeTab === "currentBooking" && booking?.status === "In-Process") ||
        (activeTab === "upcomingBooking" && booking?.status === "Confirmed") ||
        (activeTab === "pastBooking" &&
          (booking?.status === "Completed" || booking?.status === "Rejected"));

      const matchesTrailer =
        selectedTrailer === "All Trailer" ||
        booking?.trailerName === selectedTrailer;

      const matchesBookingType =
        selectedBookingType === "All Types" ||
        booking?.booking_type === selectedBookingType;

      // const matchesDateRange =
      //   (!dateRange?.from ||
      //     new Date(booking?.duration?.split(" - ")[0]) >=
      //       new Date(dateRange.from)) &&
      //   (!dateRange.to ||
      //     new Date(booking?.duration?.split(" - ")[1]) <=
      //       new Date(dateRange?.to));
      const matchesDateRange =
        (!dateRange?.from ||
          new Date(booking?.start_date) >= new Date(dateRange.from)) &&
        (!dateRange?.to ||
          new Date(booking?.end_date) <= new Date(dateRange.to));

      const matchesSearchText =
        !searchText ||
        booking?.trailer_id?.title
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        new Date(booking?.start_date)
          .toISOString()
          .split("T")[0]
          .includes(searchText) ||
        new Date(booking?.end_date)
          .toISOString()
          .split("T")[0]
          .includes(searchText);

      return (
        matchesTab &&
        matchesTrailer &&
        matchesBookingType &&
        matchesDateRange &&
        matchesSearchText
      );
    });
  };

  const filteredBookings = filterBookings();

  // Paginate Data
  const paginatedBookings = filteredBookings?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  useEffect(() => {
    dispatch(getHostMyBookingsAsync());
  }, []);

  const _changeDateFormate = (formateDate) => {
    const date = new Date(formateDate);

    const options = {
      weekday: "short", // "Thu"
      month: "short", // "Oct"
      day: "numeric", // "31"
      year: "numeric", // "2024"
      hour: "numeric", // "12"
      minute: "2-digit", // "00"
      hour12: true, // AM/PM format
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const _handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div style={{ padding: 20 }}>
      {/* Tabs */}
      <Grid container mt={5}>
        <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
          <div>
            <Tabs
              tabs={tabs}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
        </Grid>
      </Grid>

      {/* Filters */}
      <Grid container mt={5} mb={2} spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Stack width={"100%"}>
            <span className="my-booking-labels">Search</span>
            <SearchComponent
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Stack>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Stack>
            <span className="my-booking-labels">Select Booking Type</span>
            <select
              // style={{
              //   "& ::placeholder": {
              //     color: "black",
              //   },
              //   borderRadius: "12px",
              //   height: "45px",
              //   backgroundColor: "white",
              //   width: "100%",
              //   border: " 0.989px solid #D8D8D8",
              //   color: "#BDBDBD",
              //   fontSize: "16px",
              //   fontStyle: "normal",
              //   fontWeight: "400",
              //   padding: "0px 9px",
              // }}
              // value={selectedBookingType}
              // onChange={(e) => setSelectedBookingType(e.target.value)}
              style={{
                appearance: "none", // Remove default browser styling
                borderRadius: "12px",
                height: "45px",
                // backgroundColor: "#F9F9F9",
                width: "100%",
                border: "1px solid #D8D8D8",
                color: "#333", // Better contrast for text
                fontSize: "16px",
                fontWeight: "500",
                padding: "0px 12px",
                cursor: "pointer",
                outline: "none",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
              onFocus={(e) => {
                e.target.style.borderColor = themeRed;
                e.target.style.boxShadow = `0 0 5px ${themeRed}`;
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#D8D8D8";
                e.target.style.boxShadow = "none";
              }}
              value={selectedBookingType}
              onChange={(e) => setSelectedBookingType(e.target.value)}
            >
              {bookingTypes.map((type) => (
                <option
                  key={type}
                  value={type}
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#333",
                    backgroundColor: "#FFF",
                  }}
                >
                  {type}
                </option>
              ))}
            </select>
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Stack>
            <span className="my-booking-labels">
              Select by Booking Duration
            </span>
            <div className="filters-container">
              <div className="date-range-filter">
                <input
                  type="date"
                  value={dateRange.from}
                  onChange={(e) =>
                    setDateRange({ ...dateRange, from: e.target.value })
                  }
                  style={{
                    "& ::placeholder": {
                      color: "black",
                    },
                    borderRadius: "12px",
                    height: "45px",
                    backgroundColor: "white",
                    width: "100%",
                    border: " 0.989px solid #D8D8D8",
                    color: "#BDBDBD",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                />
                <input
                  type="date"
                  value={dateRange.to}
                  onChange={(e) =>
                    setDateRange({ ...dateRange, to: e.target.value })
                  }
                  style={{
                    "& ::placeholder": {
                      color: "black",
                    },
                    borderRadius: "12px",
                    height: "45px",
                    backgroundColor: "white",
                    width: "100%",
                    border: " 0.989px solid #D8D8D8",
                    color: "#BDBDBD",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                />
                <button onClick={() => filterBookings()}>Search</button>
              </div>
            </div>
          </Stack>
        </Grid>
      </Grid>

      {/* Table */}
      {trailerDetailsLaoder ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "40vh" }}
        >
          <CircularProgress size={30} sx={{ color: themeRed }} />
        </Stack>
      ) : filteredBookings?.length === 0 ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "30vh" }}
        >
          <Typography>No Data Found</Typography>
        </Stack>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 4,
              boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
              overflowX: "auto",
              maxWidth: "100%",
              display: "block",
              mb: 2,
            }}
          >
            <Table aria-label="booking table" sx={{ minWidth: 1100 }}>
              <TableHead sx={{ background: "#FCFDFD" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Booking Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Trailer Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Renter
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#202224",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedBookings?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      style={{
                        color: "#202224",
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.booking_type}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#202224",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {row?.trailer_id?.title}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#202224",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {row?.user_id?.first_name} {row?.user_id?.last_name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#202224",
                        fontSize: "16px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#202224",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        {_changeDateFormate(row?.start_date)}{" "}
                      </Typography>{" "}
                      <Typography
                        sx={{
                          color: "#202224",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        to{" "}
                      </Typography>{" "}
                     
                      <Typography
                        sx={{
                          color: "#202224",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        {_changeDateFormate(row?.end_date)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#202224",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      ${row?.booking_amount?.toLocaleString()}.00
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#202224",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      <Stack
                        sx={{
                          width: 110,
                          borderRadius: 2,
                          color:
                            row.status === "Completed"
                              ? "#00B69B"
                              : row.status === "Rejected"
                              ? themeRed
                              : row.status === "In-process"
                              ? "#FFA756"
                              : "#848484",
                          fontSize: "16px",
                          fontWeight: "700",
                          backgroundColor:
                            row.status === "Completed"
                              ? "rgb(204 240 235)"
                              : row.status === "Rejected"
                              ? "rgb(247, 230, 231)"
                              : row.status === "In-process"
                              ? "rgb(255 237 221)"
                              : "rgb(232 232 232)",
                          p: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {row.status}
                      </Stack>
                      {/* <Stack
                        sx={{
                          width: 110,
                          borderRadius: 2,
                          color:
                            row.status === "Completed"
                              ? "#00B69B"
                              : row.status === "Confirmed"
                              ? "#FFA756"
                              : "#848484",
                          fontSize: "16px",
                          fontWeight: "700",
                          backgroundColor:
                            row.status === "Completed"
                              ? "rgb(204 240 235)"
                              : row.status === "Confirmed"
                              ? "rgb(255 237 221)"
                              : "rgb(232 232 232)",
                          p: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {row.status}
                      </Stack> */}
                    </TableCell>
                    {/* <TableCell>
                                    <Stack flexDirection={"row"} alignItems={"center"} gap={1} sx={{ cursor: "pointer" }}>
                                        <Stack>
                                            <img src={require("../../assets/background/eye.png")} alt="View" />
                                        </Stack>
                                        <Stack style={{ color: "red", fontSize: "18px", fontWeight: "600" }}>
                                            View
                                        </Stack>
                                    </Stack>
                                </TableCell> */}
                    <TableCell>
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/view-booking-details/${row?._id}`)
                        }
                      >
                        <Stack>
                          <img
                            src={require("../../assets/background/eye.png")}
                            alt="View"
                          />
                        </Stack>
                        <Stack
                          style={{
                            color: themeRed,
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          View
                        </Stack>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Pagination */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Pagination
              count={Math.ceil(filteredBookings?.length / rowsPerPage)}
              page={currentPage}
              onChange={_handlePageChange}
              color="primary"
              sx={{
                "& .Mui-selected": {
                  backgroundColor: themeRed,
                  color: "white",
                },
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: themeRed,
                    color: "white",
                  },
                },
              }}
            />
          </Stack>
        </>
      )}
    </div>
  );
};

export default MyBooking;
