import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../component/navbar/Navbar';
import Footer from '../../screens/Footer/Footer';
import bannarImage from "../../assets/CategoriesImages/bannar-image.webp"
import { Stack } from '@mui/material';
import { themeRed } from '../../utils/colorTheme';
import StateGrid from '../../component/StateGrid/StateGrid';
import NewsletterSignup from '../../component/NewsletterSignup/NewsletterSignup';
import { trailerCategories } from '../HomeSections/CategorieSection/CategoriesSection';

const CategoryView = () => {
    const { name } = useParams();
    const Navigate = useNavigate()

    const category = trailerCategories.find((elem) => elem.name === name);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    console.log("category", category)

    return (
        <div style={{ backgroundColor: "white" }}>
            <Navbar />
            <Stack
                sx={{
                    position: 'relative',
                    width: '100%',
                    maxHeight: '500px',
                }}
            >
                <img
                    src={category?.image}
                    alt={category?.name}
                    style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }}
                />
                {/* Overlay */}
                <Stack
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '500px',
                        // maxHeight: '500px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity for the overlay effect
                        zIndex: 1,
                    }}
                ></Stack>
                {/* Text */}
                <Stack justifyContent={"flex-end"} alignItems={"flex-end"} mt={-10} mr={15} sx={{
                    color: 'white',
                    fontSize: 25,
                    fontWeight: "bold",
                    zIndex: 1001
                }}>{category?.name}</Stack>
            </Stack>

            <Stack mt={15} mb={3} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                <Stack sx={{
                    fontSize: 35,
                    fontWeight: 'bold',
                    color: "#000"
                }}>
                    {category?.name} at Trailer4Rent
                </Stack>
                <Stack style={{
                    maxWidth: "1224px",
                    width: "92%",
                    margin: "0 auto",
                    position: "relative",
                }}>

                    <Stack sx={{
                        fontSize: 16,
                        fontWeight: '500',
                        color: "#000",
                        mt: 2
                    }}>
                        Trailers allow you to easily transport various types of cargo, including heavy equipment, household items, recreational vehicles, and much more, by loading them onto the trailers and towing the trailer behind your truck or SUV. Instead of hiring professional moving companies or specialized transportation services, you can save a lot of money by getting the right-sized enclosed trailer rental to tow behind your vehicle. Trailer4Rent is the perfect platform to find the enclosed trailer rental for your needs.
                    </Stack>
                </Stack>
                <Stack mt={2}>
                    <Stack style={{
                        color: " #fff",
                        background: themeRed,
                        padding: "10px",
                        fontSize: "13px",
                        borderRadius: "5px",
                        textTransform: "uppercase",
                        cursor: 'pointer'

                    }} onClick={() => Navigate('/trailer-map', { state: { name: category?.name } })}>
                        {category?.name} for Rent at Trailer4Rent
                    </Stack>
                </Stack>
            </Stack>
            <Stack sx={{ backgroundColor: themeRed }} justifyContent={"center"} alignItems={"center"} mt={5}>
                <Stack py={7} style={{
                    textAlign: "center",
                    alignItems: "center"
                }}>
                    <Stack sx={{
                        fontSize: 35,
                        fontWeight: 'bold',
                        color: "#fff",

                    }}>
                        Reasons to Invest in a {category?.name} for Rent
                    </Stack>
                    <Stack sx={{
                        fontSize: 16,
                        fontWeight: '500',
                        color: "#fff",
                        mt: 2
                    }}>
                        Getting a {category?.name} for rent instead of buying one or hiring shipping services can be more practical due to several reasons, including:
                    </Stack>
                </Stack>
            </Stack>
            <Stack mt={7}>
                <StateGrid category={name} />
            </Stack>
            <Stack mt={7} className='container'>
                <NewsletterSignup />
            </Stack>
            {/*  Footer */}
            <Footer />
        </div >
    );
};

export default CategoryView;
