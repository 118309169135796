import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import { error_toast_message } from "../../utils/toast_message";

import { getHostListAsync, getUsersListAsync } from "../../services/Chat_Service";

const initialState = {
  // GET USERS LIST
  get_users_list_status: asyncStatus.IDLE,
  get_users_list_data: null,
  get_users_list_error: null,

  // GET HOST LIST
  get_hosts_list_status: asyncStatus.IDLE,
  get_hosts_list_data: null,
  get_hosts_list_error: null,
};

const chat_slice = createSlice({
  name: "chat_slice",
  initialState,
  reducers: {
    setHostChatListStatus(state) {
      state.get_hosts_list_status = asyncStatus.IDLE;
    },
    setUserChatListStatus(state) {
      state.get_users_list_status = asyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    // CHAT USERS LIST =========>>>>>>>>>>>
    builder.addCase(getUsersListAsync.pending, (state, action) => {
      state.get_users_list_status = asyncStatus.LOADING;
    });

    builder.addCase(getUsersListAsync.fulfilled, (state, { payload }) => {
      state.get_users_list_status = asyncStatus.SUCCEEDED;
      state.get_users_list_data = payload;
    });

    builder.addCase(getUsersListAsync.rejected, (state, action) => {
      state.get_users_list_status = asyncStatus.ERROR;
      state.get_users_list_error = action.error;
      error_toast_message(action.error.message);
    });

    // CHAT HOSTS LIST =========>>>>>>>>>>>
    builder.addCase(getHostListAsync.pending, (state, action) => {
      state.get_hosts_list_status = asyncStatus.LOADING;
    });

    builder.addCase(getHostListAsync.fulfilled, (state, { payload }) => {
      state.get_hosts_list_status = asyncStatus.SUCCEEDED;
      state.get_hosts_list_data = payload;
    });

    builder.addCase(getHostListAsync.rejected, (state, action) => {
      state.get_hosts_list_status = asyncStatus.ERROR;
      state.get_hosts_list_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const { setHostChatListStatus } = chat_slice.actions;

export default chat_slice.reducer;
