// import { colors, Stack } from '@mui/material'
// import React from 'react'
// import CustomButton from '../common/Button/Button'
// import { themeRed } from '../../utils/colorTheme'
// import "./HeaderShadeImage.css"
// import { useSelector } from 'react-redux'

// const HeaderShadeImage = ({ btnLabel, rateNumber, onClick, editProfile }) => {
//     const { user } = useSelector((state) => state.userAuth);

//     const dateString = user?.createdAt;
//     const dateObject = new Date(dateString);
//     const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
//     return (
//         <div>
//             <Stack>
//                 <Stack>
//                     <img src={require("../../assets/background/setting-shade.png")} alt='settings-back-shade' />
//                 </Stack>
//                 <Stack justifyContent={"flex-end"} alignItems={"flex-end"} mt={-10} mr={3} zIndex={100001}>
//                     <CustomButton
//                         onClick={onClick}
//                         children={btnLabel}
//                         style={{
//                             backgroundColor: themeRed,
//                             color: "#fff",
//                         }} />
//                 </Stack>
//                 <Stack ml={7} mt={-5}>
//                     <Stack>

//                         <img style={{ width: "125px", height: "125px", borderRadius: "50%", border: `3px solid ${themeRed}` }} src={user?.avatar} alt='profile-image' />

//                         <Stack mt={-2} ml={2} className='profile-rating' flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={1}>
//                             <Stack className='rating-number'>
//                                 {rateNumber}
//                             </Stack>
//                             <Stack>
//                                 <img src={require("../../assets/background/rating-star.png")} alt='star' />
//                             </Stack>
//                         </Stack>
//                     </Stack>
//                     <Stack className='settings-profile-name' mt={3}>
//                         {user?.first_name} {user?.last_name}

//                     </Stack>
//                     <Stack className='settings-profile-Joined' mt={1}>
//                         {formattedDate}
//                     </Stack>

//                 </Stack>
//             </Stack>
//         </div>
//     )
// }

// export default HeaderShadeImage
import { colors, Stack, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../common/Button/Button";
import { themeRed } from "../../utils/colorTheme";
import "./HeaderShadeImage.css";
import { useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { baseURL } from "../../config/apiHandle/apiHandle";
import { HiCheckBadge } from "react-icons/hi2";

const HeaderShadeImage = ({
  btnLabel,
  rateNumber,
  onClick,
  editProfile,
  ImageUrlhandle,
}) => {
  const { user } = useSelector((state) => state.userAuth);
  const [selectedImage, setSelectedImage] = useState(user?.avatar || null);
  const [uploading, setUploading] = useState(false);
  const [token, setToken] = useState(false);

  const dateString = user?.createdAt;
  const dateObject = new Date(dateString);
  const formattedDate = `${dateObject.getFullYear()}-${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${dateObject.getDate().toString().padStart(2, "0")}`;

  // Handle image upload to Cloudinary
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setUploading(true);

        const formData = new FormData();
        formData.append("binary_file", file);

        const response = await axios.post(`${baseURL}upload-files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        const imageUrl = response.data.url;
        setSelectedImage(imageUrl);
        ImageUrlhandle(imageUrl);
        setUploading(false);
      } catch (e) {
        console.error("Error cropping and uploading image:", e);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div>
      <Stack>
        <Stack>
          <img
            src={require("../../assets/background/setting-shade.png")}
            alt="settings-back-shade"
          />
        </Stack>
        {btnLabel && (
          <Stack
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            mt={-10}
            mr={3}
            zIndex={100001}
          >
            <CustomButton
              onClick={onClick}
              children={btnLabel}
              style={{
                backgroundColor: themeRed,
                color: "#fff",
              }}
            />
          </Stack>
        )}
        <Stack ml={7} mt={-5} position="relative">
          <Stack position="relative">
            {/* Profile Image */}
            <img
              style={{
                width: "125px",
                height: "125px",
                borderRadius: "50%",
                border: `3px solid ${themeRed}`,
              }}
              src={selectedImage}
              alt="profile-image"
            />

            {/* Edit Icon when editProfile is true */}
            {editProfile && (
              <Stack zIndex={100001}>
                <input
                  type="file"
                  accept="image/*"
                  id="upload-profile-image"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: themeRed,
                    borderRadius: "50%",
                    zIndex: 10001,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("upload-profile-image").click()
                  }
                >
                  <MdEdit style={{ color: "white" }} />
                </IconButton>
              </Stack>
            )}

            {/* Loading indicator */}
            {uploading && (
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginLeft: "25px",
                }}
              >
                Uploading...
              </p>
            )}

            {!editProfile && (
              <Stack
                mt={-2}
                ml={2}
                className="profile-rating"
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <Stack className="rating-number">
                  {user?.avg_rating ? user.avg_rating.toFixed(1) : "0.00"}
                </Stack>
                <Stack>
                  <img
                    src={require("../../assets/background/rating-star.png")}
                    alt="star"
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
          {!editProfile && (
            <>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                gap={3}
                my={2}
              >
                {" "}
                <Stack className="settings-profile-name" mt={3}>
                  {user?.first_name} {user?.last_name}
                </Stack>
                {user?.kyc === "Completed" && user?.status === "Approved" && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                    sx={{
                      p: "4px 8px",
                      borderRadius: "10px",
                      backgroundColor: "greenyellow",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "green",
                        fontSize: "15px",
                      }}
                    >
                      Verified
                    </Typography>
                    <HiCheckBadge style={{ color: "green" }} />
                  </Stack>
                )}
              </Stack>
              <Stack className="settings-profile-Joined" mt={1}>
                {formattedDate}
              </Stack>{" "}
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default HeaderShadeImage;
