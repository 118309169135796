import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BankDetailsTable from "../../component/BankDetailsTable/BankDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_bank_account } from "../../services/bank_mange_service";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeRed } from "../../utils/colorTheme";

const MyBankAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    get_bank_account_data,
    get_bank_account_status,
    get_bank_account_error,
    update_bank_account_status_status,
    update_bank_account_status_data,
  } = useSelector((state) => state.bankManage);
  const { user_login_status, user } = useSelector((state) => state.userAuth);

  const bankAccountStatusLoader =
    get_bank_account_status === asyncStatus.LOADING;

  useEffect(() => {
    dispatch(get_bank_account());
  }, []);

  // get_bank_account

  return (
    <Stack bgcolor={"#FAFAFA"} p={3}>
      {bankAccountStatusLoader ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "40vh" }}
        >
          <CircularProgress size={30} sx={{ color: themeRed }} />
        </Stack>
      ) : get_bank_account_data?.length === 0 ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "30vh" }}
        >
          <Typography>No Data Found</Typography>
        </Stack>
      ) : (
        <BankDetailsTable data={get_bank_account_data} />
      )}
    </Stack>
  );
};

export default MyBankAccount;
