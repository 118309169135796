import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import BarChart from "../../component/Charts/BarChart/BarChart";
import RevenueFilter from "../../component/Filters/RevenueFilter/RevenueFilter";
import RevenueCard from "../../component/common/Card/RevenueCard/RevenueCard";
import { themeRed } from "../../utils/colorTheme";
import CustomButton from "../../component/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { get_host_finance_details } from "../../services/bank_mange_service";
import FinanceTable from "../../component/FinanceTable/FinanceTable";
import { asyncStatus } from "../../utils/asyncStatus";
import styles from '../../component/FinanceTable/FinanceTable.module.css';


const MyRevenue = () => {
  const dispatch = useDispatch();

  const { get_my_finance_status, get_my_finance_data } = useSelector((state) => state.bankManage);

  useEffect(() => {
    dispatch(get_host_finance_details());
  }, [dispatch]);

  if (get_my_finance_status === asyncStatus.LOADING) {
    return <div className={styles.loading}>Loading...</div>;
  }

  if (get_my_finance_status === asyncStatus.SUCCEEDED) {
    return (
      <Stack bgcolor={"#FAFAFA"} p={3}>

        <Stack mb={2}>
          <FinanceTable get_my_finance_data={get_my_finance_data} />
        </Stack>

        {/* <Stack>
        <RevenueFilter />
      </Stack>
      <Stack>
        <BarChart />
      </Stack> */}


        <Stack>
          <Grid container spacing={4} >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
              <RevenueCard
                heading={"Monthly Earning:"}
                subHeading={"This Month"}
                price={"$2500.00"}
                child={
                  <span className='RevenueCardSubHeading'>
                    Payouts occur 2 days after your rental is marked complete. It may take up to 5-7 business days to appear on your bank statement.
                  </span>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
              <RevenueCard
                heading={"Available Funds for Withdrawal"}
                subHeading={"Withdrawals may take 3-5 business days to process and appear in your bank account."}
                price={"$2000.00"}
                child={
                  <span>
                    <CustomButton
                      style={{
                        backgroundColor: themeRed,
                        color: 'white'
                      }} children={"Payouts"} />
                  </span>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <RevenueCard
                heading={"Learn how to increase your earnings using our platform. "}
                subHeading={"Lorem ipsum dolor sit amet consectetur. Risus ultrices amet hendrerit ut quis eu. Consequat massa tristique morbi orci venenatis nisl ac. Tellus lectus vitae nunc ipsum lacus amet luctus. Dictum viverra odio ac morbi."}
                child={
                  <span>
                    <CustomButton
                      style={{
                        backgroundColor: themeRed,
                        color: 'white'
                      }} children={"Watch Video"} />
                  </span>
                }
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );

  };
};

export default MyRevenue;