import React, { useRef, useEffect, useState } from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { themeBlue } from "../../utils/colorTheme";
import { formatDate } from "./CustomFormattors";

const ChatWindow = ({ messages, avatar }) => {
  const chatWindowRef = useRef(null);
  const { user } = useSelector((state) => state.userAuth);

  const userDataArr = {
    _id: 1,
    first_name: "Martin",
    last_name: "Gyrix",
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Stack className="chat-window" gap={2}>
      {messages?.map((message, i) => {
        return (
          <Stack
            key={i}
            alignItems={
              user?._id === message?.senderId ? "flex-end" : "flex-start"
            }
            sx={{ width: "100%" }}
          >
            <Stack
              flexDirection={
                user?._id === message?.senderId ? "row-reverse" : "row"
              }
              gap={1}
              // alignItems={""}
            >
              <Stack>
                <Avatar
                  alt="Remy Sharp"
                  src={
                    user?._id === message?.senderId
                      ? user?.avatar
                      : avatar || ""
                  }
                  sx={{ width: 43, height: 43, borderRadius: "10px" }}
                />
              </Stack>
              <Stack
                sx={{
                  background:
                    user?._id === message?.senderId
                      ? "var(--REd, linear-gradient(180deg, #BF2E35 0%, #88030A 100%))"
                      : "#F1F1F1",
                  py: "8px",
                  px: "17px",
                  borderRadius: "12px",
                }}
                gap={1}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: "20px",
                    letterSpacing: "0.53px",
                    fontFamily: '"Poppins", sans-serif',
                    color: user?._id === message?.senderId ? "white" : "black",
                  }}
                >
                  {message?.content ?? ""}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 9,
                    lineHeight: "20px",
                    letterSpacing: "0.53px",
                    fontFamily: '"Poppins", sans-serif',
                    color: user?._id === message?.senderId ? "white" : "black",
                    textAlign:
                      user?._id === message?.senderId ? "start" : "end",
                  }}
                >
                  {formatDate(message?.createdAt) || ""}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
      <div ref={chatWindowRef}></div>
    </Stack>
  );
};

export default ChatWindow;
