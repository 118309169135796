import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const addTicketAsync = createAsyncThunk(
  type_constant.ADD_TICKET,
  async (tickets_data) => {
    try {
      const response = await apiHandle.post(`/host/add-ticket`, tickets_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error ADD TICKET ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getAllTicketAsync = createAsyncThunk(
  type_constant.GET_ALL_TICKET,
  async () => {
    try {
      const response = await apiHandle.get(`/host/get-tickets`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error GET ALL TICKET ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const getTicketAsync = createAsyncThunk(
  type_constant.GET_TICKET,
  async (ticket_id) => {
    try {
      const response = await apiHandle.get(`/host/view-ticket/${ticket_id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error GET TICKET ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addCommentAsync = createAsyncThunk(
  type_constant.ADD_COMMENT_TICKET,
  async (commnet_data) => {
    try {
      const response = await apiHandle.post(`/host/add-comment/`,commnet_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error ADD COMMENT ASYNC", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
