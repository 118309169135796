export const type_constant = {
  PROVIDER: "provider",
  APPLICANT: "applicant",
};

// export const url = ''

export const api_error_messages_to_exit = [
  "Token is not valid",
  "Refresh or Access Token is not valid",
  "Token is Expired",
  "Invalid token",
];

export const role_constant = {
  ADMIN: 1,
  VENDOR: 2,
  USER: 3,
};


export const session_expired = "@session_expired";

export const save_tokens_constant = "@usertokens";

export const signup_methods_constants = {
  EMAIL_PASSWORD: "email_password",
  GOOGLE: "google",
  FACEBOOK: "facebook",
  APPLE: "apple",
};