import React, { useEffect, useState } from "react";
import css from "./TermsAndCondition.module.css";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import Footer from "../../screens/Footer/Footer";
import Navbar from "../../component/navbar/Navbar";

const TermsAndCondition = () => {
  const [userType, setUserType] = useState("");
  const termsConditionsData = [
    {
      section: "Introduction",
      content:
        "Trailer4Rent Inc. is a peer-to-peer trailer rental platform owned and operated by Trailer4Rent Inc. These Terms govern your access to and use of the Trailer4Rent Inc. website, mobile applications, and other related services (collectively, the 'Services'). By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree to any part of these Terms, you must discontinue use of the platform immediately.",
    },
    {
      section: "Eligibility",
      content: [
        "To use Trailer4Rent Inc. as a Host, you must:",
        "Be at least 19 years of age.",
        "Hold a valid driver’s license.",
        "Be legally capable of entering into binding contracts.",
        "Comply with all local, provincial, and federal regulations regarding trailer rentals and meet applicable insurance requirements.",
      ],
    },
    {
      section: "Account Registration",
      content:
        "To list a trailer, you must register an account with Trailer4Rent Inc. You agree to provide accurate, complete, and up-to-date information. You are responsible for ensuring that your account information is current and that all required documentation related to your trailer is accurate and valid. You are also responsible for maintaining the confidentiality of your login credentials and for all activities conducted under your account. Trailer4Rent Inc. disclaims all liability for unauthorized access or misuse of your account by third parties.",
    },
    {
      section: "Rental Agreements",
      subSections: [
        {
          title: "Key Terms for Hosts",
          content: [
            {
              key: "Ownership and Condition",
              details:
                "You must ensure that your trailer is safe, clean, and roadworthy before each rental. Proof of ownership must be provided when listing the trailer.",
            },
            {
              key: "Insurance",
              details:
                "You are required to maintain insurance coverage for your trailer and provide proof of insurance at the time of listing. Failure to maintain current insurance will result in suspension of your listing. The deductible on your trailer insurance must not exceed $500, and the policy must include Fire, Theft, and Vandalism coverage.",
            },
            {
              key: "Condition of the Trailer",
              details:
                "You are required to inspect the trailer before each rental, document any existing damages in the presence of the Renter, and address any concerns immediately.",
            },
            {
              key: "Damage or Loss During Rental",
              details:
                "Renters are liable for any damage or loss incurred during the rental period unless otherwise agreed. As the Host, you may withhold a portion of the security deposit to cover damages, but you must submit a damage report to Trailer4Rent Inc. for review and approval prior to withholding any amount.",
            },
            {
              key: "Rental Income",
              details:
                "A flat service fee of 15% will be deducted from the rental income for each transaction, including any extensions to existing rental agreements. Hosts are responsible for reporting rental income to the Canada Revenue Agency (CRA).",
            },
          ],
        },
      ],
    },
    {
      section: "Booking and Payment Terms",
      content: [
        "Trailer4Rent Inc. processes all payments securely through our third-party payment provider.",
        "Reservation Confirmation: Upon reservation, both the Host and Renter will receive a confirmation email detailing the rental terms.",
        "Cancellations: Renters must comply with the platform's cancellation policies, which may vary depending on the Host’s settings.",
        "Payment Processing: Rental income, less applicable service fees, will be transferred to the Host after the rental period concludes. Depending on your financial institution, this may take up to five business days.",
      ],
    },
    {
      section: "Trailer Usage and Responsibilities",
      subSections: [
        {
          title: "Towing Requirements",
          details:
            "Renters must confirm in writing that their vehicle can safely tow the trailer, and you must ensure this is verified. It is strongly recommended that you include appropriate ballmount and ball rated at sufficient GVW to be used with your trailer during rental.",
        },
        {
          title: "Maintenance",
          details:
            "You are responsible for ensuring that your trailer is regularly maintained and compliant with any applicable safety and regulatory standards.",
        },
        {
          title: "Liability",
          details:
            "As the Host, you are responsible for ensuring that your trailer complies with all applicable legal and safety requirements.",
        },
      ],
    },
    {
      section: "Cancellation and Refund Policy",
      subSections: [
        {
          title: "Rentals Booked More Than 24 Hours in Advance",
          content: [
            {
              key: "",
              details: (
                <>
                  Users may cancel their rental at any time by logging into
                  their account and submitting a cancellation request.
                  Cancellations made at least{" "}
                  <span style={{ fontWeight: "600" }}> 24 hours prior </span> to
                  the scheduled trip start time are eligible for a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>
                </>
              ),
            },
            {
              key: "",
              details: (
                <>
                  If a User fails to cancel at least{" "}
                  <span style={{ fontWeight: "600" }}>24 hours before </span>{" "}
                  the trip start time or does not arrive for pick-up, the{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    full rental cost will be charged{" "}
                  </span>
                  , and no refund will be issued.
                </>
              ),
            },
            {
              key: "",
              details: (
                <>
                  Hosts must approve rental requests{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    within four (4) days or 96 hours of the request date{" "}
                  </span>{" "}
                  or{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    at least 24 hours before the trip starts,{" "}
                  </span>{" "}
                  whichever comes first. If the Host does not approve the
                  request within this timeframe, the rental will be
                  automatically canceled, and the User will receive a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>{" "}
                  Additionally, if the Host does not make trailer available for
                  pick-up, the User is entitled to a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>
                </>
              ),
            },
          ],
        },
        {
          title: "Rentals Booked Less Than 24 Hours in Advance",
          content: [
            {
              key: "",
              details: (
                <>
                  For rentals booked within{" "}
                  <span style={{ fontWeight: "600" }}> 24 hours</span> of the
                  trip start time, Users have up to{" "}
                  <span style={{ fontWeight: "600" }}> one (1) hour</span> after
                  booking to cancel and receive a{" "}
                  <span style={{ fontWeight: "600" }}> full refund</span>. If a
                  cancellation is not made within this one-hour window, the{" "}
                  <span style={{ fontWeight: "600" }}>
                    full rental cost will be charged
                  </span>
                  , and no refund will be issued.
                </>
              ),
            },
            {
              key: "",
              details: (
                <>
                  For rental requests made within{" "}
                  <span style={{ fontWeight: "600" }}> 24 hours</span> of the
                  trip start time, Hosts have up to{" "}
                  <span style={{ fontWeight: "600" }}> six (6) hours</span>{" "}
                  after the request is submitted to approve the rental. If the
                  Host does not approve the request within this timeframe, the
                  rental will be automatically canceled, and the User will
                  receive a{" "}
                  <span style={{ fontWeight: "600" }}> full refund.</span>
                </>
              ),
            },
          ],
        },
      ],

      note: (
        <>
          <div>
            <p>
              <strong>
                <u>• Host-Initiated Cancellations:</u>
              </strong>{" "}
              Hosts may cancel a reservation up to 24 hours before the rental
              start time without penalty. Cancellations made within 24 hours of
              the start time will result negatively on the Host's Profile.
              Multiple negative offenses will drop your listing down in the
              search ranks.
            </p>
            <p>
              <strong>
                <u>• Renter-Initiated Cancellations:</u>
              </strong>{" "}
              Renters may cancel up to 24 hours before the rental period begins
              for a full refund. Cancellations within 24 hours will incur a
              cancellation fee of the full rental amount payable to the Host
              (minus Trailer4Rent.ca fee).
            </p>
          </div>
        </>
      ),
    },
    {
      section: "Damage, Loss, and Insurance",
      content: [
        "Renters are responsible for any damage, loss, or theft of the trailer during the rental period.",
        "Hosts are encouraged to carry comprehensive insurance to cover potential damages or losses. Trailer4Rent Inc. may offer optional insurance products, but it is ultimately the Host’s responsibility to ensure the trailer is adequately insured.",
      ],
    },
    {
      section: "Limitation of Liability",
      content: [
        "To the maximum extent permitted by law, Trailer4Rent Inc. shall not be held liable for any:",
        "Damage or loss of property during the rental period.",
        "Injuries or accidents involving the trailer during the rental period.",
        "Legal disputes between Hosts and Renters.",
        "Trailer4Rent Inc.’s liability for any claims arising from the use of our Services will be limited to the amounts paid to Trailer4Rent Inc. in the 12 months preceding the claim.",
      ],
    },
    {
      section: "Termination",
      content:
        "Trailer4Rent Inc. reserves the right to suspend or terminate your account and access to the Services at any time, for any reason, including a breach of these Terms.",
    },
    {
      section: "Dispute Resolution",
      content:
        "In the event of a dispute between a Host and a Renter, Trailer4Rent Inc. will attempt to mediate the issue but is not liable for resolving legal disputes.",
    },
    {
      section: "Modifications",
      content:
        "Trailer4Rent Inc. reserves the right to modify these Terms at any time. Changes will take effect once the updated Terms are posted. Continued use of the platform constitutes your acceptance of any changes.",
    },
    {
      section: "Governing Law",
      content:
        "These Terms are governed by and construed in accordance with the laws of the province of British Columbia, Canada.",
    },
    {
      section: "Contact Us",
      content:
        "If you have any questions or concerns about these Terms, you can contact us at:",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const user_type = localStorage.getItem("userType");
    setUserType(user_type);
  }, []);

  const _handleOpenClick = () => {
    window.open("/" || "", "_blank"); // Opens in a new tab
  };
  return (
    <div>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={css.main_title}>
            Terms And Conditions
          </Typography>
        </Stack>
      </div>

      <Container
        sx={{
          py: 5,
        }}
        maxWidth="xl"
      >
        {/* cards */}
        {/* <<<<<<<< TERMS AND CONDITIONS >>>>>>> */}
        <Stack px={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 3 }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              fontSize: "25px",
              fontWeight: "500",
            }}
          >
            Trailer4Rent Inc. Terms and Conditions for {userType}
          </Typography>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              fontFamily: "Book Antiqua, serif !important",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            Last Updated: [09/25/24]
          </Typography>
          {/* <Stack mx={4}>
            <ol className={css.order_lst}>
              {termsConditionsData.map((section, i) => (
                <li key={i}>
                  <Typography className={css.main_heading}>
                    {section.section}
                  </Typography>

                  {Array.isArray(section.content) ? (
                    <ol className={`${css.order_lst} ${css.nested_order_lst}`}>
                      {section.content.map((item, idx) => (
                        <li key={idx} className={css.sub_data_list}>
                          <Typography
                            variant="caption"
                            className={css.light_txt}
                          >
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <Typography className={css.light_txt}>
                      {section.content}
                    </Typography>
                  )}

              
                  {section.subSections && (
                    <ol className={`${css.order_lst} ${css.nested_order_lst}`}>
                      {section.subSections.map((subSection, subIdx) => (
                        <li key={subIdx}>
                          <Typography className={css.sub_heading}>
                            {subSection.title}
                          </Typography>

                          {Array.isArray(subSection.content) ? (
                            <ol
                              className={`${css.order_lst} ${css.nested_order_lst}`}
                            >
                              {subSection.content.map((item, subItemIdx) => (
                                <li
                                  key={subItemIdx}
                                  className={css.sub_data_list}
                                >
                                  <Typography
                                    variant="caption"
                                    className={css.light_txt}
                                  >
                                    {item.key && <strong>{item.key}:</strong>}{" "}
                                    {item.details}
                                  </Typography>
                                </li>
                              ))}
                            </ol>
                          ) : (
                            <Typography className={css.light_txt}>
                              {subSection.details}
                            </Typography>
                          )}
                        </li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
            </ol>
          </Stack> */}

          <Stack mx={4}>
            <ol className={css.order_lst}>
              {termsConditionsData.map((section, i) => (
                <li key={i}>
                  <Typography
                    className={css.main_heading}
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "#000",
                      fontFamily: "Book Antiqua, serif !important",
                      borderBottom: "4px solid #C3282F",
                      paddingBottom: "4px",
                      display: "inline-block",
                    }}
                  >
                    {section.section}
                  </Typography>

                  {Array.isArray(section.content) ? (
                    <ol className={`${css.order_lst} ${css.nested_order_lst}`}>
                      {section.content.map((item, idx) => (
                        <li key={idx} className={css.sub_data_list}>
                          <Typography
                            variant="caption"
                            className={css.light_txt}
                          >
                            {item}
                          </Typography>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <Typography
                      className={css.light_txt}
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {section.content}
                    </Typography>
                  )}

                  {/* Render Note if it exists */}

                  {/* Handle Subsections */}
                  {section.subSections && (
                    <ol className={`${css.order_lst} ${css.nested_order_lst}`}>
                      {section.subSections.map((subSection, subIdx) => (
                        <li key={subIdx}>
                          <Typography
                            className={css.sub_heading}
                            sx={{
                              color: "#000",
                              fontFamily: "Book Antiqua, serif !important",
                              display: "inline-block",
                            }}
                          >
                            {subSection.title}
                          </Typography>

                          {Array.isArray(subSection.content) ? (
                            <ol
                              className={`${css.order_lst} ${css.nested_order_lst}`}
                            >
                              {subSection.content.map((item, subItemIdx) => (
                                <li
                                  key={subItemIdx}
                                  className={css.sub_data_list}
                                >
                                  <Typography
                                    variant="caption"
                                    className={css.light_txt}
                                    sx={{
                                      fontSize: "16px",
                                      marginTop: "16px",
                                    }}
                                  >
                                    {item.key && (
                                      <strong
                                        style={{
                                          fontFamily:
                                            "Book Antiqua, serif !important",
                                        }}
                                      >
                                        {item.key}:
                                      </strong>
                                    )}{" "}
                                    {item.details}
                                  </Typography>
                                </li>
                              ))}
                            </ol>
                          ) : (
                            <Typography className={css.light_txt}>
                              {subSection.details}
                            </Typography>
                          )}

                          {/* Render Note if it exists inside subSection */}
                          {subSection.note && (
                            <Typography
                              className={css.note_text}
                              sx={{
                                fontFamily: "Book Antiqua, serif !important",
                              }}
                            >
                              <strong>Note:</strong> {subSection.note}
                            </Typography>
                          )}
                        </li>
                      ))}
                    </ol>
                  )}
                  <br />
                  {section.note && (
                    <Typography
                      className={css.note_text}
                      sx={{
                        fontFamily: "Book Antiqua, serif !important",
                      }}
                    >
                      <strong>Note:</strong> {section.note}
                    </Typography>
                  )}
                </li>
              ))}
            </ol>
          </Stack>

          {/* <Stack mx={4}>
            <ol className={css.order_lst}>
              {termsConditionsData.map(({ title, description, subData }, i) => {
                return (
                  <li key={i}>
                    {title && (
                      <Typography className={css.main_heading}>
                        {title}
                      </Typography>
                    )}
                    {description && (
                      <Typography className={css.light_txt}>
                        {description}
                      </Typography>
                    )}
                    {subData.length > 0 && (
                      <ol
                        className={`${css.order_lst} ${css.nested_order_lst}`}
                      >
                        {subData.map((points, i) => {
                          return (
                            <li key={i} className={css.sub_data_list}>
                              {points && (
                                <Typography
                                  variant="caption"
                                  className={css.light_txt}
                                >
                                  {points}
                                </Typography>
                              )}
                            </li>
                          );
                        })}
                      </ol>
                    )}
                  </li>
                );
              })}
            </ol>
          </Stack> */}
        </Stack>

        <Stack px={{ xl: 14, lg: 14, md: 14, sm: 14, xs: 7 }} py={3}>
          <Typography
            variant="h5"
            // className={css.light_txt}
            sx={{
              fontWeight: "bold",
              marginBottom: "30px",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              borderBottom: "4px solid #C3282F",
              paddingBottom: "4px",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Trailer4Rent Inc.
          </Typography>
          <Typography
            variant="caption"
            className={css.light_txt}
            sx={{
              fontWeight: "bold",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Email:{" "}
            <Typography
              // onClick={_handleOpenClick}
              component="a"
              href="mailto:admin@trailer4rent.ca"
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeRed} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              admin@trailer4rent.ca
            </Typography>
          </Typography>
          <Typography
            variant="caption"
            className={css.light_txt}
            sx={{
              fontWeight: "bold",
              color: "#000",
              fontFamily: "Book Antiqua, serif !important",
              display: "inline-block",
              width: "fit-content",
            }}
          >
            Website:{" "}
            <Typography
              onClick={_handleOpenClick}
              component="a"
               variant="caption"
              // href="http://www.trailer4rent.ca"
              className={css.light_txt}
              sx={{
                color: `${themeRed} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              www.trailer4rent.ca
            </Typography>
          </Typography>
        </Stack>
      </Container>

      <Footer />
    </div>
  );
};

export default TermsAndCondition;
