import React from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
export const PrivateRoutes = () => {
    const { userAuth, user } = useSelector((state) => state.userAuth)

    return userAuth ? <Outlet /> : <Navigate to="/login" />;

}

export const Verify_Route = () => {
    const { userAuth, user } = useSelector((state) => state.userAuth)

    return userAuth && !user?.email_verified && !user?.number_verified && user?.kyc === 'Idle' ? <Outlet /> : userAuth && user?.email_verified && user?.number_verified && user?.kyc === 'Idle' ? <Navigate to='/StepOne' /> : userAuth && user?.email_verified && user?.number_verified && user?.kyc === 'Pending' || user?.kyc === 'Completed' ? <Navigate to='/dashboard-home' /> : <Navigate to='/' />
}


export const Complete_Profile_Route = () => {
    const { userAuth, user } = useSelector((state) => state.userAuth)

    return userAuth && user.email_verified && user?.number_verified && user?.kyc === 'Idle' ? <Outlet /> : userAuth && !user?.email_verified && !user?.number_verified && user?.kyc === 'Idle' ? <Navigate to='/OTPForm' /> : userAuth && user.email_verified && user?.number_verified && user?.kyc === 'Pending' || user?.kyc === 'Completed' ? <Navigate to='/dashboard-home' /> : <Navigate to='/' />

}


export const Dashboard_Routes = () => {
    const { userAuth, user } = useSelector((state) => state.userAuth)

    return userAuth && user?.email_verified && user?.number_verified && user?.kyc === 'Pending' || user?.kyc === 'Completed' ? <Outlet /> : userAuth && !user?.email_verified && !user?.number_verified && user?.kyc === 'Idle' ? <Navigate to='/OTPForm' /> : userAuth && user?.email_verified && user?.number_verified && user?.kyc === 'Idle' ? <Navigate to='/StepOne' /> : <Navigate to='/' />
}

