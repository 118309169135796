import React from "react";
import css from "./WhyRentSection.module.css";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import secondSec from "../../../assets/secondSec.png";
import why_rent_img from "../../../assets/atv_on_trailerfinal.webp";
import { themeRed } from "../../../utils/colorTheme";

const WhyRentSection = () => {
  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems={"stretch"}
          spacing={10}
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "30px 70px 150px 70px",
              lg: "30px 70px 150px 70px",
              xl: "30px 70px 150px 70px",
            },
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack sx={{ height: "100%", position: "relative" }} px={4}>
              <img
                // src={
                //   "https://res.cloudinary.com/dnptzwqt1/image/upload/v1737761905/secondSec656_4__11zon_krcvgf.webp"
                // }
                src={why_rent_img}
                alt="Car towing trailer"
                className={css.why_rent_img}
              />
              <Typography className={css.why_rent_img_txt}>
                OPEN UTILITY TRAILER  RENTAL
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack
              gap={3}
              justifyContent={"center"}
              alignItems={"flex-start"}
              sx={{ height: "100%" }}
              px={{
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: {
                      xl: "60px",
                      lg: "50px",
                      md: "45px",
                      sm: "40px",
                      xs: "30px",
                    },
                    lineHeight: "normal",
                    fontWeight: "800",
                    fontFamily: "Book Antiqua, serif !important",
                    zIndex: "1000",
                  }}
                >
                  Why Rent?
                </Typography>
                <Box
                  sx={{
                    // width: textWidth,
                    borderBottom: {
                      xl: `14px solid ${themeRed}`,
                      lg: `14px solid ${themeRed}`,
                      md: `14px solid ${themeRed}`,
                      sm: `14px solid ${themeRed}`,
                      xs: `14px solid ${themeRed}`,
                    },
                    outline: "none",
                    color: "#99000B26",
                    marginTop: {
                      xl: "-20px",
                      lg: "-18px",
                      md: "-14px",
                      sm: "-13px",
                      xs: "-9px",
                    },
                  }}
                ></Box>
              </Stack>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "normal",
                      mt: 1,
                      fontFamily: "Calibri, serif !important",
                    }}
                  >
                    {/* Because your needs change, so should your trailer. */}
                    &gt; Because your needs change, and your trailer should too.
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "normal",
                      mt: 1,
                      fontFamily: "Calibri, serif !important",
                    }}
                  >
                    {/* Because you just don’t have the space to park a trailer. */}
                    &gt; Because you "...just don’t have the funds or the space
                    to park your own."
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "20px",
                      lineHeight: "normal",
                      mt: 1,
                      fontFamily: "Calibri, serif !important",
                    }}
                  >
                    &gt; Because you "...only need to move that{" "}
                    <Typography
                      variant="caption"
                      sx={{
                        // fontSize: "20px",
                        // lineHeight: "normal",
                        // mt: 1,
                        // fontFamily: "Calibri, serif !important",
                        // textDecorationLine: "underline",
                        // color: "#918f8f",
                        // fontWeight: "300",
                        fontSize: "20px",
                        lineHeight: "normal",
                        mt: 1,
                        fontFamily: "Calibri, serif !important",
                      }}
                    >
                      (Fill in the blank)
                    </Typography>{" "}
                    this once!"
                  </Typography>
                </li>
              </ul>
              <button
                onClick={() => navigate("/rent-trailer")}
                className={css.book_trailer_btn}
              >
                Book a Trailer
              </button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default WhyRentSection;
