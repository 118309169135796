import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import chat_start from "../../assets/chat_start.png";
import ChatApp from "../../component/ChatApp/ChatApp";
import UserList from "../../component/ChatApp/userList";
import { io } from "socket.io-client";
import { FaPlus } from "react-icons/fa6";
import {
  getHostListAsync,
  getUsersListAsync,
} from "../../services/Chat_Service";
import { useLocation } from "react-router-dom";

const messagesList = [
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
  {
    senderId: 1,
    receiverId: 5,
    content: "Hy this is message",
    createdAt: "2024-07-31T00:00:00.000Z",
  },
];

const Messages = () => {
  const location = useLocation();
  const { id, first_name, last_name, avatar } = location.state || {};
  const { user } = useSelector((state) => state.userAuth);
  const { get_hosts_list_data, get_users_list_data } = useSelector(
    (state) => state.ChatSlice
  );
  const [selectedUser, setSelectedUser] = useState(null);
  // const [messages, setMessages] = useState([...messagesList]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState("");
  const [callType, setCallType] = useState(null);
  const socket = useMemo(
    () => io("https://trailer-backend-5c9a7f112e54.herokuapp.com"),
    []
  );

  const isHost = user?.role === "Host";


  const dispatch = useDispatch();

  const createRoomId = (senderId, receiverId) => {
    const ids = [senderId, receiverId].sort();
    return `${ids[0]}-${ids[1]}`;
  };

  const handleSubmit = () => {
    if (!roomId || !message || !user?._id || !selectedUser?._id) return;
    const currentDate = new Date().toISOString();
    socket.emit("message", {
      message,
      roomId,
      senderId: user?._id,
      receiverId: selectedUser?._id,
      createdAt: currentDate,
    });
    setMessage("");
    // setMessages([
    //   ...messages,
    //   {
    //     content: message,
    //     receiverId: selectedUser?._id,
    //     senderId: 4,
    //     createdAt: currentDate,
    //   },
    // ]);
  };

  const joinRoom = () => {
    if (!user?._id || !selectedUser?._id) return;

    const newRoomId = createRoomId(user?._id, selectedUser?._id);
    setRoomId(newRoomId);
    socket.emit("join-room", newRoomId);
    socket.emit("fetch-messages", { roomId: newRoomId });
  };

  useEffect(() => {
    if (selectedUser?._id) {
      joinRoom();
    }
  }, [selectedUser?._id]);

  useEffect(() => {
    socket.on("connect", () => {
    });

    socket.on("receive-message", (data) => {
      // Ensure the received message has a createdAt field
      if (!data.createdAt) {
        data.createdAt = new Date().toISOString();
      }

      setMessages((prevMessages) => [...prevMessages, data]);
    });

    socket.on("previous-messages", (data) => {
      setMessages(data);
    });

    isHost ? dispatch(getHostListAsync()) : dispatch(getUsersListAsync());

    return () => {
      socket.off();
    };
  }, []);


  // console.log("iddddddd",id)

  return (
    <Grid
      container
      alignItems={"stretch"}
      bgcolor={"#FAFAFA"}
      sx={{ height: "calc(100vh - 80px)" }}
      p={3}
    >
      {(isHost ? get_hosts_list_data?.users : get_users_list_data?.users)
        ?.length > 0 ? (
        <>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              borderRight: "1px solid #e0e0e0",
              display: {
                xl: "block",
                lg: "block",
                md: "block",
                sm: selectedUser?._id ? "none" : "block",
                xs: selectedUser?._id ? "none" : "block",
              },
            }}
          >
            <Stack
              sx={{
                height: "100%",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                px={2}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    color: "black",
                    fontSize: "21px",
                    fontWeight: "600",
                    lineHeight: "32.66px",
                  }}
                >
                  Messages
                </Typography>
                {/* <IconButton
                  sx={{
                    color: "white",
                    background:
                      "var(--REd, linear-gradient(180deg, #BF2E35 0%, #88030A 100%))",
                    ":hover": {
                      background:
                        "var(--REd, linear-gradient(180deg, #BF2E35 0%, #88030A 100%))",
                    },
                  }}
                >
                  <FaPlus size={20} />
                </IconButton> */}
              </Stack>
              <Divider sx={{ my: 2 }} />
              <Stack className="user-list-container">
                <Stack sx={{ height: "100%" }}>
                  <UserList onSelectUser={setSelectedUser} />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={selectedUser?._id ? 12 : 8}
            xs={selectedUser?._id ? 12 : 8}
            sx={{
              borderLeft: "1px solid #e0e0e0",
              display: {
                xl: "block",
                lg: "block",
                md: "block",
                sm: id || selectedUser?._id ? "block" : "none",
                xs: id || selectedUser?._id ? "block" : "none",
              },
            }}
          >
            <Stack sx={{ height: "100%" }}>
              {selectedUser?._id ? (
                <ChatApp
                  messages={messages}
                  setMessage={setMessage}
                  sendMessage={handleSubmit}
                  userName={`${selectedUser?.first_name} ${selectedUser?.last_name}`}
                  backValue={(e) => setSelectedUser(null)}
                  handleVideCall={() => setCallType("video")}
                  handleAudioCall={() => setCallType("audio")}
                  userProfile={selectedUser?.avatar}
                />
              ) : (
                <Stack
                  sx={{
                    height: "100%",
                    borderRadius: "20px",
                  }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography>
                    <img src={require("../../assets/background/chat.png")} />
                  </Typography>
                </Stack>
              )}
              <Stack
                sx={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
              >
                {/* {callType && (
              <VideoAudioCall
                appId={APP_ID}
                channel={CHANNEL_NAME}
                token={AGORA_TOKEN}
                audioOnly={callType === "audio"}
              />
            )} */}
                {/* <VideoAudioCall
            appId={APP_ID}
            channel={CHANNEL_NAME}
            token={AGORA_TOKEN}
            audioOnly={callType === "audio"}
            /> */}
              </Stack>
            </Stack>
          </Grid>
        </>
      ) : (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "100%", height: "60vh" }}
        >
          <Typography
            sx={{
              fontFamily: '"Poppins", sans-serif',
              color: "#9D9D9D",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            No Users found
          </Typography>
        </Stack>
      )}
    </Grid>
  );
};

export default Messages;
