/**
 * Converts a date string from the format "YYYY-MM-DDTHH:mm:ss.sssZ"
 * to the format "MMM DD, YYYY" (e.g., "2024-11-18T21:03:30.437Z" → "Nov 18, 2024").
 *
 * @param {string} apiDate - The date string in ISO 8601 format (e.g., "2024-11-18T21:03:30.437Z").
 * @returns {string} The formatted date string in the format "MMM DD, YYYY".
 */

export function formatApiDate(apiDate) {
  // Create a Date object from the API date string
  const date = new Date(apiDate);

  // Use built-in methods to get parts of the date
  const month = date.toLocaleString("en-US", { month: "short" }); // Get short month name
  const day = date.getDate(); // Get day of the month
  const year = date.getFullYear(); // Get full year

  // Combine into the desired format
  return `${month} ${day}, ${year}`;
}

// Export the function for use in other files
export default formatApiDate;
