import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import {
  addUserReviewsAsync,
  book_Trailer_Async,
  completeUserBookingAsync,
  getHistoryAsync,
  getHostBookingsDetailByIdAsync,
  getHostDetailByIdAsync,
  getHostMyBookingsAsync,
  getSearchTrailersListAsync,
  getTrailerByIdAsync,
  getUserBookings,
  getUserBookingsDetailByIdAsync,
  getUserTrailersListAsync,
  postNewsLetterAsync,
  updateBookingDetailsStatusAsync,
  updateHostTrailerStatusAsync,
  updateUserBookingDetailsStatusAsync,
} from "../../services/trailers_details_service";

const initialState = {
  // GET TRAILERS LIST
  get_user_trailers_list_status: asyncStatus.IDLE,
  get_user_trailers_list_data: null,
  get_user_trailers_list_error: null,

  // GET TRAILERS LIST
  get_search_trailers_list_status: asyncStatus.IDLE,
  get_search_trailers_list_data: null,
  get_search_trailers_list_error: null,

  // GET TRAILER BY ID
  get_trailer_by_id_status: asyncStatus.IDLE,
  get_trailer_by_id_data: null,
  get_trailer_by_id_error: null,

  // GET TRAILER BY ID
  get_host_detail_by_id_status: asyncStatus.IDLE,
  get_host_detail_by_id_data: null,
  get_host_detail_by_id_error: null,

  // GET TRAILER BY ID
  update_host_trailer_status_status: asyncStatus.IDLE,
  update_host_trailer_status_data: null,
  update_host_trailer_status_error: null,

  // Post Trailer Booking
  trailer_booking_status: asyncStatus.IDLE,
  trailer_booking_data: null,
  trailer_booking_error: null,

  // GET TRAILER HOST MY BOOKINGS BY ID

  get_host_my_bookings_status: asyncStatus.IDLE,
  get_host_my_bookings_data: null,
  get_host_my_bookings_error: null,

  // GET TRAILER USER MY BOOKINGS BY ID

  get_user_bookings_detail_by_id_status: asyncStatus.IDLE,
  get_user_bookings_detail_by_id_data: null,
  get_user_bookings_detail_by_id_error: null,

  // GET TRAILER HOST BOOKINGS DETAIL BY ID

  get_host_bookings_detail_by_id_status: asyncStatus.IDLE,
  get_host_bookings_detail_by_id_data: null,
  get_host_bookings_detail_by_id_error: null,

  // GET TRAILER USER BOOKINGS

  get_user_bookings_status: asyncStatus.IDLE,
  get_user_bookings_data: null,
  get_user_bookings_error: null,

  // UPDATE BOOKING DETAIL STATUS

  update_booking_detail_status_status: asyncStatus.IDLE,
  update_booking_detail_status_data: null,
  update_booking_detail_status_error: null,

  // UPDATE BOOKING DETAIL FORM USER STATUS

  user_update_booking_detail_status_status: asyncStatus.IDLE,
  user_update_booking_detail_status_data: null,
  user_update_booking_detail_status_error: null,

  // COMPLETE USER BOOKING STATE

  compelete_user_booking_status: asyncStatus.IDLE,
  compelete_user_booking_data: null,
  compelete_user_booking_error: null,

  // ADD USER REVIEW STATE

  add_user_reviews_status: asyncStatus.IDLE,
  add_user_reviews_data: null,
  add_user_reviews_error: null,

  // get_user_history
  get_user_history_status: asyncStatus.IDLE,
  get_user_history_data: null,
  get_user_history_error: null,

  // get_user_history
  post_news_letter_status: asyncStatus.IDLE,
  post_news_letter_data: null,
  post_news_letter_error: null,

  is_edit_trailer: false,
};

const trailers_details_slice = createSlice({
  name: "trailersDetailsManage",
  initialState,
  reducers: {
    setAddBankStatus(state) {
      state.get_user_trailers_list_status = asyncStatus.IDLE;
    },
    setBookingTrailerStatus(state) {
      state.trailer_booking_status = asyncStatus.IDLE;
    },
    setAddUserReviewStatus(state) {
      state.addUserReviewsAsync = asyncStatus.IDLE;
    },
    seteditTrailer(state, action) {
      state.is_edit_trailer = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET USERS TRAILERS LIST =========>>>>>>>>>>>
    builder.addCase(getUserTrailersListAsync.pending, (state, action) => {
      state.get_user_trailers_list_status = asyncStatus.LOADING;
    });

    builder.addCase(
      getUserTrailersListAsync.fulfilled,
      (state, { payload }) => {
        state.get_user_trailers_list_status = asyncStatus.SUCCEEDED;
        state.get_user_trailers_list_data = payload;
      }
    );

    builder.addCase(getUserTrailersListAsync.rejected, (state, action) => {
      state.get_user_trailers_list_status = asyncStatus.ERROR;
      state.get_user_trailers_list_error = action.error;
      error_toast_message(action.error.message);
    });

    // GET SEARCH TRAILERS LIST =========>>>>>>>>>>>
    builder.addCase(getSearchTrailersListAsync.pending, (state, action) => {
      state.get_search_trailers_list_status = asyncStatus.LOADING;
    });

    builder.addCase(
      getSearchTrailersListAsync.fulfilled,
      (state, { payload }) => {
        state.get_search_trailers_list_status = asyncStatus.SUCCEEDED;
        state.get_search_trailers_list_data = payload;
        console.log("trailers", payload || "");
      }
    );

    builder.addCase(getSearchTrailersListAsync.rejected, (state, action) => {
      state.get_search_trailers_list_status = asyncStatus.ERROR;
      state.get_search_trailers_list_error = action.error;
      error_toast_message(action.payload);
    });

    // GET USERS TRAILERS BY ID =========>>>>>>>>>>>
    builder.addCase(getTrailerByIdAsync.pending, (state, action) => {
      state.get_trailer_by_id_status = asyncStatus.LOADING;
    });

    builder.addCase(getTrailerByIdAsync.fulfilled, (state, { payload }) => {
      state.get_trailer_by_id_status = asyncStatus.SUCCEEDED;
      state.get_trailer_by_id_data = payload.data;
    });

    builder.addCase(getTrailerByIdAsync.rejected, (state, action) => {
      state.get_trailer_by_id_status = asyncStatus.ERROR;
      state.get_trailer_by_id_error = action.error;
      error_toast_message(action?.error?.message);
    });

    // GET USERS HOST DETAIL BY ID =========>>>>>>>>>>>
    builder.addCase(getHostDetailByIdAsync.pending, (state, action) => {
      state.get_host_detail_by_id_status = asyncStatus.LOADING;
    });

    builder.addCase(getHostDetailByIdAsync.fulfilled, (state, { payload }) => {
      state.get_host_detail_by_id_status = asyncStatus.SUCCEEDED;
      state.get_host_detail_by_id_data = payload.data;
    });

    builder.addCase(getHostDetailByIdAsync.rejected, (state, action) => {
      state.get_host_detail_by_id_status = asyncStatus.ERROR;
      state.get_host_detail_by_id_error = action.error;
      error_toast_message(action?.error?.message);
    });

    // Trailer Booking  =========>>>>>>>>>>>
    builder.addCase(book_Trailer_Async.pending, (state, action) => {
      state.trailer_booking_status = asyncStatus.LOADING;
    });

    builder.addCase(book_Trailer_Async.fulfilled, (state, { payload }) => {
      state.trailer_booking_status = asyncStatus.SUCCEEDED;
      state.trailer_booking_data = payload.data;
      success_toast_message("Booking Successfully");
    });

    builder.addCase(book_Trailer_Async.rejected, (state, action) => {
      state.trailer_booking_status = asyncStatus.ERROR;
      state.trailer_booking_error = action.error;
      error_toast_message(action?.error?.message);
    });

    // GET USERS HOST DETAIL BY ID =========>>>>>>>>>>>
    builder.addCase(updateHostTrailerStatusAsync.pending, (state, action) => {
      state.update_host_trailer_status_status = asyncStatus.LOADING;
    });

    builder.addCase(
      updateHostTrailerStatusAsync.fulfilled,
      (state, { payload }) => {
        state.update_host_trailer_status_status = asyncStatus.SUCCEEDED;
        state.update_host_trailer_status_data = payload.data;
        console.log("payload", payload);
        if (
          state.get_trailer_by_id_data?.trailer?._id === payload?.trailer?._id
        ) {
          state.get_trailer_by_id_data.trailer.status = payload.trailer.status;
        }
      }
    );

    builder.addCase(updateHostTrailerStatusAsync.rejected, (state, action) => {
      state.update_host_trailer_status_status = asyncStatus.ERROR;
      state.update_host_trailer_status_error = action.error;
      error_toast_message(action?.error?.message);
    });

    // GET MY BOOKINGS HOST DETAIL BY ID =========>>>>>>>>>>>
    builder.addCase(getHostMyBookingsAsync.pending, (state, action) => {
      state.get_host_my_bookings_status = asyncStatus.LOADING;
    });

    builder.addCase(getHostMyBookingsAsync.fulfilled, (state, { payload }) => {
      state.get_host_my_bookings_status = asyncStatus.SUCCEEDED;
      state.get_host_my_bookings_data = payload.data;
    });

    builder.addCase(getHostMyBookingsAsync.rejected, (state, action) => {
      state.get_host_my_bookings_status = asyncStatus.ERROR;
      state.get_host_my_bookings_error = action.error;
      error_toast_message(action?.error?.message);
    });
    // GET BOOKINGS DETAIL BY ID BY ID =========>>>>>>>>>>>
    builder.addCase(getHostBookingsDetailByIdAsync.pending, (state, action) => {
      state.get_host_bookings_detail_by_id_status = asyncStatus.LOADING;
    });
    builder.addCase(
      getHostBookingsDetailByIdAsync.fulfilled,
      (state, { payload }) => {
        state.get_host_bookings_detail_by_id_status = asyncStatus.SUCCEEDED;
        state.get_host_bookings_detail_by_id_data = payload.data;
      }
    );
    builder.addCase(
      getHostBookingsDetailByIdAsync.rejected,
      (state, action) => {
        state.get_host_bookings_detail_by_id_status = asyncStatus.ERROR;
        state.get_host_bookings_detail_by_id_error = action.error;
        error_toast_message(action?.error?.message);
      }
    );

    // GET USER BOOKINGS DETAIL BY ID BY ID =========>>>>>>>>>>>
    builder.addCase(getUserBookingsDetailByIdAsync.pending, (state, action) => {
      state.get_user_bookings_detail_by_id_status = asyncStatus.LOADING;
    });
    builder.addCase(
      getUserBookingsDetailByIdAsync.fulfilled,
      (state, { payload }) => {
        state.get_user_bookings_detail_by_id_status = asyncStatus.SUCCEEDED;
        state.get_user_bookings_detail_by_id_data = payload.data;
      }
    );
    builder.addCase(
      getUserBookingsDetailByIdAsync.rejected,
      (state, action) => {
        state.get_user_bookings_detail_by_id_status = asyncStatus.ERROR;
        state.get_user_bookings_detail_by_id_error = action.error;
        error_toast_message(action?.error?.message);
      }
    );
    // GET BOOKINGS USER =========>>>>>>>>>>>
    builder.addCase(getUserBookings.pending, (state, action) => {
      state.get_user_bookings_status = asyncStatus.LOADING;
    });

    builder.addCase(getUserBookings.fulfilled, (state, { payload }) => {
      state.get_user_bookings_status = asyncStatus.SUCCEEDED;
      state.get_user_bookings_data = payload.data;
    });

    builder.addCase(getUserBookings.rejected, (state, action) => {
      state.get_user_bookings_status = asyncStatus.ERROR;
      state.get_user_bookings_error = action.error;
      error_toast_message(action?.error?.message);
    });
    // UPDATE BOOKINGS DETAILS STATUS =========>>>>>>>>>>>
    builder.addCase(
      updateBookingDetailsStatusAsync.pending,
      (state, action) => {
        state.update_booking_detail_status_status = asyncStatus.LOADING;
      }
    );

    // builder.addCase(
    //   updateBookingDetailsStatusAsync.fulfilled,
    //   (state, { payload }) => {
    //     state.update_booking_detail_status_status = asyncStatus.SUCCEEDED;
    //     state.update_booking_detail_status_data = payload?.data?.booking;

    //     if (Object.keys(payload?.data?.booking || {})?.length > 0) {
    //       // Replace the booking object in the state with the payload's booking object directly
    //       state.get_user_bookings_detail_by_id_data.booking.status =
    //         payload?.data?.booking?.status;
    //     }
    //     success_toast_message(payload.message);
    //   }
    // );
    builder.addCase(
      updateBookingDetailsStatusAsync.fulfilled,
      (state, { payload }) => {
        state.update_booking_detail_status_status = asyncStatus.SUCCEEDED;
        state.update_booking_detail_status_data = payload?.data?.booking;

        // Add null check before accessing nested property
        if (
          state.get_user_bookings_detail_by_id_data &&
          payload?.data?.booking
        ) {
          state.get_user_bookings_detail_by_id_data.booking.status =
            payload?.data?.booking?.status;
        }
        success_toast_message(payload.message);
      }
    );

    builder.addCase(
      updateBookingDetailsStatusAsync.rejected,
      (state, action) => {
        state.update_booking_detail_status_status = asyncStatus.ERROR;
        state.update_booking_detail_status_error = action.error;
        error_toast_message(action?.error?.message);
      }
    );

    // UPDATE BOOKINGS DETAILS FROM USER STATUS =========>>>>>>>>>>>
    builder.addCase(
      updateUserBookingDetailsStatusAsync.pending,
      (state, action) => {
        state.user_update_booking_detail_status_status = asyncStatus.LOADING;
      }
    );

    builder.addCase(
      updateUserBookingDetailsStatusAsync.fulfilled,
      (state, { payload }) => {
        state.user_update_booking_detail_status_status = asyncStatus.SUCCEEDED;
        state.user_update_booking_detail_status_data = payload?.data?.booking;

        if (Object.keys(payload?.data?.booking)) {
          // Replace the booking object in the state with the payload's booking object directly
          state.get_user_bookings_detail_by_id_data.booking.status =
            payload?.data?.booking?.status;
        }
        success_toast_message(payload.message);
      }
    );

    builder.addCase(
      updateUserBookingDetailsStatusAsync.rejected,
      (state, action) => {
        state.user_update_booking_detail_status_status = asyncStatus.ERROR;
        state.user_update_booking_detail_status_error = action.error;
        error_toast_message(action?.error?.message);
      }
    );

    // COMPLETE USER BOOKING ASYNC =========>>>>>>>>>>>
    builder.addCase(completeUserBookingAsync.pending, (state, action) => {
      state.compelete_user_booking_status = asyncStatus.LOADING;
    });

    builder.addCase(
      completeUserBookingAsync.fulfilled,
      (state, { payload }) => {
        state.compelete_user_booking_status = asyncStatus.SUCCEEDED;
        state.compelete_user_booking_data = payload?.data?.booking;
        if (state.get_user_bookings_detail_by_id_data?.booking) {
          state.get_user_bookings_detail_by_id_data.booking.status =
            payload.data.booking.status;
        }
        success_toast_message(payload.message);
      }
    );

    builder.addCase(completeUserBookingAsync.rejected, (state, action) => {
      state.compelete_user_booking_status = asyncStatus.ERROR;
      state.compelete_user_booking_error = action.error;
      error_toast_message(action?.error?.message);
    });

    // ADD USER REVIEWS ASYNC =========>>>>>>>>>>>
    builder.addCase(addUserReviewsAsync.pending, (state, action) => {
      state.add_user_reviews_status = asyncStatus.LOADING;
    });

    builder.addCase(addUserReviewsAsync.fulfilled, (state, { payload }) => {
      state.add_user_reviews_status = asyncStatus.SUCCEEDED;
      state.add_user_reviews_data = payload?.review;
      console.log("add user reviews", payload);

      // Add the new review to the reviews array
      if (state.get_user_bookings_detail_by_id_data?.reviews) {
        state.get_user_bookings_detail_by_id_data.reviews.push(payload?.review);
      }

      if (Object.keys(payload?.review)) {
        state.get_user_bookings_detail_by_id_data.user_review = true;
      }

      success_toast_message(payload.message);
    });

    builder.addCase(addUserReviewsAsync.rejected, (state, action) => {
      state.add_user_reviews_status = asyncStatus.ERROR;
      state.add_user_reviews_error = action.error;
      error_toast_message(action?.error?.message);
    });
    // getHistoryAsync =========>>>>>>>>>>>
    builder.addCase(getHistoryAsync.pending, (state, action) => {
      state.get_user_history_status = asyncStatus.LOADING;
    });

    builder.addCase(getHistoryAsync.fulfilled, (state, { payload }) => {
      state.get_user_history_status = asyncStatus.SUCCEEDED;
      state.get_user_history_data = payload.bookings;
    });

    builder.addCase(getHistoryAsync.rejected, (state, action) => {
      state.get_user_history_status = asyncStatus.ERROR;
      state.get_user_history_error = action.error;
      error_toast_message(action.error.message);
    });
  
    // getHistoryAsync =========>>>>>>>>>>>
    builder.addCase(postNewsLetterAsync.pending, (state, action) => {
      state.post_news_letter_status = asyncStatus.LOADING;
    });

    builder.addCase(postNewsLetterAsync.fulfilled, (state, { payload }) => {
      state.post_news_letter_status = asyncStatus.SUCCEEDED;
      state.post_news_letter_data = payload.bookings;
      success_toast_message(payload.message);
    });

    builder.addCase(postNewsLetterAsync.rejected, (state, action) => {
      state.post_news_letter_status = asyncStatus.ERROR;
      state.post_news_letter_error = action.error;
      error_toast_message(action.error.message);
    });
  },
});

export const {
  setAddBankStatus,
  setAddTrailerStatus,
  setBookingTrailerStatus,
  setAddUserReviewStatus,
  seteditTrailer,
} = trailers_details_slice.actions;

export default trailers_details_slice.reducer;
