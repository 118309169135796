import { Grid } from "@material-ui/core";
import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { themeRed } from "../../../utils/colorTheme";
import InputField from "../../InputField/InputField";
import { MdOutlineAttachFile } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import css from "./TicketsComments.module.css";
import {
  addCommentAsync,
  getTicketAsync,
} from "../../../services/Tickets_Service";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../../utils/asyncStatus";
import { setAddCommentStatus } from "../../../store/slices/ticket_slice";

const TicketsComments = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatWindowRef = useRef(null);
  const [newComment, setNewComment] = useState("");

  const {
    get_ticket_status,
    get_ticket_data,
    get_all_ticket_data,
    add_comment_status,
  } = useSelector((state) => state.ticketManage);
  const { user } = useSelector((state) => state.userAuth);

  const getTicketLoader = get_ticket_status === asyncStatus.LOADING;
  const addCommentLoader = add_comment_status === asyncStatus.LOADING;

  useEffect(() => {
    dispatch(getTicketAsync(id));
  }, []);

  const commentsData = [
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
    {
      _id: 2,
      comment: "hy how are you",
    },
    {
      _id: 1,
      comment: "hy how are you",
    },
  ];

  const _handleSendComment = (e) => {
    e.preventDefault();
    dispatch(
      addCommentAsync({
        ticket_id: id,
        comment: newComment,
      })
    );
  };

  // Scroll to the bottom of the comments section
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (add_comment_status === asyncStatus.SUCCEEDED) {
      dispatch(setAddCommentStatus());
      setNewComment("");
      scrollToBottom();
    }
  }, [add_comment_status]);

  useEffect(() => {
    // Scroll to the bottom on initial load
    if (get_ticket_data?.comments) {
      scrollToBottom();
    }
  }, [get_ticket_data]);

  return (
    <Stack my={4} sx={{ height: "calc(100vh  - 200px)" }}>
      {getTicketLoader ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "50vh" }}
        >
          <CircularProgress size={30} sx={{ color: themeRed }} />
        </Stack>
      ) : Object.keys(get_ticket_data || {})?.length === 0 ? (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "30vh" }}
        >
          <Typography>No Data Found</Typography>
        </Stack>
      ) : (
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          sx={{ height: "calc(100vh  - 200px)" }}
        >
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Stack
              alignItems={"flex-start"}
              gap={5}
              sx={{
                borderRight: {
                  xl: "1px solid lightgrey",
                  lg: "1px solid lightgrey",
                  md: "1px solid lightgrey",
                  sm: "1px solid lightgrey",
                  xs: "1px solid white",
                },
                height: "100%",
              }}
            >
              <Stack>
                <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                  Query
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "500", color: "grey" }}
                >
                  {get_ticket_data?.query || ""}
                </Typography>
              </Stack>

              <Typography
                variant="caption"
                sx={{ fontSize: "18px", fontWeight: "600" }}
              >
                Tickets No :{" "}
                <Typography
                  variant="caption"
                  sx={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {"12312323" || ""}
                </Typography>
              </Typography>

              <Typography
                sx={{
                  padding: "8px 20px",
                  borderRadius: "10px",
                  color: "green",
                  backgroundColor: "#c9e3e2",
                }}
              >
                {get_ticket_data?.status || ""}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
            <Stack sx={{ height: "100%" }} gap={5}>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                Comments Section
              </Typography>

              <Stack
                sx={{
                  border: `1px solid ${themeRed}`,
                  borderRadius: "10px",
                  padding: "20px",
                  height: "calc(100vh - 220px)",
                  position: "relative",
                }}
                gap={2}
              >
                <Stack
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-ms-overflow-style": "none", // Internet Explorer 10+
                    "scrollbar-width": "none", // Firefox
                    width: "100%",
                    paddingBottom: "60px",
                  }}
                  gap={2}
                >
                  {get_ticket_data?.comments?.map(
                    ({ _id, comment, commented_by }, i) => {
                      const isHost = commented_by?._id === user?._id;

                      return (
                        <Stack
                          key={i}
                          alignItems={isHost ? "flex-end" : "flex-start"}
                          sx={{ width: "100%" }}
                        >
                          <Stack
                            sx={{
                              padding: "10px",
                              borderRadius: "5px",
                              backgroundColor: "#ebebeb",
                              background: isHost
                                ? "var(--REd, linear-gradient(180deg, #BF2E35 0%, #88030A 100%))"
                                : "#ebebeb",
                              color: isHost ? "white" : "black",
                            }}
                          >
                            {comment}
                          </Stack>
                        </Stack>
                      );
                    }
                  )}
                  <div ref={chatWindowRef}></div>
                </Stack>
                <Stack
                  sx={{
                    width: "calce(100% - 20px)",
                    position: "absolute",
                    bottom: "3%",
                    left: 0,
                    right: 0,
                    height: "41px",
                  }}
                >
                  <form
                    className={css.form_wrapper}
                    onSubmit={_handleSendComment}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        border: "2px solid #E2E8F0",
                        borderRadius: "10px",
                        padding: "5px 7px 5px 21px",
                        width: "100%",
                        height: "51px",
                        backgroundColor: "white",
                      }}
                    >
                      <input
                        className={css.comments_inp}
                        placeholder="Type a message"
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        style={{
                          fontFamily: '"Poppins", sans-serif',
                        }}
                      />
                      <button
                        disabled={!newComment || addCommentLoader}
                        type="submit"
                        className={css.send_btn}
                      >
                        <RiSendPlaneFill
                          size={27}
                          color={"white"}
                          sx={{ flexShrink: 0 }}
                        />
                      </button>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default TicketsComments;
