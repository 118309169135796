import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../../component/navbar/Navbar";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import { themeRed } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { asyncStatus } from "../../utils/asyncStatus";
import { contactUsFormAsync } from "../../services/authentication";

const ContactUs = () => {
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contact_us_form_status, contact_us_form_data } = useSelector(
    (state) => state.userAuth
  );

  const formLoading = contact_us_form_status === asyncStatus.LOADING;

  const _handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const _handleSubmit = (e) => {
    e.preventDefault();
    const formDataObj = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    dispatch(contactUsFormAsync(formDataObj));
  };
  return (
    <div>
      <Navbar />
      <Container sx={{ p: 4 }}>
        <Grid2
          spacing={4}
          sx={{
            backgroundColor: "white",
            boxShadow: 2,
            borderRadius: 2,
            p: 4,
          }}
        >
          {/* Left Section: Heading and Description */}
          <Grid2
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Have questions? Feel free to reach out to us. We're here to help!
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontStyle: "italic", color: "gray" }}
            >
              Phone: +123 456 7890
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontStyle: "italic", color: "gray" }}
            >
              Email: support@trailer4rent.ca
            </Typography>
          </Grid2>

          {/* Right Section: Contact Form */}
          <Grid2 item xs={12} md={6}>
            <form onSubmit={_handleSubmit}>
              <Box
                // component="form"
                // noValidate
                // autoComplete="off"
                sx={{ display: "flex", flexDirection: "column", gap: 3 }}
              >
                <TextField
                  onChange={(e) => _handleChange("name", e.target.value)}
                  label="Your Name"
                  variant="outlined"
                  fullWidth
                  required
                  sx={{
                    // "& label": {
                    //   color: themeRed, // Default label color
                    // },
                    "& label.Mui-focused": {
                      color: themeRed, // Label color when focused
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: themeRed, // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: themeRed, // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: themeRed, // Focus border color
                      },
                    },
                  }}
                />
                <TextField
                  label="Your Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  required
                  onChange={(e) => _handleChange("email", e.target.value)}
                  sx={{
                    // "& label": {
                    //   color: themeRed, // Default label color
                    // },
                    "& label.Mui-focused": {
                      color: themeRed, // Label color when focused
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: themeRed, // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: themeRed, // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: themeRed, // Focus border color
                      },
                    },
                  }}
                />
                <TextField
                  label="Your Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  onChange={(e) => _handleChange("message", e.target.value)}
                  sx={{
                    // "& label": {
                    //   color: themeRed, // Default label color
                    // },
                    "& label.Mui-focused": {
                      color: themeRed, // Label color when focused
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: themeRed, // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: themeRed, // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: themeRed, // Focus border color
                      },
                    },
                  }}
                />
                <Button
                  // onClick={_handleSubmit}
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: themeRed,
                    color: "white",
                    fontWeight: "bold",
                  }}
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <CircularProgress size={21} sx={{ color: "white" }} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </Grid2>
        </Grid2>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactUs;
