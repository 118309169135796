import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { apiHandle, baseURL } from "../config/apiHandle/apiHandle";
import { type_constant } from "../utils/asyncStatus";

export const add_bank_account = createAsyncThunk(
  type_constant.ADD_BANK,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`host/add-account`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const get_bank_account = createAsyncThunk(
  type_constant.GET_BANK,
  async () => {
    try {
      const response = await apiHandle.get(`host/bank-list`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const add_trailer = createAsyncThunk(
  type_constant.ADD_TRAILER,
  async (post_data) => {
    try {
      const response = await apiHandle.post(`host/add-trailer`, post_data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const edit_trailer = createAsyncThunk(
  type_constant.EDIT_TRAILER,
  async (post_data) => {
    const { id, data } = post_data

    try {
      const response = await apiHandle.post(`host/trailer/${id}`, data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_trailer_by_id = createAsyncThunk(
  type_constant.GET_TRAILER_BY_ID,
  async (id) => {
    try {
      const response = await apiHandle.get(`host/trailers/${id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const update_bank_account_status_async = createAsyncThunk(
  type_constant.UPDATE_BANK_ACCOUNT_STATUS_ASYNC,
  async (account_status) => {
    try {
      const response = await apiHandle.post(
        `host/set-bank-status`,
        account_status
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("ERROR POST BANK DETAILS", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);


export const get_host_finance_details = createAsyncThunk(
  type_constant.GET_MY_FINANCE,
  async () => {
    try {
      const response = await apiHandle.get(`host/get-my-finance`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log("error", error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
