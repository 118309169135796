// import React, { useState, useCallback } from "react";
// import Cropper from "react-easy-crop";
// import {
//   Button,
//   Slider,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   Typography,
//   Stack,
//   IconButton,
// } from "@mui/material";
// import { TbCameraPlus } from "react-icons/tb";
// import { MdEdit, MdRefresh } from "react-icons/md";
// import axios from "axios";
// import { baseURL } from "../../config/apiHandle/apiHandle";
// import { themeRed } from "../../utils/colorTheme";

// // Helper function to crop image
// const getCroppedImg = (imageSrc, crop) => {
//   const image = new Image();
//   image.src = imageSrc;

//   return new Promise((resolve) => {
//     image.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");

//       canvas.width = crop.width;
//       canvas.height = crop.height;

//       ctx.drawImage(
//         image,
//         crop.x,
//         crop.y,
//         crop.width,
//         crop.height,
//         0,
//         0,
//         crop.width,
//         crop.height
//       );

//       canvas.toBlob((blob) => {
//         resolve(blob);
//       }, "image/jpeg");
//     };
//   });
// };

// const ImageUploader = ({ label, onImageSelect }) => {
//   const [imageSrc, setImageSrc] = useState(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//   const [openModal, setOpenModal] = useState(false);
//   const [croppedImage, setCroppedImage] = useState(null);
//   const [uploading, setUploading] = useState(false);
//   const [token, setToken] = useState(null);
//   const [inputKey, setInputKey] = useState(Date.now());

//   // Function to load the image and open the modal
//   const onImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const imageDataUrl = await readFile(file);
//       setImageSrc(imageDataUrl);
//       setOpenModal(true);
//       setInputKey(Date.now());
//     }
//   };

//   const cancelHandle = () => {
//     setOpenModal(false);
//     resetImageState();
//   };

//   // Reset state
//   const resetImageState = () => {
//     setImageSrc(null);
//     setCrop({ x: 0, y: 0 });
//     setZoom(1);
//     setCroppedAreaPixels(null);
//     setCroppedImage(null);
//   };

//   const readFile = (file) => {
//     return new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.addEventListener("load", () => resolve(reader.result), false);
//       reader.readAsDataURL(file);
//     });
//   };

//   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   }, []);

//   const showCroppedImage = async () => {
//     try {
//       const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
//       setUploading(true);

//       const formData = new FormData();
//       formData.append("binary_file", croppedBlob);

//       const response = await axios.post(`${baseURL}upload-files`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const imageUrl = response.data.url;
//       setCroppedImage(imageUrl);
//       onImageSelect(imageUrl);
//       setOpenModal(false);
//     } catch (e) {
//       console.error("Error cropping and uploading image:", e);
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div style={{ width: "100%" }}>
//       <Typography variant="h6">{label}</Typography>

//       {/* File Input */}
//       {!croppedImage && (
//         <input
//           key={inputKey}
//           accept="image/*"
//           type="file"
//           onChange={onImageChange}
//           style={{ display: "none" }}
//           id={`upload-image-${label}`}
//         />
//       )}
//       <label htmlFor={`upload-image-${label}`}>
//         {!croppedImage ? (
//           <Stack
//             sx={{
//               padding: "25px 5vw",
//               borderRadius: "5px",
//               border: "1px solid #DDD",
//               // width: "350px ",
//               width: {
//                 xl: "fit-content",
//                 lg: "fit-content",
//                 md: "fit-content",
//                 sm: "fit-content",
//                 xs: "100%",
//               },
//               cursor: "pointer",
//               position: "relative",
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//           >
//             <TbCameraPlus
//               style={{
//                 width: "100px",
//                 height: "100px",
//                 objectFit: "contain",
//                 color: "#DADADA",
//               }}
//             />
//           </Stack>
//         ) : (
//           <Stack
//             position="relative"
//             sx={{
//               width: {
//                 xl: "17.5vw",
//                 lg: "17.5vw",
//                 md: "17.5vw",
//                 sm: "23.5vw",
//                 xs: "100%",
//               },
//               height: "155px",
//             }}
//           >
//             <img
//               src={croppedImage}
//               alt="Cropped"
//               style={{
//                 // width: "300px",
//                 borderRadius: "10px",
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//               }}
//             />
//             <IconButton
//               sx={{
//                 position: "absolute",
//                 top: "2px",
//                 right: "2px",
//                 backgroundColor: themeRed,
//                 borderRadius: "50%",
//                 padding: "5px",
//               }}
//               onClick={() => resetImageState()}
//             >
//               <MdEdit style={{ color: "#fff", fontSize: "20px" }} />
//             </IconButton>
//           </Stack>
//         )}
//       </label>

//       {/* Modal for Cropper */}
//       <Dialog open={openModal} onClose={cancelHandle} maxWidth="sm" fullWidth>
//         <DialogContent>
//           {imageSrc && (
//             <div style={{ width: "100%", height: 400, position: "relative" }}>
//               <Cropper
//                 image={imageSrc}
//                 crop={crop}
//                 zoom={zoom}
//                 aspect={4 / 3}
//                 onCropChange={setCrop}
//                 onZoomChange={setZoom}
//                 onCropComplete={onCropComplete}
//               />
//             </div>
//           )}
//           <div>
//             <label>Zoom</label>
//             <Slider
//               color="error"
//               value={zoom}
//               min={1}
//               max={3}
//               step={0.1}
//               onChange={(e, zoom) => setZoom(zoom)}
//             />
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             color="error"
//             variant="contained"
//             onClick={showCroppedImage}
//             disabled={uploading}
//           >
//             {uploading ? "Uploading..." : "Save Image"}
//           </Button>
//           <Button variant="outlined" onClick={cancelHandle} color="error">
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default ImageUploader;




// import React, { useState, useCallback, useEffect } from "react";
// import Cropper from "react-easy-crop";
// import {
//   Button,
//   Slider,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   Typography,
//   Stack,
//   IconButton,
// } from "@mui/material";
// import { TbCameraPlus } from "react-icons/tb";
// import { MdEdit } from "react-icons/md";
// import axios from "axios";
// import { baseURL } from "../../config/apiHandle/apiHandle";
// import { themeRed } from "../../utils/colorTheme";

// // Helper function to crop image
// const getCroppedImg = (imageSrc, crop) => {
//   const image = new Image();
//   image.src = imageSrc;

//   return new Promise((resolve) => {
//     image.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");

//       canvas.width = crop.width;
//       canvas.height = crop.height;

//       ctx.drawImage(
//         image,
//         crop.x,
//         crop.y,
//         crop.width,
//         crop.height,
//         0,
//         0,
//         crop.width,
//         crop.height
//       );

//       canvas.toBlob((blob) => {
//         resolve(blob);
//       }, "image/jpeg");
//     };
//   });
// };

// const ImageUploader = ({ label, onImageSelect, defaultImageUrl }) => {
//   const [imageSrc, setImageSrc] = useState(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//   const [openModal, setOpenModal] = useState(false);
//   const [croppedImage, setCroppedImage] = useState(defaultImageUrl || null);
//   const [uploading, setUploading] = useState(false);
//   const [inputKey, setInputKey] = useState(Date.now());

//   // Load default image if available
//   useEffect(() => {
//     if (defaultImageUrl) {
//       setCroppedImage(defaultImageUrl);
//     }
//   }, [defaultImageUrl]);

//   // Function to load the image and open the modal
//   const onImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const imageDataUrl = await readFile(file);
//       setImageSrc(imageDataUrl);
//       setOpenModal(true);
//       setInputKey(Date.now());
//     }
//   };

//   const cancelHandle = () => {
//     setOpenModal(false);
//     resetImageState();
//   };

//   // Reset state
//   const resetImageState = () => {
//     setImageSrc(null);
//     setCrop({ x: 0, y: 0 });
//     setZoom(1);
//     setCroppedAreaPixels(null);
//     setCroppedImage(defaultImageUrl || null);
//   };

//   const readFile = (file) => {
//     return new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.addEventListener("load", () => resolve(reader.result), false);
//       reader.readAsDataURL(file);
//     });
//   };

//   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   }, []);

//   const showCroppedImage = async () => {
//     try {
//       const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
//       setUploading(true);

//       const formData = new FormData();
//       formData.append("binary_file", croppedBlob);

//       const response = await axios.post(`${baseURL}upload-files`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       const imageUrl = response.data.url;
//       setCroppedImage(imageUrl);
//       onImageSelect(imageUrl);
//       setOpenModal(false);
//     } catch (e) {
//       console.error("Error cropping and uploading image:", e);
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div style={{ width: "100%" }}>
//       <Typography variant="h6">{label}</Typography>

//       {/* File Input */}
//       {!croppedImage && (
//         <input
//           key={inputKey}
//           accept="image/*"
//           type="file"
//           onChange={onImageChange}
//           style={{ display: "none" }}
//           id={`upload-image-${label}`}
//         />
//       )}
//       <label htmlFor={`upload-image-${label}`}>
//         {/* If an image is already set, display it */}
//         {croppedImage ? (
//           <Stack
//             position="relative"
//             sx={{
//               width: {
//                 xl: "17.5vw",
//                 lg: "17.5vw",
//                 md: "17.5vw",
//                 sm: "23.5vw",
//                 xs: "100%",
//               },
//               height: "155px",
//             }}
//           >
//             <img
//               src={croppedImage}
//               alt="Cropped"
//               style={{
//                 borderRadius: "10px",
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//               }}
//             />
//             <IconButton
//               sx={{
//                 position: "absolute",
//                 top: "2px",
//                 right: "2px",
//                 backgroundColor: themeRed,
//                 borderRadius: "50%",
//                 padding: "5px",
//               }}
//               onClick={() => resetImageState()}
//             >
//               <MdEdit style={{ color: "#fff", fontSize: "20px" }} />
//             </IconButton>
//           </Stack>
//         ) : (
//           // Placeholder when no image is available
//           <Stack
//             sx={{
//               padding: "25px 5vw",
//               borderRadius: "5px",
//               border: "1px solid #DDD",
//               width: {
//                 xl: "fit-content",
//                 lg: "fit-content",
//                 md: "fit-content",
//                 sm: "fit-content",
//                 xs: "100%",
//               },
//               cursor: "pointer",
//               position: "relative",
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//           >
//             <TbCameraPlus
//               style={{
//                 width: "100px",
//                 height: "100px",
//                 objectFit: "contain",
//                 color: "#DADADA",
//               }}
//             />
//           </Stack>
//         )}
//       </label>

//       {/* Modal for Cropper */}
//       <Dialog open={openModal} onClose={cancelHandle} maxWidth="sm" fullWidth>
//         <DialogContent>
//           {imageSrc && (
//             <div style={{ width: "100%", height: 400, position: "relative" }}>
//               <Cropper
//                 image={imageSrc}
//                 crop={crop}
//                 zoom={zoom}
//                 aspect={4 / 3}
//                 onCropChange={setCrop}
//                 onZoomChange={setZoom}
//                 onCropComplete={onCropComplete}
//               />
//             </div>
//           )}
//           <div>
//             <label>Zoom</label>
//             <Slider
//               color="error"
//               value={zoom}
//               min={1}
//               max={3}
//               step={0.1}
//               onChange={(e, zoom) => setZoom(zoom)}
//             />
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             color="error"
//             variant="contained"
//             onClick={showCroppedImage}
//             disabled={uploading}
//           >
//             {uploading ? "Uploading..." : "Save Image"}
//           </Button>
//           <Button variant="outlined" onClick={cancelHandle} color="error">
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default ImageUploader;



import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import {
  Button,
  Slider,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { TbCameraPlus } from "react-icons/tb";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { baseURL } from "../../config/apiHandle/apiHandle";
import { themeRed } from "../../utils/colorTheme";

// ✅ Convert Base64 to Blob for Upload
const dataURLtoBlob = (dataURL) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

// ✅ Crop Image with CORS Fix
const getCroppedImg = (imageSrc, crop) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous"; // Fix CORS issue
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );

      resolve(canvas.toDataURL("image/jpeg")); // ✅ Convert to Base64
    };

    image.onerror = (error) => reject(error);
  });
};

const ImageUploader = ({ label, onImageSelect, defaultImageUrl }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(defaultImageUrl || null);
  const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  // Load default image if available
  useEffect(() => {
    if (defaultImageUrl) {
      setCroppedImage(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  // Handle Image Upload and Open Cropper
  const onImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setOpenModal(true);
      setInputKey(Date.now());
    }
  };

  // Open Cropper when Clicking Edit Button
  const handleEditClick = () => {
    if (croppedImage) {
      setImageSrc(croppedImage);
      setOpenModal(true);
    }
  };

  const cancelHandle = () => {
    setOpenModal(false);
  };

  // Read Image as Base64
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    try {
      const croppedDataUrl = await getCroppedImg(imageSrc, croppedAreaPixels);
      setUploading(true);

      const formData = new FormData();
      formData.append("binary_file", dataURLtoBlob(croppedDataUrl)); // Convert Base64 to Blob

      const response = await axios.post(`${baseURL}upload-files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const imageUrl = response.data.url;
      setCroppedImage(imageUrl);
      onImageSelect(imageUrl);
      setOpenModal(false);
    } catch (e) {
      console.error("Error cropping and uploading image:", e);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h6">{label}</Typography>

      {/* File Input */}
      <input
        key={inputKey}
        accept="image/*"
        type="file"
        onChange={onImageChange}
        style={{ display: "none" }}
        id={`upload-image-${label}`}
      />

      <label htmlFor={`upload-image-${label}`}>
        {/* If an image is already set, display it */}
        {croppedImage ? (
          <Stack
            position="relative"
            sx={{
              width: {
                xl: "17.5vw",
                lg: "17.5vw",
                md: "17.5vw",
                sm: "23.5vw",
                xs: "100%",
              },
              height: "155px",
            }}
          >
            <img
              src={croppedImage}
              alt="Cropped"
              style={{
                borderRadius: "10px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: "2px",
                right: "2px",
                backgroundColor: themeRed,
                borderRadius: "50%",
                padding: "5px",
              }}
              onClick={handleEditClick} // Open cropper when clicked
            >
              <MdEdit style={{ color: "#fff", fontSize: "20px" }} />
            </IconButton>
          </Stack>
        ) : (
          // Placeholder when no image is available
          <Stack
            sx={{
              padding: "25px 5vw",
              borderRadius: "5px",
              border: "1px solid #DDD",
              width: {
                xl: "fit-content",
                lg: "fit-content",
                md: "fit-content",
                sm: "fit-content",
                xs: "100%",
              },
              cursor: "pointer",
              position: "relative",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <TbCameraPlus
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
                color: "#DADADA",
              }}
            />
          </Stack>
        )}
      </label>

      {/* Modal for Cropper */}
      <Dialog open={openModal} onClose={cancelHandle} maxWidth="sm" fullWidth>
        <DialogContent>
          {imageSrc && (
            <div style={{ width: "100%", height: 400, position: "relative" }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          )}
          <div>
            <label>Zoom</label>
            <Slider
              color="error"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={showCroppedImage}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Save Image"}
          </Button>
          <Button variant="outlined" onClick={cancelHandle} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageUploader;
